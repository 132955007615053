let allMeta = [
    {
        "count": 10000,
        "background": [
            {
                "key": "blue",
                "items": 517
            },
            {
                "key": "dark",
                "items": 572
            },
            {
                "key": "jungle",
                "items": 10
            },
            {
                "key": "lightblue",
                "items": 482
            },
            {
                "key": "lightbrown1",
                "items": 565
            },
            {
                "key": "lightbrown2",
                "items": 562
            },
            {
                "key": "lightgreen",
                "items": 606
            },
            {
                "key": "lightgrey",
                "items": 471
            },
            {
                "key": "lightorange",
                "items": 458
            },
            {
                "key": "lightpink",
                "items": 613
            },
            {
                "key": "lightred",
                "items": 641
            },
            {
                "key": "lounge",
                "items": 11
            },
            {
                "key": "mint",
                "items": 513
            },
            {
                "key": "orangeshade",
                "items": 535
            },
            {
                "key": "pink",
                "items": 591
            },
            {
                "key": "red",
                "items": 471
            },
            {
                "key": "swamp",
                "items": 593
            },
            {
                "key": "tron",
                "items": 8
            },
            {
                "key": "violet",
                "items": 612
            },
            {
                "key": "white",
                "items": 540
            },
            {
                "key": "yellow",
                "items": 629
            }
        ]
    },
    {
        "count": 10000,
        "body": [
            {
                "key": "cherry",
                "items": 569
            },
            {
                "key": "ferrari",
                "items": 543
            },
            {
                "key": "fire",
                "items": 539
            },
            {
                "key": "fire orange",
                "items": 579
            },
            {
                "key": "floral navy",
                "items": 576
            },
            {
                "key": "forest",
                "items": 572
            },
            {
                "key": "grassy cream",
                "items": 563
            },
            {
                "key": "imperial",
                "items": 599
            },
            {
                "key": "jungle flamingo",
                "items": 574
            },
            {
                "key": "maya mint",
                "items": 541
            },
            {
                "key": "peach",
                "items": 557
            },
            {
                "key": "persian",
                "items": 558
            },
            {
                "key": "redwood flamingo",
                "items": 528
            },
            {
                "key": "reflect",
                "items": 567
            },
            {
                "key": "ruby steel",
                "items": 512
            },
            {
                "key": "shadow navy",
                "items": 537
            },
            {
                "key": "snow",
                "items": 528
            },
            {
                "key": "water",
                "items": 558
            }
        ]
    },
    {
        "count": 9044,
        "cheeks": [
            {
                "key": "blue",
                "items": 956
            },
            {
                "key": "brown",
                "items": 1015
            },
            {
                "key": "cheetah",
                "items": 1031
            },
            {
                "key": "green",
                "items": 1012
            },
            {
                "key": "grey",
                "items": 989
            },
            {
                "key": "rainbow",
                "items": 1008
            },
            {
                "key": "red",
                "items": 1037
            },
            {
                "key": "silver",
                "items": 976
            },
            {
                "key": "white",
                "items": 1020
            }
        ]
    },
    {
        "count": 10000,
        "conturing": [
            {
                "key": "dark",
                "items": 10000
            }
        ]
    },
    {
        "count": 9721,
        "earrings": [
            {
                "key": "blue cone",
                "items": 253
            },
            {
                "key": "blue golden square",
                "items": 254
            },
            {
                "key": "blue pierce",
                "items": 269
            },
            {
                "key": "bluered beads",
                "items": 301
            },
            {
                "key": "brown beads",
                "items": 302
            },
            {
                "key": "browngreen cone",
                "items": 275
            },
            {
                "key": "browngreen pierce",
                "items": 274
            },
            {
                "key": "brownpurp beads",
                "items": 259
            },
            {
                "key": "brownpurp pierce",
                "items": 290
            },
            {
                "key": "cool key",
                "items": 303
            },
            {
                "key": "earring (34)",
                "items": 289
            },
            {
                "key": "flashy beads",
                "items": 305
            },
            {
                "key": "gold cross",
                "items": 285
            },
            {
                "key": "golddark rings",
                "items": 250
            },
            {
                "key": "goldsilver rings",
                "items": 283
            },
            {
                "key": "grassgreen beads",
                "items": 276
            },
            {
                "key": "green cross",
                "items": 307
            },
            {
                "key": "green pierce",
                "items": 307
            },
            {
                "key": "green ring",
                "items": 284
            },
            {
                "key": "green square",
                "items": 281
            },
            {
                "key": "greenblue beads",
                "items": 291
            },
            {
                "key": "greensilver ring",
                "items": 299
            },
            {
                "key": "icy ring",
                "items": 265
            },
            {
                "key": "ogp pin",
                "items": 276
            },
            {
                "key": "op pin",
                "items": 321
            },
            {
                "key": "purp cheetah square",
                "items": 290
            },
            {
                "key": "purp cross",
                "items": 282
            },
            {
                "key": "purpbluegreen ring",
                "items": 315
            },
            {
                "key": "red flashy beads",
                "items": 297
            },
            {
                "key": "redpurp cone",
                "items": 297
            },
            {
                "key": "rgb pin",
                "items": 309
            },
            {
                "key": "silver beads",
                "items": 270
            },
            {
                "key": "silver rings",
                "items": 285
            },
            {
                "key": "skyblue pierce",
                "items": 277
            }
        ]
    },
    {
        "count": 10000,
        "eyes": [
            {
                "key": "cute",
                "items": 1244
            },
            {
                "key": "danger",
                "items": 1236
            },
            {
                "key": "danger lash",
                "items": 1211
            },
            {
                "key": "full danger lash",
                "items": 1315
            },
            {
                "key": "liquid",
                "items": 1268
            },
            {
                "key": "melt",
                "items": 1238
            },
            {
                "key": "squeeze",
                "items": 1210
            },
            {
                "key": "tron",
                "items": 2
            },
            {
                "key": "vibrant",
                "items": 1276
            }
        ]
    },
    {
        "count": 9636,
        "feets": [
            {
                "key": "army bandages",
                "items": 372
            },
            {
                "key": "bluegreen cut bracelet",
                "items": 379
            },
            {
                "key": "bluepink cut bracelet",
                "items": 374
            },
            {
                "key": "bronze ankle bracelet",
                "items": 404
            },
            {
                "key": "bronze bracelet",
                "items": 374
            },
            {
                "key": "bronze green bracelet",
                "items": 367
            },
            {
                "key": "bronze1 fat bracelet",
                "items": 339
            },
            {
                "key": "bronze2 fat bracelet",
                "items": 386
            },
            {
                "key": "cake pat bracelet",
                "items": 354
            },
            {
                "key": "cookie pat bracelet",
                "items": 340
            },
            {
                "key": "diamondcolor cut bracelet",
                "items": 414
            },
            {
                "key": "diamondpurp cut bracelet",
                "items": 366
            },
            {
                "key": "diamondsilver cut bracelet",
                "items": 349
            },
            {
                "key": "gold bracelet",
                "items": 406
            },
            {
                "key": "gold dark bracelet",
                "items": 352
            },
            {
                "key": "gold fat bracelet",
                "items": 365
            },
            {
                "key": "goldcrystal cut bracelet",
                "items": 362
            },
            {
                "key": "greyrainbow cut bracelet",
                "items": 374
            },
            {
                "key": "junglerainbow fat bracelet",
                "items": 392
            },
            {
                "key": "metal fat bracelet",
                "items": 350
            },
            {
                "key": "muffin pat bracelet",
                "items": 365
            },
            {
                "key": "rainbow bandages",
                "items": 376
            },
            {
                "key": "rainbow jungle bracelet",
                "items": 390
            },
            {
                "key": "redyellow cut bracelet",
                "items": 368
            },
            {
                "key": "silver bracelet",
                "items": 343
            },
            {
                "key": "silver grey bracelet",
                "items": 375
            }
        ]
    },
    {
        "count": 10000,
        "forehead": [
            {
                "key": "blue",
                "items": 1095
            },
            {
                "key": "brown",
                "items": 1111
            },
            {
                "key": "cheetah",
                "items": 1127
            },
            {
                "key": "cherry",
                "items": 1076
            },
            {
                "key": "cream",
                "items": 1105
            },
            {
                "key": "green",
                "items": 1143
            },
            {
                "key": "grey",
                "items": 1135
            },
            {
                "key": "rainbow",
                "items": 1097
            },
            {
                "key": "white",
                "items": 1111
            }
        ]
    },
    {
        "count": 3292,
        "hats": [
            {
                "key": "apple fez",
                "items": 281
            },
            {
                "key": "black top",
                "items": 253
            },
            {
                "key": "blue fez",
                "items": 279
            },
            {
                "key": "crown",
                "items": 297
            },
            {
                "key": "green baseball",
                "items": 259
            },
            {
                "key": "green pointy",
                "items": 271
            },
            {
                "key": "hat (12)",
                "items": 296
            },
            {
                "key": "orange pointy",
                "items": 232
            },
            {
                "key": "pink baseball",
                "items": 282
            },
            {
                "key": "pink check",
                "items": 295
            },
            {
                "key": "tron pink",
                "items": 1
            },
            {
                "key": "tron yellow",
                "items": 1
            },
            {
                "key": "warm",
                "items": 284
            },
            {
                "key": "yellow baseball",
                "items": 261
            }
        ]
    },
    {
        "count": 8704,
        "headplate": [
            {
                "key": "black gold",
                "items": 1279
            },
            {
                "key": "gold",
                "items": 1255
            },
            {
                "key": "mirror",
                "items": 1299
            },
            {
                "key": "pallet",
                "items": 1197
            },
            {
                "key": "rainbow",
                "items": 1243
            },
            {
                "key": "rock",
                "items": 1174
            },
            {
                "key": "silver",
                "items": 1257
            }
        ]
    },
    {
        "count": 9753,
        "necklace": [
            {
                "key": "black bow",
                "items": 267
            },
            {
                "key": "blue neck handkerchief",
                "items": 243
            },
            {
                "key": "bluegreen cone",
                "items": 252
            },
            {
                "key": "bluegreen pattern",
                "items": 263
            },
            {
                "key": "blueorange pattern",
                "items": 237
            },
            {
                "key": "bluepink cone",
                "items": 270
            },
            {
                "key": "bluered pattern",
                "items": 278
            },
            {
                "key": "brown cloth",
                "items": 265
            },
            {
                "key": "brown rope",
                "items": 247
            },
            {
                "key": "cheetah rope",
                "items": 256
            },
            {
                "key": "cheetah tie",
                "items": 252
            },
            {
                "key": "diamondgold chain",
                "items": 236
            },
            {
                "key": "diamondsilver chain",
                "items": 232
            },
            {
                "key": "gold bell",
                "items": 255
            },
            {
                "key": "gold chain",
                "items": 260
            },
            {
                "key": "gold stack",
                "items": 285
            },
            {
                "key": "green muffler",
                "items": 256
            },
            {
                "key": "green neck handkerchief",
                "items": 259
            },
            {
                "key": "green tie",
                "items": 268
            },
            {
                "key": "greenpurp pattern",
                "items": 273
            },
            {
                "key": "greenvio pattern",
                "items": 260
            },
            {
                "key": "pink cloth",
                "items": 240
            },
            {
                "key": "pinkgreen cone",
                "items": 278
            },
            {
                "key": "purple muffler",
                "items": 248
            },
            {
                "key": "purpyellow pattern",
                "items": 261
            },
            {
                "key": "rainbow rope",
                "items": 265
            },
            {
                "key": "red neck handkerchief",
                "items": 261
            },
            {
                "key": "red tie",
                "items": 242
            },
            {
                "key": "redcloth",
                "items": 217
            },
            {
                "key": "samurai metal",
                "items": 278
            },
            {
                "key": "samurai red",
                "items": 247
            },
            {
                "key": "silver bell",
                "items": 273
            },
            {
                "key": "silverblack motorcycle",
                "items": 258
            },
            {
                "key": "skyblue muffler",
                "items": 241
            },
            {
                "key": "white beads",
                "items": 238
            },
            {
                "key": "whitegreen motorcycle",
                "items": 263
            },
            {
                "key": "whitepurple motorcycle",
                "items": 271
            },
            {
                "key": "whitepurplemetal motorcycle",
                "items": 258
            }
        ]
    },
    {
        "count": 10000,
        "tail": [
            {
                "key": "blackblue fat",
                "items": 426
            },
            {
                "key": "bluepurple slim",
                "items": 426
            },
            {
                "key": "browngreen fat",
                "items": 393
            },
            {
                "key": "greenblue slim",
                "items": 405
            },
            {
                "key": "greenbrown fat",
                "items": 410
            },
            {
                "key": "greenpurp cutfat",
                "items": 353
            },
            {
                "key": "greyblue cutfat",
                "items": 360
            },
            {
                "key": "greygreen cutfat",
                "items": 392
            },
            {
                "key": "greyorange cutfat",
                "items": 390
            },
            {
                "key": "maroonblack fat",
                "items": 444
            },
            {
                "key": "maroonblue fat",
                "items": 388
            },
            {
                "key": "maroonbrown fat",
                "items": 460
            },
            {
                "key": "maroonyellow coolcut",
                "items": 422
            },
            {
                "key": "orangewhite slim",
                "items": 387
            },
            {
                "key": "pinkblue coolcut",
                "items": 440
            },
            {
                "key": "rainbowgreen cutfat",
                "items": 342
            },
            {
                "key": "rainbowlight cutfat",
                "items": 352
            },
            {
                "key": "rainbowpink cutfat",
                "items": 396
            },
            {
                "key": "redblack slim",
                "items": 384
            },
            {
                "key": "redblue cutfat",
                "items": 402
            },
            {
                "key": "redwhite cutslim",
                "items": 357
            },
            {
                "key": "skyorange fat",
                "items": 421
            },
            {
                "key": "whiteblue fat",
                "items": 397
            },
            {
                "key": "whiteyellow cutslim",
                "items": 411
            },
            {
                "key": "yellowblue fat",
                "items": 442
            }
        ]
    },
    {
        "count": 10000,
        "tails": [
            {
                "key": 1,
                "items": 5357
            },
            {
                "key": 2,
                "items": 1660
            },
            {
                "key": 3,
                "items": 2983
            }
        ]
    }
]


export default allMeta
