import React, {Component} from 'react';
import api from "../../api"
import {Utils} from "../../helper"
import AuthorCard,{AuthorCardDummy} from "../../components/authorCard/index.jsx"
// import ReactCrop from 'react-image-crop';





class Following extends Component {

  constructor(props) {
      super(props)

      this.state = {
        isLoading:false,
        address:"",
        authors:[],
        selectedIds:[],
        following : [],
        page:1,
        start:1,
        limit:20,
      }
  }
  componentDidMount = () =>{
    let {user} = this.props.data
    let {following,address} = user || {}

    let {limit} = this.state
    if(following && following.length){
      let selectedIds = following.slice(0, limit);
      this.setState({selectedIds,following,address},this.fetchAuthors)
    }
  }
  fetchAuthors = async () =>{
    let{isLoading,selectedIds} = this.state;
      try {
        if(isLoading) return Utils.setToastAlert("Previous request processing","info");
        this.setState({isLoading:true})
        let payload ={authorIds:selectedIds}
        let res = await api.getAuthors(payload);
        // console.log(res);
        let{result} = res.data;
        this.setState({authors:result,isLoading:false})
      } catch (e) {
        console.log(e.response);
        Utils.setToastAlert("Failed to display following authors","error");
        this.setState({isLoading:false})
      }
  }
  renderAuthors = () =>{
    let{authors} = this.state;
    // authors = authors.concat(emptyPlace);
    return authors.map((item,i)=>{
      return <div className="col-12 col-sm-6 col-lg-4" key={item.address+1}>
        <AuthorCard data={item} key={item.address+2}/>
      </div>
    })
  }
  renderDummyAuthors = () =>{
    let count = window.innerWidth < 576?4:window.innerWidth < 992?8:window.innerWidth < 1200?12:16;
    return new Array(count).fill(undefined).map((item,i)=>{
      return <div className="col-12 col-sm-6 col-lg-4" key={i}>
        <AuthorCardDummy/>
      </div>
    })
  }
  renderPagings = () =>{
    let{following,page,start,limit} = this.state;
    let totalPage = Math.ceil(following.length /limit);
    totalPage = totalPage <= 4?totalPage:4;
    let pages = new Array(totalPage).fill(undefined).map((data,i)=>i+start);

    return pages.map((mark,i)=>{
      return <li className={page === mark?"active":""} key={i}><button key={i} onClick={()=>this.handlePaging(mark)}>{mark}</button></li>
    })
  }
  handlePaging = (val) =>{
    let{following,limit,isLoading,page} = this.state
    if(isLoading) return Utils.setToastAlert("Previous request processing","info");
    if(page === val)return console.log("on current page");
    let ini = (val * limit) - 1;
    let selectedIds = following.slice(ini, ini + limit);
    this.setState({page:val,selectedIds},this.fetchAuthors);
  }
  handlePagingArrow = (val) =>{
    let{following,page,start,limit,isLoading} = this.state;
    if(isLoading) return Utils.setToastAlert("Previous request processing","info");

    if(!following || following.length <= limit) return;
    let totalPage = Math.ceil(following.length /limit);
    let _page = page;
      if(val === "prev"){
        if(page > 1){
          _page = page-1;
          if(totalPage > 4 && start > 1){
            start = start -1;
          }
        }
      }else {
        if(page < totalPage){
          _page = page+1;
          if(totalPage > 4 && start < _page - 3){
            start = start +1;
          }
        }
      }
      if(_page !== page){
        let ini = (_page * limit) - 1;
        let selectedIds = following.slice(ini, ini + limit);
        this.setState({page:_page,start,selectedIds},this.fetchAuthors);
      }
  }
    render() {
      let{following,authors,isLoading} = this.state;

        return (
          <main className="main">
        		<div className="container">
        			<div className="row no-gutters">
        				{!isLoading && !!authors.length && this.renderAuthors()}
                {!!isLoading && this.renderDummyAuthors()}

                <div className="col-12">
                  <div className="paginator">
                    <span className="paginator__pages">{authors.length} from {following.length || 0}</span>

                    <ul className="paginator__list">
                      <li>
                        <button  onClick={()=>this.handlePagingArrow("prev")}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"/></svg>
                        </button>
                      </li>
                      {this.renderPagings()}
                      <li>
                        <button  onClick={()=>this.handlePagingArrow("next")}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"/></svg>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
        			</div>
        		</div>
        	</main>
        )
    }
}
export default Following
