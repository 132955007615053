import React, { Component } from 'react'
//import ReactTable from 'react-table'
import $ from 'jquery';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {Utils} from "../helper"
import tronWeb from "../tronweb"

import './rocks.scss';
import Banner from '../images/rock-banner.jpeg';
import SideBanner from '../images/rock-side-banner.jpeg';


const RockContract = "TPXVtc86HAPYyUVCxLWggLFjcJMj7QN9Vm";

const CreateBtn = styled.button.attrs({
  className:"sign__btn ",
  type:"button"
})``

class MintRocks extends Component{
  constructor(props) {
      super(props)
      this.state = {
        address:"",
        balance:0,
        isProcess:false,
        count:100,
        startAt:1630620000000,
        timeNow:Date.now(),
      }
  }
  componentDidMount (){
    document.title = "Mint || Rock";
    // this.fetchStartTime()

    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      const {address} = account
      if(address){
        this.setState({address,balance:0},this.fetchUserBalance)
      }else {
        this.setState({address:"",balance:0})
      }
    })


      // let timer = setInterval(function() {
      //   let timeLeft = self.state.startAt - Date.now();
      //         if (timeLeft > 0) {
      //           self.setState({timeNow:Date.now()})
      //         }else {
      //           clearInterval(timer);
      //         }
      //          }, 1000);

  }
  componentWillUnmount(){
    document.title = "Kraftly";
  }

  fetchStartTime = async() =>{
    try {
      let instance = await tronWeb.contract().at(RockContract);
      let time = await instance.startAt().call();
      time *= 1000;
      // console.log(time);
      this.setState({startAt:time});
      if(time < Date.now()){
        this.fetchRockEvents()
      }
    } catch (e) {
      console.log(e);
    }
  }
  fetchRockEvents = async() =>{
    try {
      let res = await tronWeb.getEventResult(RockContract,{size:100,eventName:"TROCKMint"});
      if(!res.length) console.log("No Trock minted");
      // console.log(res);
      this.setState({count:res.length})

    } catch (e) {
      console.log(e);
    }
  }

  fetchUserBalance = async()=>{
    let {address} = this.state
    try {
      let balance = await window.tronWeb.trx.getUnconfirmedBalance(address);
      balance = Utils.floor(balance);
      this.setState({balance})
    } catch (e) {
      console.log(e);
    }
  }
  handleMint = async() =>{
    try {
      let{address,isProcess,balance} = this.state;
      if(!address)return $(".header__action-btn--signin").click();;
      if(isProcess) return Utils.setToastAlert("Previous request processing");
      balance = Utils.floor(balance);

      if(balance < 2000 * 1e6)return Utils.setToastAlert("Not sufficient TRX balance");
      // this.setState({isProcess:true})
      let instance = await window.tronWeb.contract().at(RockContract);
      await instance.mintTROCK(address).send({
        feeLimit:100000000,
        callValue: 2000 * 1e6,
      });
      this.setState({isProcess:false},this.fetchUserBalance);
      this.fetchRockEvents()

      Utils.setToastAlert('TROCK succesfully minted','success')

    } catch (e) {
      console.log(e);
      this.setState({isLoading:false});
      if (e === 'Confirmation declined by user') {
        Utils.setToastAlert('Confirmation declined','warning')
      }else {
        // console.log(e);
        Utils.setToastAlert("Something went wrong while minting rock",'error')
      }
    }
  }

  render(){
    let {address,balance,count,startAt} = this.state
    let timeLeft = startAt - Date.now();

    let btnState = timeLeft > 0 || count === 100;

    let btnName = timeLeft > 0? Utils.getTimeOffset(startAt/1000): count === 100 ? "All TROCK Minted":"Mint TROCK"
    return(
      <main className="main">
          <div className="neo-banner">
            <ul className="neo-menu">
              <li><Link to="/trocks/mint" className="neo-active">Mint</Link></li>
              <li><Link to="/trocks/gallery">Gallery</Link></li>
            </ul>
            <img src={Banner} alt="" />
          </div>

    		<div className="container">
    				<div className="col-12">
    					<div className="neo-body">
                <div className="row">
                  <div className="col-12">
                    <p className="neo-mint-count">{count} out of 100 minted</p>
                  </div>
    						<div className="col-12 col-md-8">

                  <div className="sign__group">
                    <label className="sign__label">Your Wallet</label>
                    <input
                      type="text"
                      className="sign__input"
                      placeholder="Please connect your wallet"
                      value={address}
                      readOnly
                      />
                  </div>
                  <div className="sign__group">
                    <label className="sign__label">Your Balance</label>
                    <input
                      type="text"
                      className="sign__input"
                      value={Utils.preety.floor(balance/1e6,1)+" TRX"}
                      readOnly
                      />
                  </div>
                  <div className="sign__group">
                    <label className="sign__label price-note">NOTE</label>
                    <p>You need 2,000 TRX to mint 1 TROCK. If you do not have enough TRX please refill your wallet with TRX and reload this page.</p>
                  </div>
                    <CreateBtn
                      onClick={this.handleMint}
                      disabled={btnState}
                      >
                      {btnName}
                    </CreateBtn>
    						</div>
                <div className="col-12 col-md-4">
                  <div className="neo-mint-body-right">
                    <div className="neo-mint-body-right-content">
                      <img src={SideBanner} alt="" />
                      <h2>You get a random "TROCK"</h2>
                      <p>When you mint a TROCK, you get a random TROCK</p>
                    </div>
                  </div>
    						</div>
    					</div>
    				</div>

    			</div>
    		</div>
    	</main>
    )
  }
}

export default MintRocks;
