import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {Utils} from "../helper"
import tronWeb from "../tronweb"
import api from "../api"

import {NftNeoCardDummy,NftNeoCard} from "../components/nftCard/index.jsx"
import {Paginator,MenuSelect3,GridToggler2} from "../frag"
import Banner from '../images/tech-memory-banner.jpg';
const NeoContract = "TMUqAyK9GUkXLsVd46Vk9Z3DTKrkzrbPnk";




class NeoGallery extends Component{
  constructor(props) {
      super(props)
      let limit = window.innerWidth < 768?8:12;
      const urlParams = new URLSearchParams(window.location.search);
      let page = urlParams.get('page');
      page = Utils.floor(page)
      page = page && page > 0?page:1;
      let start = page > 4?page -3:1;

      this.state = {
        isLoading:false,
        count:0,
        page,
        start,
        limit,
        nfts:[],
        category:"all",
        series:"techMemory",
        filterString:"",
        floorPrice:0,
        volume:0,
        transaction:0,

        isGrid:localStorage.getItem('isGrid') === "true",
      }
      this.myRef = React.createRef();

  }
  componentDidMount (){
    document.title = "Gallery || Tech Memories";
    this.fetchNfts()
    // this.fetchNeoState()
  }
  componentWillUnmount(){
    document.title = "Kraftly";
  }
  fetchNeoState = async( ) =>{
    try {
      let result = await api.getNeoState({collection:"trocks"});
      // console.log(result);
      let{floorPrice,volume,transaction} = result.data.result[0];
      this.setState({floorPrice,volume,transaction})
    } catch (e) {
        console.log(e);
        console.log(e.response);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let{page} = this.state;
      const urlParams = new URLSearchParams(window.location.search);
      let _page = urlParams.get('page');
      _page = Utils.floor(_page);
      if(_page !== page){
        page = _page && _page > 0?_page:1;
        let start = page > 4?page -3:1;
        this.setState({page:_page,start},this.fetchNfts)
      }
     }

  fetchNfts = async()=>{
    let{page,limit,series,category,filterString} = this.state;
      try {
        this.setState({isLoading:true})
        let payload = {page,limit,series,category,filterString}

        let res = await api.getNeoMarket(payload);
        // console.log(res);
        let{result,count} = res.data;
        count = count || 0;
        this.setState({nfts:result,count,isLoading:false},()=>{
          this.updatePagingUrl()
          this.fetchNeoMinter()
        })
      } catch (e) {
        let msg = e.response?.data?.message??"No NFT found";
        Utils.setToastAlert(msg,"error");
        // console.log(e.response);
        this.setState({isLoading:false})
      }
  }
  fetchFilterData = async(event) =>{
      event.preventDefault()

      let{isLoading} = this.state;
      if(isLoading) return Utils.setToastAlert("Previous request processing","info");

      this.setState({nfts:[],category:"all",page:1,start:1,count:0},()=>{
        this.fetchNfts()
      })
  }
   updatePagingUrl = () =>{
     let{page} = this.state;
     const urlParams = new URLSearchParams(window.location.search);
     urlParams.set("page", page)
     let params = urlParams.toString();
     if (window.history.pushState) {
       let url = `${window.location.pathname}?${params}`;
       window.history.pushState(null, null,url);
     }
   }
   fetchNeoMinter = async()=>{
     let{nfts} = this.state;
       try {
         this.setState({isLoading:true})
         let tokenIds = nfts.map(i => i.tokenId);
         let instance = await tronWeb.contract().at(NeoContract);
         let minters = await instance.mintersOf(tokenIds).call();

         let result = nfts.map((item,i) => {
           let minter = tronWeb.address.fromHex(minters[i])
           // console.log(minter);
           if(minter !== "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"){
             item.minter = minter;
           }
           return item;
         });

         this.setState({nfts:result,isLoading:false})
       } catch (e) {
         console.log(e);
         this.setState({isLoading:false})

       }
   }

   renderNft = () =>{
     let{nfts,isGrid} = this.state;

     return nfts.map((data,i)=>{
       data.price = 1850 * 1e6;

       if(!!isGrid){
         return <div className="col-6 col-sm-4 col-lg-3 col-xl-2" key={data.tokenId+"smallA"}>
           <NftNeoCard data={data} isMobile={true} key={data.tokenId+"smallB"}/>
         </div>
       }
       return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={data.tokenId+"largeA"}>
         {<NftNeoCard data={data} key={data.tokenId+"largeB"}/>}
       </div>
     })
   }
   renderDummyNft = () =>{
     let{isGrid} = this.state;

     let _w = window.innerWidth;
     let count = _w< 576?4:_w< 768?8:_w< 992?12:_w< 1200?15:16;
     if(isGrid){
       count = _w< 576?8:_w< 768?12:_w< 992?15:_w< 1200?16:18;
     }
       return new Array(count).fill(undefined).map((item,i)=>{

         if(!!isGrid){
           return <div className="col-6 col-sm-4 col-lg-3 col-xl-2" key={"smallAD"+i}>
             <NftNeoCardDummy isMobile={true} key={"smallB"+i}/>
           </div>
         }

         return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={"largeAD"+i}>
           <NftNeoCardDummy key={"largeB"+i}/>
         </div>
       })
   }

  handleInput = e =>{
    let val = e.target.value;
    if (!val || val.length <= 40) {
      this.setState({filterString:val})
    }
  }
  handlePaginator = (value) =>{
    this.setState(value,this.fetchNfts)
    this.myRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  onOption = (name,value)=>{
    let{isLoading} = this.state
    if(isLoading) return Utils.setToastAlert("Previous request processing","info");
    if(this.state[name] === value) return console.log("same option");
    this.setState({[name]:value,nfts:[],count:0,filterString:""},this.fetchNfts)
  }

  render(){
    let {count,start,limit,page,nfts,isLoading,category,filterString,isGrid} = this.state
    let categoryOptions = {all:"All Tech Memories",onAuction:"On Auction",now:"Buy Now",hasOffers:"Has Offers",new:"Newest Auction",low:"Price: Low to high",high:"Price: High to low"};

    return(
      <main className="main">
          <div className="neo-banner">
            <ul className="neo-menu">
              <li><Link to="/tech-memory/mint">Mint</Link></li>
              <li><Link to="/tech-memory/gallery" className="neo-active">Gallery</Link></li>
            </ul>
            <img src={Banner} alt="" />
          </div>

          <div className="container" ref={this.myRef}>
            <div className="row no-gutters">
            <div className="col-12">
              <div className="ik__home__filter">
                <form onSubmit={this.fetchFilterData} className="main__filter-search">
                  <input
                    type="text"
                    placeholder="Search..."
                    value = {filterString}
                    onChange={(e)=>this.setState({filterString:e.target.value})}
                    />
                  <button
                    type="button"
                    onClick={this.fetchFilterData}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
                  </button>
                </form>
                <MenuSelect3 options={categoryOptions} value={category} onOption={(e)=>this.onOption("category",e)}/>

                <GridToggler2
                  data={{isGrid}}
                  handleGrid={(e)=>this.setState(e)}
                  />

              </div>
            </div>
      				<div className="col-12">
                <div className="row no-gutters">
                  {!isLoading && !!nfts.length && this.renderNft()}
                  {!!isLoading  && this.renderDummyNft()}
                  {!isLoading  && !nfts.length && <p>No result found</p>}
          			</div>
      			</div>
            <div className="col-12">
              <Paginator  data={{count,start,limit,page,size:nfts.length}} handler={this.handlePaginator}/>
            </div>
            </div>
      		</div>
    	</main>
    )
  }
}



export default NeoGallery;
