

import React, {Component} from 'react'
import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import { RectAreaLightUniformsLib }  from 'three/examples/jsm/lights/RectAreaLightUniformsLib';
import {ReactComponent as ScreenRotate} from '../../images/screen-rotate.svg';

import Helper from "./helper"
import BackScene from './files/scene1.png';
import './style.scss'

class CertView extends Component{

  constructor(props) {
    super(props)

    let _tokenId = props.match.params.tokenId || 0;
    _tokenId = !isNaN(_tokenId) && _tokenId >= 0 && _tokenId <100?_tokenId:0;
    _tokenId = _tokenId > 9?_tokenId.toString():"0"+_tokenId;

    this.state = {
      landscape:false,
      tokenId:_tokenId,
    }


    this.camera = null;
    this.scene = null;
    this.renderer = null;
  }


componentDidMount(){
  this.drwaCert()
}

 drwaCert = () =>{
  let{landscape,tokenId} = this.state;

  let width =  window.innerWidth;
  let height =  window.innerWidth >= 768?window.innerHeight - 70:window.innerHeight - 60;
  let _maxHeight = !landscape?2291/3248*width:3248/2291*width;
  height = height <= _maxHeight?height:_maxHeight;

  this.camera = new THREE.PerspectiveCamera( 45, width / height, 0.01, 1000 );
  this.camera.position.set( 275, -272.8, 300 );
  this.camera.lookAt(new THREE.Vector3(0,0,0));

  this.renderer = new THREE.WebGLRenderer({antialias:true,preserveDrawingBuffer:true,alpha: true});
  this.renderer.setSize( width, height );
  this.renderer.setPixelRatio( window.devicePixelRatio );

  const controls = new OrbitControls( this.camera, this.renderer.domElement );

  controls.target = new THREE.Vector3(0, 0, 0); // what
  controls.enableDamping = true
  controls.dampingFactor = .01
  controls.enablePan = false;
  controls.enableZoom  = false;


  controls.mouseButtons = {
      LEFT: THREE.MOUSE.ROTATE,
      RIGHT: THREE.MOUSE.ROTATE,
  }
  controls.screenSpacePanning = true
  controls.update()




  this.scene = new THREE.Scene();
  const sceneTexture1 = new THREE.TextureLoader().load(BackScene);
  sceneTexture1.wrapS = THREE.RepeatWrapping;
  sceneTexture1.wrapT = THREE.RepeatWrapping;
  this.scene.background = sceneTexture1;
  if(landscape &&  window.innerWidth <=768){
    this.scene.rotation.z = -90*Math.PI/180;
    this.scene.background.rotation = -90*Math.PI/180;
  }else {
    this.scene.rotation.z = 0;
    this.scene.background.rotation = 0;
  }

  const pivot = new THREE.Group();
  pivot.add( Helper.glassSlab());
  Helper.baseLayer(pivot,tokenId,width)
  pivot.add( Helper.backFacePlate());
  Helper.layerText(pivot,"KRAFTLY",6.4,1,-0.37);
  Helper.layerText(pivot,tokenId,2,-2,-0.37);

/////////////////////////////////////////////////////////////


  ////////////////////////////////////////////////////////

  const light = new THREE.HemisphereLight( 0x9a9a9a,0x000000, .1 );
  light.position.set(0, 50, 0);
  light.castShadow = true; // default false
  this.scene.add(light);

  const fronFixedLight = new THREE.DirectionalLight(0xd5d5d5, 1.2);
  fronFixedLight.position.set(0, -12, 25).normalize();
  fronFixedLight.castShadow = true; // default false
  pivot.add(fronFixedLight);

  const BackFixedLight = new THREE.DirectionalLight(0x9a9a9a, 1);
  BackFixedLight.position.set(0, 12, -20).normalize();
  BackFixedLight.castShadow = true; // default false
  pivot.add(BackFixedLight);

  const light5 = new THREE.AmbientLight( 0xffffff,0.1); // soft white light
  this.scene.add( light5 );


  RectAreaLightUniformsLib.init()
  const rectLight = new THREE.RectAreaLight( 0x9a9a9a, 5, 2,40 );
  rectLight.position.set( -10, 5, 15 );
  rectLight.matrixAutoUpdate = true;
  rectLight.lookAt(300, 0, 0 );
  pivot.add( rectLight )



  const pivot2 = new THREE.Group();
  pivot.rotation.x = -15*Math.PI/180;
  pivot2.rotation.y = 270*Math.PI/180;

  pivot2.add( pivot );
  this.scene.add( pivot2 );

  let self = this;

  function animation( time ) {
    self.renderer.clear()
    // requestAnimationFrame(animation)

    let z = Math.round(self.camera.position.z);
    let y = self.camera.position.y;
    let x = self.camera.position.x;
    if(!landscape && z > 30){
      self.camera.position.set(x-2,y+2.2,z-2);
      pivot2.rotation.y += 3.4*Math.PI/180;
    }if(!!landscape && z > 40){
      // console.log(x,y,z);
      // 4.1 -8.34 42
      // this.camera.position.set( 275, -272.8, 300 );
      self.camera.position.set(x-2.1,y+2.05,z-1.93);
      pivot2.rotation.y += 3.4*Math.PI/180;
    }else if (!!landscape) {

      pivot2.rotation.y += 0.5*Math.PI/180;
      controls.maxAzimuthAngle = 120*Math.PI/180 // min rotation along y-axis
      controls.minAzimuthAngle = -120*Math.PI/180 // min rotation along y-axis
    }else {
      pivot2.rotation.y += 0.5*Math.PI/180;
      controls.minPolarAngle = Math.PI / 2 // min rotation along x-axis
      controls.maxPolarAngle = Math.PI / 1 // max rotation along x-axis
    }
    controls.update();
    self.renderer.render( self.scene, self.camera );


  }

  this.renderer.setAnimationLoop(animation);

  window.addEventListener('resize', function(){
    let width =  window.innerWidth;
    let height =  window.innerWidth >= 768?window.innerHeight - 70:window.innerHeight - 60;
    let _maxHeight = !landscape || window.innerWidth > 768?2291/3248*width:3248/2291*width;
    height = height <= _maxHeight?height:_maxHeight;
    self.renderer.setSize ( width, height );
    self.camera.aspect = width / height ;
    self.camera.updateProjectionMatrix();

    if(landscape &&  window.innerWidth <=768){
      self.scene.rotation.z = -90*Math.PI/180;
      self.scene.background.rotation = -90*Math.PI/180;
    }else {
      self.scene.rotation.z = 0;
      self.scene.background.rotation = 0;
    }

    controls.update()
  });
  const panel = document.querySelector(".cert_generator");
  panel.innerHTML = "";
  panel.appendChild( this.renderer.domElement );

}


   setUpOrientation = () =>{
    if(this.scene){
      for( let i =0; i < this.scene.children.length; i++) {
        let obj = this.scene.children[i];
        this.scene.remove(obj);
      }
    }
    if(this.renderer){
      this.renderer.setAnimationLoop(null);
    }

    this.renderer = null;
    this.camera = null;
    this.scene = null;
    this.setState({landscape:!this.state.landscape},this.drwaCert)

  }



  render(){
    let{landscape} = this.state;

    return (
      <div className="webgl__model">
        <span
          className={`${landscape && window.innerWidth <=768?"landscape":""}`}
          onClick={this.setUpOrientation}
          ><ScreenRotate/></span>

        <div className="cert_generator"></div>
      </div>
    )
  }
}


export default CertView;
