import React from 'react'
// import styled from 'styled-components'
// import {Utils} from "../../helper"


class NftFile extends React.Component {
  renderFile = () =>{
    let {imgData} = this.props.data;
    let ext = imgData.name.split(".");
    ext = ext[ext.length - 1].toLowerCase();
    let _url = URL.createObjectURL(imgData)

    if(ext === "jpg" || ext === "gif" || ext === "png"){
      return <img src={_url} alt="" />
    }else if(ext === "mp4"){
      return <video src={_url} controls muted autoPlay loop>
        browser not supported
      </video>
    }else if(ext === "mp3"){
      return <audio src={_url} controls muted autoPlay loop>
        browser not supported
      </audio>
    }
  }
  render() {
    let {imgData} = this.props.data;
    return(
      <div className="create__page__preview">
        <div className="create__preview__box">
          <div className="create__preview__box__containt">
            {!imgData && <p>Upload file to preview your brand new NFT look</p>}
            {!!imgData && this.renderFile()}
          </div>
        </div>
      </div>
    )
  }
}
export default NftFile
