import { createCanvas, loadImage,parseFont } from 'canvas'

import * as THREE from 'three';
// import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader';
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry';

import BaseFile from './files/base1.jpg';
import BackTexture from './files/bg.jpg';
import TextTextureLight from './files/texture-light.jpg';
import TextTextureDark from './files/texture-dark.jpg';
import BackFont from './fonts/inter.json';



const glassSlab = () =>{
  const geometry = new THREE.BoxGeometry( 21, 15, 0.5 );
  const material = new THREE.MeshPhysicalMaterial({
    color: 0xffffff ,
    transmission: 1,// transparent property
    reflectivity:0.2, //
    clearcoat:1,
    opacity: 1,
    metalness: 0,
    roughness: 0,
    ior: 1.52, // index of reflection
    thickness: 0.1,
    specularIntensity: 1,
    specularColor: 0xffffff  ,
  });
  return new THREE.Mesh( geometry, material );
}

const baseLayer = async(pivot,tokenId = "01") =>{
  try {
    let baseFile =  await loadImage(BaseFile);
    let height = baseFile.height;
    let width = baseFile.width;
    // console.log(height,width);
    parseFont('./fonts/MYRIADPRO-REGULAR.OTF', { family: 'Myriad Pro' })
    const canvas = createCanvas(width,height);

    const ctx = canvas.getContext('2d');
    // ctx.imageSmoothingEnabled = false;
    ctx.drawImage(baseFile,0,0,width,height);

    // ctx.font = "215px Myriad Pro";
    ctx.fillStyle = "#a88433";
    // ctx.fillText(tokenId, 2230, 1230);
    ctx.font = "74px Myriad Pro";
    ctx.fillText(tokenId, 768, 424);


    const geometry = new THREE.PlaneGeometry(21, 15);;
    const material = new THREE.MeshStandardMaterial({
      roughness: 0,
      map:new THREE.CanvasTexture(canvas)
    });

    let mesh = new THREE.Mesh( geometry, material );
    mesh.position.set(0,0,-0.25)
    pivot.add(mesh);

  } catch (e) {
    console.log(e);
  }
}


const backFacePlate = () =>{
  const loader = new THREE.TextureLoader();
  const geometry = new THREE.PlaneGeometry(21, 15);
  const material = new THREE.MeshStandardMaterial({
    roughness: 1,
    map: loader.load(BackTexture),
  });

  const mesh = new THREE.Mesh( geometry, material );
  mesh.position.set(0,0,-0.261)
  mesh.rotation.y = Math.PI;
  return mesh;
}

const layerText = (pivot,text = "KRAFTLY",x = 6.4,y = -1,z = -0.37) =>{
  try {
    let mesh;
    const material = new THREE.MeshStandardMaterial({
      roughness: 1,
      map: new THREE.TextureLoader().load(TextTextureLight),
    });

    const texture = new THREE.TextureLoader().load(TextTextureDark);
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(0.45,0.45);

    const material2 = new THREE.MeshStandardMaterial({
      roughness: 1,
      map: texture,
    });
    const loader = new FontLoader().parse(BackFont);
    const geometry = new TextGeometry(text, {
      font: loader,
      size: 2,
      height: .1,
      bevelEnabled: true,
      bevelThickness: 0.04,
      bevelSize: .08,
      extrudeMaterial: 1,
    } );
    mesh = new THREE.Mesh( geometry, [material,material2] );

    mesh.casteShadow = true;
    mesh.rotation.y = Math.PI;
    mesh.position.set(x,y,z);
    pivot.add(mesh);

    // loader.load(BackFont,function(font){
    //
    // });

  } catch (e) {
    console.log("here");
    console.log(e);
  }
}


const Data = {
  glassSlab,
  baseLayer,
  backFacePlate,
  layerText
}


export default Data
