import React,{ Component,Fragment } from 'react'
// import { Link } from 'react-router-dom'
// import {Utils} from "../../helper"
import {ReactComponent as Question} from '../../images/question2.svg';
import {DpViewer} from "../../helper/file-viewer.jsx"
import {Utils,PopUp} from "../../helper"
import {ReactComponent as PlusIcon} from '../../images/plus.svg';
import { Link } from 'react-router-dom'

const Title = (props) =>{
  let{preview,updateId} = props.data;
 return <div className="section__title section__title__middle">
   {!preview?`${!updateId?"Create":"Update"} collectible item`:"Preview details"}
 </div>;
}

const SignGroup = (props) =>{
  return <div className={props.small?"col-12 col-sm-6 col-md-12 col-lg-6":"col-12"}>
    <div className="create__group">
      {props.children}
    </div>
  </div>
}

const InputLabel = (props) =>{
  let{htmlFor,title,tooltip} = props
  return(
    <label className="create__label" htmlFor={htmlFor}>
    <p>{title}</p>
    <Question />
    <div className="tooltip">
      <p className="tooltip__text">{tooltip}</p>
    </div>
    </label>
  )
}

export{
 Title,
 SignGroup,
 InputLabel,
}
