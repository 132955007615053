import React, { Component,Fragment } from 'react'
import { Link } from 'react-router-dom'
import $ from "jquery";

import {Utils,PopUp} from "../../../helper"
import {DpViewer} from "../../../helper/file-viewer.jsx"
import  {CollectionMain} from "../../../mintable-collection/parts"

import api from "../../../api"
import './style.scss';

import {ReactComponent as Verified} from '../../../images/verified_star.svg';
import {ReactComponent as Blocked} from '../../../images/banned.svg';
import {ReactComponent as Pen} from '../../../images/pen.svg';
import {ReactComponent as SelectFile} from '../../../images/upload-white.svg';
import {ReactComponent as ArrowDown} from '../../../images/arrow-left.svg';



const CollectionMetaDummy = (props)=>{

  return(
    <div className="profile__display dummy_display">
      <div className="profile__display__body">

        <div className="row no-gutters">
          <div className="col-12 col-sm-6 col-md-7 col-lg-12">

            <div className="display__avatar__main">
              <div className="display__avatar">
                <div className="display__avatar__body"><DpViewer/></div>
              </div>
            </div>


            <div className="profile__display__meta">
              <div className="profile__display__name">&nbsp;</div>

              <div className="profile__display__author">
                By <span>&nbsp;</span>
              </div>

              <div className="profile__display__author">
                TRC721 <span>&nbsp;</span>
              </div>

              <div className="profile__display__desc">&nbsp;</div>

              <div className="profile__display__social">
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-5 col-lg-12">
            <div className="display__body__mint__info">
              <h2 className="display__body__info__title"> Mint Info</h2>
              <div className="display__body__info__each">
                <p>Status</p>:<span><div>&nbsp;</div></span>
              </div>
              <div className="display__body__info__each">
                <p>Mint</p>:<span><div>&nbsp;</div></span>
              </div>
              <div className="display__body__info__each">
                <p>Price</p>:<span><div>&nbsp;</div></span>
              </div>
              <div className="display__body__info__each">
                <p>Type</p>:<span><div>&nbsp;</div></span>
              </div>
              <div className="display__body__info__each">
                <p>Royalty</p>:<span><div>&nbsp;</div></span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

class CollectionMeta extends Component {
  constructor(props) {
      super(props)

      this.state = {
        isOpen:false,
        isProcess:false,
        imgData:'',
        nName:"",
        nDescription:"",
        nSocials:{},

        viewDesc:false,
      }
      this.myRef = React.createRef();

  }


  componentDidMount (){
    let{name,description,socials} = this?.props?.data?.collection??{};
    socials =  socials || {};
    let nSocials = JSON.parse(JSON.stringify(socials));
    this.setState({nSocials,nName:name,nDescription:description},this.setupOnLoad)
    window.addEventListener('resize', this.setupOnLoad, {passive: true});

  }
  componentWillUnmount(){
    window.removeEventListener('resize', this.setupOnLoad, {passive: true});
  }

  setupOnLoad = () =>{
    let{description,name,verified} = this?.props?.data?.collection??{};
    name = name || "";
    description = description || "";
    if(!name) return;

    let _width = this.myRef.current.offsetWidth;
    let _nameWidth = !!verified? _width - 25:_width;

    // Adjust name font
    let namContext = document.createElement('canvas').getContext('2d');
    namContext.font = "600 24px Inter";
    let fontSize = Math.floor(_nameWidth*24/namContext.measureText(name).width);
    $(".profile__display__name").css({"font-size":fontSize > 24?24:fontSize < 16?16:fontSize})


    //description show more/less btn toggler
    let desContext = document.createElement('canvas').getContext('2d');
    desContext.font = "400 14px Inter";
    let _dwidth = Math.ceil(desContext.measureText(description).width);
    _dwidth = _dwidth > _width?"show":"hide";
    $(".profile__display__desc button")[_dwidth]()

  }


  handleInput = event =>{
    let {nSocials} = this.state

    const name = event.target.name;
    let value = event.target.value;
    if(name === 'nDescription' || name === 'nName'){
      this.setState({[name]:value})
    }else {
      nSocials[name] = value;
      this.setState({nSocials})
    }
  }


  handleFile = async(event) =>{


    try {

      let{isProcess} = this.state;
      let{author,collectionId} = this?.props?.data?.collection??{};
      let address = Utils.viewCreator();

      if(isProcess) return Utils.setToastAlert("Another request is in process");;
      let file = event.target.files[0];
      // console.log(file);
      if(!file || !file.type){
        return Utils.setToastAlert("File not selected");
      }

      let ext = file.name.split(".");
      ext = ext[ext.length - 1].toLowerCase();

      const file_ext = ["jpg","jpeg","gif","png"];

      if (!file_ext.includes(ext)) {
        return Utils.setToastAlert(`Can not upload .${ext}, Only support .jpg .jpge .png .gif files type`);
      }

      if (file && file.size > 2*1024*1024) { //2MB
        return Utils.setToastAlert("Profile size to large, try uploading file less than 2MB");
      }
      //to display image on client
      this.setState({imgData:file});


      if(!address || author !== address) return Utils.setToastAlert("Only author can change profile");


      this.setState({isProcess:true})
      const payload = new FormData()
      payload.append('address', address)
      payload.append('collectionId', collectionId)
      payload.append('fileType', "dp")
      payload.append('img', file)
      await api.uploadCollectionFile(payload);
      this.setState({isProcess:false,imgData:null},this.props.handler)
      Utils.setToastAlert("Profile successfully changed","success");

    } catch (e) {
      // console.log(e.response);
      this.setState({imgData:null,isProcess:false});
      let msg = e?.response?.data?.message??"Failed to upload profile";
      // console.log(msg);
      Utils.setToastAlert(msg,"error");
    }
  }



  onClose = ()=>{
    let {isProcess} = this.state
    if(isProcess) return Utils.setToastAlert("Previous request processing");
    this.setState({isOpen:false})
  }

  handleDataUpload = async(event) =>{
    try {
      event.preventDefault();

      let {nSocials,nName,nDescription,isProcess} = this.state
      let{author,name,description,socials,collectionId} = this?.props?.data?.collection??{};

      let address = Utils.viewCreator();
      if(!address)return $(".header__action-btn--signin").click();
      if(author !== address) return Utils.setToastAlert("Only author can change profile");

      if(nDescription && !Utils.validateDescription(nDescription)) return Utils.setToastAlert("Invalid description format");
      if(nName && !Utils.validateName(nName,3,30)) return Utils.setToastAlert("Invalid full name format");

      let{website,twitter,telegram,instagram,medium} = nSocials;
      website = website || "";
      twitter = twitter || "";
      telegram = telegram || "";
      instagram = instagram || "";
      medium = medium || "";

      if(website && (website.length > 300 || website.length < 4)) return Utils.setToastAlert("Website url size should be 4-300 long");
      if(twitter && !Utils.validateSocial(twitter)) return Utils.setToastAlert("Twitter username size should be 5-100 long");
      if(telegram && !Utils.validateSocial(telegram)) return Utils.setToastAlert("Telegram username size should be 5-100 long");
      if(instagram && !Utils.validateSocial(instagram)) return Utils.setToastAlert("Instagram username size should be 5-100 long");
      if(medium && !Utils.validateSocial(medium)) return Utils.setToastAlert("Medium username size should be 5-100 long");

      let _change = JSON.stringify(nSocials) !== JSON.stringify(socials) || nName !== name || nDescription !== description;

      if(!_change) return Utils.setToastAlert("No new input found");
      if(isProcess) return Utils.setToastAlert("Previous request processing");
      //
      this.setState({isProcess:true})
      //
      let payload = {collectionId,address,socials:nSocials,name:nName,description:nDescription};
      // console.log(payload);
      await api.editIkCollectionMeta(payload);
      // // console.log(res);
      this.setState({isProcess:false,isOpen:false},this.props.handler)
      Utils.setToastAlert("Profile updated successfully","success");

    } catch (e) {
      console.log(e);
      let msg = e.response?.data?.message??"Failed to update collection profile";
      // console.log(msg);
      Utils.setToastAlert(msg,"error");
      this.setState({isProcess:false})
    }
  }




   renderSocials = () =>{
    let{socials} = this?.props?.data?.collection??{};
    socials = !!socials && !!Object.keys(socials).length?socials:{
      "website":"",
      "twitter":"",
      "telegram":"",
      "instagram":"",
      "medium":"",
    }

    return Object.entries(socials).map(([k,v],i)=>{
      if(k === "website"){
          return<a href={`https://${v}`} target="_blank" rel="noopener noreferrer" key={k}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.41,8.64s0,0,0-.05a10,10,0,0,0-18.78,0s0,0,0,.05a9.86,9.86,0,0,0,0,6.72s0,0,0,.05a10,10,0,0,0,18.78,0s0,0,0-.05a9.86,9.86,0,0,0,0-6.72ZM4.26,14a7.82,7.82,0,0,1,0-4H6.12a16.73,16.73,0,0,0,0,4Zm.82,2h1.4a12.15,12.15,0,0,0,1,2.57A8,8,0,0,1,5.08,16Zm1.4-8H5.08A8,8,0,0,1,7.45,5.43,12.15,12.15,0,0,0,6.48,8ZM11,19.7A6.34,6.34,0,0,1,8.57,16H11ZM11,14H8.14a14.36,14.36,0,0,1,0-4H11Zm0-6H8.57A6.34,6.34,0,0,1,11,4.3Zm7.92,0h-1.4a12.15,12.15,0,0,0-1-2.57A8,8,0,0,1,18.92,8ZM13,4.3A6.34,6.34,0,0,1,15.43,8H13Zm0,15.4V16h2.43A6.34,6.34,0,0,1,13,19.7ZM15.86,14H13V10h2.86a14.36,14.36,0,0,1,0,4Zm.69,4.57a12.15,12.15,0,0,0,1-2.57h1.4A8,8,0,0,1,16.55,18.57ZM19.74,14H17.88A16.16,16.16,0,0,0,18,12a16.28,16.28,0,0,0-.12-2h1.86a7.82,7.82,0,0,1,0,4Z"/></svg></a>
      }
      if(k === "twitter"){

        return <a href={`https://twitter.com/${v}`} target="_blank" rel="noopener noreferrer" key={k}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z"/></svg></a>
      }
      if(k === "telegram"){
        return <a href={`https://t.me/${v}`} target="_blank" rel="noopener noreferrer" key={k}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.99432,2a10,10,0,1,0,10,10A9.99917,9.99917,0,0,0,11.99432,2Zm3.17951,15.15247a.70547.70547,0,0,1-1.002.3515l-2.71467-2.10938L9.71484,17.002a.29969.29969,0,0,1-.285.03894l.334-2.98846.01069.00848.00683-.059s4.885-4.44751,5.084-4.637c.20147-.189.135-.23.135-.23.01147-.23053-.36152,0-.36152,0L8.16632,13.299l-2.69549-.918s-.414-.1485-.453-.475c-.041-.324.46649-.5.46649-.5l10.717-4.25751s.881-.39252.881.25751Z"/></svg></a>
      }
      if(k === "instagram"){
        return <a href={`https://www.instagram.com/${v}`} target="_blank" rel="noopener noreferrer" key={k}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.34,5.46h0a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,17.34,5.46Zm4.6,2.42a7.59,7.59,0,0,0-.46-2.43,4.94,4.94,0,0,0-1.16-1.77,4.7,4.7,0,0,0-1.77-1.15,7.3,7.3,0,0,0-2.43-.47C15.06,2,14.72,2,12,2s-3.06,0-4.12.06a7.3,7.3,0,0,0-2.43.47A4.78,4.78,0,0,0,3.68,3.68,4.7,4.7,0,0,0,2.53,5.45a7.3,7.3,0,0,0-.47,2.43C2,8.94,2,9.28,2,12s0,3.06.06,4.12a7.3,7.3,0,0,0,.47,2.43,4.7,4.7,0,0,0,1.15,1.77,4.78,4.78,0,0,0,1.77,1.15,7.3,7.3,0,0,0,2.43.47C8.94,22,9.28,22,12,22s3.06,0,4.12-.06a7.3,7.3,0,0,0,2.43-.47,4.7,4.7,0,0,0,1.77-1.15,4.85,4.85,0,0,0,1.16-1.77,7.59,7.59,0,0,0,.46-2.43c0-1.06.06-1.4.06-4.12S22,8.94,21.94,7.88ZM20.14,16a5.61,5.61,0,0,1-.34,1.86,3.06,3.06,0,0,1-.75,1.15,3.19,3.19,0,0,1-1.15.75,5.61,5.61,0,0,1-1.86.34c-1,.05-1.37.06-4,.06s-3,0-4-.06A5.73,5.73,0,0,1,6.1,19.8,3.27,3.27,0,0,1,5,19.05a3,3,0,0,1-.74-1.15A5.54,5.54,0,0,1,3.86,16c0-1-.06-1.37-.06-4s0-3,.06-4A5.54,5.54,0,0,1,4.21,6.1,3,3,0,0,1,5,5,3.14,3.14,0,0,1,6.1,4.2,5.73,5.73,0,0,1,8,3.86c1,0,1.37-.06,4-.06s3,0,4,.06a5.61,5.61,0,0,1,1.86.34A3.06,3.06,0,0,1,19.05,5,3.06,3.06,0,0,1,19.8,6.1,5.61,5.61,0,0,1,20.14,8c.05,1,.06,1.37.06,4S20.19,15,20.14,16ZM12,6.87A5.13,5.13,0,1,0,17.14,12,5.12,5.12,0,0,0,12,6.87Zm0,8.46A3.33,3.33,0,1,1,15.33,12,3.33,3.33,0,0,1,12,15.33Z"/></svg></a>
      }
      if(k === "medium"){
        return <a href={`https://medium.com/@${v}`} target="_blank" rel="noopener noreferrer" key={k}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.20905,6.41669H22V4.08331H14.51978l-2.48584,9.16663h-.068L9.50269,4.08331H2V6.41663h.76837A.89578.89578,0,0,1,3.5,7.11139v9.83032a.84093.84093,0,0,1-.73163.6416H2v2.33338H8V17.58331H6.5V7.25h.08752L10.0451,19.91669h2.712L16.25989,7.25h.07355V17.58331H14.83337v2.33338H22V17.58331h-.79095a.83931.83931,0,0,1-.70905-.6416V7.11145A.8976.8976,0,0,1,21.20905,6.41669Z"/></svg></a>
      }
      return null;
    })
  }

  render(){
    let {collection,authorDoc,nftDoc} = this.props?.data??{};
    let{author,name,description,verified,blocked,files,size,startAt,price,currencyId,mintedSize,collectionType,nftAddress,isPublic} = collection;
    isPublic = !collection.hasOwnProperty('isPublic') || isPublic;
    let{nName,nDescription,nSocials,isOpen,isProcess,imgData,
      viewDesc} = this.state;
    let {img} = Utils.getTokenInfo(currencyId);

    let address = this.props.address;
    let url = author?`/author/${author}/profile?tab=collection`:"#";
    let ownerName = name?name:Utils.viewOwner(author,20)
    let imgSource = imgData?URL.createObjectURL(imgData):"";
    let _startAt = !size?"-":!isPublic?"Private":size === mintedSize?"Sold Out":!!startAt && startAt* 1000 > Date.now()? Utils.getTimeOffset(startAt):"Live";

    return (
    <Fragment>
      <div className="profile__display">
        <div className="profile__display__body">

          <div className="row no-gutters">
            <div className="col-12 col-sm-6 col-md-7 col-lg-12">

              <div className="display__avatar__main">
                <div className="display__avatar">
                  <div className="display__avatar__body">
                  {!!imgData && <img src={imgSource} alt=""/>}
                  {!imgData && <DpViewer address={author} data={{files}} key={author} />}

                  {!!address && author === address && <label htmlFor="file-upload">
                    <SelectFile/>
                    <input id="file-upload" type="file" accept=".png,.jpg,.jpeg,.gif" onChange={this.handleFile}/>
                  </label>}
                  </div>
                </div>
              </div>



              <div className="profile__display__meta" ref={this.myRef}>
                <div className="profile__display__name">
                  <Link to="#" onClick={(e)=>e.preventdefault()}>{ownerName}</Link>
                  {!!verified && <Verified />}
                  {!!blocked && <Blocked />}
                </div>
                <div className="profile__display__author">
                  By <Link to={url} target="_blank" rel="noopener noreferrer">{authorDoc.name}</Link>
                  {!!authorDoc.verified && <Verified />}
                  {!!authorDoc.blocked && <Blocked />}
                </div>

                <div className="profile__display__author">
                  TRC721 <Link to={`/mintable/trc721/${nftAddress}`} target="_blank" rel="noopener noreferrer">{nftDoc.name}</Link>
                  {!!nftDoc.verified && <Verified />}
                  {!!nftDoc.blocked && <Blocked />}
                </div>


                {!!description && <div className={`profile__display__desc ${viewDesc?"active":""}`}>
                  <p>{description}</p>
                  <button onClick={()=>this.setState({viewDesc:!viewDesc})}>show {viewDesc?"less":"more"} <ArrowDown/></button>
                </div>}


                <div className="profile__display__social">
                  {this.renderSocials()}
                </div>

                {!!author && author === address && <button
                  className="display__profile__edit__btn"
                  type="button"
                  onClick={()=>this.setState({isOpen:true})}
                  >Edit Details<Pen />
                </button>}

              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-5 col-lg-12">
              <div className="display__body__mint__info">
                <h2 className="display__body__info__title"> Mint Info</h2>
                  <div className="display__body__info__each">
                    <p>Status</p>:<span><div className="mint__info__content">{_startAt}</div></span>
                  </div>
                <div className="display__body__info__each">
                  <p>Mint</p>:<span><div className="mint__info__content">{Utils.preety.ceil(mintedSize,0)} / {Utils.preety.ceil(size,0)}</div></span>
                </div>
                <div className="display__body__info__each">
                  <p>Price</p>:<span><div className="mint__info__content">{Utils.preety.ceil(price/1e6,0)} <img src={img} alt=""/></div></span>
                </div>
                <div className="display__body__info__each">
                  <p>Type</p>:<span><div className="mint__info__content">{collectionType === 1?"Blind Mintable":"Mintable"}</div></span>
                </div>
                <div className="display__body__info__each">
                  <p>Royalty</p>:<span><div className="mint__info__content">{Utils.preety.ceil(nftDoc.royaltyAmount/100,1)}%</div></span>
                </div>
                {collectionType === 1 && _startAt === "Live" && <div className="display__body__info__each">
                  <button
                    className="sign__btn"
                    type="button"
                    onClick={this.props.mintHandler}
                    >Random Mint</button>
                </div>}
              </div>
            </div>
          </div>

        </div>
      </div>

      {!!isOpen && <PopUp.PopupContainer
        className="mblscr__popup"
        onClose={this.onClose}
        title="Update Details"
        >
          <div className="update__details__form">
            <div className="row no-gutters">

              <div className="col-12 ">
                <div className="update__details__group">
                  <label className="update__details__label" htmlFor="nName">Full Name</label>
                  <input
                    id="nName"
                    type="text"
                    name="nName"
                    className="update__details__input"
                    placeholder="e. g. Mintable Nft"
                    value={nName}
                    onChange={this.handleInput}/>
                </div>
              </div>

              <div className="col-12">
                <div className="update__details__group">
                  <label className="update__details__label" htmlFor="nDescription">Description</label>

                  <textarea
                    id="nDescription"
                    type="text"
                    name="nDescription"
                    className="update__details__textarea"
                    placeholder="e. g. i am a beautiful platform, which connect user and provide storage and market place for RARE NFTs (max length 100)"
                    value={nDescription}
                    onChange={this.handleInput}/>
                </div>
              </div>

              <div className="col-12">
                <div className="update__details__group">
                  <label className="update__details__label">Social Links</label>
                </div>
              </div>



              <div className="col-12 ">
                <div className="update__details__group update__details__group__social">
                  <label className="update__details__label" htmlFor="twitter">{"https://twitter.com/"}</label>
                  <input
                    id="twitter"
                    type="text"
                    name="twitter"
                    placeholder="username"
                    className="update__details__input"
                    value={nSocials.twitter || ""}
                    onChange={this.handleInput}
                    />
                </div>
              </div>

              <div className="col-12">
                <div className="update__details__group update__details__group__social">
                  <label className="update__details__label" htmlFor="instagram">{"https://instagram.com/"}</label>
                  <input
                    id="instagram"
                    type="text"
                    name="instagram"
                    placeholder="username"
                    className="update__details__input"
                    value={nSocials.instagram || ""}
                    onChange={this.handleInput}
                    />
                </div>
              </div>
              <div className="col-12">
                <div className="update__details__group update__details__group__social">
                  <label className="update__details__label" htmlFor="telegram">{"https://t.me/"}</label>
                  <input
                    id="telegram"
                    type="text"
                    name="telegram"
                    placeholder="username"
                    className="update__details__input"
                    value={nSocials.telegram || ""}
                    onChange={this.handleInput}
                    />
                </div>
              </div>
              <div className="col-12">
                <div className="update__details__group update__details__group__social">
                  <label className="update__details__label" htmlFor="medium">{"https://medium.com/@"}</label>
                  <input
                    id="medium"
                    type="text"
                    name="medium"
                    placeholder="username"
                    className="update__details__input"
                    value={nSocials.medium || ""}
                    onChange={this.handleInput}
                    />
                </div>
              </div>

              <div className="col-12">
                <div className="update__details__group update__details__group__social">
                  <label className="update__details__label" htmlFor="website">{"https://"}</label>
                  <input
                    id="website"
                    type="url"
                    name="website"
                    placeholder= "myweb.com"
                    className="update__details__input"
                    value={nSocials.website || ""}
                    onChange={this.handleInput}
                    />
                </div>
              </div>

              <div className="col-12">
                <button className="update__details__submit" type="submit" onClick={this.handleDataUpload}>
                  update
                  {!!isProcess && <div className="ball__pulse">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>}
                </button>
              </div>
            </div>
          </div>
      </PopUp.PopupContainer>}
    </Fragment>
    )
  }
}





class CollectionBody extends Component {
  constructor(props) {
      super(props)


      this.state = {
        isProcess:false,
        imgData:null,
        address: "",
      }
  }

  componentDidMount (){
    // document.title = "My Mintable Collections";
    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);
      let {address} = account
      // address = "TJ9DXJnYzLWjodSuQaUN3JQmffR7HAgd18";

      if(address){
        this.setState({address})
      }else {
        this.setState({address:""})
      }
    })

  }

  componentWillUnmount(){

  }

  handleBannerFile = event =>{
    let{isProcess} = this.state;

    if(isProcess) return Utils.setToastAlert("Another request is in process");;
    let file = event.target.files[0];
    // console.log(file);
    if(!file || !file.type){
      return Utils.setToastAlert("File not selected");
    }

    let ext = file.name.split(".");
    ext = ext[ext.length - 1].toLowerCase();

    const file_ext = ["jpg","jpeg","gif","png"];

    if (!file_ext.includes(ext)) {
      return Utils.setToastAlert(`Can not upload .${ext}, Only support .jpg .jpge .png .gif files type`);
    }

    if (file && file.size > 2*1024*1024) { //2MB
      return Utils.setToastAlert("Banner size to large, try uploading file less than 2MB");
    }
    //to display image on client
    this.setState({imgData:file});

  }

  uploadBanner = async() =>{

    try {
      let{address,isProcess,imgData} = this.state;
      let{collection:{author,collectionId}} = this.props.data;

      if(isProcess) return Utils.setToastAlert("Another request is in process");
      if(!address || author !== address) return Utils.setToastAlert("Only author can change banner");
      this.setState({isProcess:true})
      const payload = new FormData()
      payload.append('address', address)
      payload.append('collectionId', collectionId)
      payload.append('fileType', "banner")
      payload.append('img', imgData)
      await api.uploadCollectionFile(payload);
      this.setState({isProcess:false,imgData:null},this.props.handler)
      Utils.setToastAlert("Banner successfully changed","success");

    } catch (e) {
      // console.log(e.response);

      let msg = e.response?.data?.message??"Failed to upload banner";
      Utils.setToastAlert(msg,"error");

      this.setState({imgData:null,isProcess:false});
    }
  }


    render() {
      let{address,imgData} = this.state;
      let{collection} = this?.props?.data??{};
      let{author,files,collectionId} = collection;
        return (
          <CollectionMain
            data = {{address:author||true,files,imgData,isOwner:!!address && author === address}}
            handleBannerFile={this.handleBannerFile}
            uploadBanner={this.uploadBanner}
            isSmall
            >


            <div className="col-12 col-lg-3">
              {!!collectionId &&  <CollectionMeta
                data={this.props.data} address={address}
                handler={this.props.handler}
                mintHandler={this.props.mintHandler}
                key={collectionId}
                />}
              {!collectionId && <CollectionMetaDummy />}
            </div>

            <div className="col-12 col-lg-9">
              {this.props.children}
            </div>
        	</CollectionMain>
        )
    }
}

export default CollectionBody
