import React, { Component } from 'react'
import {Utils} from "../../helper"
import * as Parts from "../parts"
import api from "../../api"
import {Paginator} from "../../frag"
import {TRC721TokenWidget} from "../cards.jsx"


class MyContract extends Component{
  constructor(props) {
      super(props)

      const urlParams = new URLSearchParams(window.location.search);
      let page = urlParams.get('page');
      page = Utils.floor(page)
      page = page && page > 0?page:1;
      let start = page > 4?page -3:1;

      this.state = {
        address:"",
        count:0,
        page,
        start,
        limit:12,

        filterString:"",
        contracts:[],
        isProcess:false,
      }
      this.myRef = React.createRef();

  }
  componentDidMount (){
    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);
      let {address} = account
      if(address){
        // address = "TLtLxz9VPrtbgnaW46dmG5sv7LNPnWP9vU";

        this.setState({address},this.fetchTokens)
      }else {
        this.setState({address:""})
      }
    })
  }

  renderNft = () =>{
    let {contracts} = this.state;
    return contracts.map((item,i)=>{
      // console.log(item);
      return <div className="col-12 col-md-6" key={item.nftAddress+'a'}>
        <TRC721TokenWidget data={item} key={item.nftAddress+'b'} />
      </div>
    })
  }

  renderDummyNft = () =>{

  }

  updatePagingUrl = () =>{
    let{page} = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("page", page)
    let params = urlParams.toString();
    if (window.history.pushState) {
      let url = `${window.location.pathname}?${params}`;
      window.history.pushState(null, null,url);
    }
  }

  fetchTokens = async() =>{
    try {
      let {address,filterString,page,limit,isProcess} = this.state;
      if(isProcess) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isProcess:true})

      let payload = {address,filterString,page,limit}
      let res = await api.getNftContracts(payload);
      // console.log(res);
      let {count,result} = res.data;
      this.setState({count,contracts:result,isProcess:false},this.updatePagingUrl)
    } catch (e) {
      // console.log(e.response);
      this.setState({isProcess:false})
      let msg = e.response?.data?.message??"No NFT contract found";
      Utils.setToastAlert(msg,"error");
    }
  }

  handlePaginator = (value) =>{
    this.setState(value,this.fetchNfts)
    this.myRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  render(){
    let {count,start,limit,page,contracts,isProcess} = this.state

    return(
      <Parts.CollectionMain tab="myTrc721">

        <div className="col-12 col-md-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
          <div className="collection__create__title">
            <div className="collection__create__title__body">
              <div className="collection__create__p">My NFT(TRC721) Contracts</div>
            </div>
          </div>
          <div className="col-12">
            <div className={`row no-gutters equal`}>
              {!isProcess && !!contracts.length && this.renderNft()}
              {!!isProcess  && this.renderDummyNft()}
              {!isProcess  && !contracts.length && <p className="ik__notfound__msg">No result found</p>}
            </div>
        </div>
          <div className="row " ref={this.myRef}>
            <div className="col-12">
              <Paginator  data={{count,start,limit,page,size:contracts.length}} handler={this.handlePaginator}/>
            </div>
          </div>
        </div>



      </Parts.CollectionMain>
    )
  }
}



export default MyContract
