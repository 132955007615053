import React, { Component } from 'react'
import {Utils} from "../../helper"
import api from "../../api"
import NftCard,{NftCardDummy} from "../nftCard/index.jsx"
import {Paginator} from "../../frag"

class Created extends Component {
  constructor(props) {
      super(props)
      let limit = 24;
      const urlParams = new URLSearchParams(window.location.search);
      let page = urlParams.get('page');
      let tab = urlParams.get('tab')
      page = tab === "created"?Utils.floor(page):1;
      page = page && page > 0?page:1;
      let start = page > 4?page -3:1;

      this.state = {
        address:this.props.data.address || "",
        isLoaded:false,
        isLoading:true,
        page,
        start,
        limit,
        count:0,
        nfts:[],
        floorPrice:0,
        volume:0,
        transaction:0,
        tab:Utils.NftContract,

        isGrid:localStorage.getItem('isGrid') === "true",
      }
      this.myRef = React.createRef();

  }
  componentDidMount = async () =>{
      this.fetchNfts()
      this.fetchNeoState()
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let{page} = this.state;
      const urlParams = new URLSearchParams(window.location.search);
      let _page = urlParams.get('page');
      _page = Utils.floor(_page);
      if(_page !== page){
        page = _page && _page > 0?_page:1;
        let start = page > 4?page -3:1;
        this.setState({page,start},this.fetchNfts)
      }
     }
   updatePagingUrl = () =>{
     let{page} = this.state;
     const urlParams = new URLSearchParams(window.location.search);
     urlParams.set("page", page)
     let params = urlParams.toString();
     if (window.history.pushState) {
       let url = `${window.location.pathname}?${params}`;
       window.history.pushState(null, null,url);
     }
   }

  fetchNfts = async () =>{
    let{page,limit,address,tab} = this.state;

      try {
        this.setState({isLoading:true})
        let payload ={page,limit,address,nftAddress:tab}
        let res = await api.getUserCreatedNft(payload);
        // console.log(res);
        let{result,count} = res.data;
        this.setState({nfts:result,count,isLoading:false},this.updatePagingUrl)
      } catch (e) {
        // let msg = e?.response?.data?.message??"No NFT found"
        // console.log(msg);
        this.setState({isLoading:false})
      }
  }
  fetchNeoState = async( ) =>{
    let{address,tab} = this.state;
    try {
      let result = await api.getNeoState({address,nftAddress:tab});
       result = result.data.result[0] || {};
       // console.log(result);

       let{floorPrice,volume,transaction} = result;
      floorPrice = floorPrice || 0;
      volume = volume || 0;
      transaction = transaction || 0;

      this.setState({floorPrice,volume,transaction})
    } catch (e) {
        // console.log(e);
        // console.log(e.response);
    }
  }

  renderNft = () =>{
    let{nfts,isGrid} = this.state;
    let layout={
      isSmallScale:true,
      isMobile: !!isGrid,
      isAutoHeight:true,
    }

    return nfts.map((data,i)=>{
      let{tokenId,nftAddress} = data;
      if(!!isGrid){
        return <div className="col-6 col-sm-4 col-lg-3 col-xl-2" key={tokenId+nftAddress+"smallA"}>
          <NftCard data={data} layout={layout} key={tokenId+nftAddress+"smallB"}/>
        </div>
      }
      return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={tokenId+nftAddress+"largeA"}>
        <NftCard data={data} layout={layout} key={tokenId+nftAddress+"largeB"}/>
      </div>


    })
  }
  renderDummyNft = () =>{
    let{isGrid} = this.state;
    let layout={
      isSmallScale:true,
      isMobile: !!isGrid,
      isAutoHeight:true,
    }

    let _w = window.innerWidth;
    let count = _w< 576?4:_w< 768?8:_w< 992?12:_w< 1200?15:16;
    if(isGrid){
      count = _w< 576?8:_w< 768?12:_w< 992?15:_w< 1200?16:18;
    }
      return new Array(count).fill(undefined).map((item,i)=>{

        if(!!isGrid){
          return <div className="col-6 col-sm-4 col-lg-3 col-xl-2" key={"smallAD"+i}>
            <NftCardDummy layout={layout} key={"smallB"+i}/>
          </div>
        }

        return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={"largeAD"+i}>
          <NftCardDummy layout={layout} key={"largeB"+i}/>
        </div>
      })
  }
  handleInput = e =>{
    let val = e.target.value;
    if (!val || val.length <= 40) {
      this.setState({filterString:val})
    }
  }
  handlePaginator = (value) =>{
    this.setState(value,this.fetchNfts)
    this.myRef.current.scrollIntoView({ behavior: 'smooth' })

  }

    render() {
      let{nfts,isLoading,count,start,limit,page,floorPrice,volume,transaction} = this.state;
        return (
          <div className="tab-pane active" ref={this.myRef}>
            {!!count &&
              <div className="row">
              <div className="col-12">
              <div className="collection__status__summery">
                <div className="collection__status__summery__body">
                  <div className="collection__status__summery__each">
                    <p>Items</p>
                    <span>{Utils.preety.ceil(count,0)}</span>
                  </div>
                  <div className="collection__status__summery__each">
                    <p>Floor Price</p>
                    <span>{Utils.preety.ceil(floorPrice/1e6,0)} TRX</span>
                  </div>
                  <div className="collection__status__summery__each">
                    <p>Volume traded</p>
                    <span>{Utils.preety.ceil(volume/1e6,0)} TRX</span>
                  </div>
                  <div className="collection__status__summery__each">
                    <p>Transactions</p>
                    <span>{Utils.preety.ceil(transaction,0)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>}

            <div className="row no-gutters">
              {!isLoading && !!nfts.length && this.renderNft()}
              {!!isLoading && this.renderDummyNft()}
              {!isLoading && !nfts.length && <h2 style={{color:"#888",margin:"15px 10px"}}>No NFT found</h2>}

            </div>

            <div className="row">
              <div className="col-12">
                <Paginator  data={{count,start,limit,page,size:nfts.length}} handler={this.handlePaginator}/>
              </div>
            </div>
          </div>
        )
    }
}




export default Created
