
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as helper from '../helper'
import * as pages from './'


function Rocks() {

    return (
        <Router>
            <Switch>
                  <Route path="/tech-memory/gallery" exact component={pages.RocksGallery} />
                  <Route path={["/tech-memory","/tech-memory/mint"]} exact component={pages.MintRocks} />
                  <Route component={helper.PageNotFound} />

            </Switch>
        </Router>
    )
}

export default Rocks
