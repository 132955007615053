import React, {Component} from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import api from "../api"
import {Utils} from "../helper"
import {DpViewer} from "../helper/file-viewer.jsx"

import {AuthorCardDummy} from "../components/authorCard/index.jsx";
import {ReactComponent as Verified} from '../images/verified_star.svg';
import {ReactComponent as Blocked} from '../images/banned.svg';
// import ReactCrop from 'react-image-crop';

const BgCover = styled(Link).attrs({
  className: "author__cover author__cover--bg",
})`
background-image: url(${props=>props.data});
`
class AdminAuthorCard extends Component{
  constructor(props) {
      super(props)

      this.state = {
        isProcess:false,
        address:"",
      }
  }
  handleVerification = async() =>{
    let{username,address:author,verified} = this.props.data
    let address = Utils.viewCreator();

    try {
      let{isProcess} = this.state;
      if(isProcess) return Utils.setToastAlert("Previous verification request is in process");
      let payload = {address,author,type:"verification"}
      this.setState({isProcess:true})
      await api.updateAdminAuthor(payload)
      Utils.setToastAlert(`${username} verification changed to ${!verified}`,"success");
      this.setState({isProcess:false})
    } catch (e) {
      console.log(e);
      Utils.setToastAlert(`${username} verification changing failed to ${!verified}`,"error");
      this.setState({isProcess:false})
    }
  }
  handlePermission = async() =>{
    let{username,address:author,blocked} = this.props.data
    let address = Utils.viewCreator();

    try {
      let{isProcess} = this.state;
      if(isProcess) return Utils.setToastAlert("Previous Permission request is in process");
      let payload = {address,author,type:"permission"}
      this.setState({isProcess:true})
      await api.updateAdminAuthor(payload)
      Utils.setToastAlert(`${username} Permission changed to ${!blocked}`,"success");
      this.setState({isProcess:false})
    } catch (e) {
      console.log(e);
      Utils.setToastAlert(`${username} Permission changing failed to ${!blocked}`,"error");
      this.setState({isProcess:false})
    }
  }
  render(){
    // console.log(this.props.data);
    let{address,name,username,description,verified,blocked} = this.props.data
    name = name || address;
    username = username || address;
    return(
        <div className="author" key={address}>
          <BgCover to={`/author/${address}`} data={Utils.getBanner(address)} />
          <div className="author__meta">
            <Link to={`/author/${address}`} className="author__avatar">
              <DpViewer address={address} key={address}/>
            </Link>
            <h1 className="author__name">
              {!!name && <Link to={`/author/${address}/profile`}>
              {Utils.shortName(name,20)}
              {!!verified && <Verified />}
              {!!blocked && <Blocked />}
            </Link>}
          </h1>
            <h2 className="author__nickname">{!!username && <Link to={`/author/${address}/profile`}>{Utils.viewOwner(username,20)}</Link>}</h2>
            <p className="author__text">{description || ""}</p>
            <div className="author__wrap">
              <button
                className="author__follow"
                type="button"
                onClick={this.handleVerification}
                >{verified?"UnTick":"Tick"}
              </button>
              <button
                className="author__follow"
                type="button"
                onClick={this.handlePermission}
                >{blocked?"Unblock":"Block"}
              </button>
            </div>
          </div>
        </div>
    )
  }
}



class Authors extends Component {

  constructor(props) {
      super(props)

      this.state = {
        address:"",
        isLoading:false,
        authors:[],
        page:1,
        start:1,
        limit:20,
        count:0,
        category:"all",
        filterString:'',
      }
  }
  componentDidMount = () =>{
    document.title = "Authors || Kraftly";

    window.$('.main__select').select2({
  		minimumResultsForSearch: Infinity
  	});
    let self = this;
    window.$('.main__select').on('select2:select', function (e) {
      self.handleSelect(e.params.data.id)
    });
    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      // console.log(account);
      const {address} = account
      this.setState({address})
    })

    this.fetchAuthors()
  }
  componentWillUnmount(){
    document.title = "Kraftly";
  }
  fetchAuthors = async () =>{
    let{isLoading,page,limit,category} = this.state;
      try {
        if(isLoading) return Utils.setToastAlert("Previous request processing","info");
        this.setState({isLoading:true})
        let payload ={page,limit,category,mode:"admin"}
        let res = await api.getAuthors(payload);
        // console.log(res);
        let{result,count} = res.data;
        this.setState({authors:result,count,isLoading:false})
      } catch (e) {
        console.log(e.response);
        Utils.setToastAlert("Failed to display authors","error");
        this.setState({isLoading:false})
      }
  }
  fetchFilterData = async(event) =>{
    try {
      event.preventDefault()
      let{filterString,isLoading} = this.state;
      if(isLoading) return Utils.setToastAlert("Previous request processing","info");
      if(!filterString) return this.fetchAuthors();
      window.$(".select2-selection__rendered").text("All Authors");
      this.setState({category:"all",page:1,start:1,isLoading:true});
      let res = await api.getAuthors({filterString,mode:"admin"});
      // console.log(res);
      let{result,count} = res.data;
      this.setState({authors:result,count,isLoading:false})
    } catch (e) {
      this.setState({isLoading:false})
      Utils.setToastAlert("There are no Author for given input");
    }
  }

  renderAuthors = () =>{
    let{authors} = this.state;
    // authors = authors.concat(emptyPlace);
    return authors.map((item,i)=>{
      return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={item.address+1}>
        <AdminAuthorCard data={item} key={item.address+2}/>
      </div>
    })
  }
  renderDummyAuthors = () =>{
    let count = window.innerWidth < 768?1:window.innerWidth < 992?4:window.innerWidth < 1200?6:8;
    return new Array(count).fill(undefined).map((item,i)=>{
      return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={i}>
        <AuthorCardDummy/>
      </div>
    })
  }
  handleSelect = value =>{
    let{category,isLoading} = this.state;
    console.log(value,category);

    if(isLoading || !value){
      window.$('.main__select').val(category).trigger('change');
      return console.log("authors loading")
    }else if (category !== value) {
      this.setState({category:value,start:1,page:1,authors:[]},this.fetchAuthors)
    }
  }
  renderPagings = () =>{
    let{count,page,start,limit} = this.state;
    let totalPage = Math.ceil(count /limit);
    totalPage = totalPage <= 4?totalPage:4;
    let pages = new Array(totalPage).fill(undefined).map((data,i)=>i+start);

    return pages.map((mark,i)=>{
      return <li className={page === mark?"active":""} key={i}><button key={i} onClick={()=>this.handlePaging(mark)}>{mark}</button></li>
    })
  }
  handlePaging = (val) =>{
    let{isLoading,page} = this.state;
    if(isLoading) return Utils.setToastAlert("Previous request processing","info");
    if(page === val)return console.log("on current page");
    this.setState({page:val,authors:[]},this.fetchAuthors);
  }
  handlePagingArrow = (val) =>{
    let{count,page,start,limit,isLoading} = this.state;
    if(isLoading) return Utils.setToastAlert("Previous request processing","info");

    if(!count || count <= limit) return;
    let totalPage = Math.ceil(count /limit);
    let _page = page;
      if(val === "prev"){
        if(page > 1){
          _page = page-1;
          if(totalPage > 4 && start > 1){
            start = start -1;
          }
        }
      }else {
        if(page < totalPage){
          _page = page+1;
          if(totalPage > 4 && start < _page - 3){
            start = start +1;
          }
        }
      }
      if(_page !== page){
        this.setState({page:_page,start},this.fetchAuthors);
      }
  }
  handleInput = e =>{
    let val = e.target.value;
    if (!val || val.length <= 40) {
      this.setState({filterString:val})
    }
  }

    render() {
      let{category,count,authors,isLoading,filterString} = this.state;

        return (
          <main className="main">
        		<div className="container">
        			<div className="row equal">
        				<div className="col-12">
        					<ul className="breadcrumb">
        						<li className="breadcrumb__item"><Link to="/admin">Admin</Link></li>
        						<li className="breadcrumb__item breadcrumb__item--active">Authors</li>
        					</ul>
        				</div>

        				<div className="col-12">
        					<div className="main__title main__title--page">
        						<h1>Authors</h1>
        					</div>
        				</div>

        				<div className="col-12">
        					<div className="main__filter">
        						<form onSubmit={this.fetchFilterData.bind(this)} className="main__filter-search">
        							<input
                        type="text"
                        placeholder="Search..."
                        value = {filterString}
                        onChange={this.handleInput}
                        />
        							<button
                        onClick={this.fetchFilterData}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
                      </button>
        						</form>

        						<div className="main__filter-wrap">

                    <select
                      className="main__select"
                      name="category" onChange={this.handleSelect}
                      value={category}>
        								<option value="all">All Authors</option>
        								<option value="verified">Verified Authors</option>
                        <option value="mostFollowed">Most followed</option>
                        <option value="new">New Authors</option>
        							</select>
        						</div>
        					</div>
        				</div>
        			</div>

        			<div className="row row--grid">
                {!isLoading && !!authors.length && this.renderAuthors()}
                {!!isLoading && this.renderDummyAuthors()}
        			</div>

              <div className="row row--grid">
                <div className="col-12">
                  <div className="paginator">
                    <span className="paginator__pages">{authors.length} from {count}</span>

                    <ul className="paginator__list">
                      <li>
                        <button  onClick={()=>this.handlePagingArrow("prev")}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"/></svg>
                        </button>
                      </li>
                      {this.renderPagings()}
                      <li>
                        <button  onClick={()=>this.handlePagingArrow("next")}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"/></svg>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
        		</div>
        	</main>
        )
    }
}
export default Authors
