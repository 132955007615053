import React, { Component } from 'react'
import styled from 'styled-components'

import './style.scss'
import {ReactComponent as TokenomicsIcon} from '../../images/tokenomics.svg';
import {ReactComponent as SeedFundIcon} from '../../images/seed-fund.svg';
import {ReactComponent as LearnMoreIcon} from '../../images/learn-more.svg';
import {ReactComponent as SwapIcon} from '../../images/swap.svg';



const StyledLink  = styled.a.attrs({
    target : "_blank",
    rel : "noopener noreferrer"
})``;

const AppFeatures = (props) =>{
  return (
    <section className="content__section">
      <div className="feature__box">
        <div className="row no-gutters">
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <div className="app__feature">
              <span className="app__feature__icon app__feature__icon--blue">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10,13H4a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V14A1,1,0,0,0,10,13ZM9,19H5V15H9ZM20,3H14a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4A1,1,0,0,0,20,3ZM19,9H15V5h4Zm1,7H18V14a1,1,0,0,0-2,0v2H14a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V18h2a1,1,0,0,0,0-2ZM10,3H4A1,1,0,0,0,3,4v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4A1,1,0,0,0,10,3ZM9,9H5V5H9Z"/></svg>
              </span>
              <h3 className="app__feature__title">Create</h3>
              <p className="app__feature__text">NFT creators in the Kraftly platform are rewarded with a weekly airdrop of KTY governance tokens.</p>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <div className="app__feature">
              <span className="app__feature__icon app__feature__icon--red">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2.5,10.56l9,5.2a1,1,0,0,0,1,0l9-5.2a1,1,0,0,0,0-1.73l-9-5.2a1,1,0,0,0-1,0l-9,5.2a1,1,0,0,0,0,1.73ZM12,5.65l7,4-7,4.05L5,9.69Zm8.5,7.79L12,18.35,3.5,13.44a1,1,0,0,0-1.37.36,1,1,0,0,0,.37,1.37l9,5.2a1,1,0,0,0,1,0l9-5.2a1,1,0,0,0,.37-1.37A1,1,0,0,0,20.5,13.44Z"/></svg>
              </span>
              <h3 className="app__feature__title">Collect NFTs</h3>
              <p className="app__feature__text">NFT holders on Kraftly will occasionally be rewarded with $KTY tokens.</p>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <div className="app__feature">
              <span className="app__feature__icon app__feature__icon--purple">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.49,7.52a.19.19,0,0,1,0-.08.17.17,0,0,1,0-.07l0-.09-.06-.15,0,0h0l0,0,0,0a.48.48,0,0,0-.09-.11l-.09-.08h0l-.05,0,0,0L16.26,4.45h0l-3.72-2.3A.85.85,0,0,0,12.25,2h-.08a.82.82,0,0,0-.27,0h-.1a1.13,1.13,0,0,0-.33.13L4,6.78l-.09.07-.09.08L3.72,7l-.05.06,0,0-.06.15,0,.09v.06a.69.69,0,0,0,0,.2v8.73a1,1,0,0,0,.47.85l7.5,4.64h0l0,0,.15.06.08,0a.86.86,0,0,0,.52,0l.08,0,.15-.06,0,0h0L20,17.21a1,1,0,0,0,.47-.85V7.63S20.49,7.56,20.49,7.52ZM12,4.17l1.78,1.1L8.19,8.73,6.4,7.63Zm-1,15L5.5,15.81V9.42l5.5,3.4Zm1-8.11L10.09,9.91l5.59-3.47L17.6,7.63Zm6.5,4.72L13,19.2V12.82l5.5-3.4Z"/></svg>
              </span>
              <h3 className="app__feature__title">DeFi Farming</h3>
              <p className="app__feature__text">Liquidity providers for KTY tokens against some specific pre-declared pairs will be rewarded every week.</p>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <div className="app__feature">
              <span className="app__feature__icon app__feature__icon--green">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.47,7.37s0,0,0-.08l-.06-.15a.71.71,0,0,0-.07-.09.94.94,0,0,0-.09-.12l-.09-.07L20,6.78l-7.5-4.63a1,1,0,0,0-1.06,0L4,6.78l-.09.08-.09.07a.94.94,0,0,0-.09.12.71.71,0,0,0-.07.09l-.06.15s0,0,0,.08a1.15,1.15,0,0,0,0,.26v8.74a1,1,0,0,0,.47.85l7.5,4.63h0a.47.47,0,0,0,.15.06s.05,0,.08,0a.86.86,0,0,0,.52,0s.05,0,.08,0a.47.47,0,0,0,.15-.06h0L20,17.22a1,1,0,0,0,.47-.85V7.63A1.15,1.15,0,0,0,20.47,7.37ZM11,19.21l-5.5-3.4V9.43L11,12.82Zm1-8.12L6.4,7.63,12,4.18l5.6,3.45Zm6.5,4.72L13,19.21V12.82l5.5-3.39Z"/></svg>
              </span>
              <h3 className="app__feature__title">Referral</h3>
              <p className="app__feature__text">Refer and earn $KTY tokens when your friend mints an NFT for the first time and every time they trade.</p>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <div className="app__feature">
              <span className="app__feature__icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7,6H6V3A1,1,0,0,0,4,3V6H3A1,1,0,0,0,3,8H7A1,1,0,0,0,7,6ZM5,10a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V11A1,1,0,0,0,5,10Zm7,8a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V19A1,1,0,0,0,12,18Zm9-8H20V3a1,1,0,0,0-2,0v7H17a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Zm-2,4a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V15A1,1,0,0,0,19,14Zm-5,0H13V3a1,1,0,0,0-2,0V14H10a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Z"/></svg>
              </span>
              <h3 className="app__feature__title">Trade</h3>
              <p className="app__feature__text">NFT traders will be rewarded with KTY Tokens on weekly basis.</p>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <div className="app__feature">
              <span className="app__feature__icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.3,10.08A3,3,0,0,0,19,9H14.44L15,7.57A4.13,4.13,0,0,0,11.11,2a1,1,0,0,0-.91.59L7.35,9H5a3,3,0,0,0-3,3v7a3,3,0,0,0,3,3H17.73a3,3,0,0,0,2.95-2.46l1.27-7A3,3,0,0,0,21.3,10.08ZM7,20H5a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H7Zm13-7.82-1.27,7a1,1,0,0,1-1,.82H9V10.21l2.72-6.12A2.11,2.11,0,0,1,13.1,6.87L12.57,8.3A2,2,0,0,0,14.44,11H19a1,1,0,0,1,.77.36A1,1,0,0,1,20,12.18Z"/></svg>
              </span>
              <h3 className="app__feature__title">Vote for platform upgrades</h3>
              <p className="app__feature__text">Governance participants on Kraftly platform will be rewarded with $KTY tokens regularly.</p>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <div className="app__feature">
              <span className="app__feature__icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22,9.67A1,1,0,0,0,21.14,9l-5.69-.83L12.9,3a1,1,0,0,0-1.8,0L8.55,8.16,2.86,9a1,1,0,0,0-.81.68,1,1,0,0,0,.25,1l4.13,4-1,5.68a1,1,0,0,0,.4,1,1,1,0,0,0,1.05.07L12,18.76l5.1,2.68a.93.93,0,0,0,.46.12,1,1,0,0,0,.59-.19,1,1,0,0,0,.4-1l-1-5.68,4.13-4A1,1,0,0,0,22,9.67Zm-6.15,4a1,1,0,0,0-.29.89l.72,4.19-3.76-2a1,1,0,0,0-.94,0l-3.76,2,.72-4.19a1,1,0,0,0-.29-.89l-3-3,4.21-.61a1,1,0,0,0,.76-.55L12,5.7l1.88,3.82a1,1,0,0,0,.76.55l4.21.61Z"/></svg>
              </span>
              <h3 className="app__feature__title">Social Star</h3>
              <p className="app__feature__text">Participate in various social media and marketing campaigns and interact with the community regularly.</p>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <div className="app__feature">
              <span className="app__feature__icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.92,11.6C19.9,6.91,16.1,4,12,4S4.1,6.91,2.08,11.6a1,1,0,0,0,0,.8C4.1,17.09,7.9,20,12,20s7.9-2.91,9.92-7.6A1,1,0,0,0,21.92,11.6ZM12,18c-3.17,0-6.17-2.29-7.9-6C5.83,8.29,8.83,6,12,6s6.17,2.29,7.9,6C18.17,15.71,15.17,18,12,18ZM12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z"/></svg>
              </span>
              <h3 className="app__feature__title">Participate in moderation</h3>
              <p className="app__feature__text">Platform Content moderators and social community mods will get rewarded with KTY tokens.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

class KTY extends Component{
  render(){

    return(
      <main className="main">
    		<div className="container">

          <div className="large__kty__bg">&nbsp;</div>
          <section className="content__section">
            <div className="section__title">
              How you can get Kraftly
            </div>

            <div className="kty__info__box">
                <div className="kty__info__box__child">
                  <p>$KTY is the platform and governance token for the Kraftly market on BSC and TRON. The tokenomics is designed to maintain decentralized governance and distributed platform ownership systems.</p>
                </div>
                <div className="kty__info__box__child">
                  <p>During the initial days of the platform and token launch, all of the mentioned ways of acquiring cross chain $KTY tokens on BSC and TRON will be online slowly based on fair tokenomics.</p>
                </div>
            </div>
          </section>

          <AppFeatures/>


          <section className="content__section">
            <div className="section__title">For investors</div>

            <div className="kty__info__box">
                <div className="kty__info__box__child">
                  <p>We want to make this token and reward distribution program as impactful as possible. Not only keeping in mind the roadmap for the future development but also at the same time preventing investor's best interests.</p>
                </div>
                <div className="kty__info__box__child">
                  <p>The Kraftly team came up with a perfectly decentralized tokenomics with a company share as low as below 15% to aid our vision.
                    You can click on learn more to understand more about the tokenomics, current functionalities, and upcoming utilities of KTY tokens and how they are associated with the development and under progress decentralized governance of the Kraftly platform.</p>
                </div>
            </div>


            <div className="kty__info__btns">
              <div className="kty__info__btn">
                <TokenomicsIcon/>
                <StyledLink href="docs/tokenomics.pdf">TOKENOMICS</StyledLink>
              </div>
              <div className="kty__info__btn">
                <SeedFundIcon/>
                <StyledLink href="docs/seed-allocation.pdf">SEED FUND</StyledLink>
              </div>
            </div>
          </section>

          <section className="content__section">
            <div className="section__title">DeFi Farming</div>

            <div className="kty__info__box">
                <div className="kty__info__box__child">
                  <p>The Defi Farming program was thought of and designed keeping in mind the liquidity of KTY tokens across Defi lend and swap protocols across BSC and TRON. </p>
                </div>
                <div className="kty__info__box__child">
                  <p>This will also let us create a more fair way of distributing $KTY tokens and dividing platform governance and ownership.</p>
                </div>
            </div>
            <div className="feature__box">
              <div className="row no-gutters">
                <div className="col-12 col-lg-4">
                  <div className="app__feature">
                    <span className="app__feature__number">01</span>
                    <h3 className="app__feature__title">Provide Liquidity</h3>
                    <p className="app__feature__text">Login to Kraftly preferred Defi swap and lend protocols, ie. Currently Pancake and Just Swap and provide liquidity for the supported pairs(KTY-BNB, KTY-TRX, KTY-USDT ) and collect lp tokens.</p>
                  </div>
                </div>

                <div className="col-12 col-lg-4">
                  <div className="app__feature">
                    <span className="app__feature__number">02</span>
                    <h3 className="app__feature__title">Stake LP tokens</h3>
                    <p className="app__feature__text">Next app__feature, stake lp tokens in Kraftly lp-check smart contract.</p>
                  </div>
                </div>

                <div className="col-12 col-lg-4">
                  <div className="app__feature">
                    <span className="app__feature__number">03</span>
                    <h3 className="app__feature__title">Earn</h3>
                    <p className="app__feature__text">$KTY rewards will get distributed automatically.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="content__section">
            <div className="section__title">Cross chain $KTY value and transfers</div>

            <div className="kty__info__box">
                <div className="kty__info__box__child">
                  <p>$KTY tokens will have the same value over the BSC and TRON blockchains. During the roadmap, at any given time the inter-chain KTY swap will officially be made available.
                  Users shall use any third-party BSC-TRON $KTY swap at their own risk.</p>
                </div>
            </div>

            <div className="kty__info__btns">
              <div className="kty__info__btn"><LearnMoreIcon/><span>LEARN MORE</span></div>
              <div className="kty__info__btn"><SwapIcon/><span>SWAP</span></div>
            </div>

          </section>


          <section className="content__section">
            <div className="section__title">Referral</div>

            <div className="kty__info__box">
                <div className="kty__info__box__child">
                  <p>Register and generate referral links and QR codes with a commission kickback rate. Invite your friends to register through the referral link or QR and get rewards whenever they....</p>
                </div>
            </div>
            <div className="feature__box">
              <div className="row no-gutters">
                <div className="col-12 col-lg-6">
                  <div className="app__feature">
                    <span className="app__feature__number">01</span>
                    <h3 className="app__feature__title">First transaction</h3>
                    <p className="app__feature__text">A scratch card is received upon your friend's first transaction, scratching which might reward any of the following - BNB, TRX, KTY, or a unique NFT.</p>
                  </div>
                </div>

                <div className="col-12 col-lg-6">
                  <div className="app__feature">
                    <span className="app__feature__number">02</span>
                    <h3 className="app__feature__title">Create and trade</h3>
                    <p className="app__feature__text">You receive 10% of the trading fees collected from your friend by the Kraftly platform in the form of $KTY tokens.</p>
                  </div>
                </div>

                <div className="col-12">
                  <div className="kty__info__btns">
                    <div className="kty__info__btn"><LearnMoreIcon/><span>LEARN MORE</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className="content__section">
            <div className="section__title">Tokenomics split</div>

            <div className="kty__info__box">
                <div className="kty__info__box__child">
                  <p>Register and generate referral links and QR codes with a commission kickback rate. Invite your friends to register through the referral link or QR and get rewards whenever they....</p>
                </div>
            </div>
            <div className="feature__box">
              <div className="row no-gutters">
                <div className="col-12 col-lg-4">
                  <div className="app__feature app__feature--three">
                    <span className="app__feature__number">01</span>
                    <h3 className="app__feature__title">Founder's Reserve</h3>
                    <p className="app__feature__text">14% - TK742riNqm86NY14U89K1NJFdmszaA6RwJ</p>
                  </div>
                </div>

                <div className="col-12 col-lg-4">
                  <div className="app__feature app__feature--three">
                    <span className="app__feature__number">02</span>
                    <h3 className="app__feature__title">Referral </h3>
                    <p className="app__feature__text">2% - TW7nqzWkuPE66G8T4gWyrpqwRefkXUrMnZ</p>
                  </div>
                </div>

                <div className="col-12 col-lg-4">
                  <div className="app__feature app__feature--three">
                    <span className="app__feature__number">03</span>
                    <h3 className="app__feature__title">LP Rewards</h3>
                    <p className="app__feature__text">8% - TEsUatprkMLGfgFNCiAH8HihXSiRjoGf7u</p>
                  </div>
                </div>

                <div className="col-12 col-lg-4 app__feature--three">
                  <div className="app__feature app__feature--three">
                    <span className="app__feature__number">04</span>
                    <h3 className="app__feature__title">Trader's Incentive</h3>
                    <p className="app__feature__text">30% - TK3y8cjryTTwdgjuhyJ5yUiNDZWCY8YMU5</p>
                  </div>
                </div>

                <div className="col-12 col-lg-4">
                  <div className="app__feature app__feature--three">
                    <span className="app__feature__number">05</span>
                    <h3 className="app__feature__title">Creator's Incentive</h3>
                    <p className="app__feature__text">10% - TUSc4CUvTJJtV6H5Him7h4ovbUQLrs7ZgU</p>
                  </div>
                </div>

                <div className="col-12 col-lg-4">
                  <div className="app__feature">
                    <span className="app__feature__number">06</span>
                    <h3 className="app__feature__title">Community Rewards</h3>
                    <p className="app__feature__text">6% - TMTRGcCuCMKczGCvyFbiQtxakFrAPPSsgD</p>
                  </div>
                </div>
              </div>
            </div>

          </section>
    		</div>
    	</main>
    )
  }
}

export default KTY;
