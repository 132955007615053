import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {Utils} from "../helper"
import tronWeb from "../tronweb"
import api from "../api"

import {NftNeoCardDummy,NftNeoCard} from "../components/nftCard/index.jsx"
import {Paginator,MenuSelect3,GridToggler2} from "../frag"


import Banner from '../images/fox-banner.jpeg';


import {ReactComponent as Plus} from '../images/plus.svg';
import {ReactComponent as Close} from '../images/close.svg';
import Attributes from './attributes.js';




const Fox555Contract = "TKrCzUSnsAEJ44gjcWHzM45XQaLgJ8fpQ3";





class Fox555Gallery extends Component{
  constructor(props) {
      super(props)
      let limit = 24;
      const urlParams = new URLSearchParams(window.location.search);
      let page = urlParams.get('page');
      page = Utils.floor(page)
      page = page && page > 0?page:1;
      let start = page > 4?page -3:1;

      this.state = {
        isLoading:false,
        count:0,
        page,
        start,
        limit,
        nfts:[],
        category:"all",
        series:"fox555",
        filterString:"",
        filterOptions:{},
        openTabs : [],
        showFilter:false,
        floorPrice:0,
        volume:0,
        transaction:0,


        isGrid:localStorage.getItem('isGrid') === "true",
      }
      this.myRef = React.createRef();
  }
  componentDidMount (){
    document.title = "Gallery || FOX555";
    this.fetchNfts()
    // this.fetchNeoState()
  }
  componentWillUnmount(){
    document.title = "Kraftly";
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let{page} = this.state;
      const urlParams = new URLSearchParams(window.location.search);
      let _page = urlParams.get('page');
      _page = Utils.floor(_page);
      if(_page !== page){
        page = _page && _page > 0?_page:1;
        let start = page > 4?page -3:1;
        this.setState({page:_page,start},this.fetchNfts)
      }
     }
  fetchNeoState = async( ) =>{
    try {
      let result = await api.getNeoState({collection:"clouds"});
      // console.log(result);
      let{floorPrice,volume,transaction} = result.data.result[0];
      this.setState({floorPrice,volume,transaction})
    } catch (e) {
        // console.log(e);
        // console.log(e.response);
    }
  }
  fetchFoxMinter = async()=>{
    let{nfts} = this.state;
      try {
        this.setState({isLoading:true})
        let tokenIds = nfts.map(i => i.tokenId);
        let instance = await tronWeb.contract().at(Fox555Contract);
        let minters = await instance.mintersOf(tokenIds).call();

        let result = nfts.map((item,i) => {
          let minter = tronWeb.address.fromHex(minters[i])
          // console.log(minter);
          if(minter !== "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"){
            item.minter = minter;
          }
          return item;
        });

        this.setState({nfts:result,isLoading:false})
      } catch (e) {
        console.log(e);
        this.setState({isLoading:false})

      }
  }
  fetchNfts = async()=>{
    let{page,limit,series,category,filterString,filterOptions,isLoading} = this.state;
      try {
        if(isLoading) return Utils.setToastAlert("Previous request processing","info");
        this.setState({isLoading:true})
        let payload = {page,limit,series,category,filterString,filterOptions}
        // console.log(limit);
        let res = await api.getNeoMarket(payload);
        // console.log(res);
        let{result,count} = res.data;
        count = count || 0;
        this.setState({nfts:result,count,isLoading:false},()=>{
          this.fetchFoxMinter()
          this.updatePagingUrl()
        })
      } catch (e) {
        let msg = e.response?.data?.message??"No NFT found";
        Utils.setToastAlert(msg,"error");
        // console.log(e.response);
        this.setState({isLoading:false})
      }
  }
  fetchFilterData = async(event) =>{
      event.preventDefault()

      let{isLoading} = this.state;
      if(isLoading) return Utils.setToastAlert("Previous request processing","info");

      this.setState({nfts:[],category:"all",page:1,start:1,count:0},()=>{
        this.fetchNfts()
      })
  }

   updatePagingUrl = () =>{

     let{page} = this.state;
     const urlParams = new URLSearchParams(window.location.search);
     urlParams.set("page", page)
     let params = urlParams.toString();
     if (window.history.pushState) {
       let url = `${window.location.pathname}?${params}`;
       window.history.pushState(null, null,url);
     }
   }


   renderNft = () =>{
     let{nfts,isGrid} = this.state;
     let layout={
       isMobile:!!isGrid,
       isSmallScale:true,
     }
     return nfts.map((data,i)=>{
       data.price = 555 * 1e6;

       if(!!isGrid){
         return <div className="col-6 col-sm-4 col-lg-3 col-xl-2" key={data.tokenId+"smallA"}>
           <NftNeoCard data={data} layout={layout} key={data.tokenId+"smallB"}/>
         </div>
       }
       return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={data.tokenId+"largeA"}>
         {<NftNeoCard data={data} layout={layout} key={data.tokenId+"largeB"}/>}
       </div>
     })
   }
   renderDummyNft = () =>{
     let{isGrid} = this.state;

     let _w = window.innerWidth;
     let count = _w< 576?3:_w< 768?6:_w< 992?4:_w< 1200?6:8;
     if(isGrid){
       count = _w< 576?6:_w< 768?9:_w< 992?6:_w< 1200?8:18;
     }
       return new Array(count).fill(undefined).map((item,i)=>{
         if(!!isGrid){
           return <div className="col-6 col-sm-4 col-lg-3 col-xl-2" key={"smallAD"+i}>
             <NftNeoCardDummy isMobile={true} key={"smallB"+i}/>
           </div>
         }
         return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={"largeAD"+i}>
           <NftNeoCardDummy key={"largeB"+i}/>
         </div>
       })
   }

  handleInput = e =>{
    let val = e.target.value;
    if (!val || val.length <= 40) {
      this.setState({filterString:val})
    }
  }
  handlePaginator = (value) =>{
    this.setState(value,this.fetchNfts)
    this.myRef.current.scrollIntoView({ behavior: 'smooth' })

  }

  onOption = (name,value)=>{
    let{isLoading} = this.state
    if(isLoading) return Utils.setToastAlert("Previous request processing","info");
    if(this.state[name] === value) return console.log("same option");
    this.setState({[name]:value,nfts:[],count:0,filterString:""},this.fetchNfts)
  }

  handlerAttr = (key,value) => {
    let {filterOptions} = this.state;
    let keys = filterOptions && filterOptions[key]?filterOptions[key]:[];
    let index = keys.findIndex(i => i === value);
    if(index !== -1){
      keys[index] = keys[keys.length - 1];
      keys.pop()
    }else {
      keys.push(value);
    }
    filterOptions[key] = keys;
    this.setState({filterOptions})
  }
  handlerSelectState = (key) => {
    let {openTabs} = this.state;
    let index = openTabs.findIndex(i => i === key);
    if(index !== -1){
      openTabs[index] = openTabs[openTabs.length - 1];
      openTabs.pop()
    }else {
      openTabs.push(key);
    }
    this.setState({openTabs})
  }
  renderAttributes = () =>{
    let {filterOptions,openTabs} = this.state;
    return Attributes.map((data,i)=>{
      let [count,key] = Object.keys(data);
      let index = openTabs.findIndex(i => i === key) !== -1;

      // console.log(key);
      // console.log(data[key],data[count] );
      return <div className="attributes_values" key={key+i}>
        <p className="attributes_type" onClick={()=>this.handlerSelectState(key)}>
          <span>{key} ({Utils.preety(data[count],0)})</span>
          {!index?<Plus />:<Close />}
        </p>
        {!!index && <div className="attributes_options">
           {data[key].map((item,j)=>{
             // console.log(item);
             let keys = filterOptions && filterOptions[key]?filterOptions[key]:[];
             let selected = keys.filter(i => i === item.key).length > 0;
            return <p key={item.key+i} className={`${!!selected?"selected":""}`} onClick={()=>this.handlerAttr(key,item.key)}>{item.key} ({Utils.preety(item.items,0)})</p>
          })}
        </div>}
      </div>
    })
  }

  render(){
    let {count,start,limit,page,nfts,isLoading,category,filterString,showFilter,isGrid} = this.state
    let categoryOptions = {all:"All Fox",onAuction:"On Auction",now:"Buy Now",hasOffers:"Has Offers",new:"Newest Auction",low:"Price: Low to high",high:"Price: High to low"};
    showFilter = showFilter || window.innerWidth > 768;

    return(
      <main className="main">
          <div className="neo-banner">
            <ul className="neo-menu clouds">
              <li><Link to="/fox555/mint">Mint</Link></li>
              <li><Link to="/fox555/gallery" className="neo-active">Gallery</Link></li>
              <li><Link to="/fox555/swap">Swap</Link></li>

            </ul>
            <img src={Banner} alt="" />

          </div>

          <div className="container-fluid" >
          <div className="row" ref={this.myRef} >
            <div className="col-12 col-md-3">
              {!!showFilter && <div className="attribute_filter">
                <div className="attribute_filter_body">
                  <div className="attribute-filter-header">
                    <p onClick={()=>this.setState({filterOptions:{}})}>Clear all</p>
                    <p onClick={this.fetchFilterData}>Done</p>
                  </div>
                  {this.renderAttributes()}
                </div>
              </div>}
              <button className="attribute-filter-btn-mobile" onClick={()=>this.setState({showFilter:!showFilter})}>
                <span>Filters</span>
              </button>
            </div>
            <div className="col-12 col-md-9">
              <div className="row no-gutters">

              <div className="col-12">
                <div className="ik__home__filter">
                  <form onSubmit={this.fetchFilterData} className="main__filter-search">
                    <input
                      type="text"
                      placeholder="Search..."
                      value = {filterString}
                      onChange={(e)=>this.setState({filterString:e.target.value})}
                      />
                    <button
                      type="button"
                      onClick={this.fetchFilterData}
                      >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
                    </button>
                  </form>
                  <MenuSelect3 options={categoryOptions} value={category} onOption={(e)=>this.onOption("category",e)}/>
                  <GridToggler2
                    data={{isGrid}}
                    handleGrid={(e)=>this.setState(e)}
                    />

                </div>
              </div>
        				<div className="col-12" >
                  <div className="row no-gutters">
                    {!isLoading && !!nfts.length && this.renderNft()}
                    {!!isLoading  && this.renderDummyNft()}
                    {!isLoading  && !nfts.length && <p>No result found</p>}
            			</div>
        			</div>
              <div className="col-12">
                <Paginator  data={{count,start,limit,page,size:nfts.length}} handler={this.handlePaginator}/>
              </div>
              </div>
            </div>
          </div>
          </div>
    	</main>
    )
  }
}



export default Fox555Gallery;
