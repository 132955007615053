import React, { Component } from 'react'
import $ from "jquery";

import './style.scss'


class PopupContainer extends Component {
  constructor(props) {
      super(props)
      this.state = {
        identifier:"popup-container"+Math.floor(Math.random()*1e10),
      }
  }

  componentDidMount(){
   document.addEventListener('mouseup', this.fireEvents);
  }

   componentWillUnmount(){
    document.removeEventListener('mouseup', this.fireEvents);
  }

  fireEvents= (e)=>{
    let{identifier} = this.state;
    if ($(e.target).closest(`.${identifier}`).length === 0){
    this.props.onClose()
    }

  }
  render(){
    let{identifier} = this.state;
    let{className,title,onClose,children} = this.props;
    return(
      <div className={`popup__container ${className}`}>
        <div className="popup__container__box">
          <div className="popup__container__box__head">
            <h3 className="popup__title">{title}</h3>
            {!!onClose && <button className="popup__close" onClick={onClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>
            </button>}
          </div>
          <div className={`popup__container__box__inner ${identifier}`}>
            {children}
          </div>
        </div>
      </div>
    )
  }
}

let objectData = {

  PopupContainer,
}

export default objectData
