import React from 'react'
import styled from 'styled-components'
// import {Link} from 'react-router-dom'
import {Utils} from "../../helper"
import $ from "jquery";

const SubmitBtn = styled.button.attrs(props=>({
  className: `market__btn ${props.fullView?"market__btn__full":""}`,
  type:"button"
}))`
  background-color:${props =>props.red?"red":props.green?"green":"#6164ff"};
  &:hover {
    background-color: #222227;
  }
`
/*
* ModelBid
* it will get opened as popup
* when user want to place a bid on auction
* if success, page reload
* if failed page stay same
*/
class ModelBid extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isProcess:false,
        value:"",
        balance:0,
      }
    }
  componentDidMount(){
    const{price,bid,time} = this.props.data
    let _min = bid?bid:time?price:0;
    _min = Utils.floor(_min/1e6);
    if (_min) {
      this.setState({value:_min})
    }
    this.fetchBalance()
  }
  fetchBalance = async() =>{
    try {
      let address = Utils.viewCreator();
      let balance = await window.tronWeb.trx.getUnconfirmedBalance(address);
      balance = Utils.floor(balance/1e6);
      this.setState({balance})
    } catch (e) {

    }
  }

  closePopUp = () => {
    if(!this.state.isProcess){
      this.props.close()
    }
  }
  handleInput = (e)=>{
    let value = Utils.inputSafe(e.target.value);
    this.setState({value})
  }

  handlePlaceBid = async() =>{


    try {
      let{value,balance,isProcess} = this.state
      let {tokenId,owner,bid} = this.props.data;
      let address = Utils.viewCreator();
      if(!address) return $(".header__action-btn--signin").click();
      if(owner === address) return Utils.setToastAlert("looks like, NFT owner trying to bid");
      if(isProcess) return Utils.setToastAlert("Previous request is in process");

      if(!value) return Utils.setToastAlert('Bid amount can not be zero');
      if(value < 25) return Utils.setToastAlert('Minimum bid limit 25 TRX');

      // if(bidder === address) return console.log("already a bidder");
      value *= (40/39);
      if(balance < value) return Utils.setToastAlert('Insufficient account balance');

      value = Utils.ceil(value *  1e6,0);
      if(bid >= value) return Utils.setToastAlert("Please bid more than current highest bid");



      this.setState({isProcess:true})

      let parameter = [{type:'uint256',value:tokenId}]
      let options = {
        feeLimit:300 * 1e6,
        callValue:value,
        // shouldPollResponse:true
        }
      let txnObj = await window.tronWeb.transactionBuilder.triggerSmartContract(
        Utils.marketContract,
        "PutBid(uint256)",
        options,
        parameter)
      // console.log(txnObj);
      let signedtxn = await window.tronWeb.trx.sign(txnObj.transaction);
      await window.tronWeb.trx.sendRawTransaction(signedtxn);
      Utils.setToastAlert("Confirming transaction","success");
      this.setState({isProcess:false})
      this.props.reFetch()

    } catch (err) {
      this.setState({isProcess:false})
      console.log(err);
      if(err === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to bid on auction","error")
      }
    }
  }
  render() {
    let{value,balance,isProcess} = this.state
    let {price,time,bid} = this.props.data;
    let _min = bid?bid:time?price:0;


    let fee = value / 39;


    return (<div className="place-bid-popup">
      <div className="zoom-anim-dialog modal modal--form">
        <button className="popup_close" onClick={this.closePopUp}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>
        </button>

        <h4 className="sign__title">Place a bid</h4>

        <div className="sign__group sign__group--row">
          <label className="sign__label" htmlFor="placebid">Your bid</label>
          <div className="mode__bid_info">Balance : <p>{Utils.preety.floor(balance,2)}</p> TRX</div>
          <input
            id="placebid"
            type="number"
            name="placebid"
            className="sign__input"
            placeholder="Enter bid amount"
            value={value}
            onChange={this.handleInput}
            />
        </div>
        <div className="Bid__additional_info"><span>Must be at least {Utils.preety(_min/1e6,2)} TRX</span></div>
        <div className="Bid__additional_info"><span>Service fee</span><p>{Utils.preety.unsafe(fee)} TRX</p></div>
        <div className="Bid__additional_info"><span>You will pay</span><p>{Utils.preety.unsafe(fee+value)} TRX</p></div>


        <button className="sign__btn" onClick={this.handlePlaceBid}>
          Take a bid
          {isProcess && <div className="ball__pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>}
        </button>
        {isProcess && <p className="alert__msg">
          ** NOTE,<span>Bid once Confirmed, can not be cancelled</span>
        </p>}
      </div>

    </div>)
  }
}
/*
* CancelAuction
* it will cancel auction if created by user
* and meet necessary condition
* if success, page reload
* if failed page stay same
*/

class CancelAuction extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isProcess:false,
      }
    }
  handleCancelAuction = async() =>{

    try {
      let {tokenId,owner} = this.props.data;
      let {isProcess} = this.state;
      let address = Utils.viewCreator();
      if(!address) return $(".header__action-btn--signin").click();;
      if(owner !== address) return Utils.setToastAlert("looks like, current account is not owner of NFT");
      if(isProcess) return Utils.setToastAlert("Previous request is in process");

      this.setState({isProcess:true})

      let options = {
        feeLimit:300 * 1e6,
        callValue:0,
        // shouldPollResponse:true,
        }
        let marketInstance = await window.tronWeb.contract().at(Utils.marketContract);
        await marketInstance.cancelAuction(tokenId).send(options);
        Utils.setToastAlert("Confirming transaction","success");
        this.setState({isProcess:false})
        this.props.reFetch()


    } catch (err) {
      this.setState({isProcess:false})
      console.log(err);
      if(err === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to cancel auction","error")
      }

    }
  }
  render() {
    let {isProcess} = this.state;
    return (
      <SubmitBtn fullView={this.props.data.fullView} red onClick={this.handleCancelAuction}>
        Cancel
        {isProcess && <div className="ball__pulse">
          <div></div>
          <div></div>
          <div></div>
        </div>}
      </SubmitBtn>
      )
  }
}
/*
* AcceptAuction
* it will AcceptAuction auction if created by user
 either fixed or unlimited auction or timed auction after bidding period
 if bid more than min, released by both else only by creator
* and meet necessary condition
* if success, page reload
* if failed page stay same
*/

class AcceptAuction extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isProcess:false,
      }
    }

  handleAuctionAccept = async() =>{


    try {
      let {tokenId} = this.props.data;
      let {isProcess} = this.state;

      let address = Utils.viewCreator();
      if(!address) return $(".header__action-btn--signin").click();;
      if(isProcess) return Utils.setToastAlert("Previous request is in process");

      this.setState({isProcess:true})

      let instanceMarket = await window.tronWeb.contract().at(Utils.marketContract);
      await instanceMarket.releaseAuction(tokenId).send({
        feeLimit:300 * 1e6,
        callValue:0,
        // shouldPollResponse:true
      })
      Utils.setToastAlert("Confirming transaction","success");
      this.setState({isProcess:false})
      this.props.reFetch()

    } catch (err) {
      this.setState({isProcess:false})
      console.log(err);

      if(err === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to accept auction","error")
      }
    }
  }
  render() {
    let {isProcess} = this.state;
    return (
      <SubmitBtn fullView={this.props.data.fullView} green onClick={this.handleAuctionAccept}>
        Accept
        {isProcess && <div className="ball__pulse">
          <div></div>
          <div></div>
          <div></div>
        </div>}
      </SubmitBtn>
      )
  }
}
/*
* BuyFixedNft
* it will directly by a fixed price auction
* if success, page reload
* if failed page stay same
*/

class BuyFixedNft extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isProcess:false,
      }
    }

    buyFixedAuction = async() =>{

      try {
        let {isProcess} = this.state;
        let {tokenId,owner,price} = this.props.data;

        let address = Utils.viewCreator();
        if(!address) return $(".header__action-btn--signin").click();
        if(owner === address) return Utils.setToastAlert("looks like, NFT owner trying to buy");

        // if(balance < price) return console.log('insufficient balance');
        if(isProcess) return Utils.setToastAlert("Previous request is in process");

        // no cal difficulty for buy now
        price = Utils.ceil(price *  1.025,0);
        // price = Utils.ceil(price * 40 /39,0);

        let balance = await window.tronWeb.trx.getUnconfirmedBalance(address);

        if(balance < price) return Utils.setToastAlert('Insufficient account balance');

        this.setState({isProcess:true})

        let parameter = [{type:'uint256',value:tokenId}]
        let options = {
          feeLimit:300 * 1e6,
          callValue:price,
          // shouldPollResponse:true
          }
        let txnObj = await window.tronWeb.transactionBuilder.triggerSmartContract(
          Utils.marketContract,
          "buyFixedRateNft(uint256)",
          options,
          parameter)
        let signedtxn = await window.tronWeb.trx.sign(txnObj.transaction);
        await window.tronWeb.trx.sendRawTransaction(signedtxn);
        Utils.setToastAlert("Confirming transaction","success");
        this.setState({isProcess:false})
        this.props.reFetch()

      } catch (err) {
        console.log(err);
        this.setState({isProcess:false})
        if(err === "Confirmation declined by user"){
          Utils.setToastAlert("Confirmation declined","error")
        }else {
          Utils.setToastAlert("Failed to buy auction","error")
        }
      }
    }
  render() {
    let{price} = this.props.data;
    let {isProcess} = this.state;
    price = Utils.ceil(price *  1.025,0);
    // price = Utils.ceil(price * 40 /39,0);


    return (
      <SubmitBtn fullView={this.props.data.fullView} onClick={this.buyFixedAuction}>
        {!isProcess && `Buy - ${Utils.preety.ceil(price/1e6,2)} TRX`}
        {isProcess && <div className="ball__pulse">
          <div></div>
          <div></div>
          <div></div>
        </div>}
      </SubmitBtn>
      )
  }
}


/*
* ModelBid
* it will get opened as popup
* when user want to place a bid on auction
* if success, page reload
* if failed page stay same
*/
class InsertAuction extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isProcess:false,
        txnLayer:0,
        time:'',
        price:'',
        auctionType:"fixed"
      }
    }
  closePopUp = () => {
    if(!this.state.isProcess){
      this.props.close()
    }
  }
  handleInput = (e)=>{
    let price = Utils.inputSafe(e.target.value);
    this.setState({price})
  }
  handleInputTime = (e)=>{
    // let time = Utils.inputSafe(e.target.value);
    let time = e.target.value;
    time = new Date(time).getTime();
    console.log(time);
    if(e.target.style.borderColor && time > Date.now()){
      e.target.style.borderColor = "";
    }
    if(time <= Date.now()){
      time = 0;
      e.target.style.borderColor = "red";
    }
    this.setState({time})
  }

  handelInsertAuction = async(e) =>{
    let{price,time,auctionType,isProcess} = this.state;
    let address = Utils.viewCreator();

    if(!address) return $(".header__action-btn--signin").click();

    // console.log(address);
    let {tokenId,owner} = this.props.data;
    time = Math.floor((time - Date.now())/1000)
    try {
      if(!price && auctionType === "timed" ) return Utils.setToastAlert("Please enter minimum bid amound");
      if(!price && auctionType === "fixed" ) return Utils.setToastAlert("Please enter NFT price");

      if(time <= 0 && auctionType === "timed") return Utils.setToastAlert("Please provide auction expiry time");

      if(owner !== address) return Utils.setToastAlert("looks like, current account is not owner of NFT");
      if(isProcess) return Utils.setToastAlert("Previous request is in process");

      this.setState({isProcess:true})
      e.target.innerText = "Checking NFT Approval";

      let NftInstance = await window.tronWeb.contract().at(Utils.NftContract);
      let allowed = await NftInstance.isApprovedForAll(address,Utils.marketContract).call();
      await new Promise( res =>{
        if(allowed){
          res()
        }else {
          e.target.innerText = "Approving NFT";
          let result = NftInstance.setApprovalForAll(Utils.marketContract, true).send({
            feeLimit:50 * 1e6,
          });
          res(result)
        }
      })
      this.setState({txnLayer:1})
      e.target.innerText = "Publishing on market place";

      let options = {
        feeLimit:300 * 1e6,
        callValue:0,
        // shouldPollResponse:true
      }
      let marketInstance = await window.tronWeb.contract().at(Utils.marketContract);

        await new Promise(res =>{
            if(auctionType === "unlimited"){
              let txnObj = marketInstance.unlimitedAuctions(tokenId).send(options);
              res(txnObj)
            }else if (auctionType === "timed") {
              price *= 1e6;
              let txnObj = marketInstance.timedAuction(tokenId,price,time).send(options)
              res(txnObj)
            }else {
              price *= 1e6;
              let txnObj = marketInstance.fixedPrice(tokenId,price).send(options)
              res(txnObj)
            }
        })
        // console.log(txid);
        this.setState({txnLayer:2,isProcess:false})
        e.target.innerText = "Published on market place";
        Utils.setToastAlert("Confirming transaction \n view on tronscan","success");
        this.props.reFetch()

    } catch (err) {
      this.setState({isProcess:false})
      console.log(err);
      e.target.innerText = "Publish on Market";
      if(err === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to list on market place","error")
      }
    }
  }
  handleAuctionType=(auctionType)=>{
    let{isProcess} = this.state;
    if(isProcess) return Utils.setToastAlert("Previous request is in process");
    this.setState({auctionType})
  }
  render() {
    let{price,time,auctionType,txnLayer,isProcess} = this.state;
    return (<div className="place-bid-popup">
      <div className="zoom-anim-dialog modal modal--form">
        <button className="popup_close" onClick={this.closePopUp}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>
        </button>

        <h4 className="sign__title">Publish NFT for sale</h4>
          <div className="col-12">
            <div className="sign__group sign__group--row">

              <ul className="sign__radio sign__radio--single">
                <li>
                  <input id="type1" type="radio" name="type"  onChange={()=>this.handleAuctionType("fixed")} checked={auctionType === "fixed"}/>
                  <label htmlFor="type1">Fixed price</label>
                </li>
                <li>
                  <input id="type2" type="radio" name="type"onChange={()=>this.handleAuctionType("timed")} checked={auctionType === "timed"} />
                  <label htmlFor="type2">Timed auction</label>
                </li>
                <li>
                  <input id="type3" type="radio" name="type" onChange={()=>this.handleAuctionType("unlimited")} checked={auctionType === "unlimited"}/>
                  <label htmlFor="type3">Unlimited auction</label>
                </li>
              </ul>
            </div>
          </div>

        {auctionType !== "unlimited" && <div className="sign__group sign__group--row">
          <label className="sign__label" htmlFor="placebid">
            {auctionType === "fixed" ? "Price":"Minimum bid"}
          </label>
          <input
            id="placebid"
            type="number"
            name="placebid"
            className="sign__input"
            placeholder={auctionType === "fixed" ? "Enter Price for NFT":"Enter Minimum bid"}
            value={price}
            onChange={this.handleInput}
            />
        </div>}
        {auctionType === "timed" && <div className="sign__group sign__group--row">
            <label className="sign__label" htmlFor="placebid">Expiration Time</label>
            <input
              id="placebid"
              type="datetime-local"
              name="placebid"
              className="sign__input"
              placeholder="Enter auction start time"
              onChange={this.handleInputTime}
              />
          <span className="sign__text sign__text--small">
            Expiration Duration { Utils.getTimeOffset(time/1000,true)}
          </span>
        </div>}

        {isProcess && <div className="transaction__process">
          <div className = {txnLayer > 0?"processing__bar active":"processing__bar"}>
            <span></span> {txnLayer > 0?"NFT Approved":"Approving NFT"}
          </div>
          <p>.</p>
          <p>.</p>
          <p>.</p>
          <div className = {txnLayer > 1?"processing__bar active":"processing__bar"}>
            <span></span> {txnLayer > 1?"Auction published":"Publishing on market"}
          </div>
        </div>}

        <button className="sign__btn" onClick={this.handelInsertAuction}>Publish on Market</button>
      </div>
    </div>)
  }
}



export {
  ModelBid, //for place bid
  CancelAuction,
  AcceptAuction,
  BuyFixedNft,
  InsertAuction,
}
