import React, { Component } from 'react'
import styled from 'styled-components'
import $ from "jquery";
import {Utils} from "../../helper"
import * as Parts from "../parts"
import tronWeb from "../../tronweb"

import {ReactComponent as Question} from '../../images/question.svg';
import {ReactComponent as PlusIcon} from '../../images/plus.svg';
import './deployer.scss'
import  TRC721 from "./TRC721"

const CreateBtn = styled.button.attrs(props=>({
  className: `sign__btn ${props.fullView?" full__btn":props.largeView?" large__btn":""}`,
  type:"button"
}))``


const BackBtn = styled.button.attrs(props=>({
  className: `sign__btn red__btn ${props.fullView?" full__btn":props.largeView?" large__btn":""}`,
  type:"button"
}))``



const SignGroup = (props) =>{
  return <div className="sign__group sign__group_deploy_page">
    {props.children}
  </div>
}

const InputLabel = (props) =>{
  let{htmlFor,title,tooltip} = props
  return(
    <label className="sign__label" htmlFor={htmlFor}>
    <p>{title}</p>
    <Question />
    <div className="tooltip">
      <p className="tooltip__text">{tooltip}</p>
    </div>
    </label>
  )
}



class ContractDeployer extends Component{
  constructor(props) {
      super(props)

      this.state = {
        address:"",
        name:"",
        symbol:"",
        royality:"",
        receiver:"",

        faqActive:1,

        fees:25*1e6,
        preview:false,
        isProcess:false,

        energy:3727873,
        bandwidth:21960
      }
  }
  componentDidMount (){
    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);
      let {address} = account
      if(address){
        // address = "TPssiPJ6vLdRx9P8jE4NNTZrLofKoqFVYY";

        this.setState({address})
      }else {
        this.setState({address:"",fees:25*1e6})
      }
    })
  }


  handleRoyality = e =>{
    let{receiver,address} = this.state;
    let input = Utils.inputNumber(e.target.value,1);
    input = input >7.5?7.5:input;
    receiver = receiver || address;
    this.setState({royality:input,receiver})
  }

  handleInput = e =>{
    let name = e.target.name;
    let value = e.target.value;
    this.setState({[name]:value})
  }


  handleGenerateCollection = async()=>{
    try {
      let{name,symbol,royality,fees,address,receiver,isProcess,bandwidth,energy} = this.state;

      if(!address) return $(".header__action-btn--signin").click();
      if(isProcess) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isProcess:true})
      royality = royality || 0;
      royality = royality * 100;
      receiver = !royality?Utils.Zero_address:receiver?receiver:address;

      let _account = await window.tronWeb.trx.getAccountResources(address);
      let {EnergyLimit,EnergyUsed,NetLimit,NetUsed} = _account;
      EnergyLimit = EnergyLimit || 0;
      EnergyUsed = EnergyUsed || 0;
      NetLimit = NetLimit || 0;
      NetUsed = NetUsed || 0;
      // freeNetLimit = freeNetLimit || 0;
      // freeNetUsed = freeNetUsed || 0;

      let _bandWidth = NetLimit - NetUsed;
      let _energy = EnergyLimit - EnergyUsed;

      let _bandWidthFees = 0;
      let _energyFees = 0;

      if(_bandWidth < bandwidth){
        _bandWidthFees = (bandwidth - _bandWidth)*1000;//in weii of TRX
      }
      if(_energy < energy){
        _energyFees = (energy - _energy)*280;//in weii of TRX
      }

      let totalValue = fees + _bandWidthFees + _energyFees;


      let balance = await window.tronWeb.trx.getUnconfirmedBalance(address);
      balance = Utils.floor(balance);

      if(!balance || balance <= totalValue){
        this.setState({isProcess:false})
        return Utils.setToastAlert("Not sufficient TRX balance to cover transaction fees.");
      }

      const options = {
        name,//Contract name string
        abi:TRC721.abi,
        bytecode:TRC721.bytecode,
        callValue:fees,
        userFeePercentage:100,
        feeLimit:5000 * 1e6,
        parameters:[name,symbol,receiver,royality]
      }

      let tokenObj = await window.tronWeb.transactionBuilder.createSmartContract(options,address);
      // console.log(tokenObj);
      const signedtxn = await window.tronWeb.trx.sign(tokenObj);
      await window.tronWeb.trx.sendRawTransaction(signedtxn);

     this.setState({isProcess:false,preview:false})
     Utils.setToastAlert("Transaction submitted for deploying New TRC721 contract","success")

    } catch (e) {
      // console.log(e);
      this.setState({isProcess:false})
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to deploy TRC721 contract","error")
      }
    }
  }



  handlePreview = ()=>{
    let{name,symbol,royality,receiver} = this.state
    if(!name) return Utils.setToastAlert("Token name required");
    if(name.length <=2 || name.length >32) return Utils.setToastAlert("Token name invalid, select between 3-32");
    if(!symbol) return Utils.setToastAlert("Token symbol required");
    if(symbol.length <2 || symbol.length >8) return Utils.setToastAlert("Token symbol invalid, select between 2-8");
    if(!!royality && receiver.length !== 34) return Utils.setToastAlert("Invalid royality receiver address length");
    if(!!royality && !tronWeb.isAddress(receiver)) return Utils.setToastAlert("Invalid royality receiver address");

    this.setState({preview:true});
  }



  render(){
    let{name,symbol,royality,receiver,preview,address,isProcess,faqActive,bandwidth,energy} = this.state;
    let title = !preview?"Create TRC721":"Preview Details"


    return(
      <Parts.CollectionMain tab="deploy">

        <div className="col-12 col-md-12 col-lg-8 offset-lg-2">

          <div className="collection__create__title">
            <div className="collection__create__title__body">
              <div className="collection__create__p">{title}</div>
            </div>
          </div>

          <div className="row ">
            <div className="col-12 col-lg-6">

                 <SignGroup>
                   <InputLabel htmlFor="name" title="Name" tooltip="Name of TRC721 tokens. Name can not be changed after deployment."/>
                     <input
                       id="name"
                       type="text"
                       name="name"
                       className="sign__input"
                       placeholder="Kraft NFT"
                       value={name}
                       onChange={this.handleInput}
                       readOnly={!!preview}
                       autoFocus
                       />
                 </SignGroup>
                 <SignGroup>
                   <InputLabel htmlFor="symbol" title="Symbol" tooltip="Symbol of TRC721 tokens. Symbol can not be changed after deployment."/>
                     <input
                       id="symbol"
                       type="text"
                       name="symbol"
                       className="sign__input"
                       placeholder="KNFT"
                       value={symbol}
                       onChange={this.handleInput}
                       readOnly={!!preview}
                       />
                 </SignGroup>
                 <SignGroup>
                   <InputLabel htmlFor="royality" title="Royality" tooltip="Royalty amounts are a percentage of the re-sale price, It may not be added, changed or revoked after deployment. Leave empty in case of no royality"/>
                     <input
                       id="royality"
                       type="number"
                       name="royality"
                       className="sign__input"
                       placeholder="0 - 7.5 %"
                       value={royality}
                       onChange={this.handleRoyality}
                       readOnly={!!preview}
                       />
                 </SignGroup>
                 {!!royality && <SignGroup>
                   <InputLabel htmlFor="receiver" title="Royalty Address" tooltip="Royalty receiver address should be an active TRON account"/>
                     <input
                       id="receiver"
                       type="text"
                       name="receiver"
                       className="sign__input"
                       placeholder="T.."
                       value={receiver}
                       onChange={this.handleInput}
                       readOnly={!!preview}
                       />
                 </SignGroup>}

                   <div className="btn__panel btn__panel_full">
                   {!preview && <CreateBtn onClick={this.handlePreview} fullView>Deploy</CreateBtn>}
                   {!!preview && !isProcess && <BackBtn
                     largeView
                     onClick={()=>this.setState({preview:false})}
                     disabled={!!isProcess}
                     >
                     Cancel
                   </BackBtn>}
                 {!!preview && <CreateBtn
                   largeView={!isProcess}
                   fullView={!!isProcess}
                   onClick={this.handleGenerateCollection}
                   disabled={!!isProcess}
                   >
                   Continue
                   {!!isProcess && <div className="ball__pulse">
                     <div></div>
                     <div></div>
                     <div></div>
                   </div>}
                 </CreateBtn>}
               </div>


            </div>
            <div className="col-12 col-lg-6">
             <div className="deployer__info">
               <div className="deployer__info__body">

                 <div className={`deployer__info__each ${faqActive === 1?"info__active":""}`}>
                   <div className="deployer__info__each_title">
                     Deploy TRC721 NFT contract<PlusIcon onClick={()=>this.setState({faqActive:faqActive !== 1?1:0})}/>
                   </div>
                   <div className="deployer__info__each_details">
                     It will be based on ERC721, with all standard functionality. NFTs can be minted on Kraftly and is automatically integrated with mintable collections.
                   </div>
                 </div>

                 <div className={`deployer__info__each ${faqActive === 2?"info__active":""}`}>
                   <div className="deployer__info__each_title">
                     Contract Owner <PlusIcon onClick={()=>this.setState({faqActive:faqActive !== 2?2:0})} />
                   </div>
                   <div className="deployer__info__each_details">
                     Contract will be deployed using your currently selected wallet account : {address}
                   </div>
                 </div>

                 <div className={`deployer__info__each ${faqActive === 3?"info__active":""}`}>
                   <div className="deployer__info__each_title">
                    Royalty on re-sales<PlusIcon onClick={()=>this.setState({faqActive:faqActive !== 3?3:0})} />
                   </div>
                   <div className="deployer__info__each_details">
                     This will include ERC2981 royalties on the contract. Any compatible marketplace (like Kraftly) will read these from the contract and automatically generate royalties on any sales for any NFT minted on this contract.
                   </div>
                   <div className="deployer__info__each_details">
                     Maximum royality percentage allowed on Kraftly marketplace is less or equal to 7.5%. According to statistic, Higher royality amount reduces the frequency of re-selling.
                   </div>
                 </div>

                 <div className={`deployer__info__each ${faqActive === 4?"info__active":""}`}>
                   <div className="deployer__info__each_title">
                     Contract creation cost<PlusIcon onClick={()=>this.setState({faqActive:faqActive !== 4?4:0})} />
                   </div>
                   <div className="deployer__info__each_details">
                     The contract creation costs 25 TRX + gas fees (approximately {Utils.preety.unsafe(bandwidth)} BandWidth ~ {bandwidth/1000} TRX +  {Utils.preety.unsafe(energy)} Enenrgy ~ {Utils.preety.unsafe(energy*420/1e6)} TRX), The payment is non-refundable.
                   </div>
                   <div className="deployer__info__each_details">
                     You can try freezing TRX to cover free BandWidth and Enenrgy for transaction.
                   </div>
                 </div>

                 <div className={`deployer__info__each ${faqActive === 5?"info__active":""}`}>
                   <div className="deployer__info__each_title">
                     Create Collection or Mint NFT<PlusIcon onClick={()=>this.setState({faqActive:faqActive !== 5?5:0})} />
                   </div>
                   <div className="deployer__info__each_details">
                     You can create any number of 'Blind Collection' , 'Mintable collection' or 'Upgradable Collection' at any time.
                   </div>
                   <div className="deployer__info__each_details">
                     Collection creating or NFT minting fees on custom contract are applied same as KNFT(Kraft Nft).
                   </div>
                 </div>

               </div>
             </div>
            </div>
          </div>
        </div>


</Parts.CollectionMain>

    )
  }
}

export default ContractDeployer
