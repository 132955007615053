import React, { Component } from 'react'
//import ReactTable from 'react-table'
import $ from 'jquery';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {Utils} from "../helper"
import tronWeb from "../tronweb"

import './crypto-cloud.scss';
import Banner1 from '../images/cloud-banner-1.jpeg';
import Banner2 from '../images/cloud-banner-2.jpeg';

import SideBanner from '../images/cloud-side-banner.jpeg';


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const CloudsContract = "TBiGmvZ6gbmP1EcZ9oBCSyfYPKfLrZpift";

const CreateBtn = styled.button.attrs({
  className:"sign__btn ",
  type:"button"
})``

class MintCloud extends Component{
  constructor(props) {
      super(props)
      this.state = {
        address:"",
        balance:0,
        isProcess:false,
        count:500,
        startAt:1631048400000,
        timeNow:Date.now(),
        slipage:2,
        price:1750 * 1e6,
      }
  }
  componentDidMount (){
    document.title = "Mint || Crypto clouds";
    let self = this;
    // this.fetchStartTime()
    // this.fetchCloudMinted()

    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      const {address} = account
      if(address){
        this.setState({address,balance:0},this.fetchUserBalance)
      }else {
        this.setState({address:"",balance:0})
      }
    })


      let timer = setInterval(function() {
        let timeLeft = self.state.startAt - Date.now();
        self.setState({timeNow:Date.now()})
              if (timeLeft <= 0) {
                clearInterval(timer);
              }
            }, 1000);
  }
  componentWillUnmount(){
    document.title = "Kraftly";
  }

  fetchStartTime = async() =>{
    try {
      let instance = await tronWeb.contract().at(CloudsContract);
      let time = await instance.startAt().call();
      time *= 1000;
      this.setState({startAt:time});
    } catch (e) {
      console.log(e);
    }
  }
  fetchCloudMinted = async() =>{
    try {
      let{price} = this.state;

      let instance = await tronWeb.contract().at(CloudsContract);
      let count = await instance.mintedTokens().call();
      count = tronWeb.toDecimal(count._hex);
      price *= (1.0005 ** count);
      price = Utils.ceil(price,0);

      this.setState({count,price})

    } catch (e) {
      console.log(e);
    }
  }

  fetchUserBalance = async()=>{
    let {address} = this.state
    try {
      let balance = await window.tronWeb.trx.getUnconfirmedBalance(address);
      balance = Utils.floor(balance,0);
      this.setState({balance})
    } catch (e) {
      console.log(e);
    }
  }
  handleMint = async() =>{
    try {
      let{address,balance,price,slipage} = this.state;
      if(!address)return $(".header__action-btn--signin").click();;
      // if(isProcess) return Utils.setToastAlert("Previous minting request processing");
      slipage = Utils.floor(slipage);
      balance = Utils.floor(balance);
      if(balance < price)return Utils.setToastAlert("Not sufficient TRX balance");
      let requiredBal = price / 100 * (100 + slipage) ;
      requiredBal = Utils.ceil(requiredBal,0);
      if(balance < requiredBal)return Utils.setToastAlert("Not sufficient TRX balance, please reduce slipage percentage");

      // this.setState({isProcess:true})
      let instance = await window.tronWeb.contract().at(CloudsContract);
      await instance.mintClouds(address).send({
        feeLimit:100000000,
        callValue: requiredBal,
      });
      this.setState({isProcess:false},this.fetchUserBalance);
      this.fetchRockEvents()

      Utils.setToastAlert('CRYPTO CLOUD successfully minted','success')

    } catch (e) {
      console.log(e);
      // this.setState({isProcess:false});
      if (e === 'Confirmation declined by user') {
        Utils.setToastAlert('Confirmation declined','warning')
      }else {
        // console.log(e);
        Utils.setToastAlert("Something went wrong while minting CRYPTO CLOUD",'error')
      }
    }
  }

  handleSlipage = (e) =>{
    let slipage = e.target.value;
    slipage = slipage <= 25?slipage:25;

    this.setState({slipage})
  }

  render(){

    let {address,balance,count,startAt,price,slipage} = this.state
    let timeLeft = startAt - Date.now();

    let btnState = timeLeft > 0 || count === 500;

    let btnName = timeLeft > 0? Utils.getTimeOffset(startAt/1000): count === 500 ? "All CLOUD Minted":"Mint CRYPTO CLOUD"
    return(
      <main className="main">
          <div className="neo-banner">
            <ul className="neo-menu clouds">
              <li><Link to="/cypto-clouds/mint" className="neo-active">Mint</Link></li>
              <li><Link to="/cypto-clouds/gallery">Gallery</Link></li>
            </ul>
            <div className={`main__carousel-wrap`}>
              <OwlCarousel
                className="main__carousel"
                mouseDrag = {true}
                touchDrag = {true}
                dots = {true}
                loop = {true}
                autoplay = {true}
                autoplayHoverPause = {true}
                autoplayTimeout = {12000}
                smartSpeed = {8000}
                items={1}
                autoheight = {"true"}
                >

                <img src={Banner1} alt="" />
                <img src={Banner2} alt="" />

              </OwlCarousel>
            </div>
          </div>

    		<div className="container">
    				<div className="col-12">
    					<div className="neo-body crypto-cloud">
                <div className="row">
                  <div className="col-12">
                    <p className="neo-mint-count">{count} out of 500 minted</p>
                  </div>
    						<div className="col-12 col-md-8">

                  <div className="sign__group">
                    <label className="sign__label">Your Wallet</label>
                    <input
                      type="text"
                      className="sign__input"
                      placeholder="Please connect your wallet"
                      value={address}
                      readOnly
                      />
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="sign__group">
                        <label className="sign__label">Your Balance</label>
                        <input
                          type="text"
                          className="sign__input"
                          value={Utils.preety.floor(balance/1e6,1)+" TRX"}
                          readOnly
                          />
                      </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="sign__group">
                          <label className="sign__label">Current Price</label>
                          <input
                            type="text"
                            className="sign__input"
                            value={Utils.preety.ceil(price/1e6,1)+" TRX"}
                            readOnly
                            />

                      </div>
                      </div>
                  </div>



                  <div className="sign__group slipage-tab">
                    <label className="sign__label">Slipage*</label>
                    <div className="slipage-btn-group">
                      <button onClick={()=>this.setState({slipage:2})}>2%</button>
                      <button onClick={()=>this.setState({slipage:5})}>5%</button>
                      <button onClick={()=>this.setState({slipage:10})}>10%</button>

                      <input
                        type="number"
                        step={1}
                        value={slipage}
                        onChange={this.handleSlipage}
                        />
                    </div>

                  </div>
                  <CreateBtn
                    onClick={this.handleMint}
                    disabled={btnState}
                    >
                    {btnName}
                  </CreateBtn>
                  <div className="sign__group">
                    <label className="sign__label price-note">NOTE</label>
                    <p>You need 1750 - 2250 TRX to mint 1 unit of CCloud.
                      CCloud price will keep going up(from 1750 TRX to max 2250 TRX) with more Clouds being minted.</p>
                    <p>You can adjust your slippage to prevent the transaction from failing due to price increment.</p>
                    <p>Extra slippage amount that has not been utilized will be refunded immediately.</p>
                    <p>If you do not have enough TRX please refill your wallet with TRX and reload this page and make sure you're logged in.</p>
                  </div>

    						</div>
                <div className="col-12 col-md-4">
                  <div className="neo-mint-body-right">
                    <div className="neo-mint-body-right-content">
                      <img src={SideBanner} alt="" />
                      <h2>You get a random "CLOUD"</h2>
                      <p>When you mint a CLOUD, you get a random CLOUD</p>
                    </div>
                  </div>
    						</div>
    					</div>
    				</div>

    			</div>
    		</div>
    	</main>
    )
  }
}

export default MintCloud;
