import React, { Component } from 'react'
import {Utils} from "../../helper";
import Data from "../../pages/market3p/data.js"

import api from "../../api"
import tronWeb from "../../tronweb"
import NftCard,{NftCardDummy,Nft3pCard} from "../nftCard/index.jsx"
import {TRC721Model} from "../../frag"

class OnSale extends Component {
  constructor(props) {
      super(props)
      let limit = 24;

      this.state = {
        nfts:[],
        address:this.props.data.address || "",
        isLoading:false,
        isLoaded:false,
        limit,
        auctions:[],
        tab:"TGsDs5v2KosDx2fqoQmSC5zVsjrQARMc4U",
        balances:[],
        tokens:JSON.parse(JSON.stringify(Data.getAllSupportedNfts())),

        isGrid:localStorage.getItem('isGrid') === "true",
      }
  }
  componentDidMount = async () =>{
      this.getChainData()
      // this.getNftHoldings()
  }

  getChainData = async()=>{
    const {tab} = this.state;
    if(tab === "TGsDs5v2KosDx2fqoQmSC5zVsjrQARMc4U"){
      this.getKnftData()
    }else {
      this.getOtherNftData()
    }
  }

  fetchNfts = async () =>{

      try {
        let{nfts,auctions,limit,isLoading} = this.state;
        let selectedIds = auctions.slice(nfts.length, nfts.length + limit);

        if(!selectedIds || !selectedIds.length) return console.log("no tokenid to query");
        if(isLoading) return Utils.setToastAlert("Please wait while we finish previous request");
        this.setState({isLoading:true})
        let payload ={tokenIds:selectedIds.map(i=>i.tokenId)}
        let res = await api.getBatchNft(payload);
        let{result} = res.data;
         result = selectedIds.map((item,i)=>{
           let{price,time,bid} = item;
          let _item = result.filter(i => i.tokenId === item.tokenId);
          console.log(_item);
          if(!_item || !_item.length) return null;

          let data = _item[0];

          data.time = time;
          data.bid = bid;
          data.price = price;
          return data;
        }).filter(i => !!i);

        this.setState({nfts:[...nfts,...result],isLoading:false})
      } catch (e) {
        console.log(e);
        this.setState({isLoading:false})
      }

  }



  getNftHoldings = async()=>{
      try {
        let { address,tokens} = this.state
        if(!address) return console.log("Wallet not connected");
        let instance = await tronWeb.contract().at(Data.getFactory());
        // .filter(i=> !i.hasOwnProperty("balance"))
        let _exchanges = tokens.map(i => i.market).filter(i => !!i);
        let _balances = await instance.getAuctionsCount(address,_exchanges).call();
        _balances = _balances.map((j,i)=> tronWeb.toDecimal(j));
        // console.log(_balances);
        tokens = tokens.map((item,i)=>{
          let _index = _exchanges.findIndex(j => j === item.market);
          if(_index !== -1){
            item.balance = _balances[_index];
          }
          return item;
        })
        // console.log(tokens);
        this.setState({tokens});
      } catch (e) {
        console.log(e);
      }
  }
  getKnftData = async()=>{
      try {
        const { address,tokens} = this.state
        if(!address) return console.log("Wallet not connected");

        let instanceMarket = await tronWeb.contract().at(Utils.marketContract);
        let tokenIds = await instanceMarket.getUserAuctiondTokenIds(address).call();
        tokenIds = tokenIds.map(i=> tronWeb.toDecimal(i)).reverse();
        tokens[0].balance = tokenIds.length;
        this.setState({tokens});

        let result = [[],[],[],[],[]];
        let fetchLimit = 200;

        await new Promise(async(resolve)=>{
          try {
            async function getInfo(){
              if(!tokenIds.length) return;
              let _selectedIds = tokenIds.splice(0,fetchLimit)
              let _info = await instanceMarket.getAuctionByBatch(_selectedIds).call();
              result[1] = [...result[1],..._selectedIds];
              result[2] = [...result[2],..._info[2]];
              result[3] = [...result[3],..._info[3]];
              result[4] = [...result[4],..._info[4]];
              getInfo()
            }

            await getInfo();
            resolve()
          } catch (e) {
            // console.log(e);
            resolve()
          }
        })

        // let result = await instanceMarket.getAuctionByBatch(tokenIds).call();
        // let tokenIds = result[1].map((j,i)=> tronWeb.toDecimal(j));
        let auctions = result[1].map((tokenId,i)=>{
           return{
             tokenId,
             price : tronWeb.toDecimal(result?.[2]?.[i]??0),
             time : tronWeb.toDecimal(result?.[3]?.[i]??0),
             bid : tronWeb.toDecimal(result?.[4]?.[i]??0),
             currencyId:"",
           }
        })
        // console.log(auctions);
        this.setState({auctions,isLoaded:true},this.fetchNfts);
      } catch (e) {
        console.log(e);
        this.setState({isLoaded:true});
      }
  }
  getOtherNftData = async()=>{
    const { address,tab,tokens} = this.state
    if(address){
      try {
        let _market = tokens.find(i=>i.nftAddress === tab).market;
        if(!_market) return console.log("Marketplace not deployed yet");
        let instanceMarket = await tronWeb.contract(Data.abi,_market)
        let auctions = await instanceMarket.getUserAuctions(address).call({_isConstant:true});
        // console.log(result);
        let tokenIds = auctions[0].map((j,i)=> tronWeb.toDecimal(j._hex));

        let payload ={tokenIds,nftAddress:tab}
        let res = await api.get3pBatchNft(payload);
        let{result} = res.data;
        // console.log(result);
        auctions = tokenIds.map((tokenId,i)=>{
            let _nftMeta = result.filter(i => i.tokenId === tokenId);
           let auctionInfo = {
             tokenId,
             price : tronWeb.toDecimal(auctions[1][i]._hex),
             time : tronWeb.toDecimal(auctions[2][i]._hex),
             bid : tronWeb.toDecimal(auctions[4][i]._hex),
             currencyId : tronWeb.address.fromHex(auctions[5][i]),
           }

           if(_nftMeta && _nftMeta.length){
             auctionInfo = Object.assign({},auctionInfo,_nftMeta[0])
           }

           return auctionInfo;
        })
        // console.log(auctions);
        this.setState({auctions,isLoaded:true});
      } catch (e) {
        console.log(e);
        this.setState({isLoaded:true});
      }
    }
  }




  renderNft = () =>{
    let{nfts,tab,isGrid} = this.state;
    let layout={
      isAutoHeight:true,
      isSmallScale:true,
      isMobile: !!isGrid,
    }
    // console.log(nfts);
    return nfts.map((item,i)=>{

      if(!!isGrid){
        return <div className="col-6 col-sm-4 col-lg-3 col-xl-2" key={item.tokenId+tab+"smallA"}>
          <NftCard data={item} layout={layout} key={item.tokenId+tab+"smallB"}/>
        </div>
      }

      return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={item.tokenId+tab+"largeA"}>
        <NftCard data={item} layout={layout} key={item.tokenId+tab+"largeB"}/>
      </div>

    })
  }
  render3pNft = () =>{
    let{auctions,address,tab,isGrid} = this.state;
    let{user} = this.props.data;
    let{username,name,verified,blocked} = user || {};
    let layout={
      isAutoHeight:true,
      isSmallScale:true,
      isMobile: !!isGrid,
    }

    return auctions.map((item,i)=>{
      item.owner = address;
      item.collectionId = tab;
      item.username = username;
      item.user_name = name;
      item.verified = verified;
      item.blocked = blocked;
      if(!!isGrid){
        return <div className="col-6 col-sm-4 col-lg-3 col-xl-2" key={item.tokenId+tab+"smallA"}>
          <Nft3pCard data={item} layout={layout} key={item.tokenId+tab+"smallB"}/>
        </div>
      }
      return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={item.tokenId+tab+"largeA"}>
        <Nft3pCard data={item} layout={layout} key={item.tokenId+tab+"largeB"}/>
      </div>
    })
  }
  renderDummyNft = () =>{
    let{isGrid} = this.state;
    let layout={
      isAutoHeight:true,
      isSmallScale:true,
      isMobile: !!isGrid,
    }
    let _w = window.innerWidth;
    let count = _w< 576?4:_w< 768?8:_w< 992?12:_w< 1200?15:16;
    if(isGrid){
      count = _w< 576?8:_w< 768?12:_w< 992?15:_w< 1200?16:18;
    }
      return new Array(count).fill(undefined).map((item,i)=>{
        if(!!isGrid){
          return <div className="col-6 col-sm-4 col-lg-3 col-xl-2" key={"smallAD"+i}>
            <NftCardDummy layout={layout} key={"smallB"+i}/>
          </div>
        }
        return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={"largeAD"+i}>
          <NftCardDummy layout={layout} key={"largeB"+i}/>
        </div>
      })
  }

  onOption = (value)=>{
    let{isLoading,tab,isLoaded} = this.state
    if(isLoading || !isLoaded) return Utils.setToastAlert("Previous request processing","info");
    if(tab === value) return console.log("same option");
    // console.log(value);
    this.setState({tab:value,auctions:[],nfts:[],isLoaded:false},this.getChainData)
  }


  handleUpdateTokenList = (tokens) =>{
    this.setState({tokens},this.getNftHoldings)
  }

    render() {
      let{tokens,balances,nfts,auctions,isLoading,isLoaded,tab} = this.state;
      let btnVisibility = auctions.length && nfts.length &&  auctions.length > nfts.length;
      // tab:"TGsDs5v2KosDx2fqoQmSC5zVsjrQARMc4U",

        return (
          <div className="tab-pane active">
            <TRC721Model.AllTokens
              data={{tokens,balances,tab,view:"market"}}
              onOption={(e)=>this.onOption(e)}
              handleUpdateTokenList={this.handleUpdateTokenList}
              key="market"
              />

            <div className="row no-gutters">
              {!!nfts.length && this.renderNft()}
              {!!auctions.length && tab !== "TGsDs5v2KosDx2fqoQmSC5zVsjrQARMc4U" && this.render3pNft()}
              {(!!isLoading || !isLoaded) && this.renderDummyNft()}
              {!!isLoaded && !auctions.length && <h2 style={{color:"#888",margin:"15px 10px"}}>No Auction found</h2>}

            </div>

            <div className="row row--grid">
            {!!btnVisibility && <div className="col-12">
                <button className="main__load" onClick={this.fetchNfts}>Load more</button>
              </div>}
            </div>

          </div>
        )
    }
}


export default OnSale
