
import React,{useEffect} from 'react'
import { BrowserRouter as Router, Route, Switch,useLocation} from 'react-router-dom'

import * as helper from '../helper'
import * as pages from './'
import * as CustomContract from './custom-contract'


function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

class App extends React.Component {
  constructor(props) {
      super(props)

      this.state = {}
  }
  // componentDidUpdate(prevProps, prevState){
  //   console.log("loaded");
  //   console.log(prevProps, this.props);
  // }

  // componentWillReceiveProps(nextProps){
  //   console.log(nextProps.history.action);
  //   if(nextProps.history.action === "PUSH"){
  //       this.setState({loaderTime:Date.now()})
  //       console.log("reload");
  //   }
  // }
  render(){
    return (
        <Router >
          <ScrollToTop />
            <Switch>
                  <Route path="/mintable" exact component={pages.HomeBlind} />
                  <Route path="/mintable/:collectionId/fill" exact component={pages.CollectionFill} />
                  <Route path="/mintable/:collectionId/gallery" exact component={pages.IkCollectionGallery} />
                  <Route path="/mintable/create" exact component={pages.Create} />
                  <Route path="/mintable/my-collections" exact component={pages.MyCollections} />


                  <Route path="/mintable/create-trc721" exact component={CustomContract.ContractDeployer} />
                  <Route path="/mintable/trc721" exact component={CustomContract.TRC721Contracts} />
                  <Route path="/mintable/my-trc721" exact component={CustomContract.MyContract} />
                  <Route path="/mintable/trc721/:address" exact component={CustomContract.TRC721Gallery} />


                  <Route component={helper.PageNotFound} />

            </Switch>
        </Router>
    )
  }
}

export default App
