import React, { Component } from 'react'
//import ReactTable from 'react-table'
import $ from 'jquery';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {Utils} from "../helper"
import tronWeb from "../tronweb"

import Banner from '../images/tech-memory-banner.jpg';
import SideBanner from '../images/tech-memory-side-banner.jpg';


const NeoContract = "TMUqAyK9GUkXLsVd46Vk9Z3DTKrkzrbPnk";

const CreateBtn = styled.button.attrs({
  className:"sign__btn ",
  type:"button"
})``

class MintRocks extends Component{
  constructor(props) {
      super(props)
      this.state = {
        address:"",
        balance:0,
        isProcess:false,
        count:0,
        limit:51,
        price:1850,
        startAt:1638921600000,
        timeNow:Date.now(),
      }
  }
  componentDidMount (){
    document.title = "Mint || Tech Memories";
    let self = this;
    // this.fetchStartTime()

    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      const {address} = account
      if(address){
        this.setState({address,balance:0},this.fetchUserBalance)
      }else {
        this.setState({address:"",balance:0})
      }
    })


      let timer = setInterval(function() {
        let timeLeft = self.state.startAt - Date.now();
              if (timeLeft > 0) {
                self.setState({timeNow:Date.now()})
              }else {
                self.fetchNeoEvents()
                clearInterval(timer);
              }
               }, 1000);

  }
  componentWillUnmount(){
    document.title = "Kraftly";
  }

  fetchStartTime = async() =>{
    try {
      let instance = await tronWeb.contract().at(NeoContract);
      let time = await instance.startAt().call();
      time *= 1000;
      // console.log(time);
      this.setState({startAt:time});
      if(time < Date.now()){
        this.fetchNeoEvents()
      }
    } catch (e) {
      console.log(e);
    }
  }
  fetchNeoEvents = async() =>{
    try {
      let res = await tronWeb.getEventResult(NeoContract,{size:100,eventName:"NeoMint"});
      if(!res.length) console.log("No Tech Memories minted");
      // console.log(res);
      this.setState({count:res.length})

    } catch (e) {
      console.log(e);
    }
  }

  fetchUserBalance = async()=>{
    let {address} = this.state
    try {
      let balance = await window.tronWeb.trx.getUnconfirmedBalance(address);
      balance = Utils.floor(balance);
      this.setState({balance})
    } catch (e) {
      console.log(e);
    }
  }
  handleMint = async() =>{
    try {
      let{address,isProcess,balance,price} = this.state;
      if(!address)return $(".header__action-btn--signin").click();;
      if(isProcess) return Utils.setToastAlert("Previous request processing");
      balance = Utils.floor(balance);

      if(balance < price * 1e6)return Utils.setToastAlert("Not sufficient TRX balance");
      // this.setState({isProcess:true})
      let instance = await window.tronWeb.contract().at(NeoContract);
      await instance.mintNeoCollection(address,1).send({
        feeLimit:100000000,
        callValue: price * 1e6,
      });
      this.setState({isProcess:false},this.fetchUserBalance);
      this.fetchNeoEvents()

      Utils.setToastAlert('Tech Memories succesfully minted','success')

    } catch (e) {
      console.log(e);
      this.setState({isLoading:false});
      if (e === 'Confirmation declined by user') {
        Utils.setToastAlert('Confirmation declined','warning')
      }else {
        // console.log(e);
        Utils.setToastAlert("Something went wrong while minting Tech Memories",'error')
      }
    }
  }

  render(){
    let {address,balance,count,startAt,limit,price} = this.state
    let timeLeft = startAt - Date.now();

    let btnState = timeLeft > 0 || count === limit;

    let btnName = timeLeft > 0? Utils.getTimeOffset(startAt/1000): count === limit ? "All Tech Memories Minted":"Mint Tech Memories"
    return(
      <main className="main">
          <div className="neo-banner">
            <ul className="neo-menu">
              <li><Link to="/tech-memory/mint" className="neo-active">Mint</Link></li>
              <li><Link to="/tech-memory/gallery">Gallery</Link></li>
            </ul>
            <img src={Banner} alt="" />
          </div>

    		<div className="container">
    				<div className="col-12">
    					<div className="neo-body">
                <div className="row">
                  <div className="col-12">
                    <p className="neo-mint-count">{count} out of {limit} minted</p>
                  </div>
    						<div className="col-12 col-md-8">

                  <div className="sign__group">
                    <label className="sign__label">Your Wallet</label>
                    <input
                      type="text"
                      className="sign__input"
                      placeholder="Please connect your wallet"
                      value={address}
                      readOnly
                      />
                  </div>
                  <div className="sign__group">
                    <label className="sign__label">Your Balance</label>
                    <input
                      type="text"
                      className="sign__input"
                      value={Utils.preety.floor(balance/1e6,1)+" TRX"}
                      readOnly
                      />
                  </div>
                  <div className="sign__group">
                    <label className="sign__label price-note">NOTE</label>
                    <p>You need {price} TRX to mint 1 Tech Memories. If you do not have enough TRX please refill your wallet with TRX and reload this page.</p>
                  </div>
                    <CreateBtn
                      onClick={this.handleMint}
                      disabled={btnState}
                      >
                      {btnName}
                    </CreateBtn>
    						</div>
                <div className="col-12 col-md-4">
                  <div className="neo-mint-body-right">
                    <div className="neo-mint-body-right-content">
                      <img src={SideBanner} alt="" />
                    </div>
                  </div>
    						</div>
    					</div>
    				</div>

    			</div>
    		</div>
    	</main>
    )
  }
}

export default MintRocks;
