import React, { Component } from 'react'
import styled from 'styled-components'

import {Logo,Form,Menu,Profile} from '../parts'
import tronWeb from "../../tronweb"
import {Utils} from "../../helper"

import '../../scss/header.scss';

const HeaderMain = styled.header.attrs({
  className: 'header',
})``
const HeaderContent = styled.div.attrs({
  className: 'header__content',
})``



class Header extends Component {
  constructor(props) {
      super(props)

      this.state = {

      }
  }
  componentDidMount(){
    this.checkTrxPrice()
   }
   checkTrxPrice = async()=>{
     try {
       let _event = await tronWeb.getEventResult("TQn9Y2khEsLJW1ChVWFMSMeRDow5KcbLSE",{eventName:"Snapshot",size:1});
       if(!_event) return console.log("Usdt price not found");
       // console.log(_event);
       let {token_balance,trx_balance} = _event[0].result;
       trx_balance = parseInt(trx_balance);
       token_balance = parseInt(token_balance);

       //output_amount = output_reserve*input_amount_with_fee/(input_reserve+input_amount_with_fee)
       // getInputPrice(trx_sold, address(this).balance, token_reserve);

        let usdtPrice = (token_balance * 1e6)/(trx_balance + 1e6);
        usdtPrice = Math.ceil(usdtPrice)/1e6;
        Utils.setUsdtRate(usdtPrice)

     } catch (e) {
      console.log(e);
     }
   }
    render() {
        return (
          <HeaderMain>
        		<HeaderContent>
        			  <Logo />
                <Form />
                <Menu />
                <Profile />

        			<button className="header__btn" type="button">
        				<span></span>
        				<span></span>
        				<span></span>
        			</button>
        		</HeaderContent>
        	</HeaderMain>


        )
    }

}
export default Header
