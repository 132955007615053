import React from "react";
import styled from 'styled-components'
import '../../scss/signUp.scss'
import $ from 'jquery';
import Utils from "../../helper/utils"
import api from "../../api"


const Wrapper = styled.div.attrs({
  className:'signUp-popup-main '
})``
const SupportWrapper = styled.div.attrs({
  className:'signUp-popup-body-main'
})``
const InnerWrapper = styled.div.attrs({
  className:'signUp-popup-body slideIn'
})``
const ContinueBtn = styled.button``
const SignInput = styled.input.attrs(props=>({
  className: props.err?"error":"",
}))``
const Redirect = styled.a.attrs({
  target:"_blank",
  rel:"noopener noreferrer",
  href:"/docs/terms.pdf",
})``
class SignUp extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      ref:'',
      name:'',
      username:'',
      terms:false,
      isLoading:false,
    }
  }
   componentDidMount(){
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get('r');
    if (ref) {
      this.setState({ref})
    }
    document.addEventListener('mouseup', this.fireEvents);
  }
  async componentWillUnmount(){
    document.removeEventListener('mouseup', this.fireEvents);
  }
  fireEvents= (e)=>{
    if ($(e.target).closest(".signUp-popup-body").length === 0
    &&
     $(e.target).closest(".login-btn").length === 0
   &&
   $(e.target).closest(".header__btn").length === 0){
      Utils.setToastAlert('Sign up process declined','info');
      $(".signUp-popup-body").toggleClass('slideIn slideOut');
      this.props.hide()
      }
  }

  handleSubmit = async()=>{
    const{ref,name,username,isLoading} = this.state
    try {
      // console.log(this.state);

      if(!window.tronWeb) return $('#nologin-popup').show();
      if(!window.tronWeb.defaultAddress.base58)return Utils.setToastAlert("Please login your tron wallet",'info');
      let address = window.tronWeb.defaultAddress.base58;
      if(username === ref) return Utils.setToastAlert("Username and referral code can not be same");
      if(isLoading) return Utils.setToastAlert("Please wait while wallet connecting");
      this.setState({isLoading:true});

      let time = await api.getServerTime();
      time = time.data.time;

      let hexMsg = window.tronWeb.toHex({t:time});
      const signedMsg = await window.tronWeb.trx.sign(hexMsg,null, false);
      // let result = await window.tronWeb.trx.verifyMessage(hexMsg,signedMsg,address);


      let payload = ({address,ref,name,username,hexMsg,signedMsg})
      await api.insertUser(payload);
      // console.log(res);
      this.props.handleSignUp(address,name,username)
      Utils.setCreator(address)
      Utils.setToastAlert("Account successfully created","success");
    } catch (e) {
      // console.log(e);
      // console.log(e.response);
      this.setState({isLoading:false});
      if (e === 'Confirmation declined by user') {
        Utils.setToastAlert('Confirmation declined','warning')
      }else {
        // console.log(e);
        let msg = e?.response?.data?.message??`Failed to create account for you.`;
        Utils.setToastAlert(msg,'error')
      }
    }
  }
  render(){
    const {ref,name,username,terms,isLoading} = this.state;
    return(
      <Wrapper>
        <SupportWrapper>
          <InnerWrapper>
            <h1>Sign up</h1>

              <div className="form-group">
                <SignInput
                  name="username"
                  value={username}
                  err={!Utils.validateUserame(username)}
                  onChange={(e)=>(this.setState({username:e.target.value}))}

                />
              <label htmlFor="name">Enter username (required)</label>

              </div>
            <div className="form-group">
              <SignInput
                name="name"
                value={name}
                err={!Utils.validateName(name)}
                onChange={(e)=>(this.setState({name:e.target.value}))}

              />
            <label htmlFor="name">Enter Public Name (optional)</label>

            </div>
            <div className="form-group">
              <SignInput
                name="ref"
                value={ref}
                type="text"
                onChange={(e)=>(this.setState({ref:e.target.value}))}
              />
            <label htmlFor="ref">Enter Referral Code (optional)</label>

            </div>
            <div className="terms-group">
              <input
                id="terms"
                type="checkbox"
                checked={terms}
                onChange={(e)=>this.setState({terms : !terms})}
              />
              <label htmlFor="terms">
                <span>I have read and agree to the Terms of Service. <Redirect>Kraftly's Terms</Redirect></span>
              </label>

            </div>
            <ContinueBtn
              disabled = {!Utils.validateUserame(username) || !Utils.validateName(name) || !terms}
              onClick={this.handleSubmit}
              >
              {!isLoading?"Continue Signup":""}

              {!!isLoading &&  <div className="ball__pulse">
                <div></div>
                <div></div>
                <div></div>
              </div>}
            </ContinueBtn>
          </InnerWrapper>
        </SupportWrapper>
      </Wrapper>
    )
  }
}
export default SignUp
