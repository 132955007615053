import React, { Component,Fragment} from 'react'
// import {Utils} from "../../helper"


class CanvasArt extends Component{
  constructor(props) {
      super(props)

      this.state = {
        isPlaying: false,
        progress: 0,
        isMuted: true,
      }
  }

  componentDidMount (){
    this.main()
  }

  main() {
    const canvas = document.querySelector("#glCanvas");
    // Initialize the GL context
    const gl = canvas.getContext("webgl");

    // Only continue if WebGL is available and working
    if (gl === null) {
      alert("Unable to initialize WebGL. Your browser or machine may not support it.");
      return;
    }

    // Set clear color to black, fully opaque
    gl.clearColor(0.0, 0.4, 1, 1.0);
    // Clear the color buffer with specified clear color
    gl.clear(gl.COLOR_BUFFER_BIT);
  }

  render(){
    return(
      <Fragment>
        <canvas id="glCanvas" width="640" height="480"></canvas>

    	</Fragment>
    )
  }
}

export default CanvasArt;
