import React, { Component,Fragment } from 'react'
import {Utils,SearchNotFound} from "../helper"
import api from "../api"
import './collection.scss'
import * as Parts from "./parts"

import {MintableCardDummy,MintableCardDummyMini,MintableCard,MintableCardGrid} from "./cards.jsx"
import {Paginator,MenuSelect3,GridToggler} from "../frag"




class IkCollections extends Component{
  constructor(props) {
      super(props)

      let limit = 12;
      const urlParams = new URLSearchParams(window.location.search);
      let page = urlParams.get('page');
      page = Utils.floor(page)
      page = page && page > 0?page:1;
      let start = page > 4?page -3:1;

      let filterString = this.props.filterString ||"";

      this.state = {
        filterString,
        limit,
        page,
        start,
        count:0,
        isProcess:false,
        collections:[],
        category:"ongoing",
        type:0,
        isSmall:window.innerWidth < 768,
        isGrid:localStorage.getItem('isGrid') === "true",
      }
      this.myRef = React.createRef();
  }
  componentDidMount (){
    this.mounted = true;
    this.fetchCollections()
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  setState(state,callback){
    if (this.mounted){
      super.setState(state,callback);
    }
  }

  fetchCollections = async()=>{
    let{category,type,isProcess,limit,page,filterString} = this.state;
      try {
        if(isProcess) return Utils.setToastAlert("Previous request processing","info");
        this.setState({isProcess:true})
        let payload = {category,type,limit,page,filterString}
        let res = await api.getIkCollections(payload);
        // console.log(res);
        let{result,count} = res.data;
        this.setState({collections:result,count,isProcess:false},this.updatePagingUrl)
      } catch (e) {
        let msg = e?.response?.data?.message??"No Collection Found found";
        Utils.setToastAlert(msg,"error");
        // console.log(e.response);
        this.setState({isProcess:false})
      }
  }
  fetchFilterData = async(event) =>{
    event.preventDefault()
    this.setState({page:1,start:1,count:0,collections:[]},this.fetchCollections)
  }
  updatePagingUrl = () =>{
    let{page,filterString} = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("page", page)
    let params = urlParams.toString();
    if (window.history.pushState) {
      let url = `${window.location.pathname}?${params}`;
      window.history.pushState(null, null,url);
    }

    if(this.props.callback){
      this.props.callback(filterString)
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let{page,filterString} = this.state;
      const urlParams = new URLSearchParams(window.location.search);
      let _page = urlParams.get('page');
      _page = Utils.floor(_page);
      if(_page !== page){
        page = _page && _page > 0?_page:1;
        let start = page > 4?page -3:1;
        this.setState({count:0,collections:[],page,start},this.fetchCollections)
      }else if (this.props.callback && nextProps.filterString !== filterString) {
        this.setState({collections:[],count:0,page:1,start:1,filterString:nextProps.filterString},this.fetchCollections)
      }
     }
   renderNft = () =>{
     let{collections,isSmall,isGrid} = this.state;
     return collections.map((data,i)=>{
       let{address,minted} = data;
       if(!!isSmall && !!isGrid){
         return <div className="col-6 col-sm-4 col-lg-2" key={i}>
           <MintableCardGrid data={data} key={i+address+minted+"small"}/>
         </div>
       }
       return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={i}>
         <MintableCard data={data} key={i+address+minted+"large"}/>
       </div>
     })
   }
  renderDummyNft = () =>{
    let{isSmall,isGrid} = this.state;

    let count = isSmall&&isGrid ?12:window.innerWidth < 576?3:window.innerWidth < 992?6:window.innerWidth < 1200?9:12;
      return new Array(count).fill(undefined).map((item,i)=>{
        if(!!isSmall && !!isGrid){
          return <div className="col-6 col-sm-4 col-lg-2" key={i}>
            <MintableCardDummyMini key={i+"small"}/>
          </div>
        }
        return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={i}>
          <MintableCardDummy key={i+"large"}/>
        </div>
      })
  }

  onOption = (name,value)=>{
    let{isProcess} = this.state
    if(isProcess) return Utils.setToastAlert("Previous request processing","info");
    if(this.state[name] === value) return console.log("same option");
    this.setState({[name]:value,collections:[],page:1,start:1,count:0},this.fetchCollections)
  }
  handlePaginator = (value) =>{
    this.setState(value,this.fetchCollections)
    this.myRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  render(){
    let {filterString,collections,type,isProcess,category,isSmall,isGrid,count,start,limit,page} = this.state
    let categoryOptions = {all:"All Collection",upcoming:"Up Coming",ongoing:"Mint Now",completed:"Sold Out"};
    let typeOptions = {0:"All Types",1:"Blind Mintable",4:"Fixed price"};


    return(
      <Fragment>
          <div className="col-12" ref={this.myRef}>
            <div className="ik__home__filter">
              <form onSubmit={this.fetchFilterData.bind(this)} className="main__filter-search">
                <input
                  type="text"
                  placeholder="Search collection Id , name"
                  value = {filterString}
                  onChange={(e)=>this.setState({filterString:e.target.value})}
                  />
                <button
                  onClick={this.fetchFilterData}
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
                </button>
              </form>
              <MenuSelect3 options={categoryOptions} value={category} onOption={(e)=>this.onOption("category",e)} key={category}/>
              <MenuSelect3 options={typeOptions} value={type} onOption={(e)=>this.onOption("type",e)} key={type}/>
              <GridToggler
                data={{isGrid,isSmall}}
                handleGrid={(e)=>this.setState(e)}
                />
            </div>
          </div>
          {!isProcess && !collections.length && <SearchNotFound data={{msg:"Match Not Found",code:"Collection"}}/>}
          {!isProcess && !!collections.length && this.renderNft()}
          {!!isProcess && this.renderDummyNft()}

          <div className="col-12">
            <Paginator  data={{count,start,limit,page,size:collections.length}} handler={this.handlePaginator}/>
          </div>
    	</Fragment>
    )
  }
}



class IkCollectionHome extends Component{
  constructor(props) {
      super(props)

      this.state = {
        isSmall:window.innerWidth < 768,
      }
  }
  componentDidMount (){
    document.title = "Mintable collections";
  }
  componentWillUnmount(){
    document.title = "Kraftly";
  }

  render(){
    let {isSmall} = this.state
    return(
      <Parts.CollectionMain tab="home" isSmall={isSmall}>
        <IkCollections/>
    	</Parts.CollectionMain>
    )
  }
}



export default IkCollectionHome;

export{IkCollections}
