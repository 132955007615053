import React from 'react'
import { Link } from 'react-router-dom'


const ComingSoon =(props)=>{
    return(
      <main className="main">
    		<div className="container">
    			<div className="row row--grid">
    				<div className="col-12">
    					<ul className="breadcrumb">
    						<li className="breadcrumb__item"><Link to="/home">Home</Link></li>
    						<li className="breadcrumb__item breadcrumb__item--active">Coming soon</li>
    					</ul>
    				</div>

    				<div className="col-12">
    					<div className="page-404">
    						<div className="page-404__wrap">
    							<div className="page-404__content">
    								<h1 className="page-404__title">Oops</h1>
    								<p className="page-404__text">Page under development, Coming soon</p>
                    <button className="page-404__btn" onClick={()=>props.history.goBack()}>Back</button>
    							</div>
    						</div>
    					</div>
    				</div>
    			</div>
    		</div>
    	</main>
    )

}

export default ComingSoon;
