import React, { Component } from 'react'
//import ReactTable from 'react-table'
import '../scss/noLogin.scss'
import $ from 'jquery';
import styled from 'styled-components'


const Redirect = styled.a.attrs({
  target:"_blank",
  rel:"noopener noreferrer"
})``



class NoLogin extends Component{
  componentDidMount(){
    $(document).mouseup((e)=> {
      if (($(e.target).closest(".nologin-box").length === 0 &&
      $(e.target).closest('.nologin').length !== 0)){
                $('#nologin-popup').hide()
        }
    });
  }

  handleClosePopup = () =>{
    $('#nologin-popup').hide()
  }

  render(){
    return(
      <div className="nologin">
        <div className="container">
          <button onClick={this.handleClosePopup} className="close">x</button>
          <div className="row justify-content-center">
          <div className="col-12 col-md-9 col-lg-8 col-xl-6 ">
            <div className="nologin-box ">
              <button onClick={this.handleClosePopup} className="close">x</button>

              <div className="row equal">
                <div className="col-12 col-sm-9 col-lg-8 col-xl-8">
                  <div className="nologin-box-left">
                    <h3>PLEASE LOGIN TO YOUR TRON WALLET</h3>
                    <p>If you do not have Tronlink wallet click "Install extension". Create Tronlink wallet and you will be able to login.</p>
                    <Redirect href="https://chrome.google.com/webstore/detail/tronlink%EF%BC%88%E6%B3%A2%E5%AE%9D%E9%92%B1%E5%8C%85%EF%BC%89/ibnejdfjmmkpcnlpebklmnkoeoihofec" target="_blank">INSTALL EXTENSION</Redirect>
                    <div className="app-box">
                      <Redirect href="https://apps.apple.com/us/app/tronlink-trx-btt-wallet/id1453530188">
                        <img data-v-a8761414="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIcAAAAoCAMAAADjcxkDAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAABLUExURY2Njf7+/gAAAA8PD6mpqaKiopKSknd3d5eXl////9zc3KqqqlFRUTExMSIiInBwcJOTk15eXn5+fj8/P2lpae3t7cjIyLy8vPb29nH7knwAAAAKdFJOU+L///9RoMTv4AF4mOzLAAADyUlEQVRYw+2Y6XLjIAyADXK6a3EfBr//k64EiesjTdtpZ5Ify9BaJjL5EJKQM0zT+Pbnue1tnKZhumiQz20QLtMwavn8FsbhAi/AAZdhkK/A8fc1OOQjDq3MYcSajzYxHz/Q9r5iMt/kgCrE4Zkwq6Luk8zH4XrP/WlMuW9yFCHwMHlQUnr6syHRnABJgqFrsI0DNCcAzXdS69I42hgk01ODo0HldGKzBPgaRxBCHNGZwyAkzCXKktICLkpUnnhnAIy+SnCZ7oryc+Mo3lcws6+K7zw6qTBiklGF/SI/5KBdUfIOh0VQkWhkjF7paiS2PoMuZBUrg5+NIes0eySUgIaUqfNtbfuiHDE7TF/iQDxvJHNEJWvjMKomRdOvHJU5srJoEt44Cn9OynbL4Ykja/2JPUBHT5sH0kal3M7rQ8keacmoCUWi575y0Ny0L7GG2QD62PYFiotFrhwGQ+cgL9P5sX/omRxDzCoWvoolbrBtzm3XTWyLtdzJT7kzuaPghRwS/9cJrk+wA3NvSwz8iLEkOf2QI4tj88/IY3Y5YqB5Boc6YizmGXkd5t/ZFYCfcZiTd6TjOYLk849bqotYiv4JRzpx2HO2r59gLAJVWZjDhl/jOLiHFQXnx0YvIvLOcAoQ5df2ZZ9tpBNOibZKoBLA9CyxEaktNxtCpGBrhQKddub2TEo9kZiHfnoK2wKHxZrcz50oMgcXpdetyAdCs8cae5SzmsD1AikWfjreBj6M23JOY7BLL8gpxjaOGXNGrg02Ys+EtZ0d2gt0Doin6FREBbImCpXpUo1V+1A8cMQTh1B2m209n8SuaS62BXqgeVexKVHwo179Iwl2KItCkyLHH8w8APMCD/LpmUPobTGgrfVtetfjhpe1Ea+OgWIJNw7f97ErdrASqOEuBo7nyymhbouy1X0sc1zdRG3Fm6LnxzqH6v7yrhjuxeKR4xwxYVujYaQ2cxRdv9y3Za7iuyMtsNrD7xW1qLa1h+e+P2DUnbFcv1Tm4JICsPnHKr4vZ2YO7PCwKqru7PCFOnkfMrPdRbXt2W5h3ycPJq/Hncg+5JKhzedSVizaUH1EMQdXxaur1ERFtvmkHqvNDEohY2yV+/paiuDgQz4U5yS34lo5VOgzkVkMeW3PLleO/g0P47a/fVMB2ZKejzsDplsSTPTeQHPa2BU3IoM472NXBOddn9C31wQTrsdmij6Gz+v1r5za7j063Lmy/9X3yv8c32hWmzviD353eInfYexlGPMLcORxmMb8ZIsA5HEapmm8vA3PbG+XcZr+AfIsVSrWvZxYAAAAAElFTkSuQmCC" alt="appstore" />
                      </Redirect>
                      <Redirect href="https://play.google.com/store/apps/details?id=com.tronlinkpro.wallet">
                        <img data-v-a8761414="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIkAAAAoCAMAAAD9uimwAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACHUExURUdwTF1dXf39/WdnZ6KiopeXlwAAAKSkpHNzc6KiogLi/gwMCzc3N56enrCwsADveBkZFyojJVRUVL8QX8LCwv/MAY6OjvT09ElJSerq6s/Pz9zc3Aiyx4CAgKAqbR3SZzas1ee6FIgPRAHl2qaIDg9LIwHkzck0Qw6MSIhzD2laDQ1gQg6kt+MfDKoAAAAKdFJOUwD///+azv/I/lNdrjYeAAAD2ElEQVRYw82Yi1bbOBCGozEVCrpEEqpkWTUpabtLt/v+z9cZ2TEEsCkkp8kcIIotm08z/4wuq9Vq/Umw85r4tF6hrRvJz22yQZS14JdgYr26chdB4q5WV/IiSOTV6ppfhl2/i6T1lnPVtsq2aJJL5fBT0S3F8MPir7PHk/zabn8s9bUhmM5xY3RUOpsoudJeZ42Sl9rE0HOWLY/ieJL//9lstgsj0j3SSG6qxAN5QmnOO9JZgw1pPAv6JCRfbhBl8zCnYNlZrpSS6BI/kMiJxBBXq5kw7WlIbr4hyvbXTN9guYidMkyp5z6pJD6yxgV9GpKKspmRi+gld0YaJeVI0u5JWqS0QbGGi3wikgFlhqU3Wiuuu86gaKpPIrqjRpOFEDz3DNXSnIhkRNk8vCZdKYe/cu7WierJQLJH2T7Is1W2kWSPslmuLn+DpCYzkdx/t2cmGVC2958/3/88DJGKXemqWBeLce8PptkeTbSU5717JwkFiEDQ7v970jsCpJwA9DKJAvP0q4dqWBg1+PeS3HwbQdAeUTQUj9OeL+KdJAZrc4TwIZKvdxPJz8c3dkOs3kqqZyRs8KEB9QGSr3e3/44o36f/G+BQILgm2CtBjZ1c6yaS6bYfSCKwSoIrCvvHJAhyO6A8yR6bygGHptiTDllG+fR12YCNALqSRNRTcI8kNoMjEtQa9S6J3twlt0hSQQjlIItHn4tMZtBDoYk5KXR+Fk0HkcsCujEwkEToREw1nBjVGCPeJJ2oUqi3ENAfhvE1khHkdneYcg4lN5IkCAy6KgGDo6MFQgbVIA3JgUjQgbiQKsCm3Ek4QZFPpFOtgiKhUMD8EskE8kyYsmRbJxgrEUADzXQylxEwgtBVR20lwR8RIEdbSQLurlRNPo8jQSoAbLcVd55kBNm9TJBIDuVDGmAUWCXJaiIx4CaSlhQzbun8VH+QpIWOedQKRUaAWFDsALJ7rRbakuqTUkCxooYYh8sTAdgCbVNJ9RAdqDFzL0h6chzGi1JxUO0MSQXZzdRkV1B7uGyDrHAtCYb1mdwNqMEARiINrrBHxWr0iw9ZvfRJoMeg4uj5LK4gbrZyWZ1Ie3W95jpqimEOAPQCXsIszqhYlwyumOiqrrmTJpLU8j5B0l3Cbzm5WRIC2S1WUKuYH5+XrWCDd13TDKUN88JSBlm6rvDq+NA+CCh2eoXDBqWwnt1lIMjuiF2yDegCGRYq+oGZw5L9lMTd7Y7brRvoTNlPTm9uyVOY340eu1ysMjJ/OJoemiP2xW+zuA+P5vqCTi0u5yTnck63LufEr56CNue1egr6G9ENqpaIvfAbAAAAAElFTkSuQmCC" alt="android" />
                      </Redirect>
                    </div>
                    <span>Kraftly is available to use with Tronlink or any other Tron wallet web extension or dapp supported wallet.</span>

                  </div>
                </div>
                <div className="d-none d-sm-block col-sm-3 col-lg-4 col-xl-4 align-self-center">
                  <div className="nologin-box-right">
                    <img src="/asset/img/tronlink.png" alt="tronlink"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NoLogin;
