

import React, {Component} from 'react'
import axios from 'axios'
import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

import { RectAreaLightUniformsLib }  from 'three/examples/jsm/lights/RectAreaLightUniformsLib';
import {ReactComponent as ScreenRotate} from '../../images/screen-rotate.svg';
import { createCanvas, loadImage,parseFont } from 'canvas'

import {Utils} from "../../helper"


const fileApi = axios.create({
    baseURL: `https://server.kraftly.io/images`,
    timeout: 4 * 60 * 1000,
    withCredentials: true,
    credentials: 'include',
    responseType: 'blob'
})




class Metaverse extends Component{

  constructor(props) {
    super(props)

    this.state = {
      imgSource :"",
    }


    this.camera = null;
    this.scene = null;
    this.renderer = null;
  }


componentDidMount(){
  this.renderMarbel();

  let self = this;
  window.addEventListener('resize', function(){
    let _width =  window.innerWidth;
    let _height =  window.innerWidth >= 768?window.innerHeight - 70:window.innerHeight - 60;
    let radius = _width > _height ? _height:_width;
    radius = radius > 100?100:radius;
    self.renderer.setSize ( _width, _height );
    self.camera.aspect = _width / _height ;
    self.camera.updateProjectionMatrix();
  });

}


 renderMarbel = () =>{
  let{imgSource} = this.state;

  let width =  window.innerWidth;
  let height =  window.innerWidth >= 768?window.innerHeight - 70:window.innerHeight - 60;
  let radius = width > height ? height:width;
  radius = radius > 100?100:radius;
  // radius = radius/100;
  this.camera = new THREE.PerspectiveCamera( 45, width / height, 1, 1000 );
  this.camera.position.set( 0,0, radius*3);
  this.camera.lookAt(new THREE.Vector3(0,0,0));

  this.renderer = new THREE.WebGLRenderer({antialias:true,preserveDrawingBuffer:true,alpha: true});
  this.renderer.setSize( width, height );
  this.renderer.setPixelRatio( window.devicePixelRatio );
  const controls = new OrbitControls( this.camera, this.renderer.domElement );
  controls.target = new THREE.Vector3(0, 0, 0); // what
  // controls.enableDamping = true
  // controls.maxDistance = radius * 8;
  // controls.minDistance = radius*2;
  // // controls.enableZoom  = false;
  controls.enableKeys = true //older versions

  controls.keys = {
  	LEFT: 'ArrowLeft', //left arrow
  	UP: 'ArrowUp', // up arrow
  	RIGHT: 'ArrowRight', // right arrow
  	BOTTOM: 'ArrowDown' // down arrow
  }
  controls.mouseButtons = {
  	LEFT: THREE.MOUSE.ROTATE,
  	RIGHT: THREE.MOUSE.PAN
  }
  controls.touches = {
    ONE: THREE.TOUCH.ROTATE,
    TWO: THREE.TOUCH.DOLLY_PAN
  }

  // controls.addEventListener('change', () => console.log("Controls Change"))

  controls.update()


  this.scene = new THREE.Scene();
  this.scene.background = 0x000000;
  this.scene.add(new THREE.AxesHelper(radius*1.2))

  const pivot = new THREE.Group();
  const starGeo = new THREE.BufferGeometry();
  const vertices = new Array(18000).fill(undefined).map(()=>Math.random() * 1000 - 500);
  starGeo.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(vertices), 3));

  let starMaterial = new THREE.PointsMaterial({
    color: 0xaaaaaa,
    size: 1.5,
    transparent: true,
    map: new THREE.TextureLoader().load('../../asset/img/circle.png')
  });

  pivot.add( new THREE.Points( starGeo, starMaterial ));
  this.scene.add( pivot );
  this.scene.add( new THREE.AmbientLight( 0xffffff ) );



  let self = this;
  function animation( time ) {
    self.renderer.render( self.scene, self.camera );
  }

  this.renderer.setAnimationLoop(animation);
  this.renderScene()

}

renderScene = () =>{

  const panel = document.querySelector(".cert_generator");
  panel.innerHTML = "";
  panel.appendChild( this.renderer.domElement );
// controls.dispose(), renderer.dispose()
}




  render(){

    return (
      <div className="webgl__model">
        <div className="cert_generator"></div>
      </div>
    )
  }
}


export default Metaverse;
