import React, { Component,Fragment } from 'react'

import {ReactComponent as Grid1} from '../images/grid1.svg';
import {ReactComponent as Grid4} from '../images/grid4.svg';
import {ReactComponent as Grid9} from '../images/grid9.svg';


class GridToggler extends Component{
  constructor(props) {
      super(props)

      this.state = {

      }
  }
  componentDidMount (){
    window.addEventListener('resize', this.fireEvents, {passive: true});
  }
  componentWillUnmount(){
    window.removeEventListener('resize', this.fireEvents, {passive: true});
  }

  fireEvents = () =>{
    let {isSmall} = this.props.data;
    let currentSize = window.innerWidth < 768;
    if (currentSize !== isSmall){
      this.props.handleGrid({isSmall:currentSize})
      }
  }

  handleGrid = (val) =>{
    this.props.handleGrid({isGrid:val});
    localStorage.setItem('isGrid', val);
  }

  render(){
    let {isGrid,isSmall} = this.props.data;
    return(
      <Fragment>
        {!!isSmall && <div className="grid__options">
          <button className={`${!isGrid?"active__view":""}`} onClick={()=>this.handleGrid(false)}><Grid1 /></button>
          <button className={`${!!isGrid?"active__view":""}`} onClick={()=>this.handleGrid(true)}><Grid4 /></button>
        </div>}
      </Fragment>

    )
  }
}


export default GridToggler;


class GridToggler2 extends Component{

  handleGrid = (val) =>{
    this.props.handleGrid({isGrid:val});
    localStorage.setItem('isGrid', val);
  }

  render(){
    let {isGrid} = this.props.data;
    return(
      <div className="grid__options grid__options__large">
        <button className={`${!isGrid?"active__view":""}`} onClick={()=>this.handleGrid(false)}><Grid4 /></button>
        <button className={`${!!isGrid?"active__view":""}`} onClick={()=>this.handleGrid(true)}><Grid9 /></button>
      </div>
    )
  }
}

export  {
  GridToggler2,
}
