

import React, {Component} from 'react'
import axios from 'axios'
import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

import { RectAreaLightUniformsLib }  from 'three/examples/jsm/lights/RectAreaLightUniformsLib';
import {ReactComponent as ScreenRotate} from '../../images/screen-rotate.svg';
import { createCanvas, loadImage,parseFont } from 'canvas'

import {Utils} from "../../helper"


const fileApi = axios.create({
    baseURL: `https://server.kraftly.io/images`,
    timeout: 4 * 60 * 1000,
    withCredentials: true,
    credentials: 'include',
    responseType: 'blob'
})




class Planetory extends Component{

  constructor(props) {
    super(props)

    this.state = {
      imgSource :"",
    }


    this.camera = null;
    this.scene = null;
    this.renderer = null;
  }


componentDidMount(){
  this.renderMarbel();

  let self = this;
  window.addEventListener('resize', function(){
    let _width =  window.innerWidth;
    let _height =  window.innerWidth >= 768?window.innerHeight - 70:window.innerHeight - 60;
    let radius = _width > _height ? _height:_width;
    radius = radius > 100?100:radius;
    self.renderer.setSize ( _width, _height );
    self.camera.aspect = _width / _height ;
    self.camera.updateProjectionMatrix();
  });

}


 renderMarbel = () =>{
  let{imgSource} = this.state;

  let width =  window.innerWidth;
  let height =  window.innerWidth >= 768?window.innerHeight - 70:window.innerHeight - 60;
  let radius = width > height ? height:width;
  radius = radius > 100?100:radius;
  // radius = radius/100;
  this.camera = new THREE.PerspectiveCamera( 45, width / height, 1, 1000 );
  this.camera.position.set( 0,0, radius*3);
  this.camera.lookAt(new THREE.Vector3(0,0,0));

  this.renderer = new THREE.WebGLRenderer({antialias:true,preserveDrawingBuffer:true,alpha: true});
  this.renderer.setSize( width, height );
  this.renderer.setPixelRatio( window.devicePixelRatio );
  const controls = new OrbitControls( this.camera, this.renderer.domElement );
  controls.target = new THREE.Vector3(0, 0, 0); // what
  // controls.enableDamping = true
  // controls.maxDistance = radius * 8;
  // controls.minDistance = radius*2;
  // // controls.enableZoom  = false;
  controls.enableKeys = true //older versions

  controls.keys = {
  	LEFT: 'ArrowLeft', //left arrow
  	UP: 'ArrowUp', // up arrow
  	RIGHT: 'ArrowRight', // right arrow
  	BOTTOM: 'ArrowDown' // down arrow
  }
  controls.mouseButtons = {
  	LEFT: THREE.MOUSE.ROTATE,
  	RIGHT: THREE.MOUSE.PAN
  }
  controls.touches = {
    ONE: THREE.TOUCH.ROTATE,
    TWO: THREE.TOUCH.DOLLY_PAN
  }

  // controls.addEventListener('change', () => console.log("Controls Change"))

  controls.update()


  this.scene = new THREE.Scene();
  this.scene.background = 0x000000;
  this.scene.add(new THREE.AxesHelper(radius*1.2))

  const pivot = new THREE.Group();

  // const marbelGeometry = new THREE.SphereGeometry(radius, 40, 40,0 , Math.PI * 2, 0, Math.PI);
  const marbelGeometry = new THREE.SphereBufferGeometry(radius, 40, 40,0 , Math.PI * 2, 0, Math.PI);

	const marbelMaterial = new THREE.ShaderMaterial( {
		uniforms		: {
      color1: {
        value: new THREE.Color("red")
      },
      color2: {
        value: new THREE.Color("yellow")
      }
    },
    vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
    }
  `,
  fragmentShader: `
    uniform vec3 color1;
    uniform vec3 color2;

    varying vec2 vUv;

    void main() {

      gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);
    }
  `,
  wireframe: true
	});

  const randomCord = (max,min = 0) =>{
    max = parseInt(max);
    max = !isNaN(max)?max:0;
    min = parseInt(min);
    min = !isNaN(min)?min:0;
    if(!max && min >= max) return new THREE.Vector3(min,min,min);

    let _num1 = Math.floor(Math.random() * (max - min)) + min;
    let _num2 = Math.floor(Math.random() * (max - min)) + min;
    let _num3 = Math.floor(Math.random() * (max - min)) + min;
    _num1 = Math.random() >= 0.5?_num1:-_num1;
    _num2 = Math.random() >= 0.5?_num2:-_num2;
    _num3 = Math.random() >= 0.5?_num3:-_num3;
    return new THREE.Vector3(_num1,_num2,_num3);
  }

  const cluster = new THREE.Group();

  for (let i = 0; i < 10; i++) {
    let _sphere = new THREE.Mesh( marbelGeometry, marbelMaterial );
    _sphere.position.copy(randomCord(radius*((i+0.5)**2),radius * (i**2)));
    cluster.add(_sphere)
  }
  pivot.add(cluster);


  const starGeo = new THREE.BufferGeometry();
  const vertices = new Array(18000).fill(undefined).map(()=>Math.random() * 1000 - 500);
  starGeo.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(vertices), 3));

  let starMaterial = new THREE.PointsMaterial({
    color: 0xaaaaaa,
    size: 1.5,
    transparent: true,
    map: new THREE.TextureLoader().load('../../asset/img/circle.png')
  });

  pivot.add( new THREE.Points( starGeo, starMaterial ));




  this.scene.add( new THREE.AmbientLight( 0xffffff ) );


  this.scene.add( pivot );

  let self = this;

  function animation( time ) {
    // pivot.rotation.y += 0.1*Math.PI/180;
    controls.update();
    self.renderer.render( self.scene, self.camera );
  }

  this.renderer.setAnimationLoop(animation);
  this.renderScene()

}

renderScene = () =>{

  const panel = document.querySelector(".cert_generator");
  panel.innerHTML = "";
  panel.appendChild( this.renderer.domElement );
// controls.dispose(), renderer.dispose()
}




  render(){

    return (
      <div className="webgl__model">
        <div className="cert_generator"></div>
      </div>
    )
  }
}


export default Planetory;
