import React, { Component,Fragment } from 'react'
import {Utils,SearchNotFound} from "../../helper"
import * as Parts from "../parts"
import api from "../../api"
import {Paginator,MenuSelect3} from "../../frag"
import {TRC721TokenWidget,TRC721TokenWidgetDummy} from "../cards.jsx"


class TRC721Contracts extends Component{
  constructor(props) {
      super(props)

      const urlParams = new URLSearchParams(window.location.search);
      let page = urlParams.get('page');
      page = Utils.floor(page)
      page = page && page > 0?page:1;
      let start = page > 4?page -3:1;

      let filterString = this.props.filterString ||"";

      this.state = {
        count:0,
        page,
        start,
        limit:15,
        arrange:"new",
        filterString,
        contracts:[],
        isProcess:false,
      }
      this.myRef = React.createRef();

  }
  componentDidMount (){
    this.fetchTokens()
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let{page,filterString} = this.state;
      const urlParams = new URLSearchParams(window.location.search);
      let _page = urlParams.get('page');
      _page = Utils.floor(_page);
      if(_page !== page){
        page = _page && _page > 0?_page:1;
        let start = page > 4?page -3:1;
        this.setState({count:0,contracts:[],page,start},this.fetchTokens)
      }else if (this.props.callback && nextProps.filterString !== filterString) {
        this.setState({contracts:[],count:0,page:1,start:1,filterString:nextProps.filterString},this.fetchTokens)
      }
     }
  renderNft = () =>{
    let {contracts} = this.state;
    return contracts.map((item,i)=>{

      // console.log(item);
      return <div className="col-12 col-md-6 col-lg-4" key={item.nftAddress+'a'+i}>
        <TRC721TokenWidget data={item} key={item.nftAddress+'b'+i} />
      </div>
    })
  }

  renderDummyNft = () =>{
    let count = window.innerWidth < 768?4:window.innerWidth < 992?6:9;
      return new Array(count).fill(undefined).map((item,i)=>{

        return <div className="col-12 col-md-6 col-lg-4" key={i+'a'}>
          <TRC721TokenWidgetDummy />
        </div>
      })
  }

  updatePagingUrl = () =>{
    let{page,filterString} = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("page", page)
    let params = urlParams.toString();
    if (window.history.pushState) {
      let url = `${window.location.pathname}?${params}`;
      window.history.pushState(null, null,url);
    }

    if(this.props.callback){
      this.props.callback(filterString)
    }
  }

  fetchTokens = async() =>{
    try {
      let {filterString,page,limit,arrange,isProcess} = this.state;
      if(isProcess) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isProcess:true})

      let payload = {filterString,page,limit,arrange}
      let res = await api.getNftContracts(payload);
      // console.log(res);
      let {count,result} = res.data;
      this.setState({count,contracts:result,isProcess:false},this.updatePagingUrl)
    } catch (e) {
      // console.log(e.response);
      this.setState({isProcess:false})
      let msg = e.response?.data?.message??"No NFT contract found";
      Utils.setToastAlert(msg,"error");
    }
  }
  fetchFilterData = async(event) =>{
    event.preventDefault()
    this.setState({count:0,contracts:[],page:1,start:1},this.fetchTokens)
  }
  handlePaginator = (value) =>{
    this.setState(value,this.fetchTokens)
    this.myRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  onOption = (name,value)=>{
    let{isProcess} = this.state
    if(isProcess) return Utils.setToastAlert("Previous request processing","info");
    if(this.state[name] === value) return console.log("same option");
    this.setState({[name]:value,contracts:[],page:1,start:1,count:0},this.fetchTokens)
  }

  render(){
    let {count,start,limit,page,contracts,filterString,isProcess,arrange} = this.state
    let typeOptions = {new:"Sort: New",old:"Sort: Old"};

    return(
      <Fragment>
        <div className="col-12" ref={this.myRef}>
          <div className="ik__home__filter">
            <form onSubmit={this.fetchFilterData.bind(this)} className="main__filter-search">
              <input
                type="text"
                placeholder="Search NFT contract address , name"
                value = {filterString}
                onChange={(e)=>this.setState({filterString:e.target.value})}
                />
              <button
                onClick={this.fetchFilterData}
                >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
              </button>
            </form>
            <MenuSelect3 options={typeOptions} value={arrange} onOption={(e)=>this.onOption("arrange",e)} key={arrange}/>
          </div>
        </div>


        {!isProcess && !!contracts.length && this.renderNft()}
        {!!isProcess  && this.renderDummyNft()}
        {!isProcess  && !contracts.length && <SearchNotFound data={{msg:"Match Not Found",code:"TRC721"}}/>}

        <div className="col-12">
          <Paginator  data={{count,start,limit,page,size:contracts.length}} handler={this.handlePaginator}/>
        </div>
      </Fragment>
    )
  }
}

class TRC721ContractsPage extends Component{
  constructor(props) {
      super(props)
      this.state = {
      }
  }
  componentDidMount (){
    document.title = "NFT Contract | Kraftly";
  }
  componentWillUnmount(){
    document.title = "Kraftly";
  }

  render(){
    return(
      <Parts.CollectionMain tab="trc721">
        <div className="col-12"ref={this.myRef}>
          <div className="collection__create__title">
            <div className="collection__create__title__body">
              <div className="collection__create__p">NFT(TRC721) Contracts</div>
            </div>
          </div>
        </div>
        <div className="row no-gutters">
          <TRC721Contracts />
        </div>
      </Parts.CollectionMain>
    )
  }
}

export default TRC721ContractsPage
export {TRC721Contracts}
