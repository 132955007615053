import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const LogoBox = styled.div.attrs({
  className: 'header__logo',
})``


class Logo extends Component{
  render(){
    return(
      <LogoBox>
        <Link to="/">
          <img src="/asset/img/logo.png" alt="logo" />
        </Link>
      </LogoBox>
    )
  }
}

export default Logo
