import React, {Component} from 'react'
import api from "../../api"

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {MintableCardDummy,MintableCard} from "../../mintable-collection/cards.jsx"

class CarouselIkCollection extends Component{

  constructor(props) {
      super(props)

      this.state = {
        collections:[],
        uniqueIndex :"ikcollection-tab"+Date.now(),
      }
  }
  componentDidMount = () =>{
    this.fetchCollection()
  }

  fetchCollection = async () =>{
      try {
        let limit = window.innerWidth < 768?6:12;

        let res = await api.getIkCollections({category:"ongoing",limit});
        let{result} = res.data;
        // console.log(result);
        this.setState({collections:result})
      } catch (e) {
        // console.log(e.response);
      }
  }
  renderCollection = () =>{
    let{collections} = this.state;
    let layout = {isSlider:true}
    if(!collections || !collections.length){
      return new Array(4).fill(undefined).map((_,i)=><MintableCardDummy layout={layout} key={i}/>)
    }

    return collections.map((data,i)=>{
      return <MintableCard data={data} layout={layout} key={data.collectionId}/>
    })
  }
  handleTrigger = (val) =>{
    let{uniqueIndex} = this.state;
    if(val === "next"){
      document.querySelector(`.${uniqueIndex} .owl-next`).click();
    }else {
      document.querySelector(`.${uniqueIndex} .owl-prev`).click()
    }
  }

  render(){
    let {uniqueIndex,collections} = this.state
    let res = {
      576 : {items: 1,margin:8},
      768 : {items: 2,margin:10},
      992 : {items: 3,margin:8},
      1200 : {items: 4,margin:10,mouseDrag: false,dots: false},
    }

    return(
      <div className={`carousel__slider ${uniqueIndex}`}>
        <OwlCarousel
          mouseDrag = {true}
          touchDrag = {true}
          dots = {true}
          loop = {true}
          autoplay = {true}
          autoplayHoverPause = {true}
          autoplayTimeout = {5000}
          smartSpeed = {600}
          margin = {8}
          items={1}
          responsive = {res}
          key={collections.length + uniqueIndex}
          >

          {this.renderCollection()}
        </OwlCarousel>

        <div className="slider__switch">
          <button onClick={()=>this.handleTrigger("prev")}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"/></svg></button>
          <button onClick={()=>this.handleTrigger("next")}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"/></svg></button>
        </div>


      </div>
    )
  }
}

export default CarouselIkCollection
