
import React,{ useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch ,useLocation} from 'react-router-dom'
import { Header,Footer } from '../components'
import * as pages from '../pages'
import * as helper from '../helper'
import Rocks from '../rocks/app.js'
import TechMemory from '../tech-memory/app.js'
import CryptoCloud from '../cypto-clouds/app.js'
import Fox555 from '../fox555/app.js'
import {Ukraine} from '../pages/donate'

import MinatbleCollections from '../mintable-collection/app.js'
import WebGL3d from '../webGL3d/app.js'


import Admin from '../admin/app.js'


import '../scss/main.scss';

// import { AdminNavBar } from '../admin/app'

// eslint-disable-next-line
import styled from 'styled-components'


function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {

    return (
        <Router>
        <ScrollToTop />
        <Header />
            <Switch>
                  <Route path={["/","/home"]} exact component={pages.Home} />
                  <Route path="/explore" exact component={pages.Explore} />
                  <Route path="/explore/:collection" exact component={pages.Explore3p} />

                  <Route path="/discover" exact component={pages.Discover} />

                  <Route path="/author/:address" exact component={pages.Author} />
                  <Route path="/author/:address/profile" exact component={pages.AuthorProfile} />
                  <Route path="/author/:address/rewards" exact component={pages.Rewards} />
                  <Route path="/author/:address/referral" exact component={pages.Referral} />

                  <Route path="/authors" exact component={pages.Authors} />

                  <Route path="/create" exact component={pages.Create} />

                  <Route path="/market/:tokenId" exact component={pages.NftDetails} />
                  <Route path="/market3p/:nftAddress/:tokenId" exact component={pages.Market3p} />

                  <Route path="/search/:query" exact component={pages.SearchPanel} />

                  <Route path="/canvas" exact component={pages.CanvasArt} />

                  <Route path="/kty-offering" exact component={pages.GenesisOffering} />
                  <Route path="/kty-burn" exact component={pages.KTYBurnPool} />
                  <Route path={["/kty","/kty-info"]} exact component={pages.KTY} />

                  <Route path="/article" exact component={helper.ComingSoon} />
                  <Route path="/help" exact component={helper.ComingSoon} />
                  <Route path="/feedback" exact component={helper.ComingSoon} />
                  <Route path="/collection" exact component={helper.ComingSoon} />
                  <Route path="/privacy" exact component={helper.ComingSoon} />


                  <Route path="/trocks" component={Rocks} />
                  <Route path="/cypto-clouds" component={CryptoCloud} />
                  <Route path="/fox555" component={Fox555} />
                  <Route path="/tech-memory" component={TechMemory} />

                  <Route path="/mintable" component={MinatbleCollections} />
                  <Route path="/3d" component={WebGL3d} />

                  <Route path="/cdnfileuploader" exact component={helper.CdnUploader} />


                  <Route path="/admin" component={Admin} />


                  <Route path="/donate" component={Ukraine} />

                  <Route component={helper.PageNotFound} />

            </Switch>
            <Footer />
        </Router>
    )
}

export default App
