
import React, { Component } from 'react'
//import ReactTable from 'react-table'
import $ from 'jquery';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {Utils} from "../helper"
import {BannerViewer,DpViewer} from "../helper/file-viewer.jsx"

import {ReactComponent as Verified} from '../images/verified_star.svg';




const CreateBtn = styled.button.attrs(props=>({
  className: `sign__btn ${props.fullView?" full__btn":props.largeView?" large__btn":""}`,
  type:"button"
}))``


const BackBtn = styled.button.attrs(props=>({
  className: `sign__btn red__btn ${props.fullView?" full__btn":props.largeView?" large__btn":""}`,
  type:"button"
}))``


class MintableCardDummy extends Component{

  render(){
    let {isSlider} = this?.props?.layout??{};
    isSlider = !!isSlider?" ikCollection__card__slider":"";
    return(
        <div className={`ikCollection__card${isSlider} ikCollection_dummy`}>
          <div className="ikCollection__card__inner">


          <div className="ikCollection__card__background">
          </div>
          <div className="ikCollection__meta">
            <Link to="#" className="ikCollection__avatar" >
              <img src={Utils.getDp()} alt="" />
            </Link>
            <h1 className="ikCollection__name">
              <Link to="#"></Link>
            </h1>
            <div className="ikCollection__minting__info"><p></p><span></span></div>
            <div className="ikCollection__minting__info"><p></p><span></span></div>
            <div className="ikCollection__minting__info"><p></p><span></span></div>

            <span className={`ikCollection__timer`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.46777,8.39453l-.00225.00183-.00214.00208ZM18.42188,8.208a1.237,1.237,0,0,0-.23-.17481.99959.99959,0,0,0-1.39941.41114,5.78155,5.78155,0,0,1-1.398,1.77734,8.6636,8.6636,0,0,0,.1333-1.50977,8.71407,8.71407,0,0,0-4.40039-7.582,1.00009,1.00009,0,0,0-1.49121.80567A7.017,7.017,0,0,1,7.165,6.87793l-.23047.1875a8.51269,8.51269,0,0,0-1.9873,1.8623A8.98348,8.98348,0,0,0,8.60254,22.83594.99942.99942,0,0,0,9.98,21.91016a1.04987,1.04987,0,0,0-.0498-.3125,6.977,6.977,0,0,1-.18995-2.58106,9.004,9.004,0,0,0,4.3125,4.0166.997.997,0,0,0,.71534.03809A8.99474,8.99474,0,0,0,18.42188,8.208ZM14.51709,21.03906a6.964,6.964,0,0,1-3.57666-4.40234,8.90781,8.90781,0,0,1-.17969-.96387,1.00025,1.00025,0,0,0-.79931-.84473A.982.982,0,0,0,9.77,14.80957a.99955.99955,0,0,0-.8667.501,8.9586,8.9586,0,0,0-1.20557,4.71777,6.98547,6.98547,0,0,1-1.17529-9.86816,6.55463,6.55463,0,0,1,1.562-1.458.74507.74507,0,0,0,.07422-.05469s.29669-.24548.30683-.2511a8.96766,8.96766,0,0,0,2.89874-4.63269,6.73625,6.73625,0,0,1,1.38623,8.08789,1.00024,1.00024,0,0,0,1.18359,1.418,7.85568,7.85568,0,0,0,3.86231-2.6875,7.00072,7.00072,0,0,1-3.2793,10.457Z"/></svg>
              <span>Loading</span>
            </span>
          </div>
          </div>
        </div>
    )
  }
}
class MintableCardDummyMini extends Component{

  render(){


    return(
        <div className="ikCollection__card ikCollection__card__mini ikCollection_dummy">
          <div className="ikCollection__card__inner">
          <div className="ikCollection__card__background">&nbsp;</div>
          <div className="ikCollection__meta">
            <Link to="#" className="ikCollection__avatar" >
              <img src={Utils.getDp()} alt="" />
            </Link>
            <h1 className="ikCollection__name">
              <Link to="#">&nbsp;</Link>
            </h1>
            <div className="ikCollection__minting__info"><p>&nbsp;</p><span>&nbsp;</span></div>
            <div className="ikCollection__minting__info"><p>&nbsp;</p><span>&nbsp;</span></div>
            <div className="ikCollection__minting__info"><p>&nbsp;</p><span>&nbsp;</span></div>
            <span className={`ikCollection__timer`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.46777,8.39453l-.00225.00183-.00214.00208ZM18.42188,8.208a1.237,1.237,0,0,0-.23-.17481.99959.99959,0,0,0-1.39941.41114,5.78155,5.78155,0,0,1-1.398,1.77734,8.6636,8.6636,0,0,0,.1333-1.50977,8.71407,8.71407,0,0,0-4.40039-7.582,1.00009,1.00009,0,0,0-1.49121.80567A7.017,7.017,0,0,1,7.165,6.87793l-.23047.1875a8.51269,8.51269,0,0,0-1.9873,1.8623A8.98348,8.98348,0,0,0,8.60254,22.83594.99942.99942,0,0,0,9.98,21.91016a1.04987,1.04987,0,0,0-.0498-.3125,6.977,6.977,0,0,1-.18995-2.58106,9.004,9.004,0,0,0,4.3125,4.0166.997.997,0,0,0,.71534.03809A8.99474,8.99474,0,0,0,18.42188,8.208ZM14.51709,21.03906a6.964,6.964,0,0,1-3.57666-4.40234,8.90781,8.90781,0,0,1-.17969-.96387,1.00025,1.00025,0,0,0-.79931-.84473A.982.982,0,0,0,9.77,14.80957a.99955.99955,0,0,0-.8667.501,8.9586,8.9586,0,0,0-1.20557,4.71777,6.98547,6.98547,0,0,1-1.17529-9.86816,6.55463,6.55463,0,0,1,1.562-1.458.74507.74507,0,0,0,.07422-.05469s.29669-.24548.30683-.2511a8.96766,8.96766,0,0,0,2.89874-4.63269,6.73625,6.73625,0,0,1,1.38623,8.08789,1.00024,1.00024,0,0,0,1.18359,1.418,7.85568,7.85568,0,0,0,3.86231-2.6875,7.00072,7.00072,0,0,1-3.2793,10.457Z"/></svg>
              <span>Loading</span>
            </span>
          </div>
          </div>
        </div>
    )
  }
}

class MintableCard extends Component{
  constructor(props) {
      super(props)
      this.state = {

      }
  }
  componentDidMount (){
    let{collectionId,startAt,fillTab} = this.props.data;
    // console.log(this.props.data);
    let self = this;
     startAt = Math.floor(startAt);
     // startAt = Math.floor(Date.now()/1000)+86500;

    if(this.props.data.hasOwnProperty("startAt") && !fillTab){
      let timer = setInterval(function() {
        let{size,mintedSize} = self.props.data;

                  $(`.card__clock${collectionId}`).text(Utils.getTimeOffset(startAt));
                  if (startAt * 1000 < Date.now()) {
                    let textState = !!mintedSize && mintedSize === size?"Sold out":"Live";

                     $(`.card__clock${collectionId}`).text(textState);

                     clearInterval(timer);
                  }
               }, 1000);
       this.setState({timer})
    }
  }

  render(){
    let {isSlider} = this?.props?.layout??{};
    isSlider = !!isSlider?" ikCollection__card__slider":"";

    let{author,name,verified,size,mintedSize,price,collectionId,currencyId,collectionType,files,fillTab} = this.props.data;
    let ownerName = name?Utils.shortName(name,30):"@"+collectionId;
    let {img,symbol} = Utils.getTokenInfo(currencyId);

    let url = !fillTab?`/mintable/${collectionId}/gallery`:`/mintable/${collectionId}/fill`;

    return(
        <div className={`ikCollection__card${isSlider}`}>
          <div className="ikCollection__card__inner">


          <BannerViewer data={{address:author,files,cName:"ikCollection__card__background"}}  key={author}/>

          <div className="ikCollection__meta">
            <Link to={url} className="ikCollection__avatar" >
              <DpViewer address={author} data={{files}} key={author} />
            </Link>
            <h1 className="ikCollection__name">
              <Link to={url}>
               {ownerName}
               {!!verified && <Verified />}</Link>
            </h1>

            <div className="ikCollection__minting__info"><p>Minting</p>:<span>{Utils.preety.floor(mintedSize,0)} / {Utils.preety.floor(size,0)}</span></div>
            <div className="ikCollection__minting__info"><p>Price</p>:<span className="nft__card__price__tag">
              <div className="tooltip">
                <span className="tooltip__text">{symbol}</span>
              </div>
              {Utils.preety.unsafe(price/1e6,2)}
              <img src={img} alt="" />

            </span></div>
            <div className="ikCollection__minting__info"><p>Type</p>:<span>{collectionType === 1?"Blind Mintable":"Mintable"}</span></div>

              {!!size && !fillTab && <Link to={url} className={`ikCollection__timer ${collectionId}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.46777,8.39453l-.00225.00183-.00214.00208ZM18.42188,8.208a1.237,1.237,0,0,0-.23-.17481.99959.99959,0,0,0-1.39941.41114,5.78155,5.78155,0,0,1-1.398,1.77734,8.6636,8.6636,0,0,0,.1333-1.50977,8.71407,8.71407,0,0,0-4.40039-7.582,1.00009,1.00009,0,0,0-1.49121.80567A7.017,7.017,0,0,1,7.165,6.87793l-.23047.1875a8.51269,8.51269,0,0,0-1.9873,1.8623A8.98348,8.98348,0,0,0,8.60254,22.83594.99942.99942,0,0,0,9.98,21.91016a1.04987,1.04987,0,0,0-.0498-.3125,6.977,6.977,0,0,1-.18995-2.58106,9.004,9.004,0,0,0,4.3125,4.0166.997.997,0,0,0,.71534.03809A8.99474,8.99474,0,0,0,18.42188,8.208ZM14.51709,21.03906a6.964,6.964,0,0,1-3.57666-4.40234,8.90781,8.90781,0,0,1-.17969-.96387,1.00025,1.00025,0,0,0-.79931-.84473A.982.982,0,0,0,9.77,14.80957a.99955.99955,0,0,0-.8667.501,8.9586,8.9586,0,0,0-1.20557,4.71777,6.98547,6.98547,0,0,1-1.17529-9.86816,6.55463,6.55463,0,0,1,1.562-1.458.74507.74507,0,0,0,.07422-.05469s.29669-.24548.30683-.2511a8.96766,8.96766,0,0,0,2.89874-4.63269,6.73625,6.73625,0,0,1,1.38623,8.08789,1.00024,1.00024,0,0,0,1.18359,1.418,7.85568,7.85568,0,0,0,3.86231-2.6875,7.00072,7.00072,0,0,1-3.2793,10.457Z"/></svg>
                <span className={`card__clock${collectionId}`}></span>
              </Link>}
              {!!size && !!fillTab && <Link to={url} className={`ikCollection__timer ${collectionId}`}>
                <span className="card__clock"> Edit</span>
              </Link>}
          </div>
          </div>
        </div>
    )
  }
}



class MintableCardGrid extends Component{
  constructor(props) {
      super(props)
      this.state = {

      }
  }
  componentDidMount (){
    let{collectionId,startAt} = this.props.data;
    // console.log(this.props.data);
    let self = this;
     startAt = Math.floor(startAt);
    if(this.props.data.hasOwnProperty("startAt")){
      let timer = setInterval(function() {
        let{size,mintedSize} = self.props.data;

                  $(`.card__clock${collectionId}`).text(Utils.getTimeOffset(startAt));
                  if (startAt * 1000 < Date.now()) {
                    let textState = !!mintedSize && mintedSize === size?"Sold out":"Live";

                     $(`.card__clock${collectionId}`).text(textState);

                     clearInterval(timer);
                    }
               }, 1000);
       this.setState({timer})
    }
  }

  render(){
    let{author,name,verified,size,mintedSize,price,collectionId,currencyId,collectionType,files,fillTab} = this.props.data;
    let ownerName = name?Utils.shortName(name,30):"@"+collectionId;
    let {img,symbol} = Utils.getTokenInfo(currencyId);

    let url = !fillTab?`/mintable/${collectionId}/gallery`:`/mintable/${collectionId}/fill`;

    return(
        <div className="ikCollection__card ikCollection__card__mini">
          <div className="ikCollection__card__inner">


          <BannerViewer data={{address:author,files,cName:"ikCollection__card__background"}}  key={author}/>

          <div className="ikCollection__meta">
            <Link to={url} className="ikCollection__avatar" >
              <DpViewer address={author} data={{files}} key={author} />
            </Link>
            <h1 className="ikCollection__name">
              <Link to={url}>
               {ownerName}
               {!!verified && <Verified />}</Link>
            </h1>

            <div className="ikCollection__minting__info"><p>Minting</p>:<span>{Utils.preety.floor(mintedSize,0)} / {Utils.preety.floor(size,0)}</span></div>
            <div className="ikCollection__minting__info"><p>Price</p>:<span className="nft__card__price__tag">
              <div className="tooltip">
                <span className="tooltip__text">{symbol}</span>
              </div>
              {Utils.preety.unsafe(price/1e6,2)}
              <img src={img} alt="" />

            </span></div>
            <div className="ikCollection__minting__info"><p>Type</p>:<span>{collectionType === 1?"Blind Mintable":"Mintable"}</span></div>

            {!!size && !fillTab && <Link to={url} className={`ikCollection__timer ${collectionId}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.46777,8.39453l-.00225.00183-.00214.00208ZM18.42188,8.208a1.237,1.237,0,0,0-.23-.17481.99959.99959,0,0,0-1.39941.41114,5.78155,5.78155,0,0,1-1.398,1.77734,8.6636,8.6636,0,0,0,.1333-1.50977,8.71407,8.71407,0,0,0-4.40039-7.582,1.00009,1.00009,0,0,0-1.49121.80567A7.017,7.017,0,0,1,7.165,6.87793l-.23047.1875a8.51269,8.51269,0,0,0-1.9873,1.8623A8.98348,8.98348,0,0,0,8.60254,22.83594.99942.99942,0,0,0,9.98,21.91016a1.04987,1.04987,0,0,0-.0498-.3125,6.977,6.977,0,0,1-.18995-2.58106,9.004,9.004,0,0,0,4.3125,4.0166.997.997,0,0,0,.71534.03809A8.99474,8.99474,0,0,0,18.42188,8.208ZM14.51709,21.03906a6.964,6.964,0,0,1-3.57666-4.40234,8.90781,8.90781,0,0,1-.17969-.96387,1.00025,1.00025,0,0,0-.79931-.84473A.982.982,0,0,0,9.77,14.80957a.99955.99955,0,0,0-.8667.501,8.9586,8.9586,0,0,0-1.20557,4.71777,6.98547,6.98547,0,0,1-1.17529-9.86816,6.55463,6.55463,0,0,1,1.562-1.458.74507.74507,0,0,0,.07422-.05469s.29669-.24548.30683-.2511a8.96766,8.96766,0,0,0,2.89874-4.63269,6.73625,6.73625,0,0,1,1.38623,8.08789,1.00024,1.00024,0,0,0,1.18359,1.418,7.85568,7.85568,0,0,0,3.86231-2.6875,7.00072,7.00072,0,0,1-3.2793,10.457Z"/></svg>
              <span className={`card__clock${collectionId}`}></span>
            </Link>}
            {!!size && !!fillTab && <Link to={url} className={`ikCollection__timer ${collectionId}`}>
              <span className="card__clock"> Edit</span>
            </Link>}


          </div>
        </div>
        </div>
    )
  }
}


const AddressView = (props) =>{

  let btnRef = React.createRef();


  const handleLinkCopy = () =>{
   // console.log("btn");
   var textField = document.createElement('textarea')
   textField.innerText = props.address;
   document.body.appendChild(textField)
   textField.select()
   document.execCommand('copy')
   textField.remove();

   btnRef.current.style.display = "block";
   btnRef.current.children[0].innerText  = "copied"
   /* Alert the copied text */
   setTimeout(function() {
     btnRef.current.style.display = "none"
   }, 3000);
  }

  return (
    <div className="viewable__address" >
      {Utils.shortAddress(props.address)}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={handleLinkCopy}><path d="M18,19H6a3,3,0,0,1-3-3V8A1,1,0,0,0,1,8v8a5,5,0,0,0,5,5H18a1,1,0,0,0,0-2Zm5-9.06a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19l-.09,0L16.06,3H8A3,3,0,0,0,5,6v8a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V10S23,10,23,9.94ZM17,6.41,19.59,9H18a1,1,0,0,1-1-1ZM21,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V6A1,1,0,0,1,8,5h7V8a3,3,0,0,0,3,3h3Z"/></svg>
        <div className="tooltip" ref={btnRef}>
          <p className="tooltip__text">Copy</p>
        </div>
    </div>
  )
}



class TRC721TokenWidget extends Component{
  constructor(props) {
      super(props)

      this.state = {
        preview:false,
        isProcess:false,
        fontSize:"",
      }
      this.myRef = React.createRef();
  }

componentDidMount (){
  this.setupOnLoad()
}

  setupOnLoad = () =>{
    let {isMobile} = this?.props?.layout??{};
    let{symbol,name} = this?.props?.data??{};
    if(!name) return;
    let _width = this.myRef.current.offsetWidth;

    // Adjust name font
    let namContext = document.createElement('canvas').getContext('2d');
    namContext.font = "700 20px Inter";
    let fontSize = Math.floor(_width*20/namContext.measureText(`${name} (${symbol})`).width);
    let fontLimit = !isMobile?20:16;
    let fontLower = !isMobile?14:12;

    fontSize = fontSize > fontLimit?fontLimit:fontSize;
    fontSize = fontSize > fontLower?fontSize:fontLower;
    this.setState({fontSize})
  }

  deployMarketPlace = async() =>{
    try {
      let{isProcess} = this.state;
      let {nftAddress} = this.props.data;

      if(isProcess) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isProcess:true})

      let instance = await window.tronWeb.contract().at(Utils.MarketInregrator);
      // true if royalable
      await instance.enableMarket(nftAddress,true).send({
       feeLimit:3000 * 1e6,
     });

     this.setState({isProcess:false,preview:false})
     Utils.setToastAlert("Transaction submitted for deploying market","success")

    } catch (e) {
      this.setState({isProcess:false})
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to deploy marketplace","error")
      }
    }
  }

  render(){
    let {isSlider} = this?.props?.layout??{};
    isSlider = !!isSlider?" contract__card__slider":"";

    let{preview,isProcess,fontSize} = this.state;
    let {name,symbol,royaltyAmount,royalityReceiver,nftAddress,account,market,nfts,collections} = this.props.data;
    // console.log(this.props.data);
    let isDeployer = Utils.viewCreator() === account;

    return(
      <div className={`contract__card${isSlider}`}>
        <div className="contract__card__inner">
          <div className="contract__card__profile">

            <Link className="contract__card__logo" to={`/mintable/trc721/${nftAddress}`}>
              <DpViewer address={nftAddress} data={{type:"trc721"}} key={nftAddress}/>
            </Link>

            <div className="contract__card__profile_detail" ref={this.myRef}>
              <Link className="contract__card__name" to={`/mintable/trc721/${nftAddress}`} style={{fontSize}}>
                {name} ({symbol})
              </Link>
              <div className="contract__card__info">
                <div className="contract__card__info_each">
                  <p>Items</p>
                  <span>{Utils.preety(nfts,0)}</span>
                </div>
                <div className="contract__card__info_each">
                  <p>Collections</p>
                  <span>{Utils.preety(collections,0)}</span>
                </div>
              </div>
            </div>


          </div>

          <div className="contract__card__Meta">
            <div className="contract__card__Meta_each"><p>Contract</p> : <AddressView address={nftAddress}/></div>
            {!!isDeployer && <div className="contract__card__Meta_each"><p>Deployer</p> : <AddressView address={account}/></div>}
            <div className="contract__card__Meta_each"><p>Royality</p> : <span>{royaltyAmount/100 || 0}%</span></div>
            {!!isDeployer && <div className="contract__card__Meta_each"><p>Receiver</p> : <AddressView address={royalityReceiver}/></div>}
            {!!market && <div className="contract__card__Meta_each"><p>Market</p> : <AddressView address={market}/></div>}
            {!market && !isDeployer && <div className="contract__card__Meta_each"><p>Market</p> : <span>Not Enabled</span></div>}

          </div>
          {!market && !!isDeployer && <div className="btn__panel">

          {!preview && <CreateBtn onClick={()=>this.setState({preview:true})} fullView>Deploy MarketPlace</CreateBtn>}

          {!!preview && !isProcess && <BackBtn
            largeView
            onClick={()=>this.setState({preview:false})}
            disabled={!!isProcess}
            >
            Cancel
          </BackBtn>}
        {!!preview && <CreateBtn
          largeView={!isProcess}
          fullView={!!isProcess}
          onClick={this.deployMarketPlace}>
          Continue

          {!!isProcess && <div className="ball__pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>}
        </CreateBtn>}
      </div>}
        </div>
      </div>
    )
  }
}



class TRC721TokenWidgetDummy extends Component{
  constructor(props) {
      super(props)

      this.state = {
      }
  }


  render(){
    let {isSlider} = this?.props?.layout??{};
    isSlider = !!isSlider?" contract__card__slider":"";

    return(
      <div className={`contract__card${isSlider} dummy_card`}>
        <div className="contract__card__inner">
          <div className="contract__card__profile">

            <div className="contract__card__logo">&nbsp;</div>

            <div className="contract__card__profile_detail">
              <div className="dummy_card_name">&nbsp;</div>
              <div className="contract__card__info">
                <div className="contract__card__info_each">
                  <p>&nbsp;</p>
                  <span>&nbsp;</span>
                </div>
                <div className="contract__card__info_each">
                  <p>&nbsp;</p>
                  <span>&nbsp;</span>
                </div>
              </div>
            </div>


          </div>

          <div className="contract__card__Meta">
            <div className="contract__card__Meta_each"><p>&nbsp;</p> : <span>&nbsp;</span></div>
            <div className="contract__card__Meta_each"><p>&nbsp;</p> : <span>&nbsp;</span></div>
            <div className="contract__card__Meta_each"><p>&nbsp;</p> : <span>&nbsp;</span></div>

          </div>

        </div>
      </div>
    )
  }
}






export {
  MintableCardDummy,MintableCardDummyMini,
  MintableCard,MintableCardGrid,
  TRC721TokenWidget,TRC721TokenWidgetDummy,
}
