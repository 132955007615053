

import React, { Component,Fragment } from 'react'
import { Link } from 'react-router-dom'
import api from "../../api"

//import ReactTable from 'react-table'
import {Utils,PopUp} from "../../helper"
import {ReactComponent as PlusIcon} from '../../images/plus.svg';
import {DpViewer} from "../../helper/file-viewer.jsx"
import './style.scss'

class AllTokens extends Component{
  constructor(props) {
      super(props)
      this.state = {
        canShow:false,
        isFetching:false,
        filterString:"",
        thisName:Date.now()+"-select-explore"
      }
  }
  componentDidMount(){
    this.fetchTokens()
    document.addEventListener('mouseup', this.fireEvents);
  }
  async componentWillUnmount(){
    document.removeEventListener('mouseup', this.fireEvents);
  }

  fetchTokens = async() =>{
    try {
      let {tokens,view} = this.props.data;
      this.setState({isFetching:true})

      let mode = view === "market"?"market":"create";
      let res = await api.getNftContracts({mode});
      let contracts = res.data.result;

      tokens = [...tokens,...contracts].filter((x,i,a) => a.findIndex(j => j.nftAddress === x.nftAddress) === i)
      this.setState({isFetching:false},()=>{
        this.props.handleUpdateTokenList(tokens)
      })
    } catch (e) {
      this.setState({isFetching:false})

    }
  }


  handleOptions = (value) =>{
    this.props.onOption(value);
    this.setState({canShow:false})

  }

  renderList = () =>{
    let {tokens} = this.props.data
    let {filterString} = this.state;

    let _tokens = tokens.filter(i =>{
      let{nftAddress,name,symbol} = i;
      filterString = filterString.toUpperCase()
      if(!filterString){
        return true;
      }else if(filterString.length === 34){
        return nftAddress.toUpperCase() === filterString;
      }else{
        let _index = name.toUpperCase().search(filterString);
           _index = _index !== -1?_index:symbol.toUpperCase().search(filterString);
           return _index !== -1;
      }
    })

    _tokens = !!_tokens.length?_tokens:tokens;

    return _tokens.map((item,i)=>{
      let{symbol,name,img,nftAddress,balance} = item;

      let _balance = balance || 0

      return <div className="select__token__option"
        onClick={()=>this.handleOptions(nftAddress)}
        key={nftAddress}
        >
        <div className="select__token__info">
          {!!img && <img src={img} alt="" />}
          {!img && <DpViewer address={nftAddress} data={{type:"trc721"}} key={nftAddress+"a"}/>}
          <div className="select__token__symbol">
            <p>{symbol}</p>
            {!!name && <span>{name}</span>}
          </div>
        </div>

        <div className="select__token__info__right">
          <p>{_balance}</p>
          <span>{Utils.shortAddress(nftAddress)}</span>
        </div>



      </div>
    })
  }



  handleSearch = async(e) =>{

    try {
      e.preventDefault()
      let{isFetching} = this.state;
      if(!!isFetching) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isFetching:true})
      await Utils.wait(2000)
      this.setState({isFetching:false})
    } catch (e) {
      console.log(e);
      this.setState({isFetching:false})

    }
  }

  render(){
    let {canShow,filterString,isFetching} = this.state;
    let {tokens,tab,view} = this.props.data;
     let _index = tokens.findIndex(i => i.nftAddress === tab);
     let {name,symbol,img,balance} = tokens[_index] || {};
     let _balance = balance || 0;

    return(
       <Fragment>

        <div className="selected__token__options"
          onClick={()=>this.setState({canShow:!canShow})}
          >
          <div className="selected__token__options__left">
            {!!img && <img src={img} alt="" />}
            {!img && <DpViewer address={tab} data={{type:"trc721"}} key={tab+"a"}/>}
            <div className="selected__token__symbol">
              <p>{symbol}</p>
              <span>{name}</span>
            </div>
          </div>

          <div className="selected__token__options__right">
            <div className="selected__token__info">
              <p>{_balance}</p>
              <span>{Utils.shortAddress(tab)}</span>
            </div>
            <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M81.8457,25.3876a6.0239,6.0239,0,0,0-8.45.7676L48,56.6257l-25.396-30.47a5.999,5.999,0,1,0-9.2114,7.6879L43.3943,69.8452a5.9969,5.9969,0,0,0,9.2114,0L82.6074,33.8431A6.0076,6.0076,0,0,0,81.8457,25.3876Z"/></svg>
          </div>
        </div>


        {!!canShow && <PopUp.PopupContainer
          className="popup__tokens__view"
          title="Select NFT Token"
          onClose={()=>this.setState({canShow:false})}>
            <form onSubmit={this.handleSearch.bind(this)} className="token__list__search">
              <input
                type="text"
                className="token__list__search__input"
                placeholder="Search name,symbol or address"
                onChange={(e)=>this.setState({filterString:e.target.value})}
                value={filterString || ''}
                readOnly={!!isFetching}
                />
                <button
                  type="button"
                  onClick={this.handleSearch}
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
                </button>
            </form>
            {!!isFetching && <div className="ball__pulse__loader">
              <i></i><i></i><i></i>
            </div>}

          <div className="select__token__options">
            {this.renderList()}
          </div>

          {view === "wallet" && <Link to="/mintable/create-trc721" className="create__token__btn">
            <PlusIcon />
            Create
          </Link>}
        </PopUp.PopupContainer>}
      </Fragment>
    )
  }
}





class MyTokens extends Component{
  constructor(props) {
      super(props)
      this.state = {
        canShow:false,
        isFetching:false,
        filterString:"",
        thisName:Date.now()+"-select-explore",

      }
  }
  componentDidMount(){
    this.fetchNftTokens()
    document.addEventListener('mouseup', this.fireEvents);
  }
  async componentWillUnmount(){
    document.removeEventListener('mouseup', this.fireEvents);
  }

  fetchNftTokens = async() =>{
    let _knft = Utils.getNftsAddressInfo(Utils.NftContract);
    _knft.nftAddress = Utils.NftContract;

    try {
      let {address} = this.props.data;
      let {isFetching} = this.state;
      if(!!isFetching) return null;

      this.setState({isFetching:true})
      if(!address){
        this.setState({isFetching:false},()=>{
          this.props.handleUpdateTokenList([_knft])
        })
      }else {
        let res = await api.getNftContracts({address,mode:"create"});
        res = res.data.result;
        res.push(_knft);
        this.setState({isFetching:false},()=>{
          this.props.handleUpdateTokenList(res)
        })
      }
    } catch (e) {
      this.setState({isFetching:false})
    }
  }


  handleOptions = (value) =>{
    this.props.onOption(value);
    this.setState({canShow:false})
  }

  renderList = () =>{
    let {nftTokens} = this.props.data
    let {filterString} = this.state;

    let _nftTokens = nftTokens.filter(i =>{
      let{nftAddress,name,symbol} = i;
      filterString = filterString.toUpperCase()
      if(!filterString){
        return true;
      }else if(filterString.length === 34){
        return nftAddress.toUpperCase() === filterString;
      }else{
        let _index = name.toUpperCase().search(filterString);
           _index = _index !== -1?_index:symbol.toUpperCase().search(filterString);
           return _index !== -1;
      }
    })

    _nftTokens = !!_nftTokens.length?_nftTokens:nftTokens;

    return _nftTokens.map((item,i)=>{
      let{symbol,name,img,nftAddress} = item;

      return <div className="select__token__option"
        onClick={()=>this.handleOptions(nftAddress)}
        key={nftAddress}
        >
        <div className="select__token__info">
          {!!img && <img src={img} alt="" />}
          {!img && <DpViewer address={nftAddress} data={{type:"trc721"}} key={nftAddress+"a"}/>}
          <div className="select__token__symbol">
            <p>{symbol}</p>
            {!!name && <span>{name}</span>}
          </div>
        </div>

        <div className="select__token__info__right">
          <span>{Utils.shortAddress(nftAddress)}</span>
        </div>
      </div>
    })
  }



  handleSearch = async(e) =>{

    try {
      e.preventDefault()
      let{isFetching} = this.state;
      if(!!isFetching) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isFetching:true})
      await Utils.wait(2000)
      this.setState({isFetching:false})
    } catch (e) {
      console.log(e);
      this.setState({isFetching:false})

    }
  }

  render(){
    let {canShow,filterString,isFetching} = this.state;
    let {nftTokens,nftAddress} = this.props.data;
    nftTokens = nftTokens || []
     let {symbol,img} = nftTokens.find(i => i.nftAddress === nftAddress) || {};

    return(
      <Fragment>

       <div className="selected__token__options selected__token__options_create"
         onClick={()=>this.setState({canShow:!canShow})}
         >
         <div className="selected__token__options__left">
           {!!img && <img src={img} alt="" />}
           {!img && <DpViewer address={nftAddress} data={{type:"trc721"}} key={nftAddress+"a"}/>}
           <div className="selected__token__symbol">
             <p>{symbol}</p>
           </div>
         </div>

         <div className="selected__token__options__right">
           <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M81.8457,25.3876a6.0239,6.0239,0,0,0-8.45.7676L48,56.6257l-25.396-30.47a5.999,5.999,0,1,0-9.2114,7.6879L43.3943,69.8452a5.9969,5.9969,0,0,0,9.2114,0L82.6074,33.8431A6.0076,6.0076,0,0,0,81.8457,25.3876Z"/></svg>
         </div>
       </div>


       {!!canShow && <PopUp.PopupContainer
         className="popup__tokens__view"
         title="Select NFT Token"
         onClose={()=>this.setState({canShow:false})}>
           <div className="token__list__search">
             <input
               type="text"
               className="token__list__search__input"
               placeholder="Search name,symbol or address"
               onChange={(e)=>this.setState({filterString:e.target.value})}
               value={filterString || ''}
               readOnly={!!isFetching}
               />
               <button
                 type="button"
                 onClick={this.handleSearch}
                 >
                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
               </button>
           </div>
           {!!isFetching && <div className="ball__pulse__loader">
             <i></i><i></i><i></i>
           </div>}

         <div className="select__token__options">
           {this.renderList()}
         </div>

         <Link to="/mintable/create-trc721" className="create__token__btn">
           <PlusIcon />
           Create
         </Link>
       </PopUp.PopupContainer>}
     </Fragment>
    )
  }
}

export {AllTokens,MyTokens}
