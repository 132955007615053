import React, { Component } from 'react'

// import $ from "jquery";
// import styled from 'styled-components'
import {Utils} from "../../helper"
import * as Comp from './comp'
import * as Tasks from './helper'
import '../../scss/top-sellor-widget.scss'
import '../../scss/home-banner.scss';
import '../../scss/scroll-btn.scss';
import '../../scss/partner.scss';


import Discover from "../discover/index.jsx"
// import ReactCrop from 'react-image-crop';
import NeoCollection from "../../components/neoCollection/index.jsx"


class Home extends Component {
  constructor(props) {
      super(props)

      this.state = {
        address:"",
        up:false,

      }
      this.myRef = React.createRef();


  }
  componentDidMount = () =>{

    document.title = "Home || Kraftly";

    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      // console.log(account);
      const {address} = account
      this.setState({address})
    })
    window.addEventListener('scroll', this.switchArrow, {passive: true});

  }
  componentWillUnmount(){
    document.title = "Kraftly";
    window.removeEventListener('scroll', this.switchArrow, {passive: true});
  }
  switchArrow = () =>{
    let {up} = this.state;
    let _ref = this.myRef.current;
    let discoverTop = _ref.getBoundingClientRect().top;
    let _up = discoverTop <= window.innerHeight/2;

    if(_up !== up){
      this.setState({up:_up})
    }
  }

  handleTop = () =>{
    window.scrollTo({top: 0,left: 0, behavior: 'smooth'})
  }


    render() {

      let {up} = this.state;
      // console.log(up);
        return (
          <main className="main">

            <div className="container">
              <div className="row no-gutters">

                <div className="col-12">
                  <Comp.Banner />
                </div>

                {!!up && <div className="scroll_btn" onClick={this.handleTop}>
                  <div className="scroll_btn_arrow">
                    <svg transform='rotate(180)' fill="none" height="24" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><line x1="12" x2="12" y1="4" y2="20"/><polyline points="18 14 12 20 6 14"/></svg>
                  </div>
                </div>}

              </div>

              <section className="content__section">
                <div className="row no-gutters">
                  <div className="col-12">
                    <div className="section__title">
                      NEO collections
                    </div>
                  </div>
                  <div className="col-12">
                    <NeoCollection neoIndex ={[0,4,12,11,5,6,7]}/>
                  </div>
                </div>
              </section>

              <Tasks.TopAuthor />
              <Tasks.HomeCarouselCards />
              <section className="content__section" ref={this.myRef}>
                <Discover/>
              </section>
              <Comp.Info/>
              <Comp.Partners/>

            </div>
          </main>
        )
    }
}


export default Home
