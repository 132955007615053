import React, { Component,Fragment } from 'react'
import api from "../../api"
import {Utils,SearchNotFound} from "../../helper"
import NftCard,{NftCardDummy} from "../../components/nftCard/index.jsx"
import {Paginator,MenuSelect2,GridToggler2} from "../../frag"

import {ReactComponent as ArrowLeft} from '../../images/arrow-left.svg';
import {ReactComponent as FilterIcon} from '../../images/filter.svg';
import {ReactComponent as CloseIcon} from '../../images/close.svg';




class Explore extends Component {

  constructor(props) {
      super(props)
      let limit = 12;
      const urlParams = new URLSearchParams(window.location.search);
      let page = urlParams.get('page');
      page = Utils.floor(page)
      page = page && page > 0?page:1;
      let start = page > 4?page -3:1;

      let filterString = this.props.filterString ||"";

      this.state = {
        filterString,
        address:"",
        isLoading:false,
        nfts:[],
        page,
        start,
        limit,
        count:0,
        category:"all",
        type:"gallery",
        arrange:"auto",
        bundle:"none",
        showFilter:window.innerWidth > 768,

        isGrid:localStorage.getItem('isGrid') === "true",
      }
      this.myRef = React.createRef();
  }
  componentDidMount = () =>{
    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      // console.log(account);
      const {address} = account
      this.setState({address})
    })
    this.fetchNfts()
  }

 UNSAFE_componentWillReceiveProps(nextProps) {
   let{page,filterString} = this.state;
     const urlParams = new URLSearchParams(window.location.search);
     let _page = urlParams.get('page');
     _page = Utils.floor(_page);
     if(_page !== page){
       page = _page && _page > 0?_page:1;
       let start = page > 4?page -3:1;
       this.setState({page,start},this.fetchNfts)
     }else if (this.props.callback && nextProps.filterString !== filterString) {
       this.setState({nfts:[],count:0,page:1,start:1,filterString:nextProps.filterString},this.fetchNfts)
     }
    }
  updatePagingUrl = () =>{
    let{page} = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("page", page)
    let params = urlParams.toString();
    if (window.history.pushState) {
      let url = `${window.location.pathname}?${params}`;
      window.history.pushState(null, null,url);
    }

    if(this.props.callback){
      this.props.callback(this.state.filterString)
    }
  }
  fetchNfts = async () =>{
    let{page,limit,category,type,arrange,filterString} = this.state;
      try {
        this.setState({isLoading:true})
        let payload ={mode:"explore",page,limit,category,type,arrange}
        if(filterString){
          payload.filterString = filterString;
        }

        let res = await api.getMarket(payload);
        // console.log(res);
        let{result,count} = res.data;
        count = count || 0;
        this.setState({nfts:result,count,isLoading:false},this.updatePagingUrl)
      } catch (e) {
        let msg = e.response?.data?.message??"No NFT found";
        Utils.setToastAlert(msg,"error");
        // console.log(e.response);
        this.setState({isLoading:false})
      }
  }
  fetchFilterData = async(event) =>{
    event.preventDefault()

      let{isLoading} = this.state;
      if(isLoading) return Utils.setToastAlert("Previous request processing","info");

      this.setState({nfts:[],category:"all",type:"gallery",arrange:"auto",page:1,start:1},this.fetchNfts)
  }

  renderNft = () =>{
    let{nfts,isGrid} = this.state;
    let layout={
      isSquare:true,
      isMobile:!!isGrid,
    }

    return nfts.map((data,i)=>{
      let{tokenId,nftAddress} = data;
      if(!!isGrid){
        return <div className="col-6 col-sm-4 col-lg-3 col-xl-2" key={tokenId+nftAddress+"largeA"}>
          <NftCard data={data} layout={layout} key={tokenId+nftAddress+"largeB"}/>
        </div>
      }

      return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={tokenId+nftAddress+"smallA"}>
        <NftCard data={data} layout={layout} key={tokenId+nftAddress+"smallB"}/>
      </div>
    })
  }
  renderDummyNft = () =>{
    let{isGrid,showFilter} = this.state;
    let layout={
      isSquare:true,
      isMobile:!!isGrid,
    }

    // showFilter = false;
    let _w = window.innerWidth;
    let count = _w< 576?4:_w< 768?8:_w< 992?!showFilter?6:4:_w< 1200?!showFilter?12:9:12;
    if(isGrid){
      count = _w< 576?8:_w< 768?12:_w< 992?!showFilter?12:9:_w< 1200?12:18;
    }

      return new Array(count).fill(undefined).map((item,i)=>{
        if(!!isGrid){
          return <div className="col-6 col-sm-4 col-lg-3 col-xl-2" key={"smallAD"+i}>
            <NftCardDummy layout={layout} key={"smallB"+i}/>
          </div>
        }

        return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={"largeAD"+i}>
          <NftCardDummy layout={layout} key={"largeB"+i}/>
        </div>
      })
  }


  handleInput = e =>{
    let val = e.target.value;
    if (!val || val.length <= 40) {
      this.setState({filterString:val})
    }
  }
  handlePaginator = (value) =>{
    this.setState(value,this.fetchNfts)
    this.myRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  onOption = (name,value)=>{
    let{isLoading} = this.state
    if(isLoading) return Utils.setToastAlert("Previous request processing","info");
    if(this.state[name] === value) return console.log("same option");
    this.setState({[name]:value,nfts:[]},this.fetchNfts)
  }
  redirectBundle = (value)=>{
    window.location.href = `/${value}`
  }

    render() {
      let{category,type,arrange,bundle,nfts,filterString,isLoading,count,start,limit,page,showFilter,isGrid} = this.state;

      let typeOptions = {gallery:"Gallery",onAuction:"On Auction",now:"Buy Now",hasOffers:"Has Offers",new:"Newest Mint",old:"Oldest Mint"};
      let categoryOptions = {all:"All categories",art:"Art",photography:"Photography",games:"Games",metaverses:"Metaverses",music:"Music",domains:"Domains",memes:"Memes"};
      let priceOptions = {auto:"Price",low:"Low to high",high:"High to low"};
      let bundleOptions = {
        none:"Collections",
        trocks:"TROCK",
        "cypto-clouds":"CRYPTO CLOUD",
        "fox555":"FOX555",
        "explore/tpunks":"TPUNKS",
        "explore/bayct":"BAYCT",
        "explore/tronmeebits":"TMEEBITS",
        "explore/tronbull":"TronBull",
        "explore/tapes":"tApes",
        "explore/coolcats":"Cool Cats",
      };

        return (

          <Fragment>
            <div className="col-12"  ref={this.myRef}>
              <div className="ik__home__filter">
                <form onSubmit={this.fetchFilterData} className="main__filter-search">
                  <input
                    type="text"
                    placeholder="Search NFT by name or address"
                    value = {filterString}
                    onChange={(e)=>this.setState({filterString:e.target.value})}
                    />
                  <button
                    type="button"
                    onClick={this.fetchFilterData}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
                  </button>
                </form>
                <button className="explore__filter__btn" onClick={()=>this.setState({showFilter:!showFilter})}>
                  {!showFilter && <FilterIcon/>}

                  {!!showFilter && <ArrowLeft/>}
                  <span>Filters</span>
                </button>

                <GridToggler2
                  data={{isGrid}}
                  handleGrid={(e)=>this.setState(e)}
                  />
              </div>
            </div>

            {!!showFilter && <div className="col-12 col-md-3">
              <div className="side__filter">
                <div className="explore-filter-header">
                  <p onClick={()=>this.setState({showFilter:false})}><CloseIcon/></p>
                </div>
                <MenuSelect2 options={typeOptions} value={type} onOption={(e)=>this.onOption("type",e)}/>
                <MenuSelect2 options={categoryOptions} value={category} onOption={(e)=>this.onOption("category",e)}/>
                <MenuSelect2 options={priceOptions} value={arrange} onOption={(e)=>this.onOption("arrange",e)}/>
                <MenuSelect2 options={bundleOptions} value={bundle} isOpen="true" onOption={(e)=>this.redirectBundle(e)}/>
            </div>
            </div>}

            <div className={`col-12 ${!!showFilter?"col-md-9":"col-md-12"}`}>
              <div className="row no-gutters">
                {!isLoading && !!nfts.length && this.renderNft()}
                {!!isLoading  && this.renderDummyNft()}
                {!isLoading && !nfts.length && <div className="col-12"><SearchNotFound data={{msg:"Match Not Found",code:"NFT"}}/></div>}
              </div>
            </div>



            <div className="col-12 ">
              <Paginator  data={{count,start,limit,page,size:nfts.length}} handler={this.handlePaginator}/>
            </div>
          </Fragment>
        )
    }
}

class ExplorePage extends Component {

  constructor(props) {
      super(props)
      this.state = {
      }
  }
  componentDidMount = () =>{
    document.title = "Explore || Kraftly";
  }
  componentWillUnmount(){
    document.title = "Kraftly";
  }



    render() {


        return (
          <main className="main">
        		<div className="container">
        			<div className="row no-gutters">
        				<div className="col-12">
        					<div className="section__title section__title__middle">
        						Explore exclusive digital assets
        					</div>
        				</div>

                <Explore />

        			</div>
        		</div>
        	</main>
        )
    }
}
export default ExplorePage
export{Explore }
