import React, { Component,Fragment} from 'react'
import styled from 'styled-components'
import $ from "jquery";


import api from "../api"
import {Utils} from "../helper"
import{SignGroup,InputLabel} from "../pages/create/comp"
import NftFile from "../pages/create/nft-file-preview.jsx";


import {ReactComponent as PlusIcon} from '../images/plus.svg';
import {ReactComponent as CloseIcon} from '../images/close.svg';
import {ReactComponent as RecycleBin} from '../images/recycle.svg';


const CreateBtn = styled.button.attrs(props=>({
  className: `sign__btn ${props.fullView?" full__btn":props.largeView?" large__btn":""}`,
  type:"button"
}))``


const BackBtn = styled.button.attrs(props=>({
  className: `sign__btn red__btn ${props.fullView?" full__btn":props.largeView?" large__btn":""}`,
  type:"button"
}))``



class PreviewEachNft extends Component{
  constructor(props) {
      super(props)

      this.state = {
        isProcess:false,
        showFillOptions:false,
        tokenId:this.props.data.tokenId,
        preview:false,
        name:"",
        description:"",
        tags:"",
        adult:"false",
        imgData:null,
        attributes:[],
        attrKey:"",
        attrValue:"",
        nfts:[],
        loaded:0,
        total:0,


        showMore:false,
        unlockable:[],
        additional:{
          locked:false,
          url:"",
          formate:"image"
        },
        rarity:0,
        rank:0,


      }
  }
  componentDidMount(){
    let{description,name,tags,adult,attributes,imgData,unlockable,rank,rarity,elink} = this.props.data;
    // console.log( this.props.data);
    attributes = !!attributes && attributes[0]?attributes:[];
    unlockable = !!unlockable && unlockable[0]?unlockable:[];

    imgData = imgData?imgData:null;

    name = name || "";
    description = description || "";
    tags = tags || [];
    attributes = attributes || [];
    adult = adult || false;

    rank = rank || 0;
    rarity = rarity || 0;
    elink = elink || "";


    this.setState({description,name,tags,adult,attributes,imgData,unlockable,rank,rarity,elink})
  }

  handleFile = event =>{
    let file = event.target.files[0];
    // console.log(file);
    if(!file){
      return Utils.setToastAlert("File not selected");
    }
    let ext = file.name.split(".");
    let extName = ext[ext.length - 1].toLowerCase();
    // file.name = `${ext[0]}.${extName}`;
    // console.log(file);
    const file_ext = ["jpg","jpeg","gif","mp4","mp3","png",]

    if (!file.type || !file_ext.includes(extName)) {
      return Utils.setToastAlert(`Can not upload .${ext}, Only support .jpg .png .gif .mp4 .mp3 files type`);
    }

    if (file && file.size > 2*1024*1024) { //2MB
      return Utils.setToastAlert("NFT file size to large, try uploading file less than 2MB");
    }

    //to display image on client
    this.setState({imgData:file});
  }
  handleInput = event =>{
    const name = event.target.name;
    let value = event.target.value;

    if(name === 'description'){
      if(value.length > 300){
        event.target.style.borderColor = "red";
      }else if(event.target.style.borderColor) {
        event.target.style.borderColor = "";
      }
    }

    if(name === 'name'){
      let _name = value.replace(/ /g, "");
      let _notallowed = ["ROCKID","R0CKID","ROCKLD","R0CKLD",
      "CLOUDID","CL0UDID","CLOUDLD","CL0UDLD",
      "FOXID","F0XID","FOXLD","F0XLD"]
      .filter(i=> i === _name.substr(0,i.length).toUpperCase()).length > 0;

      if(!!value.length && !!_notallowed){
        value = "";
        event.target.style.borderColor = "yellow";
      }else if (!!value.length && (value.length < 3 || value.length > 30)) {
        event.target.style.borderColor = "red"
      }else if(event.target.style.borderColor) {
        event.target.style.borderColor = "";
      }
    }

    if(name === "elink"){
      if(!!value && !Utils.validateUrl(value)){
        event.target.style.borderColor = "red"
      }else if(event.target.style.borderColor) {
        event.target.style.borderColor = "";
      }
    }
    if(name === "rank"){
      value = Utils.inputSafe(value,0);
    }
    if(name === "rarity"){
      value = Utils.inputSafe(value,2);
    }

    this.setState({[name]:value})
  }
  handleTags = event=>{
    // console.log(event.charCode);
    let regex = /^[a-z,#]+$/;
    let value = event.target.value.replace(/\s/g, "").toLowerCase();
    if(value.length > 0 && !regex.test(value))return;

    if(value !== "#" && value.length > 0 && !value.startsWith("#")){
      value = "#"+value;
    }
    let res = value.split(",")

    res = res.filter(i => i.startsWith("#")).toString()
    if(value.length>2 && value.charAt(value.length-1) === ","){
      if(this.state.tags.charAt(this.state.tags.length-1) !== "#"){
        res += ",#";
      }

    }
    this.setState({tags:res})
  }




  handlePreview = ()=>{
    let address = Utils.viewCreator();
    if(!address) return $(".header__action-btn--signin").click();
    let{name,description,imgData,tags,elink} =this.state;
    let{author} = this.props.collection;

    if(address !== author) return Utils.setToastAlert("Looks like, collection not owned by you");


    if(!name || name.length < 3 || name.length > 30) return Utils.setToastAlert("Invalid NFT art name length");
    if(description && description.length > 300) return Utils.setToastAlert("NFT description length too large");
    if(!imgData) return Utils.setToastAlert("Please attach file (GIF,PNG,JPG,MP4 or MP3) of Art");
    if(!!elink && !Utils.validateUrl(elink)) return Utils.setToastAlert("Broken or invalid external link provided, please check and enter again");
    this.setState({preview:true})
  }


  handleDataUpload = async() =>{
    try {
      let{name,description,imgData,tags,isProcess,attributes,tokenId,adult,unlockable,rank,rarity,elink} =this.state;
      let{author,collectionId,nftAddress} = this.props.collection;

      let address = Utils.viewCreator();

      if(!address) return $(".header__action-btn--signin").click();
      if(address !== author) return Utils.setToastAlert("Looks like, collection not owned by you");
      if(isProcess) return Utils.setToastAlert("File already uploading");

      this.setState({isProcess:true})
      Utils.setToastAlert("Uploading NFT details and file","info");
      // let ext = imgData.name.split(".");
      // let fileName = `${ext[0]}.${ext[ext.length - 1].toLowerCase()}`;
      let payload = new FormData()

      // console.log(JSON.stringify(attributes));
      payload.append('collectionId', collectionId)
      payload.append('nftAddress', nftAddress)

      payload.append('name', name)
      payload.append('address', address)
      payload.append('tokenId', tokenId)
      payload.append('tags', tags)
      payload.append('description', description)
      payload.append('adult',adult)
      if(attributes && attributes.length){
        payload.append('attributes',JSON.stringify(attributes))
      }
      if(unlockable && unlockable.length){
        payload.append('unlockable',JSON.stringify(unlockable))
      }
      if(!!rank){
        payload.append('rank',rank)
      }
      if(!!rarity){
        payload.append('rarity',rarity)
      }
      if(!!elink){
        payload.append('elink',elink)
      }
      payload.append('img', imgData)

      await api.createNewIkNft(payload,{
          timeout:5*60*1000,
          onUploadProgress: progressEvent => {
            // console.log(progressEvent);
            let{loaded,total} = progressEvent
            this.setState({loaded,total})
          }
      });
      // console.log(res);
      Utils.setToastAlert(`${name} uploaded succesfully`,"success")
      this.props.handleMarkOpen("submit")
      // console.log(result);
      // window.location.href = `/market/${tokenId}`;

    } catch (e) {
      console.log(e);
      let msg = e?.response?.data?.message??"Failed to upload NFT art details";
      console.log(msg);
      this.setState({isProcess:false})
      Utils.setToastAlert(msg,"error")
      // this.props.back()
    }
  }
  renderProgressBar = () =>{
    let{loaded,total,imgData} = this.state;
    total = !!total?total:!!imgData && imgData.size?imgData.size:0;

    let precentage = Utils.ceil(loaded*100/total,0);
    precentage = precentage || 5;
    // console.log(Utils.fileSize(loaded),Utils.fileSize(total));


    return <div className="create__upload__progress">
      <div className="create__upload__progress__bar" style={{width:precentage+"%"}}>&nbsp;</div>
      <p className="create__upload__size">{Utils.fileSize(loaded)}/{Utils.fileSize(total)}</p>
    </div>
  }


  handleAttr = (e)=>{
    e.preventDefault()

    let {attrKey,attrValue,attributes,preview} = this.state;
    if(preview) return null;

    if(!attrKey) return Utils.setToastAlert("Attribute name required");
    if(attrKey.length <= 1 || attrKey.length > 150) return Utils.setToastAlert("Attribute name must be 2-150 characters");
    if(!attrValue) return Utils.setToastAlert("Attribute value required");
    if(attrValue.length <= 1 || attrValue.length > 150) return Utils.setToastAlert("Attribute value must be 2-150 characters");

    let index = attributes.findIndex(i => i[attrKey]);
    if(index !== -1) return Utils.setToastAlert("Duplicate attribute available");

    attributes.push({[attrKey]:attrValue})
    this.setState({attrKey:"",attrValue:"",attributes})
  }

  handleRemoveAttr = (index) =>{
    let {attributes,preview} = this.state;
    if(preview) return null;

    let data = attributes.splice(index, 1);
    let [k,v] = Object.entries(data[0])[0];
    this.setState({attrKey:k,attrValue:v,attributes})
  }
  renderAttributes = () =>{
    let {attributes} = this.state;
    return attributes.map((attrs,i)=>{
      let [k,v] = Object.entries(attrs)[0];
      return <div className="create__attributes__input" key={k+v+i}>
        <input
          type="text"
          className="create__input"
          value={k}
          readOnly={true}
          />
        <input
          type="text"
          className="create__input"
          value={v}
          readOnly={true}

          />
        <button
          type="button"
          onClick={()=>this.handleRemoveAttr(i)}
          ><RecycleBin/></button>
      </div>
    })
  }

  addAdditionalContent = (e)=>{
    e.preventDefault()

    let {additional,unlockable} = this.state;
    let{locked,url,formate} = additional;

    if(!url) return Utils.setToastAlert("content url required");
    if(!Utils.validateUrl(url)) return Utils.setToastAlert("Broken or invalid link provided, please check and enter again");

    let index = unlockable.findIndex(i => i.url !== url);
    if(index !== -1) return Utils.setToastAlert("Duplicate content url available");

    unlockable.push(additional)
    this.setState({additional:{locked:false,url:"",formate:"image"}})
  }

  RemoveAdditionalContent = (index) =>{
    let {additional,unlockable} = this.state;
    let {locked,url,formate} = unlockable.splice(index, 1)[0];
    this.setState({additional:{locked,url,formate}})
  }
  renderAdditionalContent = () =>{
    let {unlockable} = this.state;
    return unlockable.map((content,i)=>{
      let{locked,url,formate} = content;

      return <div className="create__content__input" key={url}>
        <input
          type="text"
          className="create__input"
          value={url}
          readOnly={true}
          />
          <input
            type="text"
            className="create__select create__select__absolute"
            value={formate === "pdf"?"PDF":formate.charAt(0).toUpperCase() + formate.slice(1)}
            disabled={true}
            />
          <input
            type="text"
            className="create__select"
            value={!!locked?"Unlockable":"public"}
            disabled={true}
            />
        <button
          type="button"
          onClick={()=>this.RemoveAdditionalContent(i)}
          ><RecycleBin /></button>
      </div>
    })
  }



  render(){
    // let{tokenId} = this.props.data;
    let{name,description,tags,adult,imgData,preview,isProcess,attributes,attrKey,attrValue,showMore,elink,rank,rarity,additional,unlockable} = this.state;

    let{author} = this.props.collection;
    let file_name = imgData?imgData.size <= 2*1024*1024?imgData.name:"File too large":"e. g. JPG, PNG, Gif, MP4, MP3";
    return(
      <div className="ik__nft__update__panel">
          <div className="ik__nft__update__body">
            <div className="row no-gutters">
              <div className="col-12 col-md-6">
              <NftFile data={{imgData}}/>
            </div>
            <div className="col-12 col-md-6">
              <div className="create__form">
                <div className="row no-gutters">
                  <div className="col-12">
                    <h4 className="create__title">Upload File :-</h4>
                  </div>

                  <div className="col-12">
                    <div className="create__file">
                      <label id="file2" htmlFor="sign__file-upload2" >{file_name}</label>
                      <input
                        id="sign__file-upload2"
                        name="file2"
                        className="sign__file-upload"
                        type="file"
                        accept=".png,.jpg,.gif,.mp4,.mp3"
                        onChange={this.handleFile}

                        />
                    </div>
                  </div>
                  <div className="col-12">
                    <h4 className="create__title">Item Metadata :-</h4>
                  </div>

                  <SignGroup>
                    <InputLabel htmlFor="name" title="Item name" tooltip="A good name can stimulate the imagination and enrich the audience's experience"/>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      className="create__input"
                      placeholder="e. g. 'Crypto Heart.png'"
                      value={name}
                      onChange={this.handleInput}
                      readOnly={!!preview}
                      />
                  </SignGroup>

                  <SignGroup>
                    <InputLabel htmlFor="description" title="Descriptione" tooltip="Introduce your work and let more people know the value of your work"/>
                    <textarea
                      id="description"
                      name="description"
                      className="create__textarea"
                      placeholder="e. g. 'After purchasing you will able to receive...'"
                      value={description}
                      onChange={this.handleInput}
                      readOnly={!!preview}

                      />
                  </SignGroup>


                  <SignGroup>
                    <InputLabel htmlFor="tags" title="Tags" tooltip="Appropriate tags may enhance search appearance and visibility to target audience"/>
                    <textarea
                      id="tags"
                      name="tags"
                      className="create__textarea create__textarea__mini"
                      placeholder="e. g. #art,#anime,#memes"
                      value={tags}
                      onChange={this.handleTags}
                      readOnly={!!preview}

                      ></textarea>
                  </SignGroup>
                  <SignGroup >
                    <InputLabel htmlFor="adult" title="18+ content?" tooltip="Select Yes for 18+ or NFSW contents"/>
                    <select
                      id="adult"
                      name="adult"
                      className="create__select"
                      onChange={(e)=>this.setState({adult:e.target.value})}
                      value={adult}
                      disabled={!!preview}

                      >
                      <option value={"true"}>Yes.</option>
                      <option value={"false"}>No!</option>

                    </select>
                  </SignGroup>
                  <div className="col-12 ">
                    <button className="create__more__btn" onClick={()=>this.setState({showMore:!showMore})}>
                      <PlusIcon /> Advance options
                    </button>
                  </div>
                  {!!showMore && <Fragment>

                    <SignGroup >
                      <InputLabel htmlFor="attributes" title="Attributes" tooltip="It is often used in a collection to highlight the characteristics of this item in the collection, you can skip this step directly"/>

                      {!!attributes.length && this.renderAttributes()}
                      <div className="create__attributes__input">
                        <input
                          type="text"
                          className="create__input"
                          id="attributes"
                          name="attrKey"
                          placeholder = "e. g. background"
                          value={attrKey}
                          onChange={(e)=>this.setState({attrKey:e.target.value})}
                          disabled={!!preview}
                          />
                        <input
                          type="text"
                          className="create__input"
                          id="attributes"
                          name="attrValue"
                          placeholder = "e. g. purple"
                          value={attrValue}
                          onChange={(e)=>this.setState({attrValue:e.target.value})}
                          disabled={!!preview}

                          />
                        <button
                          type="button"
                          onClick={this.handleAttr}
                          >
                          <PlusIcon />
                        </button>

                      </div>
                    </SignGroup>

                    <SignGroup small>
                      <InputLabel htmlFor="rarity" title="Rarity" tooltip="It is used in a collection to highlight the rarity score of this item in the collection, you can skip this step directly"/>
                      <input
                        type="number"
                        className="create__input"
                        id="rarity"
                        name="rarity"
                        value={rarity}
                        onChange={this.handleInput}
                        disabled={!!preview}
                        />
                    </SignGroup>

                    <SignGroup small>
                      <InputLabel htmlFor="rank" title="Rank" tooltip="It is used in a collection to highlight the Rank of this  item in the collection, you can skip this step directly"/>
                      <input
                        type="number"
                        className="create__input"
                        id="rank"
                        name="rank"
                        value={rank}
                        onChange={this.handleInput}
                        disabled={!!preview}
                        />
                    </SignGroup>

                    <SignGroup>
                      <InputLabel htmlFor="elink" title="External Link" tooltip="It is used to add External url related to this item , you can skip this step directly."/>
                      <input
                        type="text"
                        className="create__input"
                        id="elink"
                        name="elink"
                        placeholder = "https://mywebsite.com/page"
                        value={elink}
                        onChange={this.handleInput}
                        disabled={!!preview}
                        />
                    </SignGroup>


                    <SignGroup >
                      <InputLabel htmlFor="additional-content" title="Additional content" tooltip="It is used to add additional public viewable or unlockale contents (eg you work file in high quality), you can skip this step directly"/>

                      {!!unlockable.length && this.renderAdditionalContent()}
                      <div className="create__content__input">
                        <input
                          type="text"
                          className="create__input"
                          id="additional-content"
                          name="additional-content"
                          placeholder = "https://mywebsite.com/content.jpg"
                          value={additional.url}
                          onChange={(e)=>this.setState(p=>({additional:{...p.additional,url:e.target.value}}))}
                          disabled={!!preview}
                          />
                          <select
                            id="additional-content"
                            name="additional-content"
                            className="create__select create__select__absolute"
                            onChange={(e)=>this.setState(p=>({additional:{...p.additional,formate:e.target.value}}))}
                            value={additional.formate}
                            >
                            <option value={"image"}>Image</option>
                            <option value={"video"}>Video</option>
                            <option value={"audio"}>Audio</option>
                            <option value={"pdf"}>PDF</option>
                          </select>
                          <select
                            id="additional-content"
                            name="additional-content"
                            className="create__select"
                            onChange={(e)=>this.setState(p=>({additional:{...p.additional,locked:e.target.value}}))}
                            value={additional.locked}
                            >
                            <option value={"true"}>Unlockable</option>
                            <option value={"false"}>Public</option>
                          </select>
                        <button
                          type="button"
                          onClick={this.addAdditionalContent}
                          >
                          <PlusIcon />
                        </button>
                        <a href="https://www.pinata.cloud/" target="_blank" rel="noopener noreferrer">Where to upload ?</a>

                      </div>
                    </SignGroup>


                  </Fragment>}


                  {!!isProcess && this.renderProgressBar()}

                  { <div className="col-12">
                    <div className="btn__panel">
                      {!preview && <BackBtn largeView onClick={()=>this.props.handleMarkOpen()}>Back</BackBtn>}
                      {!preview && <CreateBtn largeView onClick={this.handlePreview} disabled={!author}>Upload Meta</CreateBtn>}
                      {!!preview && !isProcess && <BackBtn largeView onClick={()=>this.setState({preview:false})} disabled={!!isProcess}>Cancel</BackBtn>}
                      {!!preview && <CreateBtn
                        onClick={this.handleDataUpload}
                        largeView={!isProcess}
                        fullView={!!isProcess}

                        disabled={!!isProcess}
                        >
                        Continue
                        {!!isProcess && <div className="ball__pulse">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>}
                      </CreateBtn>}
                    </div>
                  </div>}

                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="changeArrow">
            <span  onClick={()=>this.props.handleMarkOpen("prev")}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"/></svg></span>
            <span  onClick={()=>this.props.handleMarkOpen("next")}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"/></svg></span>
          </div>
      </div>
    )
  }
}




export {
  PreviewEachNft
}
