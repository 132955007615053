import React, { Component } from 'react'

import OwlCarousel from 'react-owl-carousel';
import {NeoCard} from "../../components/authorCard/index.jsx"

import CloudNeoBanner from '../../images/cloud_neo_banner.png';
import TrocksNeoBanner from '../../images/trocks_neo_banner.png';
import Fox555NeoBanner from '../../images/fox555_neo_banner.png';
import DwpNeoBanner from '../../images/dwp_neo_banner.png';
import SecondaryMarketNeoBanner from '../../images/secondary_neo_banner.png';
import TpunkNeoBanner from '../../images/tpunk_neo_banner.png';
import TmeebitNeoBanner from '../../images/tmeebit_neo_banner.png';
import TronbullNeoBanner from '../../images/tronbull_neo_banner.png';

import BayctNeoBanner from '../../images/bayct_neo_banner.png';
import CoolcatsNeoBanner from '../../images/coolcats_neo_banner.png';
import TapeNeoBanner from '../../images/tapes_neo_banner.png';
import TechMemoryNeoBanner from '../../images/tech_memories_neo_banner.jpg';
import TCutiesNeoBanner from '../../images/tcuties_neo_banner.jpg';

class NeoCollection extends Component{
  constructor(props) {
      super(props)

      this.state = {
        uniqueIndex :"Neo"+Date.now(),
      }
  }
  handleTrigger = (val) =>{
    let{uniqueIndex} = this.state;
    if(val === "next"){
      document.querySelector(`.${uniqueIndex} .owl-next`).click();
    }else {
      document.querySelector(`.${uniqueIndex} .owl-prev`).click()
    }
  }

  renderCollection = () =>{
    let neoIndex = this.props.neoIndex;
    const neoCards = [
      {
        name:"Secondary market",
        address:"",
        logo:"/asset/img/secondary_neo_logo.gif",
        url:"/explore/tpunks",
        description:"A gateway to entire NFT ecosystem on TRON network. All supported NFT collection contracts can be traded without any forced floor and restrictions.",
        verified:true,
        banner:SecondaryMarketNeoBanner,
        bg:"#203b5a",
      },
      {
        name:"TPUNKS",
        address:"",
        logo:"/asset/img/tpunk-logo.png",
        url:"/explore/tpunks",
        description:"TPUNKS is one of the first NFTs on TRON Blockchain, there are 10,000 unique collectable characters like Crypto Punks. The NFT contract the governs ownership is a standard TRC-721 that works with any compatible service or exchange (TPUNKS is not associated with https://larvalabs.com/cryptopunks )",
        verified:true,
        banner:TpunkNeoBanner,
        bg:"#415763",
      },
      {
        name:"TMEEBITS",
        address:"",
        logo:"/asset/img/tmeebit-logo.png",
        url:"/explore/tronmeebits",
        description:"Tron Meebits are 20,000 unique 3D Avatar with 8 different attributes, created by a custom generative algorithm and registered on the Tron blockchain. The NFT contract the governs ownership is a standard TRC-721 that works with any compatible service or exchange. (TMeebits is not associated with https://meebits.larvalabs.com )",
        verified:true,
        banner:TmeebitNeoBanner,
        bg:"#2a1a4f",
      },
      {
        name:"TronBull",
        address:"",
        logo:"/asset/img/tronbull-logo.png",
        url:"/explore/tronbull",
        description:"TRON bull concept is an original concept of TRON Foundation which then was converted into 10,000 generative art NFTs by APENFT Foundation. In the metaverse of the tron chain, you can see 10,000 adventurous Tron Bulls on an amazing journey.",
        verified:true,
        banner:TronbullNeoBanner,
        bg:"#3b487a",
      },
      {
        name:"DWP",
        address:"TPVkcFYTEi9Dia45AveiTcaYoaU9ux7xC7",
        url:"/mintable/148/gallery",
        description:"DWP is the first decentralised e-book on TRON Blockchain! #Web3 . Owning a #DWP gives users ownership of an original NFT, the e-book, resell rights and cash back options in NoleCrafts.",
        verified:true,
        banner:DwpNeoBanner,
        bg:"#333030",
      },
      {
        name:"FOX555",
        address:"TBgfcXzTHa6A3h9k812xKKmXtpX4GRt8cW",
        url:"fox555",
        description:"FOX Triple five is a collection of 10,000 randomly generated Fox NFTs on the TRON blockchain. The 10,000 FOX555 create our community known as The Skulk. Our Fox555 are dashing, charming, amusing, and a little angry. FOX555 are known for their legendary memes and originality.",
        verified:true,
        banner:Fox555NeoBanner,
        bg:"#4d4a28",
      },
      {
        name:"TROCKS",
        address:"TX2J1DH9s2zFx972hnxskPW2i4XNNSmQ6i",
        url:"trocks",
        description:"TROCKS, one of the first crypto collectibles NFT-TYPE project on the TRON blockchain,having launched shortly after tpunks.Ony 100 TROCKS can ever be available, and each new virgin TROCK is sold at a constant price of 2000 TRX.",
        verified:true,
        banner:TrocksNeoBanner,
        bg:"#7e5919",
      },
      {
        name:"Crypto Clouds",
        address:"TL1Csvtk6DaAPjYuYrcxiHbPzX1g3tepC5",
        url:"cypto-clouds",
        description:"The first-ever 100% original crypto-collectibles NFT-TYPE project on the TRON blockchain. Only 495 Clouds and 5 SUN can ever be available, and each new virgin CCloud is sold based on an incremental price curve.",
        verified:true,
        banner:CloudNeoBanner,
        bg:"#1a495d",
      },
      {
        name:"BAYCT",
        address:"",
        logo:"/asset/img/bayct-logo.png",
        url:"/explore/bayct",
        description:"The BAYCTron project is an homage to the OG Bored Ape Yacht CLub and is not affiliated with Yuga Labs LLC(https://www.yugalabs.io/)",
        verified:true,
        banner:BayctNeoBanner,
        bg:"#15382d",
      },
      {
        name:"tApes",
        address:"",
        logo:"/asset/img/tapes-logo.png",
        url:"/explore/tapes",
        description:"TronApes is the only originally made generative project on Tron, with each TronApe having unique characteristics and traits that are made by top artists from the world",
        verified:true,
        banner:TapeNeoBanner,
        bg:"#852a2a",
      },
      {
        name:"Cool Cats",
        address:"",
        logo:"/asset/img/coolcats-logo.png",
        url:"/explore/coolcats",
        description:"Cool Cats Tron is a collection of 10,000 unique cat-themed digital illustrations hosted on Tron blockchain. This community driven project is among the first high-quality NFT collections to make use of Tron’s TRC-721 standard.(Cool Cats Tron is not associated with https://www.coolcatsnft.com/ )",
        verified:true,
        banner:CoolcatsNeoBanner,
        bg:"#305563",
      },
      {
        name:"Tech Memories",
        address:"TXQJCQnUNW73vzd4koeZKdzChfKWv8J9G5",
        url:"tech-memory",
        description:"The First ever BTFS backed collection. These NFTs will bring you back to those good old times before the big Web 2.0 and smartphones explosion. The whole collection is loaded with nostalgia.",
        verified:true,
        banner:TechMemoryNeoBanner,
        bg:"#1C4050",
      },
      {
        name:"TCuties",
        address:"TQPyEHSBXuBjoNMmeAhJPzSM2Jt14vemXa",
        url:"/mintable/25/gallery",
        description:"1000 unique collectables Cuties with proof of ownership stored on TRON blockchain and IPFS. Each and every TRC721 Non-Fungible TRON Cutie was sold for 100 TRX and distributed fairly through blind auction.",
        verified:true,
        banner:TCutiesNeoBanner,
        bg:"#3a2e5d",
      },

    ]
    return neoIndex.map((index,i)=>{
      return <NeoCard key={index+"b"} data={neoCards[index]}/>
    })
  }

  render(){
    let{uniqueIndex} = this.state;
    let mini = this.props.mini;

    let res = {
      0 : {items: !!mini?2:1,margin:8},
      576 : {items: 1,margin:10},
      768 : {items: 2,margin:15},
      1200 : {items: 3,margin:18,mouseDrag: false,dots: false},
    }

    return(

          <div className={`carousel__slider carousel__slider__mg ${uniqueIndex}`}>
            <OwlCarousel
              mouseDrag = {true}
              touchDrag = {true}
              dots = {true}
              loop = {true}
              autoplay = {true}
              autoplayHoverPause = {true}
              autoplayTimeout = {6000}
              smartSpeed = {1000}
              margin = {8}
              responsive = {res}
              >

              {this.renderCollection()}
            </OwlCarousel>

            <div className="slider__switch">
              <button onClick={()=>this.handleTrigger("prev")}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"/></svg></button>
              <button onClick={()=>this.handleTrigger("next")}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"/></svg></button>
            </div>
          </div>
        )
  }
}

export default NeoCollection
