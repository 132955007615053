

import React, {Component} from 'react'
import axios from 'axios'
import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

import { RectAreaLightUniformsLib }  from 'three/examples/jsm/lights/RectAreaLightUniformsLib';
import {ReactComponent as ScreenRotate} from '../../images/screen-rotate.svg';
import { createCanvas, loadImage,parseFont } from 'canvas'
import PlusIcon from '../../images/kty-coin.png';

import {Utils} from "../../helper"


const fileApi = axios.create({
    baseURL: `https://server.kraftly.io/images`,
    timeout: 4 * 60 * 1000,
    withCredentials: true,
    credentials: 'include',
    responseType: 'blob'
})
class CertView extends Component{

  constructor(props) {
    super(props)

    let _tokenId = props.match.params.tokenId || 0;
    this.state = {
      tokenId:Utils.floor(_tokenId),
      imgSource :PlusIcon,
    }


    this.camera = null;
    this.scene = null;
    this.renderer = null;
  }


componentDidMount(){
  this.renderMarbel();
  // this.fetchFile()
}

fetchFile = async() =>{
  try {
    let{tokenId} = this.state;
    let url = `/TGsDs5v2KosDx2fqoQmSC5zVsjrQARMc4U${tokenId}.png`;
    let size = 1300;
    let _file = await fileApi.get(url);
    let baseFile =  await loadImage(URL.createObjectURL(_file.data));
    const canvas = createCanvas(size,size);
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = true;
    ctx.fillStyle = "#f00";
    ctx.fill();

    ctx.beginPath();
    ctx.arc(size/2,size/2,size/2,0,Math.PI*2);
    ctx.clip();
    ctx.closePath();
    ctx.drawImage(baseFile,355,336,1286,1286,0,0,size,size);
    let imgSource = canvas.toDataURL("image/png", 1);
    // document.querySelector(".cert_generator").appendChild( canvas );
    this.setState({imgSource},this.renderMarbel)

  } catch (e) {
    console.log(e);
  }
}

 renderMarbel = () =>{
  let{tokenId,imgSource} = this.state;

  let width =  window.innerWidth;
  let height =  window.innerWidth >= 768?window.innerHeight - 70:window.innerHeight - 60;
  let radius = width > height ? height:width;
  radius = radius > 100?100:radius;
  this.camera = new THREE.PerspectiveCamera( 65, width / height, 1, 1000 );
  this.camera.position.set( 0,0, radius*3);
  this.camera.lookAt(new THREE.Vector3(0,0,0));

  this.renderer = new THREE.WebGLRenderer({antialias:true,preserveDrawingBuffer:true,alpha: true});
  this.renderer.setSize( width, height );
  this.renderer.setPixelRatio( window.devicePixelRatio );
  const controls = new OrbitControls( this.camera, this.renderer.domElement );
  controls.target = new THREE.Vector3(0, 0, 0); // what
  controls.enableDamping = true
  controls.maxDistance = radius * 8;
  controls.minDistance = radius*2;
  // controls.enableZoom  = false;


  controls.mouseButtons = {
      LEFT: THREE.MOUSE.ROTATE,
      RIGHT: THREE.MOUSE.ROTATE,
  }
  controls.update()


  this.scene = new THREE.Scene();
  this.scene.background = 0x000000;

  const pivot = new THREE.Group();

  const loader = new THREE.TextureLoader();
  loader.needsUpdate = true;

  const marbelGeometry = new THREE.SphereGeometry(radius, 100, 100,0 , Math.PI * 2, 0, Math.PI);
	const marbelMaterial = new THREE.ShaderMaterial( {
		uniforms		: {
    		"tex": { value: loader.load(imgSource) }
    },
		vertexShader	: `
    varying vec3 vNormal;

    void main() {

    	vNormal = normal;

    	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

    }
    `,
		fragmentShader	: `
    uniform sampler2D tex;

    varying vec3 vNormal;

    void main() {

      vec2 uv = normalize( vNormal ).xy * 0.485 + 0.5;

      vec3 color = texture2D( tex, uv ).rgb;

      gl_FragColor = vec4( color, 1.0 );

    }`
	});

  pivot.add( new THREE.Mesh( marbelGeometry, marbelMaterial ));

  const geometry = new THREE.SphereGeometry(radius+2, 100, 100);
  const material = new THREE.MeshPhysicalMaterial({
    transparent: true,
    transmission: 1,// transparent property
    reflectivity: .8,
    metalness: 0,
    roughness: 0,
    ior: 1.5, // index of reflection
  });
  pivot.add( new THREE.Mesh( geometry, material ));





  const starGeo = new THREE.BufferGeometry();
  const vertices = new Array(18000).fill(undefined).map(()=>Math.random() * 1000 - 500);
  starGeo.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(vertices), 3));

  let starMaterial = new THREE.PointsMaterial({
    color: 0xaaaaaa,
    size: 1.5,
    transparent: true,
    map: new THREE.TextureLoader().load('../../asset/img/circle.png')
  });

  // this.scene.add( new THREE.Points( starGeo, starMaterial ));




  this.scene.add( new THREE.AmbientLight( 0xffffff ) );


  this.scene.add( pivot );

  let self = this;

  function animation( time ) {
    pivot.rotation.y += 0.1*Math.PI/180;
    self.renderer.render( self.scene, self.camera );
  }

  this.renderer.setAnimationLoop(animation);

  window.addEventListener('resize', function(){
    let _width =  window.innerWidth;
    let _height =  window.innerWidth >= 768?window.innerHeight - 70:window.innerHeight - 60;
    let radius = _width > _height ? _height:_width;
    radius = radius > 100?100:radius;
    self.renderer.setSize ( _width, _height );
    self.camera.aspect = _width / _height ;
    self.camera.updateProjectionMatrix();
  });

  const panel = document.querySelector(".cert_generator");
  panel.innerHTML = "";
  panel.appendChild( this.renderer.domElement );




}




  render(){

    return (
      <div className="webgl__model">
        <div className="cert_generator"></div>
      </div>
    )
  }
}


export default CertView;
