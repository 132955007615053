import React, { Component } from 'react'
// import styled from 'styled-components'
import { Link } from 'react-router-dom'
import $ from "jquery";

import api from "../api"
import {Utils} from "../helper"
import {DpViewer,NftViewer} from "../helper/file-viewer.jsx"

import {NftCardDummy} from "../components/nftCard/index.jsx"
import {Paginator,MenuSelect2} from "../frag"
import Banned from '../images/ban-vertical.png';


class AdminNftCard extends Component{
  constructor(props) {
      super(props)

      this.state = {
        timer:'',
        isProcess:false,
      }
  }
  async componentDidMount(){
    let{time,tokenId} = this.props.data;
    if(time > 0){
      let timer = setInterval(function() {
                  $(`.card__clock${tokenId}`).text(Utils.getTimeOffset(time));
                  if (time * 1000 < Date.now()) {
                     $('.card__time').fadeOut('slow');
                     clearInterval(timer);
                  }
               }, 1000);
       this.setState({timer})
    }
  }
  componentWillUnmount(){
    if(this.state.timer){
      clearInterval(this.state.timer);
    }
  }
  handleDeleteNft = async() =>{
    let{name,tokenId,nftAddress} = this.props.data
    let address = Utils.viewCreator();

    try {
      let{isProcess} = this.state;
      if(isProcess) return Utils.setToastAlert("Previous deletion request is in process");
      if (!window.confirm(`you want to delete ${name}, tokenId: ${tokenId}`)) return Utils.setToastAlert(`${name} ${tokenId} confirmation declined`);
      let payload = {address,tokenId,nftAddress,type:"delete"}
      // return console.log(payload);
      this.setState({isProcess:true})
      await api.updateNftAdmin(payload)
      Utils.setToastAlert(`${name} ${tokenId} meta data deleted`,"success");
      this.setState({isProcess:false})
    } catch (e) {
      console.log(e);
      let msg = e?.response?.data?.message??`${name} ${tokenId} meta data delete failed.`;
      Utils.setToastAlert(msg,"error");
      this.setState({isProcess:false})
    }
  }
  handleBanNft = async() =>{
    let{name,tokenId,isBanned,nftAddress} = this.props.data
    let address = Utils.viewCreator();

    try {
      let{isProcess} = this.state;
      if(isProcess) return Utils.setToastAlert("Previous ban request is in process");
      let payload = {address,tokenId,nftAddress,type:"ban"}
      this.setState({isProcess:true})
      await api.updateNftAdmin(payload)
      Utils.setToastAlert(`${name} ${tokenId} ban status changed to ${!isBanned}`,"success");
      this.setState({isProcess:false})
    } catch (e) {
      console.log(e);
      let msg = e?.response?.data?.message??`${name} ${tokenId} ban status changing failed to ${!isBanned}`;
      Utils.setToastAlert(msg,"error");
      this.setState({isProcess:false})
    }
  }
  handleLike = async() =>{
    let{isProcess} = this.state;
    let{tokenId,likes,handleLike} = this.props.data
    try {
      let address = Utils.viewCreator();
      if(!address) return $(".header__action-btn--signin").click();
      if(isProcess) return Utils.setToastAlert("Another request is in process");
      let payload = {tokenId,address,handleLike}
      this.setState({isProcess:true})
      let liked = Utils.updateLike(handleLike+tokenId)
      await api.likeNft(payload)
      this.props.data.likes = liked?likes+1:likes-1
      this.setState({isProcess:false})
    } catch (e) {
      console.log(e);
      Utils.updateLike(handleLike+tokenId)
      this.setState({isProcess:false})
    }
  }
  renderFile = () =>{
    let{url,isBanned} = this.props.data;
    // console.log(url);
    let fileType = url?url.split(".")[1]:"jpg";
    if(!url){
      return <img src={Utils.getImage()} alt=""/>
    }else if (!!isBanned) {
      return <img src={Banned} alt=""/>
    }else if(fileType === "jpg" || fileType === "gif" || fileType === "png"){
      return <img src={Utils.getImage(url)} alt=""/>
    }else if (fileType === "mp3") {
      return <audio src={Utils.getImage(url)} controls muted>
        browser not supported
      </audio>
    }else if (fileType === "mp4") {
      return <video src={Utils.getImage(url)} controls muted autoPlay loop>
        browser not supported
      </video>
    }
  }
  render(){
    // console.log(this.props.data);
    let{owner,bid,price,time,tokenId,name,likes,username,user_name,verified,blocked,eventName,isBanned,nftAddress} = this.props.data;
    // console.log(this.props.data);
    let _liked = Utils.viewCreator("liked").findIndex(i=> i === nftAddress+tokenId) !== -1;

    price = price || 0;
    bid = bid && bid > 0? bid * 39 /40:0;
    time = time || 0;
    let feesToken = "TRX";
    let priceTag = bid?"Current bid":!bid  && time?"Minimum Bid":price && !time?"Fixed price":"Minimum Bid";
    let rate = bid >= price?bid:price;
    rate = !!rate?Utils.preety.ceil(rate/1e6,2):"> 0"

    if(!eventName){
      priceTag = "Not for sale";
      rate = "";
      feesToken = ""
    }
    if (eventName) {
      if (eventName === "SoldAuction") {
        rate = Utils.preety.ceil(bid/1e6,2);
        priceTag = "Last sold for"
      }else if (eventName === "CancelAuction") {
        rate = Utils.preety.ceil(bid/1e6,2);
        priceTag = "Last bid for"
      }
    }

    let ownerName = user_name?Utils.shortName(user_name,18):Utils.viewOwner(username || owner,20)
    return(
        <div className="card">
          <Link to={`/market/${tokenId}`} className="card__cover">
            <NftViewer data={this.props.data} key={tokenId}/>
              {!!time && <span className="card__time card__time--clock">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.46777,8.39453l-.00225.00183-.00214.00208ZM18.42188,8.208a1.237,1.237,0,0,0-.23-.17481.99959.99959,0,0,0-1.39941.41114,5.78155,5.78155,0,0,1-1.398,1.77734,8.6636,8.6636,0,0,0,.1333-1.50977,8.71407,8.71407,0,0,0-4.40039-7.582,1.00009,1.00009,0,0,0-1.49121.80567A7.017,7.017,0,0,1,7.165,6.87793l-.23047.1875a8.51269,8.51269,0,0,0-1.9873,1.8623A8.98348,8.98348,0,0,0,8.60254,22.83594.99942.99942,0,0,0,9.98,21.91016a1.04987,1.04987,0,0,0-.0498-.3125,6.977,6.977,0,0,1-.18995-2.58106,9.004,9.004,0,0,0,4.3125,4.0166.997.997,0,0,0,.71534.03809A8.99474,8.99474,0,0,0,18.42188,8.208ZM14.51709,21.03906a6.964,6.964,0,0,1-3.57666-4.40234,8.90781,8.90781,0,0,1-.17969-.96387,1.00025,1.00025,0,0,0-.79931-.84473A.982.982,0,0,0,9.77,14.80957a.99955.99955,0,0,0-.8667.501,8.9586,8.9586,0,0,0-1.20557,4.71777,6.98547,6.98547,0,0,1-1.17529-9.86816,6.55463,6.55463,0,0,1,1.562-1.458.74507.74507,0,0,0,.07422-.05469s.29669-.24548.30683-.2511a8.96766,8.96766,0,0,0,2.89874-4.63269,6.73625,6.73625,0,0,1,1.38623,8.08789,1.00024,1.00024,0,0,0,1.18359,1.418,7.85568,7.85568,0,0,0,3.86231-2.6875,7.00072,7.00072,0,0,1-3.2793,10.457Z"/></svg>
                <span className={"card__clock"+tokenId}></span>
              </span>}
          </Link>
          <h3 className="card__title">
            <Link to={`/market/${tokenId}`}>{name || tokenId}</Link>
            <span className="nft_tokenId_mark">#{tokenId}</span>

          </h3>
          <div className={`card__author ${blocked?"card__author--banned":verified?"card__author--verified":""}`}>
            <DpViewer address={owner} key={owner}/>
            <Link to={`/author/${owner}/profile?tab=onsale`}>{ownerName}</Link>
          </div>
          <div className="card__info">
            <div className="card__price">
              <span>{priceTag}</span>
              <span>{rate} {feesToken}</span>
            </div>

            <button className={`card__likes ${_liked?"card__likes--active":""}`} type="button" onClick={this.handleLike} disabled={!name}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                {!_liked && <path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z"/>}
                {!!_liked && <path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/>}
              </svg>
              <span>{likes || 0}</span>
            </button>
          </div>
          <div className="card__info">
            <button
              className="author__follow author__follow--true"
              type="button"
              onClick={this.handleDeleteNft}
              >Delete
            </button>
            <button
              className="author__follow"
              type="button"
              onClick={this.handleBanNft}
              >{isBanned?"Unban":"Ban"}
            </button>
          </div>
        </div>
    )
  }
}






class Explore extends Component {

  constructor(props) {
      super(props)
      let limit = window.innerWidth < 768?8:12;
      const urlParams = new URLSearchParams(window.location.search);
      let page = urlParams.get('page');
      page = Utils.floor(page)
      page = page && page > 0?page:1;
      let start = page > 4?page -3:1;
      this.state = {
        filterString:"",
        address:"",
        isLoading:false,
        nfts:[],
        page,
        start,
        limit,
        count:0,
        category:"all",
        type:"gallery",
        arrange:"auto",
        bundle:"none",
        showFilter:false,
      }
  }
  componentDidMount = () =>{
    document.title = "Admin || Explore";

    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      // console.log(account);
      const {address} = account
      this.setState({address})
    })
    this.fetchNfts()
  }
  componentWillUnmount(){
    document.title = "Kraftly";
  }

 UNSAFE_componentWillReceiveProps(nextProps) {
   let{page} = this.state;
     const urlParams = new URLSearchParams(window.location.search);
     let _page = urlParams.get('page');
     _page = Utils.floor(_page);
     if(_page !== page){
       page = _page && _page > 0?_page:1;
       let start = page > 4?page -3:1;
       this.setState({page:_page,start},this.fetchNfts)
     }
    }
  updatePagingUrl = () =>{
    let{page} = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("page", page)
    let params = urlParams.toString();
    if (window.history.pushState) {
      let url = `${window.location.pathname}?${params}`;
      window.history.pushState(null, null,url);
    }
  }
  fetchNfts = async () =>{
    let{page,limit,category,type,arrange,filterString} = this.state;
      try {
        this.setState({isLoading:true})
        let payload ={mode:"explore",page,limit,category,type,arrange}
        if(filterString){
          payload.filterString = filterString;
        }

        let res = await api.getMarket(payload);
        // console.log(res);
        let{result,count} = res.data;
        count = count || 0;
        this.setState({nfts:result,count,isLoading:false},this.updatePagingUrl)
      } catch (e) {
        let msg = e.response?.data?.message??"No NFT found";
        Utils.setToastAlert(msg,"error");
        // console.log(e.response);
        this.setState({isLoading:false})
      }
  }
  fetchFilterData = async(event) =>{
    event.preventDefault()

      let{isLoading} = this.state;
      if(isLoading) return Utils.setToastAlert("Previous request processing","info");

      this.setState({nfts:[],category:"all",type:"gallery",arrange:"auto",page:1,start:1},()=>{
        this.fetchNfts()
      })
  }
  renderNft = () =>{
    let{nfts} = this.state;

    return nfts.map((data,i)=>{
      return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={data.tokenId+"1"+i}>
        {<AdminNftCard data={data} key={data.tokenId+"2"+i}/>}
      </div>
    })
  }
  renderDummyNft = () =>{
    let count = window.innerWidth < 576?1:window.innerWidth < 768?4:window.innerWidth < 992?6:8;
      return new Array(count).fill(undefined).map((item,i)=>{
        return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={i}>
          <NftCardDummy/>
        </div>
      })
  }


  handleInput = e =>{
    let val = e.target.value;
    if (!val || val.length <= 40) {
      this.setState({filterString:val})
    }
  }
  handlePaginator = (value) =>{
    this.setState(value,this.fetchNfts)
  }

  onOption = (name,value)=>{
    let{isLoading} = this.state
    if(isLoading) return Utils.setToastAlert("Previous request processing","info");
    if(this.state[name] === value) return console.log("same option");
    this.setState({[name]:value,nfts:[]},this.fetchNfts)
  }
  redirectBundle = (value)=>{
    window.location.href = `/${value}`
  }

    render() {
      let{category,type,arrange,bundle,nfts,filterString,isLoading,count,start,limit,page,showFilter} = this.state;
      showFilter = showFilter || window.innerWidth > 576;
      let typeOptions = {gallery:"Gallery",onAuction:"On Auction",now:"Buy Now",hasOffers:"Has Offers",new:"Newest Mint",old:"Oldest Mint"};
      let categoryOptions = {all:"All categories",art:"Art",photography:"Photography",games:"Games",metaverses:"Metaverses",music:"Music",domains:"Domains",memes:"Memes"};
      let priceOptions = {auto:"Price",low:"Low to high",high:"High to low"};
      let bundleOptions = {none:"Collections",trocks:"TROCK","cypto-clouds":"CRYPTO CLOUD"};

        return (
          <main className="main">
        		<div className="container">
        			<div className="row row--grid">
        				<div className="col-12">
        					<ul className="breadcrumb">
        						<li className="breadcrumb__item"><Link to="/home">Home</Link></li>
        						<li className="breadcrumb__item breadcrumb__item--active">Explore</li>
        					</ul>
        				</div>

        				<div className="col-12">
        					<div className="main__title main__title--page">
        						<h1>Explore exclusive digital assets</h1>
        					</div>
        				</div>

        				<div className="col-12">
        					<div className="main__filter">
        						<form onSubmit={this.fetchFilterData} className="main__filter-search">
        							<input
                        type="text"
                        placeholder="Search..."
                        value = {filterString}
                        onChange={(e)=>this.setState({filterString:e.target.value})}
                        />
        							<button
                        type="button"
                        onClick={this.fetchFilterData}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
                      </button>
        						</form>
                    <button className="explore-filter-btn-mobile" onClick={()=>this.setState({showFilter:!showFilter})}>
                      <span>Filters</span>
                    </button>
                    {!!showFilter && <div className="explor--filter-body">
                      <div className="explore-filter-header">
                        <p onClick={()=>this.setState({showFilter:false})}>Done</p>
                      </div>
                      <div className="main__filter-wrap explore-filter-wrap">
                        <MenuSelect2 options={typeOptions} value={type} onOption={(e)=>this.onOption("type",e)}/>
                        <MenuSelect2 options={categoryOptions} value={category} onOption={(e)=>this.onOption("category",e)}/>
                        <MenuSelect2 options={priceOptions} value={arrange} onOption={(e)=>this.onOption("arrange",e)}/>
                        <MenuSelect2 options={bundleOptions} value={bundle} onOption={(e)=>this.redirectBundle(e)}/>
          						</div>
                    </div>}
        					</div>
        				</div>
        			</div>

        			<div className="row equal explore__nfts__list">
                {!isLoading && !!nfts.length && this.renderNft()}
                {!!isLoading  && this.renderDummyNft()}
        			</div>

              <div className="row row--grid">
                <div className="col-12">
                  <Paginator  data={{count,start,limit,page,size:nfts.length}} handler={this.handlePaginator}/>
                </div>
              </div>
        		</div>
        	</main>
        )
    }
}
export default Explore
