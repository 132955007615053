import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import {Utils} from "../../helper"
import {DpViewer,BannerViewer} from "../../helper/file-viewer.jsx"

import api from "../../api"
import './style.scss';


import {ReactComponent as Verified} from '../../images/verified_star.svg';
import {ReactComponent as Blocked} from '../../images/banned.svg';



class AuthorCard extends Component{
  constructor(props) {
      super(props)

      this.state = {
        isProcess:false,
        address:"",
      }
  }
  handleFollow = async() =>{
    let{address:author,follower} = this.props.data
    try {
      let{isProcess} = this.state;
      let address = Utils.viewCreator();
      if(!address) return document.querySelector(".header__action-btn--signin").click();
      if(isProcess) return Utils.setToastAlert("Another request is in process");
      let payload = {author,address}
      this.setState({isProcess:true})
      let followed = Utils.updateFollow(author);
      await api.followAuthor(payload)
      this.props.data.follower = followed?follower+1:follower-1
      this.setState({isProcess:false})
    } catch (e) {
      console.log(e);
      Utils.updateFollow(author)
      this.setState({isProcess:false})
    }
  }
  render(){
    let {isMobile,isSlider} = this?.props?.layout??{};
    isMobile = !!isMobile?" user__card__sm":"";
    isSlider = !!isSlider?" user__card__slider":"";

    let{address,name,username,follower,verified,blocked} = this.props.data
    let followed = Utils.viewCreator("following").findIndex(i=> i === address) !== -1;
    let imgUrl = !name || !!blocked?'':address;
    let ownerName = name?Utils.shortName(name,18):username?Utils.viewOwner(username,20):Utils.shortAddress(address,true);

    return(
        <div className={`user__card ${isMobile} ${isSlider}`}>
          <div className="user__card__body">
            <BannerViewer data={{address,cName:"user__card__cover"}}  key={address}/>
            <Link to={`/author/${address}/profile`} className="user__card__avatar">
              <DpViewer address={imgUrl} key={imgUrl} />
            </Link>

            <div className="user__card__meta">
              <div className="user__card__meta__left">
                <div className="user__card__name">
                  <Link to={`/author/${address}/profile`}>{ownerName}</Link>
                  {!!verified && <Verified />}
                  {!!blocked && <Blocked />}
                </div>
                <div className="user__card__followers">
                  {Utils.preety(follower || 0,0)} Followers
                </div>
              </div>

              <div className="user__card__meta__right">
                {Utils.viewCreator() !== address && <button
                  className={`user__card__followBtn ${followed?"user__card__followBtn_true":""}`}
                  type="button"
                  onClick={this.handleFollow}
                  disabled={!this.props.data.username}
                  >{followed?"Unfollow":"Follow"}</button>}
              </div>
            </div>

          </div>
        </div>
    )
  }
}



export default AuthorCard




class AuthorCardDummy extends Component{

  render(){
    let {isMobile,isSlider} = this?.props?.layout??{};
    isMobile = !!isMobile?" user__card__sm":"";
    isSlider = !!isSlider?" user__card__slider":"";

    return(
        <div className={`user__card ${isMobile} dummy_card ${isSlider}`}>
          <div className="user__card__body">
            <div className="user__card__cover">&nbsp;</div>
            <div className="user__card__avatar"><DpViewer/></div>


            <div className="user__card__meta">
              <div className="user__card__meta__left">
                <div className="user__card__name">&nbsp;</div>
                <div className="user__card__followers">&nbsp;</div>
              </div>

              <div className="user__card__meta__right">
                <div className="user__card__followBtn">&nbsp;</div>
              </div>
            </div>

          </div>
        </div>
    )
  }
}




class NeoCard extends Component{
  constructor(props) {
      super(props)

      this.state = {
        isProcess:false,
        address:"",
      }
  }

  render(){
    let{address,name,description,verified,url,banner,logo,bg} = this.props.data

    return(
        <div className="user__card user__card__slider hot__collection__card" >
          <div className="user__card__body" style={{background:bg}} >
          <div className="user__card__cover">
            <img src={banner} alt="" />
          </div>
          <Link className="user__card__avatar" to={url}>
            <div className="user__card__avatar__body">
              {!!logo && <img src={logo} alt="" />}
              {!logo && <DpViewer address={address} key={address} />}
            </div>
          </Link>

          <div className="user__card__meta">
            <div className="user__card__meta__left">
              <div className="user__card__name"><Link to={url}>{name} {!!verified && <Verified />}</Link></div>
              <div className="user__card__description">{description}</div>
            </div>
          </div>
          </div>
        </div>
    )
  }
}

export {AuthorCardDummy,NeoCard}
