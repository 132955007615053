import React, { Component } from 'react'
import api from "../../api"
import {Utils} from "../../helper"
import NftCard,{NftCardDummy} from "../../components/nftCard/index.jsx"





class Discover extends Component {

  constructor(props) {
      super(props)
      let limit = window.innerWidth < 768?32:48;

      this.state = {
        address:"",
        isLoading:false,
        custom:true,
        nfts:[],
        limit,
      }
      this.myRef = React.createRef();
  }
  componentDidMount = () =>{
    // document.title = "Discover || Kraftly";

    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      // console.log(account);
      const {address} = account
      this.setState({address})
    })
    this.fetchNfts();
    // window.addEventListener('scroll', this.loadMore);

  }
  componentWillUnmount(){
    // document.title = "Kraftly";
    // window.removeEventListener('scroll', this.loadMore);
  }

loadMore = () =>{
  let{isLoading} = this.state;
  let btnPosition = this.myRef.current.getBoundingClientRect().top;
  let bottomPoint = window.innerHeight;

  // console.log(btnPosition,bottomPoint);
  if(!isLoading && bottomPoint > btnPosition){
    this.fetchNfts()
  }


}

  fetchNfts = async () =>{
    let{limit,custom,nfts} = this.state;
      try {
        this.setState({isLoading:true})
        let payload ={custom,limit}

        let res = await api.getDiscover(payload);
        // console.log(res);
        let{result} = res.data;
        result = [...nfts,...result]
        this.setState({nfts:result,custom:false,isLoading:false})
      } catch (e) {
        // let msg = e.response?.data?.message??"No NFT found";
        // Utils.setToastAlert(msg,"error");
        // console.log(e.response);
        this.setState({isLoading:false})
      }
  }

  renderNft = () =>{
    let{nfts} = this.state;
    let layout={
      isSquare:true,
    }
    return nfts.map((data,i)=>{
      return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={data.tokenId+"1"+i}>
        {<NftCard data={data} layout={layout} key={data.tokenId+"2"+i}/>}
      </div>
    })
  }
  renderDummyNft = () =>{
    let{limit} = this.state;
      return new Array(limit).fill(undefined).map((item,i)=>{
        return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={i}>
          <NftCardDummy/>
        </div>
      })
  }

    render() {
      let{nfts,isLoading} = this.state;

        return (
          <div className="row no-gutters" ref={this.myRef}>
            <div className="col-12" >
              <div className="section__title">
                Discover exclusive collection
              </div>
            </div>

            {!!nfts.length && this.renderNft()}
            {!!isLoading  && this.renderDummyNft()}

            {!isLoading && <div className="col-12">
                <button className="main__load" onClick={this.fetchNfts}>Load more</button>
              </div>}
          </div>
        )
    }
}
export default Discover
