import React, { Component } from 'react'
import styled from 'styled-components'
import {ReactComponent as TwitterBot} from '../../images/twitter-bot.svg';
import {ReactComponent as ArrowMark} from '../../images/hand-up.svg';
// import {Utils} from "../../helper"

const RedirectTems = styled.a.attrs({
  target:"_blank",
  rel:"noopener noreferrer",
  href:"/docs/terms.pdf",
})``

class Footer extends Component {

  constructor(props){
      super(props);

      this.state = {
        price:0.187,
        usdt:0.067,
      }
  }

  async componentDidMount() {
    try {

    } catch (e) {
        console.log(e);
    }
  }



    render() {
      // let{price,usdt} = this.state;
      const d = new Date();
      let year = d.getFullYear();


      // <a className="footer__kty__price" href="#" onClick={(e)=>e.preventDefault()}>
      //   <img src="/asset/img/kty-sm.png" alt="" />
      //   {Utils.preety.ceil(price,2)}TRX ~ ${Utils.preety.ceil(price*usdt,3)}
      // </a>
        return (
          <footer className="footer">

        		<div className="container-fluid">
        			<div className="row">
        				<div className="col-12 col-sm-8 col-md-5 col-lg-4">
        					<div className="footer__logo">
        						<img src="/asset/img/logo.png" alt="" />
        					</div>
        					<p className="footer__tagline">Digital marketplace for crypto collectibles <br/>and non-fungible tokens (NFTs). <br/>Buy, sell, and discover exclusive digital assets.</p>


        				</div>
                <div className="col-6 col-sm-4 col-md-3 col-lg-2">
        					<h6 className="footer__title">KTY Token</h6>
                    <div className="footer__nav">
            					<a href="/kty-info">Info</a>
                      <a href="/kty-burn">Buy back & Burn</a>


                      <a className="footer__kty__buy" href="https://sunswap.com/#/home?tokenAddress=TTroZqb95vmsw4kppupQ8tVEzkNDDP2bcG&type=swap"  target="_blank" rel="noopener noreferrer">
                        Buy KTY<ArrowMark/>
                      </a>
          					</div>

        				</div>

        				<div className="col-6 col-sm-4 col-md-4 col-lg-2">
        					<h6 className="footer__title">NTF Marketplace</h6>
        					<div className="footer__nav">
        						<a href="/explore">Explore</a>
        						<a href="/authors">Authors</a>
                    <a href="/mintable">Collections</a>
                    <RedirectTems>Kraftly's Terms</RedirectTems>
        					</div>
        				</div>

        				<div className="col-6 col-sm-4 col-md-3 col-lg-2">
        					<h6 className="footer__title">Help</h6>
        					<div className="footer__nav">
        						<a href="https://kraft-chef.gitbook.io/kraftly-docs/account/your-collection/secondary-nfts" target="_blank" rel="noopener noreferrer">User Guide</a>
        						<a href="https://t.me/kraftlynft" target="_blank" rel="noopener noreferrer">Tech support</a>
        					</div>
        				</div>


        				<div className="col-6 col-sm-4 col-md-3 col-lg-2">
        					<h6 className="footer__title">Language</h6>
        					<div className="footer__lang">
        						<a className="footer__lang-btn" href="/" role="button" id="dropdownLang" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        							<img src="/asset/img/flags/uk.svg" alt=""/>
        							<span>English</span>
        						</a>

        						<ul className="dropdown-menu footer__lang-dropdown" aria-labelledby="dropdownLang">
        							<li><a href="/" onClick={(e)=>e.preventDefault()}><img src="/asset/img/flags/spain.svg" alt=""/><span>Spanish</span></a></li>
        							<li><a href="/" onClick={(e)=>e.preventDefault()}><img src="/asset/img/flags/russia.svg" alt=""/><span>Russian</span></a></li>
        							<li><a href="/" onClick={(e)=>e.preventDefault()}><img src="/asset/img/flags/china.svg" alt=""/><span>Chinese</span></a></li>
        						</ul>
        					</div>
        				</div>
        			</div>

        			<div className="row">
        				<div className="col-12">
        					<div className="footer__content">
        						<div className="footer__social">

        							<a href="https://twitter.com/Kraftlynft" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z"/></svg></a>

        							<a href="https://t.me/kraftlynft" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.99432,2a10,10,0,1,0,10,10A9.99917,9.99917,0,0,0,11.99432,2Zm3.17951,15.15247a.70547.70547,0,0,1-1.002.3515l-2.71467-2.10938L9.71484,17.002a.29969.29969,0,0,1-.285.03894l.334-2.98846.01069.00848.00683-.059s4.885-4.44751,5.084-4.637c.20147-.189.135-.23.135-.23.01147-.23053-.36152,0-.36152,0L8.16632,13.299l-2.69549-.918s-.414-.1485-.453-.475c-.041-.324.46649-.5.46649-.5l10.717-4.25751s.881-.39252.881.25751Z"/></svg></a>


                      <a href="https://mobile.twitter.com/kraftlybot" target="_blank" rel="noopener noreferrer"><TwitterBot /></a>

        							<a href="mailto:support@kraftly.io" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19,4H5A3,3,0,0,0,2,7V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,19,4Zm-.41,2-5.88,5.88a1,1,0,0,1-1.42,0L5.41,6ZM20,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V7.41l5.88,5.88a3,3,0,0,0,4.24,0L20,7.41Z"/></svg></a>
        						</div>

        						<small className="footer__copyright">Copyright ©{year} Kraftly.io</small>
        					</div>
        				</div>
        			</div>
        		</div>
        	</footer>
        )
    }

}
export default Footer
