
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as helper from '../helper'
import * as pages from './'


function Fox555() {

    return (
        <Router>
            <Switch>
                  <Route path={["/fox555","/fox555/mint"]} exact component={pages.MintFox555} />
                  <Route path="/fox555/gallery" exact component={pages.Fox555Gallery} />
                  <Route path="/fox555/swap" exact component={pages.SwapForFox} />

                  <Route component={helper.PageNotFound} />

            </Switch>
        </Router>
    )
}

export default Fox555
