import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {Utils} from "../../helper"
import {NftViewer} from "../../helper/file-viewer.jsx"

import api from "../../api"
import {Paginator} from "../../frag"
import "../../scss/activity.scss"


class ActivityCard extends Component {

  renderFile = () =>{
    let{url,cid,baseurl,formate} = this.props.data;

    let fileType = url?url.split(".")[1].toLowerCase():formate?formate:"jpg";
    url = !!url?Utils.getImage(url):cid?`${baseurl}${cid}`:"";

    if(!url){
      return <img src={Utils.getImage()} alt=""/>
    }else if(fileType === "jpg" || fileType === "gif" || fileType === "png"){
      return <img src={url} alt=""/>
    }else if (fileType === "mp3") {
      return <audio src={url} controls muted>
        browser not supported
      </audio>
    }else if (fileType === "mp4") {
      return <video src={url} controls muted autoPlay loop>
        browser not supported
      </video>
    }
  }
  renderDetails = () =>{
    let{name,price,time,account,bidder,bid,owner,currencyId} = this.props.data;
    bid = bid && bid > 0? bid:0;
    bid = name === "NFTminted"?bid:name === "NewOffer" || name === "OfferRemoved"?bid / 1.05:bid / 1.025;
    let _currencyName = Utils.getCurrencyName(currencyId)

    let pElement = "";

    if(name === "AuctionOrder"){
      if(time){
        pElement = <p>Put on sale for min bid <b>{Utils.floor(price/1e6,1)} {_currencyName}</b> by <Link to={`/author/${owner}`}>{Utils.viewOwner(owner)}</Link></p>
      }else if (price) {
        pElement = <p>Put on sale for <b>{Utils.floor(price/1e6,1)} {_currencyName}</b> by <Link to={`/author/${owner}`}>{Utils.viewOwner(owner)}</Link></p>
      }else {
        pElement = <p>Put on sale by <Link to={`/author/${owner}`}>{Utils.viewOwner(owner)}</Link></p>
      }
    }else if (name === "CancelAuction") {
      pElement = <p>Auction cancelled by <Link to={`/author/${account}`}>{Utils.viewOwner(account)}</Link></p>
    }else if (name === "AuctionBid") {
      pElement = <p>Bid placed for <b>{Utils.floor(bid/1e6,1)} {_currencyName}</b> by <Link to={`/author/${bidder}`}>{Utils.viewOwner(bidder)}</Link></p>
    }else if (name === "SoldAuction") {
      pElement = <p>Auction sold to <Link to={`/author/${bidder}`}>{Utils.viewOwner(bidder)}</Link> for <b>{Utils.floor(bid/1e6,1)} {_currencyName}</b> by <Link to={`/author/${owner}`}>{Utils.viewOwner(owner)}</Link></p>
    }else if (name === "BidRemoved") {
      pElement = <p><Link to={`/author/${bidder}`}>{Utils.viewOwner(bidder)}</Link> bid removed of <b>{Utils.floor(bid/1e6,1)} {_currencyName}</b></p>
    }else if (name === "NewOffer") {
      pElement = <p><Link to={`/author/${bidder}`}>{Utils.viewOwner(bidder)}</Link> offered <b>{Utils.floor(bid/1e6,1)} {_currencyName}</b> to mint</p>
    }else if (name === "OfferRemoved") {
      pElement = <p><Link to={`/author/${bidder}`}>{Utils.viewOwner(bidder)}</Link> offer of <b>{Utils.floor(bid/1e6,1)} {_currencyName}</b> removed</p>
    }else if (name === "NFTminted") {
      pElement = <p>Minted by <Link to={`/author/${bidder}`}>{Utils.viewOwner(bidder)}</Link> for <b>{Utils.floor(bid/1e6,1)} {_currencyName}</b></p>
    }
    return pElement;
  }
  render(){
    let{artName,timestamp,tokenId,nftAddress} = this.props.data;
    let _marketUrl = !nftAddress || nftAddress === Utils.NftContract?`/market/${tokenId}`:`/market3p/${nftAddress}/${tokenId}`;

    return(

        <div className="activity">
          <Link to={_marketUrl} className="activity__cover">
            <NftViewer data={this.props.data} key={tokenId}/>
          </Link>
          <div className="activity__content">
            <h3 className="activity__title"><Link to={_marketUrl}>{artName}</Link></h3>
            <p className="activity__text">{this.renderDetails()}</p>
            <span className="activity__time">{Utils.getTimeAgo(timestamp)}</span>
          </div>
        </div>

    )
  }
}
class ActivityCardDummy extends Component {

  render(){
    return(

        <div className="activity activity-page-dummy">
          <Link to="#" className="activity__cover"></Link>
          <div className="activity__content">
            <h3 className="activity__title"> </h3>
            <p className="activity__text"></p>
            <span className="activity__time"></span>
          </div>
        </div>

    )
  }
}

class Activity extends Component {
  constructor(props) {
      super(props)
      let limit = window.innerWidth < 768?8:12;
      const urlParams = new URLSearchParams(window.location.search);
      let page = urlParams.get('page');
      let tab = urlParams.get('tab')
      page = tab === "activity"?Utils.floor(page):1;
      page = page && page > 0?page:1;
      let start = page > 4?page -3:1;

      this.state = {
        address:this.props.data.address || "",
        isLoaded:false,
        isLoading:false,
        page,
        start,
        limit,
        count:0,
        activities:[],
      }
      this.myRef = React.createRef();
  }
  componentDidMount = async () =>{
      this.fetchActivities()
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let{page} = this.state;
      const urlParams = new URLSearchParams(window.location.search);
      let _page = urlParams.get('page');
      _page = Utils.floor(_page);
      if(_page !== page){
        page = _page && _page > 0?_page:1;
        let start = page > 4?page -3:1;
        this.setState({page,start},this.fetchActivities)
      }
     }
   updatePagingUrl = () =>{
     let{page} = this.state;
     const urlParams = new URLSearchParams(window.location.search);
     urlParams.set("page", page)
     let params = urlParams.toString();
     if (window.history.pushState) {
       let url = `${window.location.pathname}?${params}`;
       window.history.pushState(null, null,url);
     }
   }

  fetchActivities = async () =>{
    let{page,limit,address} = this.state;

      try {
        this.setState({isLoading:true})
        let payload ={page,limit,address}
        let res = await api.getUserActivity(payload);
        // console.log(res);
        let{result,count} = res.data;
        this.setState({activities:result,count,isLoading:false},this.updatePagingUrl)
      } catch (e) {
        let msg = e?.response?.data?.message??"No NFT found"
        console.log(msg);
        this.setState({isLoading:false})
      }

  }
  renderNft = () =>{
    let{activities} = this.state;

    return activities.map((data,i)=>{
      let{tokenId,nftAddress} = data;
      return <div className="col-12 col-lg-6" key={tokenId+nftAddress+"1"+i}>
        {<ActivityCard data={data} key={tokenId+nftAddress+"2"+i}/>}
      </div>
    })
  }
  renderDummyNft = () =>{
    let count = window.innerWidth < 576?1:window.innerWidth < 768?4:window.innerWidth < 992?6:8;
      return new Array(count).fill(undefined).map((item,i)=>{
        return <div className="col-12 col-lg-6" key={i}>
          <ActivityCardDummy />
        </div>
      })
  }
  handleInput = e =>{
    let val = e.target.value;
    if (!val || val.length <= 40) {
      this.setState({filterString:val})
    }
  }
  handlePaginator = (value) =>{
    this.setState(value,this.fetchActivities)
    this.myRef.current.scrollIntoView({ behavior: 'smooth' })

  }

    render() {
      let{activities,isLoading,count,start,limit,page} = this.state;
        return (
          <div className="container" ref={this.myRef}>
            <div className="row ">
              {!isLoading && !!activities.length && this.renderNft()}
              {!!isLoading && this.renderDummyNft()}
              {!isLoading && !activities.length && <h2 style={{color:"#888",margin:"15px 10px"}}>No activity found</h2>}

            </div>

            <div className="row row--grid">
              <div className="col-12">
                <Paginator  data={{count,start,limit,page,size:activities.length}} handler={this.handlePaginator}/>
              </div>
            </div>
          </div>
        )
    }
}




export default Activity
