import React, { Component } from 'react'
import {Utils} from "../helper"
import * as Parts from "./parts"

import api from "../api"
import './collection.scss'

import {MintableCardDummy,MintableCardDummyMini,MintableCard,MintableCardGrid} from "./cards.jsx"
import {Paginator,MenuSelect3,GridToggler} from "../frag"




class MyCollections extends Component{
  constructor(props) {
      super(props)

      let limit = 12;
      const urlParams = new URLSearchParams(window.location.search);
      let page = urlParams.get('page');
      page = Utils.floor(page)
      page = page && page > 0?page:1;
      let start = page > 4?page -3:1;

      this.state = {
        address:"",
        limit,
        page,
        start,
        count:0,
        isProcess:false,
        collections:[],
        category:"all",
        type:0,
        isSmall:window.innerWidth < 768,
        isGrid:localStorage.getItem('isGrid') === "true",
      }
      this.myRef = React.createRef();
  }
  componentDidMount (){
    document.title = "My Mintable Collections";
    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);
      let {address} = account
      // address = "TY7UBmaLpytNP1AQ8TT84qgTX35MJVvWL1";

      if(address){

        this.setState({address},this.fetchIkCollections)
      }else {
        this.setState({address:"",collections:[],count:0,page:1,start:1})
      }
    })
  }
  componentWillUnmount(){
    document.title = "Kraftly";
  }

  fetchIkCollections = async()=>{
    let{category,type,isProcess,limit,page,address} = this.state;
      try {
        if(isProcess) return Utils.setToastAlert("Previous request processing","info");
        this.setState({isProcess:true})
        let payload = {mode:"author",category,type,address,limit,page}
        let res = await api.getIkCollections(payload);
        let{result,count} = res.data;
        this.setState({isProcess:false,count,collections:result})


      } catch (e) {
        let msg = e.response?.data?.message??"No Collection Found found";
        Utils.setToastAlert(msg,"error");
        // console.log(e.response);
        this.setState({isProcess:false})
      }
  }


   renderNft = () =>{
     let{collections,isSmall,isGrid} = this.state;
     return collections.map((data,i)=>{
       let{address,minted} = data;
       data.fillTab = true;
       if(!!isSmall && !!isGrid){
         return <div className="col-6 col-sm-4 col-lg-2" key={i}>
           <MintableCardGrid data={data} key={i+address+minted+"small"}/>
         </div>
       }
       return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={i}>
         <MintableCard data={data} key={i+address+minted+"large"}/>
       </div>
     })
   }
  renderDummyNft = () =>{
    let{isSmall,isGrid} = this.state;

    let count = isSmall&&isGrid ?12:window.innerWidth < 576?3:window.innerWidth < 992?6:window.innerWidth < 1200?9:12;
      return new Array(count).fill(undefined).map((item,i)=>{
        if(!!isSmall && !!isGrid){
          return <div className="col-6 col-sm-4 col-lg-2" key={i}>
            <MintableCardDummyMini key={i+"small"}/>
          </div>
        }
        return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={i}>
          <MintableCardDummy key={i+"large"}/>
        </div>
      })
  }

  onOption = (name,value)=>{
    let{isProcess} = this.state
    if(isProcess) return Utils.setToastAlert("Previous request processing","info");
    if(this.state[name] === value) return console.log("same option");
    this.setState({[name]:value,collections:[],page:1,start:1,count:0},this.fetchIkCollections)
  }
  handlePaginator = (value) =>{
    this.setState(value,this.fetchIkCollections)
    this.myRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  render(){
    let {collections,type,isProcess,category,isSmall,isGrid,count,start,limit,page} = this.state
    let categoryOptions = {all:"All Collection",onlypublic:"Only Public",onlyprivate:"Only Private"};
    let typeOptions = {0:"All Types",1:"Blind Mintable",2:"Fixed price",3:"Upgradable Fixed price"};

    return(
      <Parts.CollectionMain tab="collections" isSmall={isSmall && isGrid}>

        <div className="col-12">
          <div className="collection__create__title">
            <div className="collection__create__title__body">
              <div className="collection__create__p">My Mintable Collections</div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="ik__home__filter">

            <MenuSelect3 options={categoryOptions} value={category} onOption={(e)=>this.onOption("category",e)} key={category}/>
            <MenuSelect3 options={typeOptions} value={type} onOption={(e)=>this.onOption("type",e)} key={type}/>

            <GridToggler
              data={{isGrid,isSmall}}
              handleGrid={(e)=>this.setState(e)}
              />

          </div>
        </div>
        <div className="col-12">
          <div className="row no-gutters">
            {!isProcess && !count && <p className="ik__notfound__msg">No Collection found</p>}
            {!isProcess && !!count && this.renderNft()}
            {!!isProcess && this.renderDummyNft()}
          </div>
      </div>
      <div className="col-12">
        <Paginator  data={{count,start,limit,page,size:collections.length}} handler={this.handlePaginator}/>
      </div>
      </Parts.CollectionMain>
    )
  }
}



export default MyCollections;
