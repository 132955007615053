import React, { Component } from 'react'
import styled from 'styled-components'
import $ from "jquery";
import { Link } from 'react-router-dom'
import {Utils,PopUp} from "../helper"
import * as Parts from "./parts"
import api from "../api"
import {DpViewer} from "../helper/file-viewer.jsx"

import {ReactComponent as Question} from './../images/question.svg';
import {ReactComponent as HandArrow} from './../images/hand-up.svg';
import {ReactComponent as PlusIcon} from './../images/plus.svg';

const CreateBtn = styled.button.attrs(props=>({
  className: `sign__btn ${props.fullView?" full__btn":props.largeView?" large__btn":""}`,
  type:"button"
}))``


const BackBtn = styled.button.attrs(props=>({
  className: `sign__btn red__btn ${props.fullView?" full__btn":props.largeView?" large__btn":""}`,
  type:"button"
}))``


const SignGroup = (props) =>{
  return <div className="col-12">
    <div className="sign__group sign__group_create_page">
      {props.children}
    </div>
  </div>
}



const InputLabel = (props) =>{
  let{htmlFor,title,tooltip} = props
  return(
    <label className="sign__label" htmlFor={htmlFor}>
    <p>{title}</p>
    <Question />
    <div className="tooltip">
      <p className="tooltip__text">{tooltip}</p>
    </div>
    </label>
  )
}


class InputWithSelect extends Component {
  constructor(props) {
      super(props)

      this.state = {
        isOpen:false,

      }
  }

  componentDidMount(){
    // let {type,contracts} = this.props.data;
    //
    // if(!!contracts && !!contracts.length){
    //
    // }
    // if(type === "nftAddress"){
    //   this.setState({isOpen:true})
    //
    // }
  }

  selectToken = (value) =>{
    let {type} = this.props.data;
    this.setState({isOpen:false})
    this.props.handleState({[type]:value});
  }

  handleInput = e =>{
  let {name} = this.props.data;
  let input = Utils.inputNumber(e.target.value,0);
  this.props.handleState({[name]:input})
  }

  renderList = () =>{
    let {type,contracts} = this.props.data;
    let data = type === "token"?Utils.getAllTokens():contracts;

    // console.log(data);
    return Object.entries(data).map((item,i)=>{
      let{symbol,name,img} = item[1];

      return <div className="select__token__option"
        onClick={()=>this.selectToken(item[0])}
        key={item[0]}
        >
        <div className="select__token__info">
          {!!img && <img src={img} alt="" />}
          {!img && <DpViewer address={item[0]} data={{type:"trc721"}} key={item[0]}/>}
          <div className="select__token__symbol">
            <p>{symbol}</p>
            {!!name && <span>{name}</span>}
          </div>
        </div>
        <div className="select__token__info__right">
          <span>{item[0] === Utils.Zero_address?"-":Utils.shortAddress(item[0])}</span>
        </div>
      </div>
    })
  }


  render(){
    let {name,preview,type,contracts} = this.props.data;

    let inputValue = this.props.data[name];
    let selectedOption = this.props.data[type];

    let{isOpen} = this.state;
    let {symbol,img} = type === "token"?Utils.getTokenInfo(selectedOption):contracts[selectedOption];
    return (
      <div className="sign__input__token__select">
        <input
          name={name}
          className="select__input"
          type={!preview?"number":"text"}
          value={!preview?inputValue:Utils.preety.unsafe(inputValue)}
          onChange={this.handleInput}
          readOnly={!!preview}

          />
        <button
          className="select__token"
          onClick={()=>this.setState({isOpen:!isOpen})}>
          {!!img && <img src={img} alt="" />}
          {!img && <DpViewer address={selectedOption} data={{type:"trc721"}} key={selectedOption}/>}
          {symbol}
          <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M81.8457,25.3876a6.0239,6.0239,0,0,0-8.45.7676L48,56.6257l-25.396-30.47a5.999,5.999,0,1,0-9.2114,7.6879L43.3943,69.8452a5.9969,5.9969,0,0,0,9.2114,0L82.6074,33.8431A6.0076,6.0076,0,0,0,81.8457,25.3876Z"/></svg>
        </button>

        {!!isOpen && !preview &&  <PopUp.PopupContainer
          className="popup__tokens__view"
          onClose={()=>this.setState({isOpen:false})}
          title={`Select ${type === "token"?"Minting Token":"NFT Contract"}`}
          >
          <div className="select__token__options">
            {this.renderList()}
          </div>

          {type !== "token" && <Link to="/mintable/create-trc721" className="create__token__btn">
            <PlusIcon />
            Create
          </Link>}
        </PopUp.PopupContainer>}

      </div>
    )
  }
}



class CollectionType extends Component {
  constructor(props) {
      super(props)

      this.state = {
        isOpen:false,
        types:[
          {
            name:"Blind Mintable",
            info:"Blind mintable collection can be of any size while booking, but collection size not upgradeable after booking. Each NFT will be minted randomly and fairly to for everyone."
          },
          {
            name:"Fixed Price Mintable",
            info:"Fixed price collection can be of any size while booking, but collection size not upgradeable after booking. Each NFT will minted on a fixed mint price and can receive offer of lower amount."
          },
          {
            name:"Upgradable Fixed Price Mintable",
            info:"Upgradable fixed price collection can be of maximum size 50 NFTs while booking, and collection size can be upgradeable at any time. Each NFT will minted on a fixed mint price and can receive offer of lower amount."
          }
        ]
      }
  }

  selectToken = (value) =>{
    this.setState({isOpen:false})
    this.props.handleState({collectionType:value});
  }

  renderList = () =>{
    let {types} = this.state;

    return types.map((item,i)=>{
      let{name,info} = item;

      return <div className="select__token__option"
        onClick={()=>this.selectToken(i+1)}
        key={name}
        >
        <div className="select__token__info">
          <div className="select__token__symbol select__token__symbol__types">
            <p>{name}</p>
            <span>{info}</span>
          </div>
        </div>
      </div>
    })
  }


  render(){
    let {preview,collectionType} = this.props.data;

    let{isOpen,types} = this.state;

    return (
      <div className="sign__input__token__select">

        <button
          className="select__mint__type"
          onClick={()=>this.setState({isOpen:!isOpen})}>
          {types[collectionType-1].name}
          <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M81.8457,25.3876a6.0239,6.0239,0,0,0-8.45.7676L48,56.6257l-25.396-30.47a5.999,5.999,0,1,0-9.2114,7.6879L43.3943,69.8452a5.9969,5.9969,0,0,0,9.2114,0L82.6074,33.8431A6.0076,6.0076,0,0,0,81.8457,25.3876Z"/></svg>
        </button>

        {!!isOpen && !preview &&  <PopUp.PopupContainer
          onClose={()=>this.setState({isOpen:false})}
          title="Select Collection Type"
          className="popup__tokens__view"
          >
          <div className="select__token__options">
            {this.renderList()}
          </div>
        </PopUp.PopupContainer>}
      </div>
    )
  }
}

class CreateCollection extends Component{
  constructor(props) {
      super(props)

      this.state = {
        address:"",
        contracts:Utils.getAllNfts(),
        token: Utils.Zero_address,
        nftAddress: Utils.NftContract,
        collectionType:1,

        fees:25*1e6,

        preview:false,
        isProcess:false,
        size:5,
        price:25,
        time: Date.now() + 86400000,
      }
  }
  componentDidMount (){
    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);
      let {address} = account
      if(address){
        // address = "TPssiPJ6vLdRx9P8jE4NNTZrLofKoqFVYY";

        this.setState({address},()=>{
          this.getDiscount()
          this.fetchTokens()
        })
      }else {
        this.setState({address:"",fees:25*1e6,contracts:Utils.getAllNfts()})
      }
    })
  }

  getDiscount = async() =>{
    let{address} = this.state;
    try {
      let instance = await window.tronWeb.contract().at(Utils.KnftCollection);
      let fees = await instance.getBookingFee(address,1).call();
      fees = window.tronWeb.toDecimal(fees);
      // console.log(fees);
      this.setState({fees})
    } catch (e) {
        console.log(e);
        this.setState({fees:25*1e6})
    }
  }

  fetchTokens = async() =>{
    try {
      let {address,nftAddress} = this.state;

      let res = await api.getNftContracts({address,mode:"create"});
      let contracts = res.data.result;
      // data = Object.assign({},data);

        contracts = contracts.map((item,i)=>{
          let {nftAddress,name,symbol} = item;
          return [nftAddress,{name,symbol}]
        })
      contracts.push([Utils.NftContract,Utils.getNftsAddressInfo(Utils.NftContract)]);

      const urlParams = new URLSearchParams(window.location.search);
      let token = urlParams.get('t');

      if(!!token && token.length === 34){
        let _token = contracts.find( i => i[0] === token);
        if(_token && _token.length){
          nftAddress = token;
          // urlParams.delete('t')
          // let params = urlParams.toString();
          if (window.history.pushState) {
            let url = `${window.location.pathname}`;
            window.history.pushState(null, null,url);
          }
        }
      }

      contracts = Object.fromEntries(contracts);
      this.setState({contracts,nftAddress})
    } catch (e) {
      // console.log(e.response);
    }
  }



  handleGenerateCollection = async()=>{
    try {
      let{address,size,price,time,token,nftAddress,collectionType,fees,isProcess} = this.state;

      if(!address) return $(".header__action-btn--signin").click();

      price = price * 1e6;
      time = new Date(time).getTime()/1000;
      time = Math.floor(time);
      fees = Utils.ceil(fees*size);

      if(isProcess) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isProcess:true})

      let balance = await window.tronWeb.trx.getUnconfirmedBalance(address);
      balance = Utils.floor(balance);

      if(!balance || balance < fees){
        this.setState({isProcess:false})
        return Utils.setToastAlert("Not sufficient TRX balance");
      }

      let instance = await window.tronWeb.contract().at(Utils.KnftCollection);
      await instance.createCollection(size,price,time,token,nftAddress,collectionType).send({
       feeLimit:3000 * 1e6,
       callValue:fees,
     });
     this.setState({isProcess:false,preview:false})

     Utils.setToastAlert("Transaction submitted for booking collection","success")

    } catch (e) {
      console.log(e);
      this.setState({isProcess:false})
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to Reserve collection","error")
      }
    }
  }

  handleInputTime = (e)=>{
    // if(!!this.state.preview)return null;

    // let time = Utils.inputSafe(e.target.value);
    let time = e.target.value;
    // console.log(time);
    time = new Date(time).getTime();
    // console.log(time);
    if(e.target.style.borderColor && time > Date.now()){
      e.target.style.borderColor = "";
    }
    if(time - 1800000 < Date.now()){
      e.target.style.borderColor = "red";
    }
    this.setState({time})
  }

  handlePreview = ()=>{
    let{size,price,time,collectionType} = this.state
    if(!size || size < 2) return Utils.setToastAlert("Collection size should be atleast 2.");
    if(collectionType === 3 && size > 50) return Utils.setToastAlert("Upgradable fixed price collection can be of maximum 50 NFT size while booking");

    if(!price || price < 25) return Utils.setToastAlert("Minimum Collection minting price should be 25 TRX");

    let _time = new Date(time).getTime() - 1800000;

    if(!time || _time <= Date.now()) return Utils.setToastAlert("Collection minting start time should be atleast 30 mins ahead of now");
    this.setState({preview:true})
  }
  render(){
    let{size,price,token,nftAddress,collectionType, time,preview,fees,isProcess,contracts} = this.state;
    let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    let title = !preview?"Create Collection":"Preview Details"
    return(
      <Parts.CollectionMain tab="create" isSmall>

      <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div className="collection__create__title">
          <div className="collection__create__title__body">
            <div className="collection__create__p">{title}</div>
          </div>
        </div>
         <div className="row no-gutters">
           <SignGroup>
             <InputLabel htmlFor="type" title="Collection type" tooltip="select minting type of collection"/>
               <CollectionType
                 data={{collectionType,preview}}
                 handleState={(e)=>this.setState(e)}
                 />
           </SignGroup>


           <SignGroup>
             <InputLabel htmlFor="size" title="Collection size" tooltip="No. of NFTs in collection"/>
               <InputWithSelect
                 data={{size,preview,nftAddress,contracts,name:"size",type:"nftAddress"}}
                 handleState={(e)=>this.setState(e)}
                 />
           </SignGroup>

           <SignGroup>
             <InputLabel htmlFor="price" title="Minting price" tooltip="Minting price of each NFT after publishing"/>

             <InputWithSelect
               data={{price,preview,token,name:"price",type:"token"}}
               handleState={(e)=>this.setState(e)}
               />

           </SignGroup>

           <SignGroup>
             <InputLabel htmlFor="time" title="Auction starts at" tooltip="Countdown timer if published before given time"/>
             <input
               id="time"
               type="datetime-local"
               name="time"
               className="sign__input"
               min={new Date().toISOString().substring(0, 16)}
               value={time?new Date(time - tzoffset).toISOString().substring(0, 16):""}
               onChange={this.handleInputTime}
               readOnly={!!preview}
               />
               <p className="sign__group__right__label">Minting start In :- {Utils.getTimeOffset(Utils.floor(time/1000,0),true)}</p>

           </SignGroup>


           <div className="col-12 ">
             <div className="btn__panel">

             {!preview && <CreateBtn onClick={this.handlePreview} fullView>Create Collection</CreateBtn>}

             {!!preview && !isProcess && <BackBtn
               largeView
               onClick={()=>this.setState({preview:false})}
               disabled={!!isProcess}
               >
               Cancel
             </BackBtn>}
           {!!preview && <CreateBtn
             largeView={!isProcess}
             fullView={!!isProcess}
             onClick={this.handleGenerateCollection}>
             Continue

             {!!isProcess && <div className="ball__pulse">
               <div></div>
               <div></div>
               <div></div>
             </div>}
           </CreateBtn>}
         </div>

        <p className="mint_note"><HandArrow/>Collection hosting and storage fees {Utils.ceil(fees/1e6,2)} TRX per unit of NFT</p>

           </div>
         </div>
     </div>
</Parts.CollectionMain>

    )
  }
}

export default CreateCollection
