import React, { Component,Fragment} from 'react'
// import {Utils} from "../../helper"
import './video-player.scss'

import {ReactComponent as Play} from '../../images/play.svg';
import {ReactComponent as Pause} from '../../images/pause.svg';
import {ReactComponent as Speaker} from '../../images/speaker.svg';
import {ReactComponent as Muted} from '../../images/muted.svg';


class VideoPlayer extends Component{
  constructor(props) {
      super(props)

      this.state = {
        isPlaying: false,
        progress: 0,
        isMuted: true,
      }
      this.videoElement = React.createRef(document.createElement('video'));
  }
  componentDidMount(){
    this.setState({isPlaying:true});
    this.videoElement.current.muted = true;
    this.videoElement.current.play();
  }
  togglePlay = (e) => {
    e.preventDefault()
    let{isPlaying} = this.state;
    isPlaying = !isPlaying;
    this.setState({isPlaying});
    isPlaying? this.videoElement.current.play(): this.videoElement.current.pause();
  }
  handleOnTimeUpdate = () => {
    const progress = (this.videoElement.current.currentTime / this.videoElement.current.duration) * 100;
    this.setState({progress});
    if(progress === 100){
      this.videoElement.current.play()
    }
  }
  toggleMute = (e) => {
    e.preventDefault()
    let{isMuted} = this.state;
    isMuted = !isMuted;
    this.setState({isMuted});
    isMuted? (this.videoElement.current.muted = true): (this.videoElement.current.muted = false);

  }


  render(){
    let{isPlaying,isMuted} = this.state;
    let src = this.props.src || "";

    // let maxWidth = this.videoElement?.current?.clientWidth??"100%";
    // console.log(maxWidth);
    return(
      <Fragment>
        {!!!!src && <video
          src={src}
          ref={this.videoElement}
          onTimeUpdate={this.handleOnTimeUpdate}
          playsInline
        />}

      {!!src && <div className="video-controls">

          <div className="mute_btn" onClick={this.toggleMute}>
            {!!isMuted && <Muted />}
            {!isMuted && <Speaker />}
          </div>
          <div className="play_btn" onClick={this.togglePlay}>
            {!isPlaying && <Play />}
            {!!isPlaying && <Pause />}
          </div>
        </div>}



    	</Fragment>
    )
  }
}

export default VideoPlayer;
