import React, { Component } from 'react'
import {useParams,useLocation} from 'react-router-dom'


// import $ from 'jquery';

// import { lang } from '../../lang'



class Form extends Component {
  constructor(props) {
      super(props)
      let filterString = this.props.location.pathname;
      filterString= filterString.startsWith("/search/")?filterString.replace("/search/", ""):"";

      this.state = {
        filterString,
      }
  }
  componentDidMount = () =>{

  }
  handleInput = e =>{
    let val = e.target.value;
    if (!val || val.length <= 40) {
      this.setState({filterString:val})
    }
  }
  handleSubmit = e =>{

    e.preventDefault()
    let{filterString} = this.state;
    if(!filterString) return "";
    const urlParams = new URLSearchParams(window.location.search);
    let type = urlParams.get('type') || "nfts";


    let _filterString = filterString.toUpperCase();
    let redirectLocation = `/search/${filterString}?type=${type}`;

    if(_filterString.substr(0,5) === "TAPES" || _filterString.substr(0,4) === "TAPE"){
      redirectLocation = "/explore/tapes";
    }else if (_filterString.substr(0,6) === "TPUNKS" || _filterString.substr(0,5) === "TPUNK") {
      redirectLocation = "/explore/tpunks";
    }else if (_filterString.substr(0,8) === "TMEEBITS" || _filterString.substr(0,7) === "TMEEBIT") {
      redirectLocation = "/explore/tronmeebits";
    }else if (_filterString.substr(0,5) === "BAYCT" || _filterString.substr(0,7) === "APECLUB") {
      redirectLocation = "/explore/bayct";
    }else if (_filterString.substr(0,3) === "CCT" || _filterString.substr(0,8) === "COOlCATS" || _filterString.substr(0,7) === "COOlCAT") {
      redirectLocation = "/explore/coolcats";
    }else if (_filterString.substr(0,8) === "TRONBULL" || _filterString.substr(0,3) === "TRB") {
      redirectLocation = "/explore/tronbull";
    }
    window.location.href = redirectLocation;

  }

    render() {
      let{filterString} = this.state;
        return (
          <form onSubmit={this.handleSubmit.bind(this)}  className="header__search">
            <input
              type="text"
              placeholder="Search items, collections, and creators"
              value = {filterString}
              onChange={this.handleInput}
              />
            <button
              type="button"
              onClick={this.handleSubmit}
              >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
            </button>
            <button type="button" className="close"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg></button>
          </form>
        )
    }
}






function WithProps(props){
    const params = useParams();
    const location = useLocation();


    return (
        <Form
            {...props}
            params={params}
            location={location}

        />
    );
}

export default WithProps
