
import React, { Component } from 'react'
import $ from 'jquery';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import '../../scss/donate.scss';
import {Utils} from "../../helper"
import tronWeb from "../../tronweb"

const DonationCtrt = "TLw2dgQQ8wPtJqFftp3aCN7zaqiscX4ywK";
const Zero_address = "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb";
const Usdt_address = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";

const StyledLink  = styled.a.attrs({
    target : "_blank",
    rel : "noopener noreferrer"
})``;

let data = [
      {
        address:"-",
        symbol:"TRX",
        name:"TRON",
        decimals:6,
        img:"https://coin.top/production/logo/trx.png",
      },
      {
        address:"TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t", //USDT
        symbol:"USDT",
        name:"Tether USD",
        decimals:6,
        img:"https://coin.top/production/logo/usdtlogo.png",

      },
      {
        address:"TFczxzPhnThNSqr5by8tvxsdCFRRz6cPNq", //APENFT
        symbol:"NFT",
        name:"APENFT",
        decimals:6,
        img:"https://coin.top/production/upload/logo/TFczxzPhnThNSqr5by8tvxsdCFRRz6cPNq.png",

      },
      {
        address:"TCFLL5dx5ZJdKnWuesXxi1VPwjLVmWZZy9", //JST
        symbol:"JST",
        name:"JUST GOV v1.0",
        decimals:18,
        img:"https://coin.top/production/logo/just_icon.png",

      },
      {
        address:"TAFjULxiVgT4qWk6UZwjqwZXTSaGaqnVp4", //BTT
        symbol:"BTT",
        name:"BitTorrent",
        decimals:18,
        img:"https://coin.top/production/logo/1002000.png",

      },
      {
        address:"TSSMHYeV2uE9qYH95DqyoCuNCzEL1NvU3S",  //SUN
        symbol:"SUN",
        name:"SUN TOKEN",
        decimals:18,
        img:"https://coin.top/production/logo/TSSMHYeV2uE9qYH95DqyoCuNCzEL1NvU3S.png",

      },
      {
        address:"TLa2f6VPqDgRE67v1736s7bJ8Ray5wYjU7", //WIN
        symbol:"WIN",
        name:"WINK",
        decimals:6,
        img:"https://coin.top/profile_images/JKtJTydD_400x400.jpg",

      },
      {
        address:"TTroZqb95vmsw4kppupQ8tVEzkNDDP2bcG", //KTY
        symbol:"KTY",
        name:"KRAFTLY",
        decimals:6,
        img:"https://coin.top/production/upload/logo/TTroZqb95vmsw4kppupQ8tVEzkNDDP2bcG.png?t=1629585080507",

      },
      {
        address:"TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8", //USDC
        symbol:"USDC",
        name:"USD Coin",
        decimals:6,
        img:"https://coin.top/production/upload/logo/TEkxiTehnzSmSe2XqrBj4w32RUN966rdz81.png",

      },
      {
        address:"TN3W4H6rK2ce4vX9YnFQHwKENnHjoxb3m9", //BTC
        symbol:"BTC",
        name:"Bitcoin",
        decimals:8,
        img:"https://coin.top/production/logo/TN3W4H6rK2ce4vX9YnFQHwKENnHjoxb3m9.png",

      },
      {
        address:"TXpw8XeWYeTUd4quDskoUqeQPowRh4jY65", //WBTC
        name:"Wrapped BTC",
        symbol:"WBTC",
        decimals:8,
        img:"https://coin.top/production/logo/TXpw8XeWYeTUd4quDskoUqeQPowRh4jY65.png",
      },
      {
        address:"THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF", //ETH
        name:"Ethereum",
        symbol:"ETH",
        decimals:18,
        img:"https://coin.top/production/logo/THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF.png",
      },
      {
        address:"TXWkP3jLBqRGojUih1ShzNyDaN5Csnebok", //WETH
        name:"Wrapped ETH",
        symbol:"WETH",
        decimals:18,
        img:"https://coin.top/production/logo/TXWkP3jLBqRGojUih1ShzNyDaN5Csnebok.png",
      },
      {
        address:"TMwFHYXLJaRUPeW6421aqXL4ZEzPRFGkGT", //USDJ
        symbol:"USDJ",
        name:"JUST Stablecoin v1.0",
        decimals:18,
        img:"https://coin.top/production/logo/usdj.png",
      },
      {
        address:"TUpMhErZL2fhh4sVNULAbNKLokS4GjC1F4", //TUSD
        symbol:"TUSD",
        name:"TrueUSD",
        decimals:18,
        img:"https://coin.top/production/logo/TUpMhErZL2fhh4sVNULAbNKLokS4GjC1F4.png",
      },
      {
        address:"TR3DLthpnDdCGabhVDbD3VMsiJoCXY3bZd", //LTC
        symbol:"Litecoin",
        name:"LTC",
        decimals:8,
        img:"https://coin.top/production/logo/TR3DLthpnDdCGabhVDbD3VMsiJoCXY3bZd.png",
      },
      {
        address:"TDyvndWuvX5xTBwHPYJi7J3Yq8pq8yh62h", //HT
        symbol:"HT",
        name:"HuobiToken",
        decimals:18,
        img:"https://coin.top/production/logo/TDyvndWuvX5xTBwHPYJi7J3Yq8pq8yh62h.png",
      },
      {
        address:"TKttnV3FSY1iEoAwB4N52WK2DxdV94KpSd", //DICE
        symbol:"DICE",
        name:"TRONbetDice",
        decimals:6,
        img:"https://coin.top/production/logo/TKttnV3FSY1iEoAwB4N52WK2DxdV94KpSd.jpg",
      },
      {
        address:"TVgAYofpQku5G4zenXnvxhbZxpzzrk8WVK", //LIVE
        symbol:"LIVE",
        name:"TRONbetLive",
        decimals:6,
        img:"https://coin.top/production/upload/logo/TVgAYofpQku5G4zenXnvxhbZxpzzrk8WVK.png",
      },


    ]


class Ukraine extends Component{
  constructor(props){
    super(props)

    let customTokens = localStorage.getItem('customTRC') || "[]";
    customTokens = JSON.parse(customTokens);
    // console.log(customTokens);
    this.state ={
      token:"",
      symbol:"",
      decimals:0,
      img:"",
      isPopUp:false,
      address:"",
      balance:0,

      input:0,
      output:0,

      filterString:"",
      customTokens,

      isProcess:false,
      isFetching:false,

    }
  }
  componentDidMount (){
    document.title = "Donate | Ukraine";
    let checker = setInterval(async()=>{
      if(window.tronWeb){
        window.tronLink.request({method: 'tron_requestAccounts'})

        this.handleAddressChange()
        clearInterval(checker)
      }
    },400)
  }

  componentWillUnmount(){
    document.title = "Kraftly";
  }


  handleAddressChange = () =>{
    setInterval(async()=>{
      let {address}  = this.state
      let currentAddress = window.tronWeb.defaultAddress.base58;
      // console.log(currentAddress);
        if (currentAddress !== address) {
          if(!!currentAddress){
            this.setState({address:currentAddress,balance:0},this.fetchUserBalance)
          }else {
            this.setState({address:"",balance:0})
          }
        }
    },2000)
  }



  fetchUserBalance = async()=>{
    let {address,token} = this.state
    try {
      if(!token) return console.log("select token");
      if(!address) return console.log("connect wallet");

      if(token === "-"){
        let balance = await window.tronWeb.trx.getUnconfirmedBalance(address);
        balance = window.tronWeb.toDecimal(balance);
        this.setState({balance});

      }else {
        let instance = await window.tronWeb.contract().at(token);
        let balance = await instance.balanceOf(address).call();
        balance = window.tronWeb.toDecimal(balance._hex);
        this.setState({balance});

      }

    } catch (e) {
      console.log(e);
    }
  }

  InTrx = async() =>{
    try {
      let{symbol,decimals,input} = this.state;
      let _input = Utils.floor(input*(10**decimals),0);


      let instance = await window.tronWeb.contract().at(DonationCtrt);

      await instance.donateTrx().send({
        feeLimit:100 * 1e6,
        callValue:_input,

      });

      this.setState({isProcess:false},this.fetchUserBalance)
      Utils.setToastAlert(`Successfully donated USDT worth ${Utils.preety.unsafe(input,6)} ${symbol}`,"success")
    } catch (e) {
      this.setState({isProcess:false})
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to send transaction","error")
      }
    }
  }

  InToken = async() =>{
    try {
      let{address,token,symbol,decimals,input} = this.state;
      let _input = Utils.floor(input*(10**decimals),0);

      let tokenInstance = await window.tronWeb.contract().at(token);
      let allowance = await tokenInstance.allowance(address,DonationCtrt).call();
      allowance = window.tronWeb.toDecimal(allowance);

      await new Promise( res =>{
        if(allowance >= _input){
          res()
        }else {
          let result = tokenInstance.approve(DonationCtrt, _input).send({
            feeLimit:100 * 1e6,
          });
          res(result)
        }
      })

      let instance = await window.tronWeb.contract().at(DonationCtrt);

      await instance.donateToken(token, _input).send({
         feeLimit:100 * 1e6,
       })

      this.setState({isProcess:false},this.fetchUserBalance)
      Utils.setToastAlert(`Successfully donated USDT worth ${Utils.preety.unsafe(input,6)} ${symbol}`,"success")
    } catch (e) {
      this.setState({isProcess:false})
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to send transaction","error")
      }
    }
  }

  handleDonate = async() =>{
    let{address,token,balance,decimals,input,isProcess} = this.state;

    if(!address)return $(".header__action-btn--signin").click();;
    if(!token) return Utils.setToastAlert("Please select a token");

    if(isProcess) return Utils.setToastAlert("Previous request processing");
    if(!input) return Utils.setToastAlert("Amount can not br zero");
    let _input = Utils.floor(input*(10**decimals),0);

    if(balance < _input) return Utils.setToastAlert('Insufficient account balance');
    this.setState({isProcess:true})

    if(token === "-"){
      this.InTrx()
    }else {
      this.InToken()
    }

  }


  handleSearch = async(e) =>{
    e.preventDefault()
    let{filterString,isFetching,customTokens} = this.state;
    try {
      let isNotExists = [...data,...customTokens].filter(i => i.address.toUpperCase() === filterString.toUpperCase()).length === 0;
      if(isFetching) return Utils.setToastAlert("Previous request processing");

      if(isNotExists && filterString.length === 34 && tronWeb.isAddress(filterString)){
        this.setState({isFetching:true})

        let sunSwapFactory = await tronWeb.contract().at("TXk8rQSAvPvBBNtqSoY6nCfsXWCSSpTVQF");
        let exchangeId = await sunSwapFactory.getExchange(filterString).call();

        if(exchangeId === "410000000000000000000000000000000000000000"){
          this.setState({isFetching:false})
          return Utils.setToastAlert('Token pair does not exist on Sun swap');;
        }

        let instance = await tronWeb.contract().at(filterString);
        let symbol = await instance.symbol().call();
        let decimals = await instance.decimals().call();
        decimals = window.tronWeb.toDecimal(decimals._hex);

        let name = await instance.name().call();
        let img = `https://coin.top/production/upload/logo/${filterString}.png`;

        customTokens.push({address:filterString,name,symbol,decimals,img});
        localStorage.setItem('customTRC',JSON.stringify(customTokens));
        this.setState({customTokens,isFetching:false})

      }
    } catch (e) {
      console.log(e);
      this.setState({isFetching:false})

    }
  }

  handleInput = (e)=>{
    let input = Utils.inputNumber(e.target.value,2);
    this.setState({input},this.handleOutput)
  }

  handleOutput = async() =>{
    try {
      let{token,input,decimals} = this.state;
      if(!token || !input || input <= 0) {
        return this.setState({output:0});
      }else if (token === Usdt_address) {
        return this.setState({output:input*(10 ** decimals)});
      }
      token = token ==="-"?Zero_address:token;
      input = Utils.floor(input * (10 ** decimals),0);
      let instance = await tronWeb.contract().at(DonationCtrt);
      let output = await instance.getUsdtOutput(token,input).call();
      // console.log(output);
      output = window.tronWeb.toDecimal(output);

      this.setState({output})

    } catch (e) {
      console.log(e);
      this.setState({output:0})
    }
  }

  selectToken = (token) =>{
    let{customTokens} = this.state;

    let {symbol,decimals,img} = [...data,...customTokens].filter(i => i.address === token)[0];
    this.setState({symbol,decimals,token,img,balance:0,isPopUp:false},this.fetchUserBalance)
  }
  switchPopUp = () => {
    let{isProcess,isPopUp} = this.state;
    if(!isProcess){
      this.setState({isPopUp:!isPopUp})
    }
  }

  renderList = () =>{
    let{filterString,customTokens} = this.state;
    let _data = [...data,...customTokens];

    let result = _data.filter(i =>{
      let{address,name,symbol} = i;
      filterString = filterString.toUpperCase()
      if(!filterString){
        return true;
      }else if(filterString.length === 34){
        return address.toUpperCase() === filterString;
      }else{
        let _index = name.toUpperCase().search(filterString);
           _index = _index !== -1?_index:symbol.toUpperCase().search(filterString);
           return _index !== -1;
      }
    })
    result = !!result.length?result:_data;

    return result.map((item,i)=>{
      let{address,symbol,name,img} = item;
      return <div className="donate__token__option"
        onClick={()=>this.selectToken(address)}
        key={address}
        >
        <div className="donate__token__info">
          <img src={img} alt="" />
          <div className="donate__token__symbol">
            <p>{symbol}</p>
            {!!name && <span>{name}</span>}
          </div>
        </div>

        <p className="token__address">{Utils.shortAddress(address)}</p>

      </div>
    })
  }

  render(){
    let {symbol,isPopUp,balance,decimals,img,input,output,address,isProcess,isFetching,filterString} = this.state;
    decimals = 10 ** decimals;

    return(
      <main className="main">
    		<div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="breadcrumb">
                <li className="breadcrumb__item"><Link to="/home">Home</Link></li>
                <li className="breadcrumb__item">Community</li>
                <li className="breadcrumb__item"><Link to="/donate">Donate</Link></li>
                <li className="breadcrumb__item breadcrumb__item--active">Ukraine</li>
              </ul>
            </div>
          </div>

              <div className="donate__section">
                <div className="row justify-content-center">




                <div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6">
                <div className="donate__title">
                  <div className="donate__title__header">
                    <h1>Donate to Ukraine</h1>
                  </div>

                  <p>
                    <Link to="https://sunswap.com/#/home">Made using Sunswap </Link>
                    &#8203;  with ❤️ of
                    <StyledLink href="https://twitter.com/justinsuntron?t=kCtcM1VilPuW-t5E1Xzsgw&s=09">&#8203; H.E.Justin sun</StyledLink>
                  </p>
                </div>


                <div className="donate__form">

                  <p className="donate__desc">Select a token to donate - it will be converted to USDT before sending.</p>

                  <div className="donate__form__price_info">
                    <p><span>Balance :</span> {Utils.preety.unsafe(balance/decimals,2)} {symbol}</p>
                    <p><span>Donation :</span> {Utils.preety.unsafe(output/1e6,4)} USDT</p>
                  </div>

                  <div className="donate__form__input_box">
                    <input
                      type="Number"
                      placeholder={"0.0"}
                      value={input || ''}
                      onChange={this.handleInput}
                      readOnly={!!isProcess}
                      />

                    <button
                      onClick={this.switchPopUp}
                      >
                      {!!img && <img src={img} alt="" />}
                      {symbol || "Select a token"}

                      <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M81.8457,25.3876a6.0239,6.0239,0,0,0-8.45.7676L48,56.6257l-25.396-30.47a5.999,5.999,0,1,0-9.2114,7.6879L43.3943,69.8452a5.9969,5.9969,0,0,0,9.2114,0L82.6074,33.8431A6.0076,6.0076,0,0,0,81.8457,25.3876Z"/></svg>
                    </button>
                  </div>
                  <div className="donate__form__price_info">
                    <p></p>
                    <p className="price__info_dim">
                      <span>T0 -</span>
                      <StyledLink href="https://tronscan.org/#/address/TEFccmfQ38cZS1DTZVhsxKVDckA8Y6VfCy">Ukrain Government TRC20(USDT)</StyledLink>
                      </p>
                  </div>
                  <button
                    className="donation__submit"
                    onClick={this.handleDonate}
                    >
                    {!address?"Connect Wallet":!!isProcess?"Donating":"Donate"}
                    {!!isProcess && <div className="ball__pulse">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>}
                  </button>

                  <p className="donate__desc mini_desc">* No protocol fees. <StyledLink href="https://tronscan.org/#/contract/TLw2dgQQ8wPtJqFftp3aCN7zaqiscX4ywK/code">Protocol contract</StyledLink></p>
                  <p className="donate__desc mini_desc">* Automatically convert Selected token to USDT(TRC20) using <StyledLink href="https://sunswap.com/#/home">Sunswap.com</StyledLink> and donate in one transaction.</p>



                </div>

                {!!isPopUp && <div className="place-bid-popup donate__popup">
                  <div className="zoom-anim-dialog modal">
                    <button className="popup_close" onClick={this.switchPopUp}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>
                    </button>

                    <h4 className="sign__title">Select a token</h4>
                    <form onSubmit={this.handleSearch.bind(this)} className="main__filter-search">
                      <input
                        type="text"
                        className="sign__input"
                        placeholder="Search name or paste address"
                        onChange={(e)=>this.setState({filterString:e.target.value})}
                        value={filterString || ''}
                        readOnly={!!isFetching}
                        />
                        <button
                          type="button"
                          onClick={this.handleSearch}
                          >
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
                        </button>
                    </form>
                    {!!isFetching && <div className="ball__pulse">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>}


                    <div className="donate__token__options">
                      {this.renderList()}

                    </div>
                  </div>
                </div>}


              </div>
              </div>

    				</div>
    		</div>
    	</main>
    )
  }
}

export default Ukraine;
