import React, { Component } from 'react'

import './loader.scss'
class HomeLoader extends Component{
  render(){
    return(
      <div className="preloader-home__svg">
        <div class="close-home-loading">X</div>
        <svg width="100%" height="497" viewBox="0 0 1421 497" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="m805.28894,366.49448l-1.5,-2.17964l-40.91294,1.52016c-80.754,80.975 -165.498,101.059 -273.689,103.135l-6.212,0c-111.296,0 -222.593,-42.564 -306.453,-126.135c-33.648,-33.74 -57.978,-72.152 -81.2728,-112.121c46.0718,-48.274 76.6138,-110.563 87.4838,-178.5618c3.106,-14.0151 10.354,-21.2821 21.224,-21.2821l11.907,0l0,-28.0301l-93.179,0l0,28.0301l11.389,0c11.388,0 20.188,5.1907 20.188,20.2439c-10.871,67.999 -42.448,128.731 -94.2136,175.448l0,-174.4098c0,-11.9388 9.3178,-21.2821 21.224,-21.2821l11.3885,0l0,-28.0301l-92.6609,0l0,28.0301l10.8708,0c11.9062,0 21.224,9.3433 21.224,21.2821l0,265.2478c0,10.9 -9.3178,21.282 -21.224,21.282l-10.8708,0l0,28.03l92.6609,0l0,-28.03l-11.3885,0c-11.9062,0 -21.224,-10.382 -21.224,-21.282l0,-54.503c9.8355,-7.786 12.4238,-9.863 14.4944,-11.939c86.9662,153.647 236.5702,246.042 406.8792,246.042l6.212,0c115.438,-1.038 230.68894,-43.30052 317.65494,-130.50552z" fill="white" class="home-loader-svg-1"></path>
          <path d="M441.18 366.36V338.378H429.785C402.853 338.378 363.49 284.486 343.291 249.767C385.243 227.485 413.73 183.957 413.73 133.693C413.73 64.2555 357.275 2.59095 286.837 2.59095H236.597V30.5732H247.474C259.386 30.5732 268.709 39.9006 268.709 51.8189V317.132C268.709 329.05 259.386 338.378 247.474 338.378H236.597V366.36H329.825V338.378H317.912C307.036 338.378 296.677 329.05 296.677 317.132V264.277C304.446 262.722 310.661 262.204 316.876 260.131C329.307 279.304 354.685 307.805 354.685 324.387C354.685 331.123 350.542 336.823 338.63 338.378V366.36H441.18ZM385.761 133.693C385.761 184.994 346.399 228.522 296.677 235.776V31.6096C346.399 38.8642 385.761 82.3921 385.761 133.693Z" fill="white" class="home-loader-svg-2"></path>
          <path d="M688.108 366.36V338.378H676.713C665.836 338.378 655.478 328.532 655.478 317.65V138.357C655.478 76.692 623.884 30.5732 572.609 0C522.37 30.5732 490.258 77.7284 490.258 138.357V250.286H467.469V278.268H490.258V317.65C490.258 328.532 480.935 338.378 469.023 338.378H457.628V366.36H550.856V338.378H539.461C527.549 338.378 518.226 329.05 518.226 317.132V278.268H627.51V317.132C627.51 329.05 618.187 338.378 606.274 338.378H595.398V366.36H688.108ZM627.51 250.286H518.226V138.357C518.226 96.3832 538.943 56.4826 572.609 33.1641C607.31 56.4826 627.51 96.3832 627.51 138.357V250.286Z" fill="white" class="home-loader-svg-3"></path>
          <path d="m882.105,51.8189l0,-49.22795l-177.132,0l0,27.98225l11.394,0c11.913,0 21.235,9.3274 21.235,21.2457l0,48.1921l-33.147,0l0,27.982l33.147,0l0,191.73c-1.553,10.364 -10.358,18.655 -20.717,18.655l-11.394,0l0,27.982l100.54958,0l-6.99358,-28.4l-6.285,0.418l-5.956,0c-10.877,0 -21.235,-10.364 -21.235,-21.246l0,-189.139l65.259,0l0,-27.982l-65.259,0l0,-69.4378l88.566,0l0,21.2457l27.968,0z" fill="white" class="home-loader-svg-4"></path>
          <path d="M1049.91 62.7009V2.59095H898.679V62.7009H926.647V51.8189C926.647 39.9006 935.97 30.5732 947.882 30.5732H959.795V317.132C959.795 329.05 950.472 338.378 938.56 338.378H927.683V366.36H1020.91V338.378H1009.52C997.604 338.378 988.281 329.05 988.281 317.132V30.5732H1000.71C1012.62 30.5732 1021.95 39.9006 1021.95 51.8189V62.7009H1049.91Z" fill="white" class="home-loader-svg-5"></path>
          <path d="M1243.22 366.36V305.732H1215.25V317.65C1215.25 328.532 1206.44 338.378 1194.53 338.378H1127.2V51.8189C1127.2 39.9006 1136.52 30.5732 1148.43 30.5732H1159.83V2.59095H1066.6V30.5732H1078C1089.91 30.5732 1099.23 39.9006 1099.23 51.8189V317.65C1099.23 328.532 1089.91 338.378 1078 338.378H1066.6V366.36H1243.22Z" fill="white" class="home-loader-svg-6"></path>
          <path d="M1421 30.5732V2.59095H1360.4V160.121C1360.4 202.094 1340.2 240.958 1305.5 265.313C1271.84 240.958 1251.12 201.576 1251.12 160.121V2.59095H1190.52V30.5732H1201.92C1213.83 30.5732 1223.15 39.9006 1223.15 51.8189V160.121C1223.15 211.94 1249.56 260.649 1291.52 289.15V317.132C1291.52 329.05 1282.19 338.378 1270.28 338.378H1259.41V366.36H1352.12V338.378H1340.72C1329.84 338.378 1319.49 329.05 1319.49 317.132V289.668C1362.47 260.649 1388.37 211.94 1388.37 160.121V51.8189C1388.37 39.9006 1398.73 30.5732 1409.61 30.5732H1421Z" fill="white" class="home-loader-svg-7"></path>
        </svg>
      </div>
    )
  }
}


export default HomeLoader
