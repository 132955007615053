import { ToastProvider, useToasts } from 'react-toast-notifications'
import React from 'react'
import Utils from "./utils"

//type info success error warning

const FormWithToasts = () => {
  const { addToast } = useToasts()
  Utils.getToastAlert((err,toastAlert)=>{
    if(err) return console.log(err);
    const{appearance,content} = toastAlert
    addToast(content,{ appearance})
    // if (toastAlert && toastAlert.length) {
    //   toastAlert.forEach((data,i)=>{
    //     const{appearance,content} = data
    //     addToast(content,{ appearance})
    //   })
    // }
  });
  return null
}

const ToastAlert = () => {
      return <ToastProvider
        autoDismiss={true}
        autoDismissTimeout={5000}
        transitionDuration={600}>
        <FormWithToasts />
      </ToastProvider>
}

export default ToastAlert;
