



import React, { Component } from 'react'
import {Utils} from "../helper"




class TronRevenue extends Component{

  constructor(props) {
      super(props)

      this.state = {
        balances:[0,0],
        isProcess:false,
      }
  }
  componentDidMount (){
    this.checkSingle()
    this.checkCollection()
  }

  checkSingle = async() =>{
    try {
      console.log("checking mint contract");
      let mintBal = await window.tronWeb.trx.getUnconfirmedBalance(Utils.MintContract);
      // console.log(mintBal);
      let{balances} = this.state;
      balances[0] = mintBal;
      this.setState({balances})
    } catch (e) {
      console.log(e);
    }
  }
  checkCollection = async() =>{
    try {
      console.log("checking blind mint contract");
      let blindMintBal = await window.tronWeb.trx.getUnconfirmedBalance(Utils.BlindContract);
      let{balances} = this.state;
      balances[1] = blindMintBal;
      this.setState({balances})
    } catch (e) {
      console.log(e);
    }
  }
  withdrawSingle = async() =>{
    try {
      let{balances,isProcess} = this.state;
      let address = "TPEXXRBtKsQAkYE6x13wko1yWwE7AAGq45";

      let cAddress = window?.tronWeb?.defaultAddress?.base58??"" === address;
      if(!cAddress) return Utils.setToastAlert("Tronlink login required");
      if(isProcess) return Utils.setToastAlert("Previous request processing");
      if(!balances[0]) return Utils.setToastAlert("Not enough balance");

      this.setState({isProcess:true})

      let mintInstance = await window.tronWeb.contract().at(Utils.MintContract);
      await mintInstance.claimTRX(address,balances[0]).send({feeLimit:5 * 1e7});
      Utils.setToastAlert("successfully withdrawn from mint contract","success");


    } catch (e) {
      this.setState({isProcess:false},this.checkSingle)
      Utils.setToastAlert(e,"error");
      console.log(e);
    }
  }
  withdrawBlind = async() =>{
    try {
      let{balances,isProcess} = this.state;
      let address = "TPEXXRBtKsQAkYE6x13wko1yWwE7AAGq45";

      let cAddress = window?.tronWeb?.defaultAddress?.base58??"" === address;
      if(!cAddress) return Utils.setToastAlert("Tronlink login required");
      if(isProcess) return Utils.setToastAlert("Previous request processing");
      if(!balances[1]) return Utils.setToastAlert("Not enough balance");
      this.setState({isProcess:true})

      let blindMintInstance = await window.tronWeb.contract().at(Utils.BlindContract);
      await blindMintInstance.claimTRX(address,balances[1]).send({feeLimit:5 * 1e7});
      Utils.setToastAlert("successfully withdrawn from blind mint contract","success");
      this.setState({isProcess:false},this.checkCollection)


    } catch (e) {
      this.setState({isProcess:false})
      Utils.setToastAlert(e,"error");

      console.log(e);
    }
  }
  render(){
    let{balances,isProcess} = this.state;

    return(
      <main className="main">
    		<div className="container">
    			<div className="row">

          <div className="col-12 col-lg-6 offset-lg-3">
            <div className="buy__burn__title">
              <h1>Kraftly (TRON)</h1>
              <h3>Unclaimed revenue</h3>
            </div>
          </div>

          <div className="col-12 col-lg-6 offset-lg-3">

            <div className={`buy__burn__table`}>
              <div className="buy__burn__row buy__burn__row__head">
                  <p>Type</p>
                  <p>Available</p>
                  <p>Action</p>
              </div>

              <div className="buy__burn__row">
                  <p>Single Mint</p>
                  <p>{Utils.preety.ceil(balances[0]/1e6,2)}</p>
                  <div className="btn__panel">
                    <button
                      className="sign__btn full__btn"
                      onClick={this.withdrawSingle}
                      >
                      Claim
                      {!!isProcess && <div className="ball__pulse">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>}
                    </button>
                  </div>

              </div>

              <div className="buy__burn__row">
                  <p>Blind Collection</p>
                  <p>{Utils.preety.ceil(balances[1]/1e6,2)}</p>
                  <div className="btn__panel">
                    <button
                      className="sign__btn full__btn"
                      onClick={this.withdrawBlind}
                      >
                      Claim
                      {!!isProcess && <div className="ball__pulse">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>}
                    </button>
                  </div>
              </div>

            </div>
        </div>
    			</div>


    		</div>
    	</main>
    )
  }
}

export default TronRevenue;
