/***********************************|
|         Toast alerts              |
|__________________________________*/
// let toastAlert = [];
let toastCb;
const setToastAlert = (content,appearance="warning")=>{
  if (content && toastCb) {
    // toastAlert.push({appearance,content})
    toastCb(null,{appearance,content})
  }
}
const getToastAlert = (callback)=>{
  toastCb = callback;
  // setInterval(function () {
  //   if (toastAlert.length) {
  //     let _content = toastAlert;
  //     toastAlert = []
  //     callback(null,_content)
  //   }
  // }, 500);
}
/******************************************************************/
/***********************************|
|       live usdt price provider          |
|__________________________________*/
let usdtPrice = 0;

const viewUsdtRate = ()=>{
  return usdtPrice;
}

const setUsdtRate = (val = 0)=>{
  usdtPrice = val;
}

/******************************************************************/
/***********************************|
|       live user provider          |
|__________________________________*/
let creator = {
  address:false,
  name:"",
  username:"",
  verified:false,
  following:[],
  liked:[],
}
const viewCreator = (val = "address")=>{
  return creator[val];
}
const viewUser = ()=>{
  return creator;
}
const updateLike = (_likeId)=>{
  let index = creator.liked.indexOf(_likeId);
  if(index === -1){
    creator.liked.push(_likeId);
    return true;
  }else {
    creator.liked.splice(index, 1);
    return false;
  }
}
const updateFollow = (author)=>{
  let index = creator.following.indexOf(author);
  if(index === -1){
    creator.following.push(author);
    return true;
  }else {
    creator.following.splice(index, 1);
    return false;
  }
}
const setCreator = (address = "", following = [],liked = [], username = "", name = "",verified = false)=>{
  following = following && following.length?following:[];
  liked = liked && liked.length?liked:[];
  creator = {address,following,liked,username,name,verified}
  // console.log(creator);
}

const getCreator =(callback)=>{
  callback(null,creator)
  let _address = creator.address;
  setInterval(function () {
    // console.log(creator);

    if (_address !== creator.address) {
      _address = creator.address;
      // console.log(creator);
      callback(null,creator)
    }
  }, 2000);
}

/******************************************************************/
/***********************************|
|           Image source            |
|__________________________________*/
// let url = "https://31.220.109.154:5000"
// let url = "https://192.168.45.87:5000"
let url = "https://server.kraftly.io"

const getImage = (path) =>{
  if(!path) return "/asset/img/bg/bg2.png";
  let cdn = path.split("/").length === 2;
  if(cdn) return "https://cdn.kraftly.io/images/"+path;
  return url+"/images/"+path;
}
const getDp = (address , path = "") =>{
  if(!address && !path) return "/asset/img/avatars/empty.jpg";
  if(path) return url+"/collection/"+path;
  return url+"/profile/"+address;
}
const getBanner = (address , path = "") =>{
  if(!address && !path) return "/asset/img/dummy-banner.png";

  if(path) return url+"/collection/"+path;
  return url+"/banner/"+address;
}
const getCidBaseUrl = (path) =>{
  return  path === "https://gateway.btfs.io/btfs/" || (path && path !== "ipfs")?path:"https://kraftly.mypinata.cloud/ipfs/";
}

/******************************************************************/

// asynchronous waiting for async/await
const wait = async(time = 10000) =>await new Promise((resolve)=>setTimeout(resolve, time));

/***********************************|
|         Info beautifier           |
|__________________________________*/
const viewOwner = (owner,size = 6)=>{
  if(!owner) return "";
  if(owner === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb") return "Null Address"
  owner = owner || ""
 if(owner.length === 34 ){
   owner = owner.substring(0, 7);
 }else if(owner.length > size ) {
   owner = owner.substring(0, size)+"..";
 }
 return '@'+owner;
}
const shortName = (owner,size = 8)=>{
  if(!owner) return "";
  owner = owner || "";

 if(owner.length > 8) {
   owner = owner.substring(0, size);
 }
 return owner;
}

const ellipsisName = (name,size = 18)=>{
  if(!name) return "";
  name = name || "";
 if(name.length > size) {
   name = name.substring(0, size-2)+"...";
 }
 return name;
}
const ellipsisNftName = (name,size = 16)=>{
  if(!name) return "";
  name = name || "";
 if(name.length > size) {
   name = name.substring(0, size-2)+"...";
 }
 return name;
}

const shortAddress = (owner,_short=false)=>{
  if(!owner) return "";
  if(owner === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb") return "Null Address"
  if(owner.length !== 34) return owner;
  if(window.innerWidth < 768 || !!_short){
    return owner.substring(0, 2)+"..."+owner.substring(owner.length - 5, owner.length);
  }
  return owner.substring(0, 5)+"..."+owner.substring(owner.length - 8, owner.length);
}
const shortHash = (txid)=>{
  if(!txid) return "";
  return txid.substring(0, 10)+"...";
}
/******************************************************************/

/***********************************|
|        Number sanitizer           |
|__________________________________*/
const floor = (val,decimal = 6)=>{
  val = parseFloat(val);
 if(isNaN(val) || val < 0) return 0;
  decimal = Math.floor(decimal)
 if(isNaN(decimal)){
   decimal = 6;
 }
return Math.floor(val * (10 ** decimal)) / (10 ** decimal);
}
const ceil = (val,decimal = 6)=>{
  val = parseFloat(val);
 if(isNaN(val) || val < 0) return 0;
  decimal = Math.floor(decimal)
 if(isNaN(decimal)){
   decimal = 6;
 }
return Math.ceil(val * (10 ** decimal)) / (10 ** decimal);
}

const largePreety = (val,fraction = 2) => {
  val = parseFloat(val);
   if(isNaN(val) || val < 0 || !val)return 0;
   let divider = val >= 1e9?9:val >= 1e6?6:val >= 1e3?3:0;
   let name = val >= 1e9?'B':val >= 1e6?"M":val >= 1e3?"K":'';

   val /= 10 ** divider;
   val = Math.ceil(val * 100) / (100);
  return val.toLocaleString(undefined, { maximumFractionDigits: fraction })+name;
}

const preety = (function(){
  function calculate(val, decimal = 6){
    val = parseFloat(val);
   if(isNaN(val) || val <= 0){
     val = 0;
   }
    decimal = Math.floor(decimal)
   if(isNaN(decimal)){
     decimal = 6;
   }
   return[val,decimal]
  }
  function main(a, b) {
    let [v,d] = calculate(a, b)
    d = v > 1e8?Math.min(1,d):v > 1e7?Math.min(2,d):d;
    v = Math.ceil(v * (10 ** d)) / (10 ** d);
   return v.toLocaleString(undefined, { minimumFractionDigits: d });
  }
  function floor(a, b) {
    let [v,d] = calculate(a, b)
    v = Math.floor(v * (10 ** d)) / (10 ** d);
   return v.toLocaleString(undefined, { minimumFractionDigits: d });
  }
  function ceil(a, b) {
    let [v,d] = calculate(a, b)
    v = Math.ceil(v * (10 ** d)) / (10 ** d);
   return v.toLocaleString(undefined, { minimumFractionDigits: d });
  }
  function real(v, d) {
   return v.toLocaleString(undefined, { maximumFractionDigits: d });
  }
  function unsafe(a, b) {
    let [v,d] = calculate(a, b)
    v = Math.ceil(v * (10 ** d)) / (10 ** d);
   return v.toLocaleString(undefined, { maximumFractionDigits: d });
  }

  main.floor= floor;
  main.ceil= ceil;
  main.real= real;
  main.unsafe= unsafe;


  return main;
})();
/******************************************************************/

/***********************************|
|   Time offset and viewable        |
|__________________________________*/


const getTimeOffset = (ms,largeView = false)=>{
  if(Date.now() >= ms * 1000) {return "Expired"};
  let offset = ms - Math.floor(Date.now() / 1000);
  // console.log(ms,offset);

  let s = offset%60;
  s = s ===0?'00':s <= 9?`0${s}`:s;
  if(offset < 60 && !largeView){
    return `${s}`
  }
  let m = Math.floor((offset%3600)/60)
  m = m ===0?'00':m <= 9?`0${m}`:m;
  if(offset < 3600 && !largeView){
    return `${m}:${s}`
  }
  let h = Math.floor((offset%86400)/3600)
  h = h ===0?'00':h <= 9?`0${h}`:h;
  if(offset < 86400 && !largeView){
    return `${h}:${m}:${s}`
  }
  let d = Math.floor(offset/86400);

  d = d ===0?'00':d <= 9?`0${d}`:d;

  if(largeView){
    return `${d} Days :${h}:${m}:${s}`
  }
  return `${d}:${h}:${m}:${s}`
}

const getRemainigTime = (ms)=>{
  let offset = Math.ceil((ms - Date.now())/1000);
  if(offset < 0) {return "0s"};

  let s = offset%60;
  s = s ===0?'00':s <= 9?`0${s}`:s;
  if(offset < 60 ){
    return `${s}s`
  }
  let m = Math.floor((offset%3600)/60)
  m = m ===0?'00':m <= 9?`0${m}`:m;
  if(offset < 3600){
    return `${m}m:${s}s`
  }
  let h = Math.floor((offset%86400)/3600)
  h = h ===0?'00':h <= 9?`0${h}`:h;
  if(offset < 86400){
    return `${h}h:${m}m:${s}s`
  }
  let d = Math.floor(offset/86400);

  d = d ===0?'00':d <= 9?`0${d}`:d;

  return `${d}d:${h}h:${m}m:${s}s`
}

const getTimeAgo = (ms)=>{
  if(Date.now() <= ms) {return "now"};
  let offset = (Date.now() - ms)/1000;
  let d = Math.floor(offset/86400);
  if(d){
    return `${d}day ago`
  }
  let h = Math.floor((offset%86400)/3600)
  if(h){
    return `${h}hours ago`
  }

  let m = Math.floor((offset%3600)/60)
  if(m){
    return `${m}min ago`
  }

  let s = Math.floor(offset%60);
    return `${s}sec ago`;
}

const getTimeInGMT = (timestamp)=>{
  let _date = new Date(timestamp)
  let _offset  = _date.getTimezoneOffset(), _o = Math.abs(_offset);;
  return `${_date.toLocaleString()} GMT${_offset <= 0?"+":"-"}${("00" + Math.floor(_o / 60)).slice(-2)}:${("00" + (_o % 60)).slice(-2)}`;
}
/******************************************************************/

/***********************************|
|        Input sanitizer            |
|__________________________________*/

const inputSafe = (val,decimal = 6) => {
  decimal = decimal >6?6:decimal;
  val = parseFloat(val);
   if(isNaN(val) || val < 0)return '';
   if(val === 0) return val;
   return Math.floor(val * (10 ** decimal))/(10 ** decimal);
}

const inputNumber = (val,decimal = 6) => {
  decimal = decimal >6?6:decimal;
  // val = parseFloat(val);

   if(isNaN(val) || val < 0)return '';
   if(val === 0) return val;
   let ret = Math.floor(val * (10 ** decimal))/(10 ** decimal);
   return ret !== parseFloat(val)?ret:val;
}
/******************************************************************/

/***********************************|
|        String validator           |
|__________________________________*/
const validateName = (name,min=3,max=21) =>{
  if(!name) return true;
  let regex = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/);
  if(name.length >= min && name.length <= max && regex.test(name)) return true;
  return false;
}
const validateUserame = (name,min=3,max=21) =>{
  let regex = new RegExp(/^(?=[a-zA-Z0-9])+(?!.*[_.]$)(?!.*?[._]{2})[a-zA-Z0-9_.]+$/);
  if(name.length >= min && name.length <= max && regex.test(name)) return true;
  return false;
}
const validateDescription = (value,min=0,max=250) =>{
  //(?!.*[,-_\s]$) => not allowed at end
  // (?!.*[,-_]{2}) => not allow consecutive pattern
  // if(!min && !name.length) return true;
  // let regex = new RegExp(/^[a-zA-Z]+(?!.*[,-_\s]$)(?!.*[,-_]{2})(?!.*[-_\s]{2})([ A-Za-z0-9,.-_]+)*$/);
  // if(name.length >= min && name.length <= max && regex.test(name)) return true;
  if(value.length >= min && value.length <= max) return true;

  return false;
}

const validateSocial = (value,min=5,max=100) =>{
  if(value.length >= min && value.length <= max) return true;
  return false;
}

const validateUrl = (value,min=3,max=200) =>{
  let expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  let regex = new RegExp(expression);
  if(value.length >= min && value.length <= max && regex.test(value)) return true;
  return false;
}
const tronscanAddress = (address) =>{
  return `https://tronscan.org/#/address/${address}`
}
const tronscanHash = (hash) =>{
  return `https://tronscan.org/#/transaction/${hash}`
}

/******************************************************************/
/******************************************************************/

/***********************************|
|        Input sanitizer            |
|__________________________________*/

const fileSize = (val) => {
  val = parseFloat(val);
   if(isNaN(val) || val <= 0)return '0 KB';
   let _size = val/1024;
   if(_size < 1) return "0 KB";
   if(_size < 1024) return `${Math.floor(_size*10)/10} KB`
   return `${Math.floor(_size/10.24)/100} MB`
}
/******************************************************************/

/***********************************|
|       currency converter          |
|__________________________________*/

const tokens = {
  "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb" : {
    symbol:"TRX",
    name:"TRON",
    decimals:6,
    min:5,
    img:"/asset/img/trx-sm.png",
  },
  "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t" : {
    symbol:"USDT",
    name:"Tether USD",
    decimals:6,
    img:"/asset/img/usdt-sm.png",
    min:1,
  },
  "TTroZqb95vmsw4kppupQ8tVEzkNDDP2bcG" : {
    symbol:"KTY",
    name:"KRAFTLY",
    decimals:6,
    img:"/asset/img/kty-sm.png",
    min:50,
  },
  "TFczxzPhnThNSqr5by8tvxsdCFRRz6cPNq" : {
    symbol:"NFT",
    name:"ApeNft",
    decimals:6,
    img:"/asset/img/apenft-sm.png",
    min:1e5,
  },
}


const minBid = (address) => {
  if(!address) return 5;
  return tokens[address]?tokens[address].min:5;
}

const getCurrencyName = (address) => {
  if(!address) return "TRX";
  return tokens[address]?tokens[address].symbol:"TRX";
}
const getTokenInfo = (address) => {
  if(!address || !tokens[address]) return tokens["T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"];
  return tokens[address]
}

const getAllTokens = () => tokens;


const nfts = {
  "TGsDs5v2KosDx2fqoQmSC5zVsjrQARMc4U" : {
    symbol:"KNFT",
    name:"KRAFTLY",
    img:"/asset/img/kty-sm.png",
  },
}

const getNftsAddressInfo = (address) => {
  if(!address || !nfts[address]) return nfts["TGsDs5v2KosDx2fqoQmSC5zVsjrQARMc4U"];
  return nfts[address]
}

const getAllNfts = () => nfts;

/******************************************************************/


const Utils = {

  NftContract : 'TGsDs5v2KosDx2fqoQmSC5zVsjrQARMc4U',
  ProxyContract: 'TPttyUhyT8GcvgPPnjf8JGmNGT8QfvVeN5',
  MintContract : 'TB5sJDKdjr1yAaHbTRqDrgNEfwKeAByeRY',
  marketContract : 'TU5Su9LF6VrDN7kCwQuedpp3Yw16HLV7yG',
  KTYContract : 'TTroZqb95vmsw4kppupQ8tVEzkNDDP2bcG',
  BlindContract : 'THwvzZLh6KLdNirW4MJVYYrYQzfqaDTsEY',
  BlindProxy : 'TEKYVL4pAJy16HyhDkrTaMkojR3doRHh2g',
  KnftCollection : 'TRHV5wRC1RTG1kSkPAY1RHmyXihoJCKDn3',
  // MarketInregrator : "TJoFV3L4YtFS8oAqMhudHEnaVNbDb4obTr",
  MarketInregrator : "TTZJjVKNa8jSkMABAN3dpkU52kpN14bAgu",

  Zero_address: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",

  setToastAlert,getToastAlert,
  viewUsdtRate,setUsdtRate,

  creator,viewCreator,viewUser,setCreator,getCreator,updateLike,updateFollow,

  wait,
  getImage,getDp,getBanner,getCidBaseUrl,

  viewOwner,shortName,ellipsisName,ellipsisNftName,shortAddress,shortHash,

  floor,ceil,preety,largePreety,

  getTimeOffset,getRemainigTime,getTimeAgo,getTimeInGMT,

  inputSafe,inputNumber,

  validateName,validateUserame,validateDescription,validateSocial,validateUrl,

  fileSize,

  tronscanAddress,tronscanHash,

  minBid,
  getCurrencyName,getTokenInfo,getAllTokens,getNftsAddressInfo,getAllNfts,

}


export default Utils
