import React, {Fragment} from 'react'
import styled from 'styled-components'
// import {Link} from 'react-router-dom'
import Data from "./data.js"
import {Utils} from "../../helper"
// import api from "../../api"
import $ from "jquery";
// import {ReactComponent as OfferIcon} from '../../images/offer.svg';

const SubmitBtn = styled.button.attrs(props=>({
  className: `market__btn ${props.fullView?"market__btn__full":""}`,
  type:"button"
}))`
  background-color:${props =>props.red?"red":props.green?"green":"#6164ff"};
  &:hover {
    background-color: #222227;
  }
`
/*
* MakeAnOffer
* it will get opened as popup
* when user want to make a offer to a NFT
* if success, page reload
* if failed page stay same
*/
class MakeAnOffer extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isProcess:false,
        value:"",
        currencyId:this.props.data.currencyId || "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        balance:0,
        txnLayer:0,
      }
    }
  componentDidMount(){
    const{price,bid,time} = this.props.data

    let _min = bid?bid:time?price:0;
    _min = Utils.floor(_min/1e6);
    if (_min) {
      this.setState({value:_min})
    }
    this.fetchBalance()
  }
  fetchBalance = async() =>{
    try {
      let{currencyId} = this.state;

      let address = Utils.viewCreator();
      this.setState({balance:0})
      if(!address) return
      if(currencyId === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"){
        let balance = await window.tronWeb.trx.getUnconfirmedBalance(address);
        balance = Utils.floor(balance);
        this.setState({balance})
      }else {
        let tokenInstance = await window.tronWeb.contract().at(currencyId);
        let balance = await tokenInstance.balanceOf(address).call();
        balance = Utils.floor(window.tronWeb.toDecimal(balance._hex));
        this.setState({balance})
      }
    } catch (e) {
      this.setState({balance:0})
    }
  }

  closePopUp = () => {
    if(!this.state.isProcess){
      this.props.close()
    }
  }
  handleInput = (e)=>{
    let value = Utils.inputSafe(e.target.value);
    this.setState({value})
  }
  handleCurrency = (e)=>{
    const{bid} = this.props.data;
    if(!!bid) return console.log("bid available");
    this.setState({currencyId:e.target.value},this.fetchBalance)
  }

  offreInTrx = async() =>{
    try {
      let{value,currencyId} = this.state
      let {tokenId,market} = this.props.data;

      value = Utils.ceil(value * 1.025 *  1e6,0);

        let options = {feeLimit:500 * 1e6,callValue:value}
        this.setState({txnLayer:1})
// console.log(tokenId,currencyId);
        let marketInstance = await window.tronWeb.contract(Data.abi,market)
        await marketInstance.PutOffer(0,tokenId,currencyId).send(options)

        Utils.setToastAlert("Confirming transaction","success");
        this.setState({isProcess:false,txnLayer:2})
        this.props.reFetch()

    } catch (err) {
      this.setState({isProcess:false,txnLayer:0})
      if(err === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to offer on NFT","error")
      }
    }

  }
  offerInToken = async() =>{
    try {
      let{value,currencyId} = this.state
      let {tokenId,market} = this.props.data;
      let address = Utils.viewCreator();

      value = Utils.ceil(value * 1.025 *  1e6,0);

        let tokenInstance = await window.tronWeb.contract().at(currencyId);
        let allowed = await tokenInstance.allowance(address,market).call();
        // console.log(allowed);
        allowed = allowed.remaining?allowed.remaining:allowed;

        await new Promise(res=>{
          if(window.tronWeb.toDecimal(allowed._hex) > value){
            res()
          }else {
             let _allowing =  tokenInstance.approve(market, value * 100).send({
              feeLimit:500 * 1e6,
              // shouldPollResponse:true
            });
            res(_allowing)
          }
        })

        let options = {feeLimit:500 * 1e6}
        this.setState({txnLayer:1})

        let marketInstance = await window.tronWeb.contract(Data.abi,market)
        await marketInstance.PutOffer(value,tokenId,currencyId).send(options)
        // console.log(data);
        Utils.setToastAlert("Confirming transaction","success");
        this.setState({isProcess:false,txnLayer:2})
        this.props.reFetch()
    } catch (err) {
      console.log(err);
      this.setState({isProcess:false,txnLayer:0})
      if(err === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to offer on NFT","error")
      }
    }
  }
  handlePlaceBid = () =>{

      let{value,balance,isProcess,currencyId} = this.state
      let {bidder,bid} = this.props.data;
      let address = Utils.viewCreator();
      if(!address) return $(".header__action-btn--signin").click();
      if(bidder === address) return Utils.setToastAlert("looks like, You are highest bidder");

      if(!value) return Utils.setToastAlert('Offer amount can not be zero');
      if(value < Data.minBid(currencyId)) return Utils.setToastAlert(`Minimum offer limit ${ Data.minBid(currencyId)} ${Utils.getCurrencyName(currencyId)}`);

      value = Utils.ceil(value * 1.025 *  1e6,0);

      if(bid >= value) return Utils.setToastAlert("Please offer more than current highest offer");
      if(balance < value) return Utils.setToastAlert('Insufficient account balance');



      if(isProcess) return Utils.setToastAlert("Previous request is in process");


      this.setState({isProcess:true})
      if(currencyId === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"){
        this.offreInTrx()
      }else {
        this.offerInToken()
      }
  }
  render() {
    let{value,balance,currencyId,isProcess,txnLayer} = this.state
    let {price,time,bid,isPopUp,fullView} = this.props.data;
    let _min = bid?bid:time?price:0;
    let minBidLimit = Data.minBid(currencyId);
    minBidLimit *= 1e6;
    _min = _min >= minBidLimit?_min:minBidLimit;

    let fee = value * 0.025;

    let currencySymbol = Utils.getCurrencyName(currencyId);

    return (
      <Fragment>
        <SubmitBtn fullView={fullView} onClick={this.props.open}>
          Make Offer
          {isProcess && <div className="ball__pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>}
        </SubmitBtn>

      {!!isPopUp && <div className="place-bid-popup">
      <div className="zoom-anim-dialog modal modal--form">
        <button className="popup_close" onClick={this.closePopUp}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>
        </button>

        <h4 className="sign__title">Make your offer</h4>

        <div className="sign__group sign__group--row">
          <label className="sign__label" htmlFor="placebid">Amount</label>
          <div className="mode__bid_info">Balance : <p> {Utils.preety.floor(balance/1e6,2)} </p> {currencySymbol}</div>
          <input
            id="placebid"
            type="number"
            name="placebid"
            className="sign__input"
            placeholder="Enter offer amount"
            value={value}
            onChange={this.handleInput}
            />
        </div>
        <div className="sign__group sign__group--row">
          <label className="sign__label" htmlFor="currencyId">Currency</label>
          <select
            id="currencyId"
            name="currencyId"
            className="sign__select"
            onChange={this.handleCurrency}
            value={currencyId}
            >
            <option value="T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb" >TRX</option>
            <option value="TTroZqb95vmsw4kppupQ8tVEzkNDDP2bcG" >KTY</option>
            <option value="TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t" >USDT</option>
            <option value="TFczxzPhnThNSqr5by8tvxsdCFRRz6cPNq" >ApeNft</option>

          </select>
        </div>
        <div className="Bid__additional_info"><span>Must be at least {Utils.preety(_min/1e6,2)} {currencySymbol}</span></div>
        <div className="Bid__additional_info"><span>Service fee</span><p>{Utils.preety.unsafe(fee)} {currencySymbol}</p></div>
        <div className="Bid__additional_info"><span>You will pay</span><p>{Utils.preety.unsafe(fee+value)} {currencySymbol}</p></div>

          {isProcess && <div className="transaction__process">
            <br/>
            <div className = {txnLayer > 0?"processing__bar active":"processing__bar"}>
              <span></span> {txnLayer > 0?`${currencySymbol} Approved`:`Approving ${currencySymbol}`}
            </div>
            <p>.</p>
            <p>.</p>
            <p>.</p>
            <div className = {txnLayer > 1?"processing__bar active":"processing__bar"}>
              <span></span> {txnLayer > 1?"Offer placed":"Posting offer"}
            </div>
          </div>}

        <button className="sign__btn" onClick={this.handlePlaceBid}>
          Submit offer
          {isProcess && <div className="ball__pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>}
        </button>
        <p className="alert__msg">
           ** NOTE,
          {!bid && <span>This offer can be cancelled by you anytime</span>}
          {!!bid && <span>Offer will be locked for 24 hours</span>}

        </p>
      </div>
    </div>}
  </Fragment>
  )
  }
}



/*
* WithdrawBid
* it will cancel auction if created by user
* and meet necessary condition
* if success, page reload
* if failed page stay same
*/

class WithdrawBid extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isProcess:false,
      }
    }
  handleRemoveBid = async() =>{

    try {
      let {tokenId,bidder,market,highestBidTime} = this.props.data;
      highestBidTime =( Math.floor(highestBidTime) + 86400) * 1000; // 1day lock

      let {isProcess} = this.state;
      let address = Utils.viewCreator();
      if(!address) return $(".header__action-btn--signin").click();;
      if(bidder !== address) return Utils.setToastAlert("looks like, You are not highest bidder");
      if(highestBidTime > Date.now()) return Utils.setToastAlert(`Bid can be removed after ${Utils.getRemainigTime(highestBidTime)}`);
      if(isProcess) return Utils.setToastAlert("Previous request is in process");

      this.setState({isProcess:true})

      let options = {
        feeLimit:300 * 1e6,
        callValue:0,
        // shouldPollResponse:true,
        }
        let marketInstance = await window.tronWeb.contract(Data.abi,market);
        await marketInstance.removeBid(tokenId).send(options);
        // console.log(txid);
        Utils.setToastAlert("Confirming transaction","success");
        this.setState({isProcess:false})
        this.props.reFetch()


    } catch (err) {
      this.setState({isProcess:false})
      console.log(err);
      if(err === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to withdraw offer","error")
      }

    }
  }
  render() {
    let {isProcess} = this.state;
    let {bid,currencyId,fullView} = this.props.data;
    bid /= (1.025);
    return (
      <SubmitBtn fullView={fullView} red onClick={this.handleRemoveBid}>
        Withdraw offer of {Utils.preety.floor(bid/1e6,2)} {Utils.getCurrencyName(currencyId)}
        {isProcess && <div className="ball__pulse">
          <div></div>
          <div></div>
          <div></div>
        </div>}
      </SubmitBtn>
      )
  }
}


/*
* ModelBid
* it will get opened as popup
* when user want to place a bid on auction
* if success, page reload
* if failed page stay same
*/
class ModelBid extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isProcess:false,
        value:"",
        balance:0,
        txnLayer:0,
      }
    }
  componentDidMount(){
    const{price,bid,time} = this.props.data;
    let _min = bid?bid:time?price:0;
    _min = Utils.floor(_min/1e6);
    if (_min) {
      this.setState({value:_min})
    }
    this.fetchBalance()
  }
  fetchBalance = async() =>{
    try {
      let{currencyId} = this.props.data;

      let address = Utils.viewCreator();
      this.setState({balance:0})
      if(!address) return
      if(currencyId === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"){
        let balance = await window.tronWeb.trx.getUnconfirmedBalance(address);
        balance = Utils.floor(balance);
        this.setState({balance})
      }else {
        let tokenInstance = await window.tronWeb.contract().at(currencyId);
        let balance = await tokenInstance.balanceOf(address).call();
        balance = Utils.floor(window.tronWeb.toDecimal(balance._hex));
        this.setState({balance})
      }
    } catch (e) {
      this.setState({balance:0})
    }
  }

  closePopUp = () => {
    if(!this.state.isProcess){
      this.props.close()
    }
  }
  handleInput = (e)=>{
    let value = Utils.inputSafe(e.target.value);
    this.setState({value})
  }


  bidInTrx = async() =>{
    try {
      let{value} = this.state
      let {tokenId,currencyId,market} = this.props.data;

      value = Utils.ceil(value * 1.025 *  1e6,0);

        let options = {feeLimit:500 * 1e6,callValue:value}
        this.setState({txnLayer:1})

        let marketInstance = await window.tronWeb.contract(Data.abi,market)
        await marketInstance.PutBid(0,tokenId,currencyId).send(options)

        Utils.setToastAlert("Confirming transaction","success");
        this.setState({isProcess:false,txnLayer:2})
        this.props.reFetch()

    } catch (err) {
      this.setState({isProcess:false,txnLayer:0})
      if(err === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to bid on auction","error")
      }
    }

  }
  bidInToken = async() =>{
    try {
      let{value} = this.state
      let {tokenId,currencyId,market} = this.props.data;
      let address = Utils.viewCreator();

      value = Utils.ceil(value * 1.025 *  1e6,0);

        let tokenInstance = await window.tronWeb.contract().at(currencyId);
        let allowed = await tokenInstance.allowance(address,market).call();
        // console.log(allowed);
        allowed = allowed.remaining?allowed.remaining:allowed;

        await new Promise(res=>{
          if(window.tronWeb.toDecimal(allowed._hex) > value){
            res()
          }else {
             let _allowing =  tokenInstance.approve(market, value * 100).send({
              feeLimit:500 * 1e6,
              // shouldPollResponse:true
            });
            res(_allowing)
          }
        })

        let options = {feeLimit:500 * 1e6}
        this.setState({txnLayer:1})

        let marketInstance = await window.tronWeb.contract(Data.abi,market)
        await marketInstance.PutBid(value,tokenId,currencyId).send(options)
        // console.log(data);
        Utils.setToastAlert("Confirming transaction","success");
        this.setState({isProcess:false,txnLayer:2})
        this.props.reFetch()
    } catch (err) {
      console.log(err);
      this.setState({isProcess:false,txnLayer:0})
      if(err === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to bid on auction","error")
      }
    }
  }
  handlePlaceBid = () =>{

      let{value,balance,isProcess} = this.state
      let {bidder,time,bid,price,currencyId} = this.props.data;
      let address = Utils.viewCreator();
      if(!address) return $(".header__action-btn--signin").click();
      if(bidder === address) return Utils.setToastAlert("looks like, You are highest bidder");

      if(!value) return Utils.setToastAlert('Bid amount can not be zero');
      if(value < Data.minBid(currencyId)) return Utils.setToastAlert(`Minimum bid limit ${ Data.minBid(currencyId)} ${Utils.getCurrencyName(currencyId)}`);


      value = Utils.ceil(value * 1.025 *  1e6,0);
      if(bid >= value) return Utils.setToastAlert("Place bid of more than current highest bid");
      if(value >= price && !time) return Utils.setToastAlert("Auction can be directly bought at lower bid");
      if(value < price && !!time) return Utils.setToastAlert(`Minimum bid ${Utils.preety.unsafe(price/1e6)} ${ Utils.getCurrencyName(currencyId)}`);

      if(balance < value) return Utils.setToastAlert('Insufficient account balance');



      if(isProcess) return Utils.setToastAlert("Previous request is in process");


      this.setState({isProcess:true})
      if(currencyId === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"){
        this.bidInTrx()
      }else {
        this.bidInToken()
      }
  }

  render() {
    let{value,balance,isProcess,txnLayer} = this.state
    let {price,time,bid,fullView,isPopUp,currencyId,market} = this.props.data;
    let _min = bid?bid:!!time?price:0;
    let minBidLimit = Data.minBid(currencyId);
    minBidLimit *= 1e6;
    _min = _min >= minBidLimit?_min:minBidLimit;
    // console.log(bid);

    let fee = value * 0.025;
    let currencySymbol = Utils.getCurrencyName(currencyId);


    return (
      <Fragment>
        <SubmitBtn fullView={fullView} onClick={this.props.open} >
          Place a Bid
          {isProcess && <div className="ball__pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>}
        </SubmitBtn>
      {!!isPopUp && <div className="place-bid-popup">
      <div className="zoom-anim-dialog modal modal--form">
        <button className="popup_close" onClick={this.closePopUp}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>
        </button>

        <h4 className="sign__title">Place a bid</h4>

        <div className="sign__group sign__group--row">
          <label className="sign__label" htmlFor="placebid">Your bid</label>
          <div className="mode__bid_info">Balance : <p>{Utils.preety.floor(balance/1e6,2)}</p> {Utils.getCurrencyName(currencyId)}</div>
          <input
            id="placebid"
            type="number"
            name="placebid"
            className="sign__input"
            placeholder="Enter bid amount"
            value={value}
            onChange={this.handleInput}
            />
        </div>
        <div className="Bid__additional_info"><span>Must be at least {Utils.preety(_min/1e6,2)} {currencySymbol}</span></div>
        <div className="Bid__additional_info"><span>Service fee</span><p>{Utils.preety.unsafe(fee)} {currencySymbol}</p></div>
        <div className="Bid__additional_info"><span>You will pay</span><p>{Utils.preety.unsafe(fee+value)} {currencySymbol}</p></div>

          {isProcess && <div className="transaction__process">
            <br/>
            <div className = {txnLayer > 0?"processing__bar active":"processing__bar"}>
              <span></span> {txnLayer > 0?`${currencySymbol} Approved`:`Approving ${currencySymbol}`}
            </div>
            <p>.</p>
            <p>.</p>
            <p>.</p>
            <div className = {txnLayer > 1?"processing__bar active":"processing__bar"}>
              <span></span> {txnLayer > 1?"Bid placed":"Posting bid"}
            </div>
          </div>}
        <button className="sign__btn" onClick={this.handlePlaceBid} disabled={!market}>
          Take a bid
          {isProcess && <div className="ball__pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>}
        </button>
        <p className="alert__msg">
           ** NOTE,
          {!bid && <span>This offer can be cancelled by you anytime</span>}
          {!!bid && <span>Offer will be locked for 24 hours</span>}

        </p>
      </div>

    </div>}
    </Fragment>
  )
  }
}




/*
* AcceptOffer
* it will AcceptAuction auction if created by user
 either fixed or unlimited auction or timed auction after bidding period
 if bid more than min, released by both else only by creator
* and meet necessary condition
* if success, page reload
* if failed page stay same
*/

class AcceptOffer extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isProcess:false,
        isPopUp:false,
        txnLayer:0,
      }
    }

  handleAcceptOffer = async() =>{


    try {
      let {tokenId,owner,nftAddress,market,time} = this.props.data;
      let {isProcess} = this.state;
      let timeNow = Math.floor(Date.now()/1000);

      let address = Utils.viewCreator();
      if(!address) return $(".header__action-btn--signin").click();

      if(time === 0 && owner !== address) return Utils.setToastAlert("looks like, You are not owner of NFT");
      if(!time  && timeNow < time ) return Utils.setToastAlert("Bidding window still open");

      if(isProcess) return Utils.setToastAlert("Previous request is in process");
      this.setState({isProcess:true})

      let NftInstance = await window.tronWeb.contract().at(nftAddress);
      let allowed = await NftInstance.isApprovedForAll(address,market).call();
      await new Promise( res =>{
        if(allowed){
          res()
        }else {
          let result = NftInstance.setApprovalForAll(market, true).send({
            feeLimit:150 * 1e6,
          });
          res(result)
        }
      })
      this.setState({txnLayer:1})


      let options = {feeLimit:500 * 1e6}

      let marketInstance = await window.tronWeb.contract(Data.abi,market)
      await marketInstance.releaseAuction(tokenId).send(options);

      Utils.setToastAlert("Confirming transaction","success");
      this.setState({isProcess:false,txnLayer:2})
      this.props.reFetch()

    } catch (err) {
      this.setState({isProcess:false,txnLayer:0})
      console.log(err);

      if(err === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to accept offer","error")
      }
    }
  }
  closePopUp = () => {
    if(!this.state.isProcess){
      this.setState({isPopUp:false})
    }
  }
  handlePopUp =() =>{
    // let {isPopUp} = this.state;
    this.props.close()
    this.setState({isPopUp:true})

  }

  render() {
    let {isProcess,txnLayer,isPopUp} = this.state;
    let {fullView,bid,currencyId,time,market} = this.props.data;
    bid /= (1.025);
    // let currencySymbol = Utils.getCurrencyName(currencyId);

    let timeNow = Math.floor(Date.now()/1000);
    let btnName  = !time || timeNow < time?`Accept for - ${Utils.preety.ceil(bid/1e6,2)} ${Utils.getCurrencyName(currencyId)}`:"Release auction";
    return (
      <Fragment>
        <SubmitBtn fullView={fullView} green onClick={this.handlePopUp} >
          {!isProcess && btnName}

          {isProcess && <div className="ball__pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>}
        </SubmitBtn>
      {!!isPopUp && <div className="place-bid-popup">
      <div className="zoom-anim-dialog modal modal--form">
        <button className="popup_close" onClick={this.closePopUp}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>
        </button>

        <h4 className="sign__title">Accepting Offer</h4>
          <div className="transaction__process">
            <div className = {txnLayer > 0?"processing__bar active":"processing__bar"}>
              <span></span> {txnLayer > 0?"NFT Approved":"Approving NFT"}
            </div>
            <p>.</p>
            <p>.</p>
            <p>.</p>
            <div className = {txnLayer > 1?"processing__bar active":"processing__bar"}>
              <span></span> {txnLayer > 1?"NFT Released":"NFT Releasing"}
            </div>
          </div>
          <SubmitBtn fullView={true} onClick={this.handleAcceptOffer} disabled={!market}>
            {btnName}

            {isProcess && <div className="ball__pulse">
              <div></div>
              <div></div>
              <div></div>
            </div>}
          </SubmitBtn>
      </div>
    </div>}
    </Fragment>

      )
  }
}


/*
* CancelAuction
* it will cancel auction if created by user
* and meet necessary condition
* if success, page reload
* if failed page stay same
*/

class CancelAuction extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isProcess:false,
      }
    }
  handleCancelAuction = async() =>{

    try {
      let {tokenId,owner,market} = this.props.data;
      let {isProcess} = this.state;
      let address = Utils.viewCreator();
      if(!address) return $(".header__action-btn--signin").click();;
      if(owner !== address) return Utils.setToastAlert("looks like, current account is not owner of NFT");
      if(isProcess) return Utils.setToastAlert("Previous request is in process");

      this.setState({isProcess:true})

      let options = {
        feeLimit:300 * 1e6,
        callValue:0,
        // shouldPollResponse:true,
        }
        let marketInstance = await window.tronWeb.contract(Data.abi,market);
        await marketInstance.cancelAuction(tokenId).send(options);
        Utils.setToastAlert("Confirming transaction","success");
        this.setState({isProcess:false})
        this.props.reFetch()


    } catch (err) {
      this.setState({isProcess:false})
      console.log(err);
      if(err === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to cancel auction","error")
      }

    }
  }
  render() {
    let {isProcess} = this.state;
    let {fullView,market} = this.props.data;
    return (
      <SubmitBtn fullView={fullView} red onClick={this.handleCancelAuction} disabled={!market}>
        Cancel
        {isProcess && <div className="ball__pulse">
          <div></div>
          <div></div>
          <div></div>
        </div>}
      </SubmitBtn>
      )
  }
}
/*
* AcceptAuction
* it will AcceptAuction auction if created by user
 either fixed or unlimited auction or timed auction after bidding period
 if bid more than min, released by both else only by creator
* and meet necessary condition
* if success, page reload
* if failed page stay same
*/

class AcceptAuction extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isProcess:false,
      }
    }

  handleAuctionAccept = async() =>{


    try {
      let {tokenId} = this.props.data;
      let {isProcess} = this.state;

      let address = Utils.viewCreator();
      if(!address) return $(".header__action-btn--signin").click();

      if(isProcess) return Utils.setToastAlert("Previous request is in process");

      this.setState({isProcess:true})

      let instanceMarket = await window.tronWeb.contract().at(Utils.marketContract);
      await instanceMarket.releaseAuction(tokenId).send({
        feeLimit:300 * 1e6,
        callValue:0,
        // shouldPollResponse:true
      })
      Utils.setToastAlert("Confirming transaction","success");
      this.setState({isProcess:false})
      this.props.reFetch()

    } catch (err) {
      this.setState({isProcess:false})
      console.log(err);

      if(err === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to accept auction","error")
      }
    }
  }
  render() {
    let {isProcess} = this.state;
    let {market,fullView} = this.props.data;

    return (
      <SubmitBtn fullView={fullView} green onClick={this.handleAuctionAccept} disabled={!market}>
        Accept
        {isProcess && <div className="ball__pulse">
          <div></div>
          <div></div>
          <div></div>
        </div>}
      </SubmitBtn>
      )
  }
}
/*
* BuyFixedNft
* it will directly by a fixed price auction
* if success, page reload
* if failed page stay same
*/

class BuyFixedNft extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isProcess:false,
        balance:0,
      }
    }
    async componentDidMount(){
      try {
        let{currencyId} = this.props.data;

        let address = Utils.viewCreator();
        this.setState({balance:0})
        if(!address) return
        if(currencyId === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"){
          let balance = await window.tronWeb.trx.getUnconfirmedBalance(address);
          balance = Utils.floor(balance);
          this.setState({balance})
        }else {
          let tokenInstance = await window.tronWeb.contract().at(currencyId);
          let balance = await tokenInstance.balanceOf(address).call();
          balance = Utils.floor(window.tronWeb.toDecimal(balance._hex));
          this.setState({balance})
        }
      } catch (e) {
        this.setState({balance:0})
      }
    }


    buyInTrx = async() =>{
      try {
        // let{isProcess} = this.state
        let {tokenId,price,market} = this.props.data;

        price = Utils.ceil(price *  1.025,0);

          let options = {feeLimit:500 * 1e6,callValue:price}

          let marketInstance = await window.tronWeb.contract(Data.abi,market)
          await marketInstance.buyFixedRateNft(tokenId,0).send(options)

          Utils.setToastAlert("Confirming transaction","success");
          this.setState({isProcess:false})
          this.props.reFetch()

      } catch (err) {
        console.log(err);
        this.setState({isProcess:false})
        if(err === "Confirmation declined by user"){
          Utils.setToastAlert("Confirmation declined","error")
        }else {
          Utils.setToastAlert("Failed to buy auction","error")
        }
      }

    }
    buyInToken = async() =>{
      try {
        // let{isProcess} = this.state
        let {tokenId,price,market,currencyId} = this.props.data;
        let address = Utils.viewCreator();

        price = Utils.ceil(price *  1.025,0);

          let tokenInstance = await window.tronWeb.contract().at(currencyId);
          let allowed = await tokenInstance.allowance(address,market).call();
          // console.log(allowed);
          allowed = allowed.remaining?allowed.remaining:allowed;

          await new Promise(res=>{
            if(window.tronWeb.toDecimal(allowed._hex) > price){
              res()
            }else {
               let _allowing =  tokenInstance.approve(market, price * 100).send({
                feeLimit:500 * 1e6,
                // shouldPollResponse:true
              });
              res(_allowing)
            }
          })

          let options = {feeLimit:500 * 1e6}

          let marketInstance = await window.tronWeb.contract(Data.abi,market)
          await marketInstance.buyFixedRateNft(tokenId,price).send(options)
          // console.log(data);
          Utils.setToastAlert("Confirming transaction","success");
          this.setState({isProcess:false})
          this.props.reFetch()
      } catch (err) {
        console.log(err);
        this.setState({isProcess:false})
        if(err === "Confirmation declined by user"){
          Utils.setToastAlert("Confirmation declined","error")
        }else {
          Utils.setToastAlert("Failed to buy auction","error")
        }
      }
    }
    handleBuyAuction = () =>{

        let{balance,isProcess} = this.state
        let {bidder,price,currencyId} = this.props.data;
        let address = Utils.viewCreator();
        if(!address) return $(".header__action-btn--signin").click();
        if(bidder === address) return Utils.setToastAlert("looks like, You are highest bidder");

        price = Utils.ceil(price *  1.025,0);

        if(balance < price) return Utils.setToastAlert('Insufficient account balance');

        if(isProcess) return Utils.setToastAlert("Previous request is in process");


        this.setState({isProcess:true})
        if(currencyId === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"){
          this.buyInTrx()
        }else {
          this.buyInToken()
        }
    }

  render() {
    let{price,currencyId,market} = this.props.data;
    let {isProcess} = this.state;
    price = Utils.ceil(price *  1.025,0);
    // price = Utils.ceil(price * 40 /39,0);


    return (
      <SubmitBtn fullView={false} onClick={this.handleBuyAuction} disabled={!market}>
        {!isProcess && `Buy - ${Utils.preety.ceil(price/1e6,2)} ${Utils.getCurrencyName(currencyId)}`}
        {isProcess && <div className="ball__pulse">
          <div></div>
          <div></div>
          <div></div>
        </div>}
      </SubmitBtn>
      )
  }
}


/*
* ModelBid
* it will get opened as popup
* when user want to place a bid on auction
* if success, page reload
* if failed page stay same
*/
class InsertAuction extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isProcess:false,
        txnLayer:0,
        time:'',
        price:'',
        currencyId:"T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        auctionType:"fixed"
      }
    }
  closePopUp = () => {
    if(!this.state.isProcess){
      this.props.close()
    }
  }
  handleInput = (e)=>{
    let price = Utils.inputSafe(e.target.value);
    this.setState({price})
  }
  handleInputTime = (e)=>{
    // let time = Utils.inputSafe(e.target.value);
    let time = e.target.value;
    time = new Date(time).getTime();
    console.log(time);
    if(e.target.style.borderColor && time > Date.now()){
      e.target.style.borderColor = "";
    }
    if(time <= Date.now()){
      time = 0;
      e.target.style.borderColor = "red";
    }
    this.setState({time})
  }

  handelInsertAuction = async(e) =>{
    let{price,time,auctionType,currencyId,isProcess} = this.state;
    let address = Utils.viewCreator();

    if(!address) return $(".header__action-btn--signin").click();

    // console.log(address);
    let {tokenId,owner,nftAddress,market} = this.props.data;
    time = Math.floor((time - Date.now())/1000)
    try {
      if(!price) return Utils.setToastAlert( auctionType === "timed"?"Please enter minimum bid amound":"Please enter NFT price");

      if(time <= 0 && auctionType === "timed") return Utils.setToastAlert("Please provide auction expiry time");

      if(owner !== address) return Utils.setToastAlert("looks like, current account is not owner of NFT");
      if(isProcess) return Utils.setToastAlert("Previous request is in process");

      this.setState({isProcess:true})
      e.target.innerText = "Checking NFT Approval";

      let NftInstance = await window.tronWeb.contract().at(nftAddress);
      let allowed = await NftInstance.isApprovedForAll(address,market).call();
      await new Promise( res =>{
        if(allowed){
          res()
        }else {
          e.target.innerText = "Approving NFT";
          let result = NftInstance.setApprovalForAll(market, true).send({
            feeLimit:150 * 1e6,
          });
          res(result)
        }
      })
      this.setState({txnLayer:1})
      e.target.innerText = "Publishing on market place";

      let options = {
        feeLimit:300 * 1e6,
        callValue:0,
        // shouldPollResponse:true
      }
      let marketInstance = await window.tronWeb.contract(Data.abi,market)

        await new Promise(res =>{
             if (auctionType === "timed") {
              price *= 1e6;
              let txnObj = marketInstance.timedAuction(tokenId,price,time,currencyId).send(options)
              res(txnObj)
            }else {
              price *= 1e6;
              let txnObj = marketInstance.fixedPrice(tokenId,price,currencyId).send(options)
              res(txnObj)
            }
        })
        // console.log(txid);
        this.setState({txnLayer:2,isProcess:false})
        e.target.innerText = "Published on market place";
        Utils.setToastAlert("Confirming transaction \n view on tronscan","success");
        this.props.reFetch()

    } catch (err) {
      this.setState({isProcess:false})
      console.log(err);
      e.target.innerText = "Publish on Market";
      if(err === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to list on market place","error")
      }
    }
  }
  handleAuctionType=(auctionType)=>{
    let{isProcess} = this.state;

    if(isProcess) return Utils.setToastAlert("Previous request is in process");
    this.setState({auctionType})
  }
  render() {
    let{price,time,auctionType,txnLayer,isProcess,currencyId} = this.state;
    let {isPopUp,fullView,market} = this.props.data;


    return (
      <Fragment>
      <SubmitBtn fullView={fullView} onClick={this.props.open}>
        Put On Sale
        {isProcess && <div className="ball__pulse">
          <div></div>
          <div></div>
          <div></div>
        </div>}
      </SubmitBtn>

      {!!isPopUp && <div className="place-bid-popup">
      <div className="zoom-anim-dialog modal modal--form">
        <button className="popup_close" onClick={this.closePopUp}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>
        </button>

        <h4 className="sign__title">Publish NFT for sale</h4>
          <div className="col-12">
            <div className="sign__group sign__group--row">

              <ul className="sign__radio sign__radio--single">
                <li>
                  <input id="type1" type="radio" name="type"  onChange={()=>this.handleAuctionType("fixed")} checked={auctionType === "fixed"}/>
                  <label htmlFor="type1">Fixed price</label>
                </li>
                <li>
                  <input id="type2" type="radio" name="type"onChange={()=>this.handleAuctionType("timed")} checked={auctionType === "timed"} />
                  <label htmlFor="type2">Timed auction</label>
                </li>
              </ul>
            </div>
          </div>

          <div className="sign__group sign__group--row">
          <label className="sign__label" htmlFor="placebid">
            {auctionType === "fixed" ? "Price":"Minimum bid"}
          </label>
          <input
            id="placebid"
            type="number"
            name="placebid"
            className="sign__input"
            placeholder={auctionType === "fixed" ? "Enter Price for NFT":"Enter Minimum bid"}
            value={price}
            onChange={this.handleInput}
            />
        </div>
          <div className="sign__group sign__group--row">
            <label className="sign__label" htmlFor="Currency">Currency</label>
            <select
              id="Currencye"
              name="Currencye"
              className="sign__select"
              onChange={(e)=>this.setState({currencyId:e.target.value})}
              value={currencyId}
              >
              <option value="T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb" >TRX</option>
              <option value="TTroZqb95vmsw4kppupQ8tVEzkNDDP2bcG" >KTY</option>
              <option value="TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t" >USDT</option>
              <option value="TFczxzPhnThNSqr5by8tvxsdCFRRz6cPNq" >ApeNft</option>

            </select>
          </div>

        {auctionType === "timed" && <div className="sign__group sign__group--row">
            <label className="sign__label" htmlFor="placebid">Expiration Time</label>
            <input
              id="placebid"
              type="datetime-local"
              name="placebid"
              className="sign__input"
              placeholder="Enter auction start time"
              onChange={this.handleInputTime}
              />
            {!!time && <span className="sign__text sign__text--small">
            Expiration Duration { Utils.getTimeOffset(time/1000,true)}
          </span>}
        </div>}

        {isProcess && <div className="transaction__process">
          <div className = {txnLayer > 0?"processing__bar active":"processing__bar"}>
            <span></span> {txnLayer > 0?"NFT Approved":"Approving NFT"}
          </div>
          <p>.</p>
          <p>.</p>
          <p>.</p>
          <div className = {txnLayer > 1?"processing__bar active":"processing__bar"}>
            <span></span> {txnLayer > 1?"Auction published":"Publishing on market"}
          </div>
        </div>}

        <button className="sign__btn" onClick={this.handelInsertAuction} disabled={!market}>Publish on Market</button>
      </div>
    </div>}
  </Fragment>
  )
  }
}




export {
  MakeAnOffer,
  AcceptOffer,
  WithdrawBid,
  ModelBid, //for place bid
  CancelAuction,
  AcceptAuction,
  BuyFixedNft,
  InsertAuction,
}
