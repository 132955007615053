import React , { Fragment }from 'react'
import styled from 'styled-components'
import {Utils,PopUp} from "../../helper"
import api from "../../api"
import $ from "jquery";
import NftFile from "./nft-file-preview.jsx";

import {MarketBodyCreate} from "../nft-details/market-body"
import {ReactComponent as HandArrow} from '../../images/hand-up.svg';
import tronWeb from "../../tronweb"

const SubmitBtn = styled.button.attrs(props=>({
  className: `market__btn ${props.fullView?"market__btn__full":""}`,
}))`
  &&{background:${props =>!!props.red?"#600":!!props.green?"#050":"#6164ff"};color:#ddd;}
  &:hover {
    background-color: #222227;
  }
`

class NftDetailsPreview extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        tokenId:"",
        isProcess:false,
        isUploading:false,
        loaded:0,
        total:0,
        fees:50*1e6,
        owner:"",
      }
  }
  componentDidMount (){
    let{updateId,nftAddress} = this.props.data;

    if(!!this.props.data.address){
      if(!!updateId && this.props.data.nftAddress === nftAddress){
        this.fetchNftOwner(updateId,nftAddress);
      }else {
        this.fetchMintingFees();
      }
    }

  }

  fetchMintingFees = async()=>{
    try {
      let {address} = this.props.data;
      if(!address) return this.setState({fees:25*1e6});
      let instance = await window.tronWeb.contract().at(Utils.MarketInregrator)
      let result = await instance.getSingleMintFee(address).call();
      result = window.tronWeb.toDecimal(result._hex?result._hex:result);
      this.setState({fees:result})
    } catch (e) {
      console.log(e);
      this.setState({fees:25*1e6})
    }
  }

  fetchNftOwner = async(tokenId,nftAddress) =>{
    try {
      let {address} = this.props.data;
      let owner = await new Promise(async(resolve)=>{
        if (nftAddress === Utils.NftContract) {
          let instanceNFT = await tronWeb.contract().at(Utils.NftContract);
          let _owner = await instanceNFT.ownerOf(tokenId).call();
          resolve(_owner)
        }else {
          let instance = await tronWeb.contract().at(Utils.MarketInregrator);
          let _owner = await instance.getSingleMinter(tokenId,nftAddress).call();
          resolve(_owner)
        }
      });
      owner = tronWeb.address.fromHex(owner);
      if(owner !== address){
        tokenId = "";
        Utils.setToastAlert("Looks like, NFT ID not owned by you.");
      }
      this.setState({tokenId,owner})
    } catch (e) {
    console.log(e);
    }
  }



  handleDataUpload = async() =>{
    let{address,name,description,tags,adult,imgData,attributes,rank,rarity,unlockable,elink,nftAddress} =this.props.data
    let{tokenId} = this.state
    try {
      let ext = imgData.name.split(".");
      let fileName = `${ext[0]}.${ext[ext.length - 1].toLowerCase()}`;

      Utils.setToastAlert("Uploading NFT details and file","info")
      this.setState({isUploading:true})
      let payload = new FormData()
      payload.append('name', name)
      payload.append('creator', address)
      payload.append('tokenId', tokenId)
      payload.append('tags', tags)
      payload.append('description', description)
      payload.append('adult',adult)
      payload.append('nftAddress',nftAddress)


      if(attributes && attributes.length){
        payload.append('attributes',JSON.stringify(attributes))
      }

      if(unlockable && unlockable.length){
        payload.append('unlockable',JSON.stringify(unlockable))
      }
      if(!!rank){
        payload.append('rank',rank)
      }
      if(!!rarity){
        payload.append('rarity',rarity)
      }
      if(!!elink){
        payload.append('elink',elink)
      }

      payload.append('img', imgData,fileName)

      await api.newNft(payload,{
          timeout:5*60*1000,
          onUploadProgress: progressEvent => {
            let{loaded,total} = progressEvent
            this.setState({loaded,total})
          }
      });

      let _url = nftAddress === Utils.NftContract?`/market/${tokenId}`:`/market3p/${nftAddress}/${tokenId}`
      window.location.href = _url;

    } catch (e) {
      this.setState({isProcess:false})
      let msg = e.response?.data?.message??"Failed to upload NFT metadata details";
      Utils.setToastAlert(msg,"error");
      // this.props.back()
    }
  }

  mintNft = async() =>{
    try {
      let{address,nftAddress} =this.props.data;
      let{fees} = this.state;

        let balance = await window.tronWeb.trx.getUnconfirmedBalance(address);
        balance = Utils.floor(balance);
        if(balance < fees){
          this.setState({isProcess:false})
          return Utils.setToastAlert("Not sufficient TRX balance");
        }

        let instance = await window.tronWeb.contract().at(Utils.MarketInregrator);
        let tokenId = await instance.singleMint(address,nftAddress).send({
          feeLimit:100000000,
          callValue:fees,
          shouldPollResponse:true
        });
        this.setState({tokenId : window.tronWeb.toDecimal(tokenId._hex)},this.handleDataUpload)

    } catch (err) {
      // console.log(err);
      this.setState({isProcess:false})
      if(err === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to mint NFT","error")
      }
    }

  }

  handleSubmit = () =>{
    let{address} =this.props.data;
    let{isProcess,tokenId,owner} = this.state;

    if(!address)return $(".header__action-btn--signin").click();;
    if(isProcess) return Utils.setToastAlert("Previous request processing");
    if(!!owner && owner !== address) return Utils.setToastAlert("Looks like, NFT ID not owned by you.");

    if(!!tokenId && !!address && owner === address){
      this.setState({isProcess:true},this.handleDataUpload)
    }else {
      this.setState({isProcess:true},this.mintNft)
    }
  }

  renderProgressBar = () =>{
    let{loaded,total} = this.state;
    let{imgData} = this.props.data
    total = total || imgData.size;
    let precentage = Utils.ceil(loaded*100/total,0);
    precentage = precentage || 5;
    return <div className="create__upload__progress">
      <div className="create__upload__progress__bar" style={{width:precentage+"%"}}>&nbsp;</div>
      <p className="create__upload__size">{Utils.fileSize(loaded)}/{Utils.fileSize(total)}</p>
    </div>
  }

  renderAttributes = () => {
    let {attributes} = this.props.data
    if(!attributes || !attributes.length) return <p>None</p>
    return attributes.map((attrs,i)=>{
      let [k,v] = Object.entries(attrs)[0];
      return <div className="attributes__input__preview" key={k+v+i}>
        <span>{k}</span><p> : {v}</p>
        </div>
    })
  }

  render() {


    let{tokenId,isProcess,isUploading,fees,owner} = this.state
    let {name,description,tags,rank,rarity,attributes,unlockable,elink,adult,imgData,nftTokens,nftAddress,address} = this.props.data;
    let nft = {
      name,description,rank,rarity,attributes,unlockable,nftAddress,tokenId,elink,imgData,
      adult:adult === "yes",
      tags:tags.split(","),
      attributes:attributes.map((attrs,i)=>{
        let [k,v] = Object.entries(attrs)[0];
        return {name:k,value:v}
      }),
      formate:!imgData || !imgData.name?"jpg":imgData.name.split('.')[1],
    }
    let nftDoc = nftTokens.find(i => i.nftAddress === nftAddress);
    owner = owner || Utils.Zero_address;
    let author = owner === Utils.Zero_address || owner === address?Utils.viewUser():{};

    return (
        <MarketBodyCreate data={{address,owner,nft,author,nftDoc}}>
          <div className="market__btns">
            <SubmitBtn fullView={false} onClick={this.props.back} red >Cancel</SubmitBtn>
            <SubmitBtn fullView={false} onClick={this.handleSubmit} green >
              {!tokenId?"create":"Submit"}
              {!!isProcess && <div className="ball__pulse">
                <div></div>
                <div></div>
                <div></div>
              </div>}
            </SubmitBtn>
          </div>
          {!tokenId && <p className="mint_note"><HandArrow/>NFT hosting and storage fees {Utils.ceil(fees/1e6,2)} TRX</p>}


          {!!isProcess && <PopUp.PopupContainer
          title={"Processing"}>
            <div className="create__transaction__process">
              <div className = {!!tokenId?"create__processing__bar active":"create__processing__bar"}>
                <span></span> {!tokenId?"NFT Minting": `NFT Minted (Token Id : ${tokenId})`}
              </div>
              <i>.</i>
              <i>.</i>
              <i>.</i>
              <div className = "create__processing__bar">
                <span></span> {!isUploading?"Metadata Upload":"Metadata Uploading"}
              </div>
            </div>

            {!!isUploading && this.renderProgressBar()}

            <div className="create__alert__msg">
              {!tokenId && <span>Waiting for Confirmation from blockchain, Please do not refresh. This page might take long (upto 5 minutes)</span>}
              {!!tokenId && <span>Please do not refresh this page. if anything goes wrong while uploading metadata, go to your wallet and upload again.</span>}

            </div>

          </PopUp.PopupContainer>}

        </MarketBodyCreate>
      )
  }
}
export default NftDetailsPreview
