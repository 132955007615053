import React, { Component,Fragment } from 'react'
import styled from 'styled-components'
import $ from "jquery";
import api from "../../api"
import { Link } from 'react-router-dom'
import {Utils,PopUp} from "../../helper"
import{Title, SignGroup,InputLabel} from "./comp"
import {TRC721Model} from "../../frag"

import NftDetailsPreview from "./preview.jsx";
import NftFile from "./nft-file-preview.jsx";
import {ReactComponent as HandArrow} from '../../images/hand-up.svg';
import {ReactComponent as PlusIcon} from '../../images/plus.svg';
import {ReactComponent as RecycleBin} from '../../images/recycle.svg';
import PaintBrush from '../../images/brush.png';

import './style.scss';


const CreateBtn = styled.button.attrs({
  className:"sign__btn ",
  type:"button"
})``



class Create extends Component {
  constructor(props) {
      super(props)

      this.state = {
        address:"",
        preview:false,
        name:'',
        creator:'',
        description:'',
        tags:'',
        elink:'',
        attributes:[],
        adult:"false",
        attrKey:"",
        attrValue:"",
        imgData:null,
        tokenId:'',

        showMore:false,
        unlockable:[],
        additional:{
          locked:false,
          url:"",
          formate:"image"
        },
        rarity:"",
        rank:"",

        nftAddress:Utils.NftContract,
        nftTokens:[],


        updateId:0,
      }
  }
  componentDidMount (){
    document.title = "Create || Kraftly";

    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      let {address} = account
      if(address){
        // address = ""
        this.setState({address})
      }else {
        this.setState({address,nftAddress:Utils.NftContract})

      }
    })
  }
  componentWillUnmount(){
    document.title = "Kraftly";
  }

  handleFile = event =>{
    let file = event.target.files[0];
    // console.log(file);
    if(!file){
      return Utils.setToastAlert("File not selected");
    }
    // console.log(file);
    let ext = file.name.split(".");
    ext = ext[ext.length - 1].toLowerCase();

    const file_ext = ["jpg","gif","mp4","mp3","png"]

    if (!file.type || !file_ext.includes(ext)) {
      return Utils.setToastAlert(`Can not upload .${ext}, Only support .jpg .png .gif .mp4 .mp3 files type`);
    }

    if (file && file.size > 2*1024*1024) { //2MB
      return Utils.setToastAlert("NFT file size to large, try uploading file less than 2MB");
    }

    //to display image on client
    this.setState({imgData:file});
  }



  handleInput = event =>{
    const name = event.target.name;
    let value = event.target.value;

    if(name === 'description'){
      if(value.length > 300){
        event.target.style.borderColor = "red";
      }else if(event.target.style.borderColor) {
        event.target.style.borderColor = "";
      }
    }

    if(name === 'name'){
      let _name = value.replace(/ /g, "");
      let _notallowed = ["ROCKID","R0CKID","ROCKLD","R0CKLD",
      "CLOUDID","CL0UDID","CLOUDLD","CL0UDLD",
      "FOXID","F0XID","FOXLD","F0XLD"]
      .filter(i=> i === _name.substr(0,i.length).toUpperCase()).length > 0;

      if(!!value.length && !!_notallowed){
        value = "";
        event.target.style.borderColor = "yellow";
      }else if (!!value.length && (value.length < 3 || value.length > 30)) {
        event.target.style.borderColor = "red"
      }else if(event.target.style.borderColor) {
        event.target.style.borderColor = "";
      }
    }

    if(name === "elink"){
      if(!!value && !Utils.validateUrl(value)){
        event.target.style.borderColor = "red"
      }else if(event.target.style.borderColor) {
        event.target.style.borderColor = "";
      }
    }
    if(name === "rank"){
      value = Utils.inputSafe(value,0);
    }
    if(name === "rarity"){
      value = Utils.inputSafe(value,2);
    }

    this.setState({[name]:value})
  }


  handleTags = event=>{
    // console.log(event.charCode);
    var regex = /^[a-z,#]+$/;
    let value = event.target.value.replace(/\s/g, "").toLowerCase();
    if(value.length > 0 && !regex.test(value))return;

    if(value !== "#" && value.length > 0 && !value.startsWith("#")){
      value = "#"+value;
    }
    let res = value.split(",")

    res = res.filter(i => i.startsWith("#")).toString()
    if(value.length>2 && value.charAt(value.length-1) === ","){
      if(this.state.tags.charAt(this.state.tags.length-1) !== "#"){
        res += ",#";
      }

    }
    this.setState({tags:res})
  }
  handlePreview = ()=>{
    // let {address,balance} = JSON.parse(localStorage.getItem('activeUser'))|| {};
    let{name,description,imgData,address,elink} =this.state
    if(name.length < 3 || name.length > 30) return Utils.setToastAlert("Invalid NFT art name length");
    if(description.length > 300) return Utils.setToastAlert("NFT description length too large");
    if(!imgData) return Utils.setToastAlert("Please attach file (GIF,JPG,PNG,MP4 or MP3) of Art");
    if(!!elink && !Utils.validateUrl(elink)) return Utils.setToastAlert("Broken or invalid external link provided, please check and enter again");

    this.setState({preview:true})
  }


  handleAttr = (e)=>{
    e.preventDefault()

    let {attrKey,attrValue,attributes} = this.state;
    if(!attrKey) return Utils.setToastAlert("Attribute name required");
    if(attrKey.length <= 1 || attrKey.length > 150) return Utils.setToastAlert("Attribute name must be 2-150 characters");
    if(!attrValue) return Utils.setToastAlert("Attribute value required");
    if(attrValue.length <= 1 || attrValue.length > 150) return Utils.setToastAlert("Attribute value must be 2-150 characters");

    let index = attributes.findIndex(i => i[attrKey]);
    if(index !== -1) return Utils.setToastAlert("Duplicate attribute available");

    attributes.push({[attrKey]:attrValue})
    this.setState({attrKey:"",attrValue:"",attributes})
  }

  handleRemoveAttr = (index) =>{
    let {attributes} = this.state;
    let data = attributes.splice(index, 1);
    let [k,v] = Object.entries(data[0])[0];
    this.setState({attrKey:k,attrValue:v,attributes})
  }
  renderAttributes = () =>{
    let {attributes} = this.state;
    return attributes.map((attrs,i)=>{
      let [k,v] = Object.entries(attrs)[0];
      return <div className="create__attributes__input" key={k+v+i}>
        <input
          type="text"
          className="create__input"
          value={k}
          readOnly={true}
          />
        <input
          type="text"
          className="create__input"
          value={v}
          readOnly={true}

          />
        <button
          type="button"
          onClick={()=>this.handleRemoveAttr(i)}
          ><RecycleBin /></button>
      </div>
    })
  }

  addAdditionalContent = (e)=>{
    e.preventDefault()

    let {additional,unlockable} = this.state;
    let{locked,url,formate} = additional;

    if(!url) return Utils.setToastAlert("content url required");
    if(!Utils.validateUrl(url)) return Utils.setToastAlert("Broken or invalid link provided, please check and enter again");

    let index = unlockable.findIndex(i => i.url !== url);
    if(index !== -1) return Utils.setToastAlert("Duplicate content url available");

    unlockable.push(additional)
    this.setState({additional:{locked:false,url:"",formate:"image"}})
  }

  RemoveAdditionalContent = (index) =>{
    let {additional,unlockable} = this.state;
    let {locked,url,formate} = unlockable.splice(index, 1)[0];
    this.setState({additional:{locked,url,formate}})
  }
  renderAdditionalContent = () =>{
    let {unlockable} = this.state;
    return unlockable.map((content,i)=>{
      let{locked,url,formate} = content;

      return <div className="create__content__input" key={url}>
        <input
          type="text"
          className="create__input"
          value={url}
          readOnly={true}
          />
          <input
            type="text"
            className="create__select create__select__absolute"
            value={formate === "pdf"?"PDF":formate.charAt(0).toUpperCase() + formate.slice(1)}
            disabled={true}
            />
          <input
            type="text"
            className="create__select"
            value={!!locked?"Unlockable":"public"}
            disabled={true}
            />
        <button
          type="button"
          onClick={()=>this.RemoveAdditionalContent(i)}
          ><RecycleBin /></button>
      </div>
    })
  }
  onOption = (value)=>{
    let{nftAddress,updateId} = this.state
    if(!!updateId || nftAddress === value) return null;
    // console.log(value);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("t", value)
    let params = urlParams.toString();
    if (window.history.pushState) {
      let url = `${window.location.pathname}?${params}`;
      window.history.pushState(null, null,url);
    }
    this.setState({nftAddress:value})
  }

  handleUpdateTokenList = (value) =>{
    const urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get('t');
    let updateId = 0;
    let nftAddress = Utils.NftContract;
    if(!!token && token.length === 34){
      let isToken = value.findIndex(i => i.nftAddress === token) !== -1;
      if(isToken){
        nftAddress = token;
        updateId = Utils.floor(urlParams.get('tokenId') || 0);
      }else {
        urlParams.set("t", nftAddress)
        urlParams.delete('tokenId');
        let params = urlParams.toString();
        if (window.history.pushState) {
          let url = `${window.location.pathname}?${params}`;
          window.history.pushState(null, null,url);
        }
      }
    }else{
      urlParams.set("t", nftAddress)
      urlParams.delete('tokenId');
      let params = urlParams.toString();
      if (window.history.pushState) {
        let url = `${window.location.pathname}?${params}`;
        window.history.pushState(null, null,url);
      }
    }

    this.setState({nftTokens:value,nftAddress,updateId})
  }

    render() {

      let{name,description,tags,adult,imgData,preview,attrKey,attrValue,attributes,nftAddress,address,nftTokens,showMore,elink,rank,rarity,additional,unlockable,updateId} =this.state;
      let file_name = imgData?imgData.size <= 2*1024*1024?imgData.name:"File too large":"e. g. JPG, PNG, Gif, MP4, MP3";

        return (
          <div className="main">


          <div className="container">
            <div className="row no-gutters">
              <div className="create__page__ik__redirect">
                <HandArrow />
                <Link to="/mintable/create">Create Mintable collection</Link>
              </div>
              <div className="col-12">
                <Title data={{preview,updateId}}/>
              </div>
              {!!preview && <NftDetailsPreview data={this.state} back={()=>this.setState({preview:false})} key={address}/>}


              {!preview && <div className="col-12 col-md-6">
                <NftFile data={{imgData}}/>
              </div>}

          {!preview && <div className="col-12 col-md-6">
            <form action="#" className="create__form" onSubmit={(e)=>e.preventDefault()}>
              <div className="row no-gutters">
                <div className="col-12">
                  <h4 className="create__title">Upload File :-</h4>
                </div>

                <div className="col-12">
                  <div className="create__file">
                    <label id="file1" htmlFor="sign__file-upload" >{file_name}</label>
                    <input
                      data-name="#file1"
                      id="sign__file-upload"
                      name="file"
                      className="sign__file-upload"
                      type="file"
                      accept=".png,.jpg,.gif,.mp4,.mp3"
                      onChange={this.handleFile}

                      />
                  </div>
                </div>


                <div className="col-12">
                  <h4 className="create__title">Item Metadata :-</h4>
                </div>
                <SignGroup>
                  <InputLabel htmlFor="name" title="Item name" tooltip="A good name can stimulate the imagination and enrich the audience's experience"/>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    className="create__input"
                    placeholder="e. g. 'Crypto Heart'"
                    value={name}
                    onChange={this.handleInput}
                    />
                </SignGroup>

                <SignGroup>
                  <InputLabel htmlFor="description" title="Descriptione" tooltip="Introduce your work and let more people know the value of your work"/>
                  <textarea
                    id="description"
                    name="description"
                    className="create__textarea"
                    placeholder="e. g. 'After purchasing you will able to receive...'"
                    value={description}
                    onChange={this.handleInput}
                    />
                </SignGroup>


                <SignGroup>
                  <InputLabel htmlFor="tags" title="Tags" tooltip="Appropriate tags may enhance search appearance and visibility to target audience"/>
                  <textarea
                    id="tags"
                    name="tags"
                    className="create__textarea create__textarea__mini"
                    placeholder="e. g. #art,#anime,#memes,#photography,#inspirational"
                    value={tags}
                    onChange={this.handleTags}
                    ></textarea>
                </SignGroup>

                <SignGroup small>
                  <InputLabel htmlFor="adult" title="18+ content?" tooltip="Select Yes for 18+ or NFSW contents"/>
                  <select
                    id="adult"
                    name="adult"
                    className="create__select"
                    onChange={(e)=>this.setState({adult:e.target.value})}
                    value={adult}
                    >
                    <option value={"true"}>Yes.</option>
                    <option value={"false"}>No!</option>

                  </select>
                </SignGroup>

                <SignGroup small>
                  <InputLabel htmlFor="nftAddress" title="NFT Contract" tooltip="Choose TRC721 contract name of your choice"/>
                  <TRC721Model.MyTokens
                    data={{address,nftAddress,nftTokens}}
                    onOption={(e)=>this.onOption(e)}
                    handleUpdateTokenList = {this.handleUpdateTokenList}
                    key={address}
                    />
                </SignGroup>

                <div className="col-12 ">
                  <button className="create__more__btn" onClick={()=>this.setState({showMore:!showMore})}>
                    <PlusIcon /> Advance options
                  </button>
                </div>

                {!!showMore && <Fragment>

                  <SignGroup >
                    <InputLabel htmlFor="attributes" title="Attributes" tooltip="It is often used in a collection to highlight the characteristics of this item in the collection, you can skip this step directly"/>

                    {!!attributes.length && this.renderAttributes()}
                    <div className="create__attributes__input">
                      <input
                        type="text"
                        className="create__input"
                        id="attributes"
                        name="attrKey"
                        placeholder = "e. g. background"
                        value={attrKey}
                        onChange={(e)=>this.setState({attrKey:e.target.value})}
                        disabled={!!preview}
                        />
                      <input
                        type="text"
                        className="create__input"
                        id="attributes"
                        name="attrValue"
                        placeholder = "e. g. purple"
                        value={attrValue}
                        onChange={(e)=>this.setState({attrValue:e.target.value})}
                        disabled={!!preview}

                        />
                      <button
                        type="button"
                        onClick={this.handleAttr}
                        >
                        <PlusIcon />
                      </button>

                    </div>
                  </SignGroup>

                  <SignGroup small>
                    <InputLabel htmlFor="rarity" title="Rarity" tooltip="It is used in a collection to highlight the rarity score of this item in the collection, you can skip this step directly"/>
                    <input
                      type="number"
                      className="create__input"
                      id="rarity"
                      name="rarity"
                      value={rarity}
                      onChange={this.handleInput}
                      disabled={!!preview}
                      />
                  </SignGroup>

                  <SignGroup small>
                    <InputLabel htmlFor="rank" title="Rank" tooltip="It is used in a collection to highlight the Rank of this  item in the collection, you can skip this step directly"/>
                    <input
                      type="number"
                      className="create__input"
                      id="rank"
                      name="rank"
                      value={rank}
                      onChange={this.handleInput}
                      disabled={!!preview}
                      />
                  </SignGroup>

                  <SignGroup>
                    <InputLabel htmlFor="elink" title="External Link" tooltip="It is used to add External url related to this item , you can skip this step directly."/>
                    <input
                      type="text"
                      className="create__input"
                      id="elink"
                      name="elink"
                      placeholder = "https://mywebsite.com/page"
                      value={elink}
                      onChange={this.handleInput}
                      disabled={!!preview}
                      />
                  </SignGroup>


                  <SignGroup >
                    <InputLabel htmlFor="additional-content" title="Additional content" tooltip="It is used to add additional public viewable or unlockale contents (eg you work file in high quality), you can skip this step directly"/>

                    {!!unlockable.length && this.renderAdditionalContent()}
                    <div className="create__content__input">
                      <input
                        type="text"
                        className="create__input"
                        id="additional-content"
                        name="additional-content"
                        placeholder = "https://mywebsite.com/content.jpg"
                        value={additional.url}
                        onChange={(e)=>this.setState(p=>({additional:{...p.additional,url:e.target.value}}))}
                        disabled={!!preview}
                        />
                        <select
                          id="additional-content"
                          name="additional-content"
                          className="create__select create__select__absolute"
                          onChange={(e)=>this.setState(p=>({additional:{...p.additional,formate:e.target.value}}))}
                          value={additional.formate}
                          >
                          <option value={"image"}>Image</option>
                          <option value={"video"}>Video</option>
                          <option value={"audio"}>Audio</option>
                          <option value={"pdf"}>PDF</option>
                        </select>
                        <select
                          id="additional-content"
                          name="additional-content"
                          className="create__select"
                          onChange={(e)=>this.setState(p=>({additional:{...p.additional,locked:e.target.value}}))}
                          value={additional.locked}
                          >
                          <option value={"true"}>Unlockable</option>
                          <option value={"false"}>Public</option>
                        </select>
                      <button
                        type="button"
                        onClick={this.addAdditionalContent}
                        >
                        <PlusIcon />
                      </button>
                      <a href="https://www.pinata.cloud/" target="_blank" rel="noopener noreferrer">Where to upload ?</a>

                    </div>
                  </SignGroup>


                </Fragment>}

                <div className="col-12 ">
                  <CreateBtn onClick={this.handlePreview}>Create item</CreateBtn>
                </div>


              </div>
            </form>
          </div>}
        </div>
      </div>
      </div>
        )
    }
}

export default Create
