import React, { Component } from 'react'
//import ReactTable from 'react-table'
import $ from 'jquery';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {Utils} from "../helper"
import tronWeb from "../tronweb"
import './fox555.scss'

import Banner from '../images/fox-banner.jpeg';
import SideBanner from '../images/fox-side-banner.jpeg';


const Fox555Contract = "TKrCzUSnsAEJ44gjcWHzM45XQaLgJ8fpQ3";

const CreateBtn = styled.button.attrs({
  className:"sign__btn ",
  type:"button"
})``

class MintFox555 extends Component{
  constructor(props) {
      super(props)
      this.state = {
        address:"",
        balance:0,
        isProcess:false,
        count:10000,
        total:10000,
        mintSize:1,
        startAt:1633296600000,
        timeNow:Date.now(),
        price:555 * 1e6,
      }
  }
  componentDidMount (){
    document.title = "Mint || FOX555";
    let self = this;
    this.fetchStartTime()
    this.fetchFox555Minted()

    let checker = setInterval(async()=>{
      if(window.tronWeb){
        this.handleAddressChange()
        clearInterval(checker)
      }
    },400)

    // Utils.getCreator((err,account)=>{
    //   if(err) return console.log(err);;
    //   const {address} = account
    //   if(address){
    //     this.setState({address,balance:0},this.fetchUserBalance)
    //   }else {
    //     this.setState({address:"",balance:0})
    //   }
    // })


      let timer = setInterval(function() {
        let timeLeft = self.state.startAt - Date.now();
        self.setState({timeNow:Date.now()})
              if (timeLeft <= 0) {
                clearInterval(timer);
              }
            }, 1000);
  }
  componentWillUnmount(){
    document.title = "Kraftly";
  }

  handleAddressChange = () =>{
    setInterval(async()=>{
      let {address}  = this.state
      let currentAddress = window.tronWeb.defaultAddress.base58;
      // console.log(currentAddress);
        if (currentAddress !== address) {
          if(!!currentAddress){
            this.setState({address:currentAddress,balance:0},this.fetchUserBalance)
          }else {
            this.setState({address:"",balance:0})
          }
        }
    },2000)
  }

  fetchStartTime = async() =>{
    try {
      let instance = await tronWeb.contract().at(Fox555Contract);
      let time = await instance.startAt().call();
      time *= 1000;
      this.setState({startAt:time});
    } catch (e) {
      console.log(e);
    }
  }
  fetchFox555Minted = async() =>{
    try {
      let instance = await tronWeb.contract().at(Fox555Contract);
      let count = await instance.mintedTokens().call();
      count = tronWeb.toDecimal(count._hex);
      this.setState({count})

    } catch (e) {
      console.log(e);
    }
  }

  fetchUserBalance = async()=>{
    let {address} = this.state
    try {
      let balance = await window.tronWeb.trx.getUnconfirmedBalance(address);
      balance = Utils.floor(balance,0);
      this.setState({balance})
    } catch (e) {
      console.log(e);
    }
  }
  handleMint = async() =>{
    try {
      let{address,balance,price,mintSize} = this.state;
      if(!address)return $(".header__action-btn--signin").click();
      // if(isProcess) return Utils.setToastAlert("Previous minting request processing");
      let requiredBal = Utils.ceil(price * mintSize,0);
      balance = Utils.floor(balance);
      if(!balance || balance < requiredBal)return Utils.setToastAlert("Not sufficient TRX balance");

      // this.setState({isProcess:true})
      let instance = await window.tronWeb.contract().at(Fox555Contract);
      await instance.mintFOX(address,mintSize).send({
        feeLimit:150 * 1e6 * mintSize,
        callValue: requiredBal,
      });

      this.setState({isProcess:false},this.fetchUserBalance);
      this.fetchFox555Minted()

      Utils.setToastAlert(`Transaction successfully submitted to mint ${mintSize} FOX555.`,'success')

    } catch (e) {
      console.log(e);
      // this.setState({isProcess:false});
      if (e === 'Confirmation declined by user') {
        Utils.setToastAlert('Confirmation declined','warning')
      }else {
        // console.log(e);
        Utils.setToastAlert("Something went wrong while minting FOX555",'error')
      }
    }
  }

  render(){

    let {address,balance,count,total,startAt,mintSize} = this.state
    let timeLeft = startAt - Date.now();
    // timeLeft = 0;
    let btnState = timeLeft > 0 || count === total;

    let btnName = timeLeft > 0? Utils.getTimeOffset(startAt/1000): count === total ? "All FOX555 Minted":"Mint FOX555"
    return(
      <main className="main">
          <div className="neo-banner">
            <ul className="neo-menu clouds">
              <li><Link to="/fox555/mint" className="neo-active">Mint</Link></li>
              <li><Link to="/fox555/gallery">Gallery</Link></li>
              <li><Link to="/fox555/swap">Swap</Link></li>

            </ul>
            <img src={Banner} alt="" />

          </div>

    		<div className="container fox-body">
    				<div className="col-12">
    					<div className="neo-body crypto-cloud ">
                <div className="row">
                  <div className="col-12">
                    <p className="neo-mint-count">{count} out of {total} minted</p>
                  </div>
    						<div className="col-12 col-md-8">

                  <div className="sign__group">
                    <label className="sign__label">Your Wallet</label>
                    <input
                      type="text"
                      className="sign__input"
                      placeholder="Please connect your wallet"
                      value={address}
                      readOnly
                      />
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="sign__group">
                        <label className="sign__label">Your Balance</label>
                        <input
                          type="text"
                          className="sign__input"
                          value={Utils.preety.floor(balance/1e6,1)+" TRX"}
                          readOnly
                          />
                      </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="sign__group">
                          <label className="sign__label" htmlFor="mint_size">No. of NFT</label>
                          <select
                            id="mint_size"
                            name="mint_size"
                            className="sign__select"
                            onChange={(e)=>this.setState({mintSize:e.target.value})}
                            value={mintSize}
                            >
                            {new Array(10).fill(undefined).map((i,j)=>{
                              return <option value={j+1} key={j+1}>{j+1}</option>
                            })}
                          </select>
                        </div>
                        <p className="sign__group__below__label">{mintSize} FOX + {Utils.preety(mintSize*111,0)} $KTY bonus</p>
                      </div>
                  </div>

                  <CreateBtn
                    onClick={this.handleMint}
                    disabled={btnState}
                    >
                    {btnName}
                  </CreateBtn>
                  <div className="sign__group">
                    <label className="sign__label price-note">NOTE</label>
                    <p>You need 555 TRX to mint 1 unit of FOX.If you do not have enough TRX please refill your wallet with TRX and reload this page and make sure you're logged in.</p>
                    <p></p>
                  </div>
    						</div>
                <div className="col-12 col-md-4">
                  <div className="neo-mint-body-right">
                    <div className="neo-mint-body-right-content">
                      <img src={SideBanner} alt="" />
                      <p>When you mint a FOX, you get a random FOX and 111 $KTY bonus</p>
                    </div>
                  </div>
    						</div>
    					</div>
    				</div>
    			</div>
    		</div>
    	</main>
    )
  }
}

export default MintFox555;
