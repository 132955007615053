import React, { Component,Fragment } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {Utils} from "../../helper"
import $ from 'jquery';
import Data from "../../pages/market3p/data.js"

// import { lang } from '../../lang'

const MenuList = styled.ul.attrs({
  className: 'header__nav',
})``
const MenuItem = styled.li.attrs({
  className: 'header__nav-item',
})``
const StyledLink  = styled(Link).attrs({
  className : "header__nav-link",
})``;


class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address:"",
      screenSize:0,//just to update state on screen size change
    }

  }

  componentDidMount() {
    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      //console.error(user);
      const { address} = account
      this.setState({address})
    })
    window.addEventListener("resize", this.updateDimensions.bind(this));
   }
   updateDimensions = () =>{
     this.setState({screenSize:window.innerWidth})
   }
   handleWallet = () =>{
     $(".header__action-btn--signin").click();
   }
   renderMarketOptions = () =>{
     let bundleOptions = {
       "/explore/tpunks":"TPUNKS",
       "/explore/bayct":"BAYCT",
       "/explore/tronmeebits":"TMEEBITS",
       "/explore/tronbull":"TronBull",
       "/explore/tapes":"tApes",
       "/explore/coolcats":"Cool Cats",
     }
     return Object.entries(bundleOptions).map(([k,v],i)=>{
       // console.log(k,v);
       return <li key={k}><Link to={k} key={k}>{v}</Link></li>
     })
   }
   renderWalletOptions = () =>{
     let{address} = this.state;
     return Data.getAllSupportedNfts().map((item,i)=>{
       let{name,symbol,nftAddress} = item
       // console.log(k,v);
       let url = `/author/${address}/profile?tab=collection&nft=${nftAddress}`;
       return <li key={nftAddress}><Link to={url}>{name} ({symbol})</Link></li>
     })
   }
    render() {
      let{address} = this.state;
      let smallMenu = window.innerWidth < 1200;
      let isMobile = window.innerWidth < 576;
      // className="highlight-link"
      // console.log(window.location.pathname+window.location.search);
      // <MenuItem className="highlight-link">
      //   <StyledLink to="/fox555">FOX555</StyledLink>
      // </MenuItem>

        return (
          <div className="header__menu">
            <MenuList>
              {!!smallMenu &&  !!address &&
                <MenuItem>
                  <StyledLink  to={`/author/${address}/profile?tab=collection`} role="button" id="dropdownMenu0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Wallet
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/>
                    </svg>
                  </StyledLink>

                  <ul className="dropdown-menu header__nav-menu" aria-labelledby="dropdownMenu0">
                    {this.renderWalletOptions()}
                  </ul>
                </MenuItem>}
              <MenuItem>
                <StyledLink to="/home">Home</StyledLink>
              </MenuItem>
              <MenuItem>
                  <StyledLink to="/explore">Explore</StyledLink>
              </MenuItem>
              <MenuItem>
                <StyledLink to="/create">Create</StyledLink>
              </MenuItem>
              {!smallMenu && <MenuItem>
                <StyledLink to="/kty-info">KTY</StyledLink>
              </MenuItem>}



              {!smallMenu && <MenuItem >
                <StyledLink className="highlight-link" to="#" role="button" id="neomenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Collection
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/>
                  </svg>
                </StyledLink>

                <ul className="dropdown-menu header__nav-menu" aria-labelledby="neomenu">
                  <li><Link className="highlight-link" to="/mintable">Mintable collection</Link></li>
                  <li><Link to="/mintable/create">Create Collection</Link></li>
                  <li><Link to="/mintable/trc721">NFT Contracts</Link></li>
                  <li><Link to="/mintable/create-trc721">Deploy TRC721</Link></li>
                  <li>&nbsp;</li>
                  <li><Link to="/mintable/25/gallery">Tron Cuties</Link></li>
                  <li><Link to="/tech-memory">Tech Memories</Link></li>
                  <li><Link to="/FOX555">FOX555</Link></li>
                  <li><Link to="/cypto-clouds" >Crypto Clouds</Link></li>
                  <li><Link to="/trocks">TROCKS</Link></li>


                </ul>
              </MenuItem>}

              {!smallMenu && <MenuItem>
                <StyledLink to="#" role="button" id="dropdownMenu0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Community
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/>
                  </svg>
                </StyledLink>

                <ul className="dropdown-menu header__nav-menu" aria-labelledby="dropdownMenu0">
                  <li><Link to="/kty-info">KTY Token  Info</Link></li>
                  <li><Link to="/kty-burn">KTY Buy & Burn</Link></li>
                  <li><Link to="/donate">Donation</Link></li>
                  <li><Link to="/authors">Authors</Link></li>
                  <li><Link to="/article">Article</Link></li>
                  <li><a href="https://kraft-chef.gitbook.io/kraftly-docs/account/your-collection/secondary-nfts" target="_blank" rel="noopener noreferrer">User Guide</a></li>
                  <li><a href="https://t.me/kraftlynft" target="_blank" rel="noopener noreferrer">Tech Support</a></li>
                </ul>
              </MenuItem>}

              {!!smallMenu && <Fragment>
                <MenuItem>
                  <StyledLink to="/explore/tpunks" role="button" id="dropdownMenu0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Secondary Market
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/>
                    </svg>
                  </StyledLink>

                  <ul className="dropdown-menu header__nav-menu" aria-labelledby="dropdownMenu0">
                    {this.renderMarketOptions()}
                  </ul>
                </MenuItem>
                <MenuItem>
                  <StyledLink className="highlight-link"  to="/mintable" role="button" id="dropdownMenu0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Collection
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/>
                    </svg>
                  </StyledLink>

                  <ul className="dropdown-menu header__nav-menu" aria-labelledby="dropdownMenu0">
                    <li><Link className="highlight-link" to="/mintable">Mintable collection</Link></li>
                    <li><Link to="/mintable/create">Create Collection</Link></li>
                    <li><Link to="/mintable/trc721">NFT Contracts</Link></li>
                    <li><Link to="/mintable/create-trc721">Deploy TRC721</Link></li>
                  </ul>
                </MenuItem>


                <MenuItem>
                  <StyledLink  to="/kty-info" role="button" id="dropdownMenu0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    KTY Token
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/>
                    </svg>
                  </StyledLink>

                  <ul className="dropdown-menu header__nav-menu" aria-labelledby="dropdownMenu0">
                    <li><Link to="/kty-info">KTY Token Info</Link></li>
                    <li><Link to="/kty-burn">KTY Buy & Burn</Link></li>
                  </ul>
                </MenuItem>
                <MenuItem>
                  <StyledLink to="/authors">Authors</StyledLink>
                </MenuItem>

                <MenuItem>
                  <StyledLink to="/tech-memory">Tech Memories</StyledLink>
                </MenuItem>
                <MenuItem>
                  <StyledLink to="/FOX555">FOX555</StyledLink>
                </MenuItem>
                <MenuItem>
                  <StyledLink to="/cypto-clouds">Crypto Clouds</StyledLink>
                </MenuItem>
                <MenuItem>
                  <StyledLink to="/trocks">TROCKS</StyledLink>
                </MenuItem>

                <MenuItem>
                  <StyledLink to="/donate">Donation</StyledLink>
                </MenuItem>

                <MenuItem>
                  <StyledLink to="/article">Article</StyledLink>
                </MenuItem>
                <MenuItem>
                  <a className="header__nav-link" href="https://kraft-chef.gitbook.io/kraftly-docs/account/your-collection/secondary-nfts" target="_blank" rel="noopener noreferrer">User Guide</a>
                </MenuItem>
                <MenuItem>
                  <a className="header__nav-link" href="https://t.me/kraftlynft" target="_blank" rel="noopener noreferrer">Tech Support</a>
                </MenuItem>

              </Fragment>}
              {!address && !!isMobile && <MenuItem>
                <button
                  className="header__action-btn--login"
                  onClick={this.handleWallet}
                  >
                  <span>Connect Wallet</span>
                </button>
              </MenuItem>}


            </MenuList>
          </div>
        )
    }
}








export default Menu
