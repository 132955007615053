import React, { Component } from 'react'
import {Utils} from "../helper"





class Paginator extends Component{
  constructor(props) {
      super(props)
      this.state = {
        pageInput:'',
      }
  }
  renderPagings = () =>{
    let{count,page,limit,start} = this.props.data;
    limit = limit || 1;
    let totalPage = Math.ceil(count /limit);
    totalPage = totalPage <= 4?totalPage:4;
    let pages = new Array(totalPage).fill(undefined).map((data,i)=>i+start);

    return pages.map((mark,i)=>{
      return <li className={page === mark?"active":""} key={i}>
        <button key={i} onClick={()=>this.handlePaging(mark)}>
          {mark}
        </button></li>
    })
  }
  handlePaging = (val) =>{
    let{page,start,limit,count} = this.props.data;
    if(page === val) return '';
    let totalPage = Math.ceil(count /limit);
    if(totalPage < val) return '';

    if(totalPage > 4){
      let endGap = totalPage - val;
      endGap = endGap <= 2?endGap:2;
      start = val - endGap;
      start = start > totalPage - 3?totalPage - 3:start > 0?start:1;
    }
    this.props.handler({page:val,start})
  }
  handlePagingArrow = (val) =>{
    let{count,page,start,limit} = this.props.data;
    if(!count || count <= limit) return;
    let totalPage = Math.ceil(count /limit);
    let _page = page;
      if(val === "prev"){
        if(page > 1){
          _page = page-1;
          if(totalPage > 4 && start > 1){
            start = start - 1;
          }
        }
      }else {
        if(page < totalPage){
          _page = page+1;
          if(totalPage > 4 && start < totalPage - 3){
            start = start + 1;
          }
        }
      }
      if(_page !== page){
        this.props.handler({page:_page,start})
      }
  }
  handlePagingInput = (e) =>{
    let value = e.target.value?Utils.floor(e.target.value):"";
    this.setState({pageInput:value});
  }
  handlePagingGoto = () =>{
    let{pageInput} = this.state;
    let{count,page,limit} = this.props.data;
    if(!pageInput || pageInput === page) return "";

    let totalPage = Math.ceil(count /limit);
    pageInput = pageInput <= totalPage?pageInput:totalPage;
    this.setState({pageInput})
    let start = pageInput >= 4?pageInput - 3:1;
    this.props.handler({page:pageInput,start});
  }
  render(){
    let{pageInput} = this.state;

    let {count,size} = this.props.data;
    return(
      <div className="paginator">
        <span className="paginator__pages">{size} out of {count}</span>

        <ul className="paginator__list">
          <li>
            <button  onClick={()=>this.handlePagingArrow("prev")}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"/></svg>
            </button>
          </li>
          {this.renderPagings()}
          <li>
            <button  onClick={()=>this.handlePagingArrow("next")}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"/></svg>
            </button>
          </li>
          <li className="go-to-paging">
            <input
              type="number"
              onChange={this.handlePagingInput}
              value={pageInput}
              />
            <button
              onClick={this.handlePagingGoto}
              disabled={false}
              >
              Go to
            </button>

          </li>
        </ul>
      </div>
    )
  }
}

export default Paginator;
