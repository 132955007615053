import React, { Component } from 'react'
import $ from 'jquery';
import { Link } from 'react-router-dom'
import '../../scss/kty.scss';
import {Utils} from "../../helper"
import tronWeb from "../../tronweb"

const SaleContract = "TKveqiyWbT3XxPoFHwnrDyCYxpYvghsZFy";
const KTYToken = "TTroZqb95vmsw4kppupQ8tVEzkNDDP2bcG";


const RoundWidget = (props) =>{

  let [Phase,price,size,round] = props.data;

  return <div className={`round-pane ${Phase === round?"current_sale_round":""}`}>
    <div className="triangle-up"></div>
    <div className="round-container">
      <p><span>Phase :</span> {Phase}</p>
      <p><span>Price :</span> {price} TRX</p>
      <p><span>Size :</span> {size} Million</p>
    </div>
  </div>
}

class GenesisOffering extends Component{
  constructor(props){
    super(props)
    this.state ={
      round:3,
      size:12.5 * 1e6,
      price:0.8,
      left:10539983 * 1e6,
      address:"",
      balance:0,
      isProcess:false,

      ktyamount:'',
    }
  }
  componentDidMount (){
    document.title = "KTY GENESIS OFFERING";
    // this.fetchSoldTokens()

    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      const {address} = account
      if(address){
        this.setState({address,balance:0},this.fetchUserBalance)
      }else {
        this.setState({address:"",balance:0})
      }
    })
  }

  componentWillUnmount(){
    document.title = "Kraftly";
  }
  fetchSoldTokens = async() =>{
    try {
      let instance = await tronWeb.contract().at(KTYToken);
      let left = await instance.balanceOf(SaleContract).call();
      this.setState({left})
    } catch (e) {
      console.log(e);
    }
  }

  fetchUserBalance = async()=>{
    let {address} = this.state
    try {
      let balance = await window.tronWeb.trx.getUnconfirmedBalance(address);
      balance = Utils.floor(balance);
      this.setState({balance})
    } catch (e) {
      console.log(e);
    }
  }
  handleInput = (e)=>{
    let ktyamount = Utils.inputSafe(e.target.value,0);
    this.setState({ktyamount})
  }
  handleBuyKTY = async() =>{
    try {
      let{ktyamount,balance,address,isProcess,price} = this.state;

      if(!address)return $(".header__action-btn--signin").click();;
      if(isProcess) return Utils.setToastAlert("Previous request processing");
      balance = Utils.floor(balance);

      let trxReq = Utils.floor(ktyamount * price * 1e6);
      if(balance < trxReq)return Utils.setToastAlert("Not sufficient TRX balance");

        let instance = await window.tronWeb.contract().at(SaleContract);
        await instance.BuyKty(address).send({
          feeLimit:100000000,
          callValue:trxReq,
        });
        this.fetchSoldTokens()
        this.fetchUserBalance()
        this.setState({isProcess:false})
        Utils.setToastAlert("Transaction sent successfully","success")

    } catch (err) {
      this.setState({isProcess:false})
      if(err === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to send transaction","error")
      }
    }

  }


  render(){
    let {round,price,balance,left,size,ktyamount} = this.state;
    left = Utils.ceil(left/1e6,0);
    let sold = size - left;
    let width = Utils.ceil(sold * 100 / size) ;
    width = width < 10?10:width;
    width = 100;
    return(
      <main className="main">
    		<div className="container">

    				<div className="col-12">
    					<ul className="breadcrumb">
    						<li className="breadcrumb__item"><Link to="/home">Home</Link></li>
                <li className="breadcrumb__item"><Link to="/kty-info">KTY-info</Link></li>
    						<li className="breadcrumb__item breadcrumb__item--active">Genesis offering</li>
    					</ul>
    				</div>

              <div className="genesis-section">
                <div className="row justify-content-center">


                <div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6">
                <div className="genesis-title">
                  <h1>Kraftly (KTY)</h1>
                  <h3>Genesis Offering</h3>
                </div>
                <div className="genesis-rounds">
                  <div className="genesis-round">
                    <span onClick={()=>this.setState({round:1})}>1
                      <RoundWidget data = {[1,0.6,10,round]}/>
                    </span>
                  </div>
                  <div className="genesis-round">
                    <span onClick={()=>this.setState({round:2})}>2
                      <RoundWidget data = {[2,0.7,12.5,round]}/>
                    </span>
                  </div>
                  <div className="genesis-round">
                    <span onClick={()=>this.setState({round:3})}>3
                      <RoundWidget data = {[3,0.8,12.5,round]}/>
                      </span>
                  </div>
                  <div className="genesis-round">
                    <span onClick={()=>this.setState({round:4})}>4
                      <RoundWidget data = {[4,0.85,15,round]}/>
                      </span>
                  </div>
                </div>

                <div className="genesis-form">
                  <div className="sale-meter">
                    <div className="sale-meter-progress" style={{width:width+"%"}}>
                      <span>{Utils.preety.floor(left,0)} / {Utils.preety.floor(size,0)}</span>
                    </div>
                  </div>
                  <div className="genesis-price-info">
                    <p><span>Balance :</span> {Utils.preety.floor(balance/1e6,0)} TRX</p>
                    {ktyamount > 0 && <p>{Utils.preety.floor(ktyamount,0)}<span> KTY</span> for {Utils.preety.ceil(ktyamount*price,1)} TRX</p>}
                  </div>
                  <div className="genesis-input-box">
                    <input
                      type="number"
                      placeholder="Enter KTY amount"
                      value={ktyamount}
                      onChange={this.handleInput}
                      />
                    <button
                      disabled={true}
                      onClick={this.handleBuyKTY}
                      >
                      Offering Closed</button>
                  </div>
                </div>

              </div>
              </div>

    				</div>
    		</div>
    	</main>
    )
  }
}

export default GenesisOffering;
