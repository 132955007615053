import React, { Component } from 'react'
import $ from 'jquery';
import { Link } from 'react-router-dom'
import '../scss/kty.scss';
import {Utils} from "../helper"
import tronWeb from "../tronweb"

const Fox555Contract = "TKrCzUSnsAEJ44gjcWHzM45XQaLgJ8fpQ3";



class SwapForFox extends Component{
  constructor(props){
    super(props)
    this.state ={
      tokenIds:[],
      selectedId:"",
      address:"",
      startAt:1633296600000,
      isProcess:false,
    }
  }
  componentDidMount (){
    document.title = "Swap || FOX555";

    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      const {address} = account
      if(address){
        this.setState({address,tokenIds:[],selectedId:""},this.fetchUserBalance)
      }else {
        this.setState({address:"",tokenIds:[],selectedId:""})
      }
    })
  }

  componentWillUnmount(){
    document.title = "Kraftly";
  }



  fetchUserBalance = async()=>{
    let {address} = this.state
    try {
      let instanceNft = await tronWeb.contract().at(Utils.NftContract);
      let result = await instanceNft.tokensOfOwner(address).call();
      let tokenIds = result.map((j,i)=> tronWeb.toDecimal(j));
      tokenIds = tokenIds.filter(i=> i < 600);
      let selectedId = tokenIds[0];

      this.setState({tokenIds,selectedId});
    } catch (e) {
      console.log(e);
    }
  }

  handleSwap = async() =>{
    try {
      let{selectedId,address,isProcess} = this.state;

      if(!address)return $(".header__action-btn--signin").click();;
      if(!selectedId) return Utils.setToastAlert("No nft selected");

      if(isProcess) return Utils.setToastAlert("Previous request processing");

        let NftInstance = await window.tronWeb.contract().at(Utils.NftContract);
        let allowed = await NftInstance.isApprovedForAll(address,Fox555Contract).call();

        await new Promise( res =>{
          if(allowed){
            res()
          }else {
            let result = NftInstance.setApprovalForAll(Fox555Contract, true).send({
              feeLimit:10000000,
            });
            res(result)
          }
        })

        let instance = await window.tronWeb.contract().at(Fox555Contract);
        let functionName = selectedId < 100?"swapTrock" :"swapCloud";
        await instance[functionName](selectedId).send({
          feeLimit:1500000000,
        });
        this.fetchUserBalance()

        this.setState({isProcess:false})
        Utils.setToastAlert("Swap transaction sent successfully","success")

    } catch (err) {
      console.log(err);
      this.setState({isProcess:false})
      if(err === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to send transaction","error")
      }
    }

  }

  renderTokenIds = () =>{
    let {tokenIds} = this.state;
    if(!tokenIds) return <option value="" key={"vacant"}>Select Nft</option>;

    return tokenIds.map((id,j)=>{
      let tokenId = id >= 100? id - 100:id;
      let name = id >= 100? "Cloud Id ":"Rock Id";
      return <option value={id} key={id}>{name} {tokenId}</option>
    })
  }

  render(){
    let {tokenIds,address,selectedId,startAt} = this.state;
    let timeLeft = startAt - Date.now();

    let btnState = !tokenIds.length || !address || !selectedId || timeLeft > 0;
    return(
      <main className="main">
    		<div className="container">

    				<div className="col-12">
    					<ul className="breadcrumb">
    						<li className="breadcrumb__item"><Link to="/home">Home</Link></li>
                <li className="breadcrumb__item"><Link to="/fox555/mint">FOX555</Link></li>
    						<li className="breadcrumb__item breadcrumb__item--active">Swap</li>
    					</ul>
    				</div>

              <div className="genesis-section">
                <div className="row justify-content-center">


                <div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6">
                <div className="genesis-title">
                  <h1>Swap For FOX55</h1>
                  <h3>Clouds and Trock</h3>
                </div>


                <div className="genesis-form nft-swap">

                  <div className="genesis-price-info">
                    <p><span>TROCKS :</span> 10 FOX</p>
                    <p><span>CLOUDS :</span> 4 FOX</p>
                  </div>

                  <div className="genesis-input-box">
                    <select
                      className="sign__select"
                      onChange={(e)=>this.setState({selectedId:e.target.value})}
                      value={selectedId}
                      >
                      {this.renderTokenIds()}
                    </select>

                    <button
                      onClick={this.handleSwap}
                      disabled={btnState}
                      >
                      Swap </button>
                  </div>
                </div>

              </div>
              </div>

    				</div>
    		</div>
    	</main>
    )
  }
}

export default SwapForFox;
