import React, { Component } from 'react'
import {Utils} from "../../helper"
import Data from "../../pages/market3p/data.js"

import api from "../../api"
import tronWeb from "../../tronweb"
import NftCard,{NftCardDummy,NftCardBlank} from "../nftCard/index.jsx"
import {Paginator,TRC721Model} from "../../frag"

class Collection3p extends Component {
  constructor(props) {
      super(props)

      let limit = this.props.data.tab !== "TPdiyKSHfz4kzFcCx238b6KaeS3advLEWm"?10:7;

      this.state = {
        nfts:[],
        address:this.props.data.address || "",
        isLoaded:false,
        isLoading:false,
        page:1,
        start:1,
        limit,
        tokenIds:[],
      }
      this.myRef = React.createRef();
  }
  componentDidMount = () =>{
    // console.log("Collection3p");
    this.getNftAvailable()
  }
  getNftAvailable = async()=>{
    let { address,tab,count} = this.props.data
    let {page,limit} = this.state;
    let left = count - (( page - 1 ) * limit)
    if(tab && count > 0 && left > 0){
      try {

        limit = left >= limit?limit:left;
        let initial = (page - 1) * limit;
        this.setState({isLoading:true});
        let instance = await tronWeb.contract().at(Utils.ProxyContract);
        let tokenIds = await instance.getNftAvailable(address,tab,initial,limit).call();
        tokenIds = tokenIds.map((j,i)=> tronWeb.toDecimal(j));
        // console.log(tokenIds);
        this.setState({tokenIds,isLoading:false,isLoaded:true},this.fetchNfts);
      } catch (e) {
        console.log(e);
        this.setState({isLoading:false});
        Utils.setToastAlert("Failed to fetch NFT holding");
      }
    }
  }
  fetchNfts = async () =>{
    let{tokenIds} = this.state;
    let {tab} = this.props.data

    if(tokenIds && tokenIds.length){
      try {
        this.setState({isLoading:true})
        let payload ={tokenIds:tokenIds,nftAddress:tab}
        let res = await api.get3pBatchNft(payload);
        let{result} = res.data;

        result = tokenIds.map((item,i)=>{
          let _item = result.filter(i => i.tokenId === item);
          return _item && _item.length?_item[0]:item;
        })

        this.setState({nfts:result,isLoading:false})
      } catch (e) {
        console.log(e.response);
        this.setState({isLoading:false})
      }
    }
  }


  renderNft = () =>{
    let{nfts} = this.state;
    let { address,tab,user} = this.props.data;
    let{username,name,verified,blocked} = user || {};
    // console.log(user);
// console.log(tokenIds);
    return nfts.map((item,i)=>{

      if(!item.name){
        item = {tokenId:item};
      }

      item.owner = address;
      item.nftAddress = tab;
      item.username = username;
      item.user_name = name;
      item.verified = verified;
      item.blocked = blocked;

      return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={item.tokenId+tab+"2"}>
        {!!item.name && <NftCard data={item} key={item.tokenId+tab+"3"}/>}
        {!item.name && <NftCardBlank data={item} key={item.tokenId+tab+"4"+i}/>}

      </div>
    })
  }
  renderDummyNft = () =>{
    let _w = window.innerWidth;
    let count = _w< 576?4:_w< 768?8:_w< 992?12:_w< 1200?15:16;
      return new Array(count).fill(undefined).map((item,i)=>{
        return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={"a"+i}>
          <NftCardDummy key={"b"+i}/>
        </div>
      })
  }

  handleInput = e =>{
    let val = e.target.value;
    if (!val || val.length <= 40) {
      this.setState({filterString:val})
    }
  }
  handlePaginator = (value) =>{
    // console.log(value);
    this.setState(value,this.getNftAvailable)
    this.myRef.current.scrollIntoView({ behavior: 'smooth' })
  }


    render() {
      let{nfts,tokenIds,isLoading,start,limit,page} = this.state;
      let {count} = this.props.data

        return (
            <div className="row" ref={this.myRef}>

              <div className="col-12">
                <div className="row no-gutters">
                {!isLoading && !!nfts.length && this.renderNft()}
                {!!isLoading && !!count && this.renderDummyNft()}
                {!count && <h2 style={{color:"#888",margin:"15px 10px"}}>No NFT found</h2>}
                </div>
              </div>

              <div className="col-12">
                <Paginator  data={{count,start,limit,page,size:tokenIds.length}} handler={this.handlePaginator}/>
              </div>
            </div>
        )
    }
}

class Collection extends Component {
  constructor(props) {
      super(props)
      const urlParams = new URLSearchParams(window.location.search);
      let tab = urlParams.get('nft') || "TGsDs5v2KosDx2fqoQmSC5zVsjrQARMc4U"

      this.state = {
        nfts:[],
        address:this.props.data.address || "",
        isLoaded:false,
        isLoading:false,
        page:1,
        start:1,
        limit:24,
        tokenIds:[],
        owners:[],
        prices:[],
        time:[],
        bids:[],
        tab,
        tokens:JSON.parse(JSON.stringify(Data.getAllSupportedNfts())),
        balances:[],

        isGrid:localStorage.getItem('isGrid') === "true",
      }
      this.myRef = React.createRef();

  }
  componentDidMount = async () =>{
      // this.getKNftHoldings()
      this.getNftHoldings()
      if(this.state.tab === "TGsDs5v2KosDx2fqoQmSC5zVsjrQARMc4U"){
        this.getKNftHoldings()
      }

  }
  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    let _tab = urlParams.get('nft') || "TGsDs5v2KosDx2fqoQmSC5zVsjrQARMc4U";

   if(_tab && _tab !== this.state.tab){

     // console.log("here");
     this.setState({tab:_tab},()=>{
       if(_tab === "TGsDs5v2KosDx2fqoQmSC5zVsjrQARMc4U"){
         this.getKNftHoldings()
       }
     })
   }
 }
  getNftHoldings = async()=>{

      try {
        let { address,tokens} = this.state
        if(!address) return console.log("Wallet not connected");
        let _tokens = tokens.filter(i=> !i.hasOwnProperty("balance")).map(i => i.nftAddress);
        if(!_tokens.length) return console.log("All balance fetched");

        let instance = await tronWeb.contract().at(Utils.ProxyContract);
        let balances = await instance.getNftBalance(address,_tokens).call();
        balances = balances.map((j,i)=> tronWeb.toDecimal(j._hex?j._hex:j));

        tokens = tokens.map((item,i)=>{
          let _index = _tokens.findIndex(j => j === item.nftAddress);
          let _bal = balances[_index]?balances[_index]:item.balance?item.balance:0;
          item.balance = _bal;
          return item;
        })

        this.setState({tokens});
      } catch (e) {
        console.log(e);
        Utils.setToastAlert("Failed to fetch NFT balance");
      }

  }

  getKNftHoldings = async()=>{
    let { address} = this.state
    if(address){
      try {
        let instanceNft = await tronWeb.contract().at(Utils.NftContract);
        let result = await instanceNft.tokensOfOwner(address).call();
        let tokenIds = result.map((j,i)=> tronWeb.toDecimal(j)).reverse();
        this.setState({tokenIds,isLoaded:true},this.fetchNfts);
      } catch (e) {
        console.log(e);
        Utils.setToastAlert("Failed to fetch collections balance");
      }
    }
  }

  fetchNfts = async () =>{
    let{page,limit,tokenIds} = this.state;
      try {
        let skip = (page - 1) * limit;
        let selectedIds = tokenIds.slice(skip,skip+limit);
        if(!selectedIds || !selectedIds.length) return console.log("Failed to get collection data, please reload page again");;

        this.setState({isLoading:true})

        let res = await api.getBatchNft({tokenIds:selectedIds});
        let{result} = res.data;
        // console.log(result);
        result = selectedIds.map((item,i)=>{
          let _item = result.filter(i => i.tokenId === item);
          return _item && _item.length?_item[0]:item;
        })
        this.setState({nfts:result,isLoading:false})
      } catch (e) {
        console.log(e);
        this.setState({isLoading:false})
      }

  }
  renderNft = () =>{
    let{nfts,address,isGrid,tab} = this.state;
    let{username,name,verified,blocked} = this.props.data.user || {};
    let layout={
      isAutoHeight:false,
      isSmallScale:true,
      isMobile: !!isGrid,
    }
    let myAddress = Utils.viewCreator();
    if(!myAddress || address !== myAddress){
      nfts = nfts.filter(i => i.name);
    }
    // console.log(nfts);
    return nfts.map((item,i)=>{
      // console.log(item);
      if(!item.name){
        item = {tokenId:item};
      }
      item.owner = address;
      item.username = username;
      item.user_name = name;
      item.verified = verified;
      item.blocked = blocked;

      if(!!isGrid){
        return <div className="col-6 col-sm-4 col-lg-3 col-xl-2" key={item.tokenId+tab+"smallA"}>
          {!!item.name && <NftCard data={item} layout={layout} key={item.tokenId+tab+"smallB"}/>}
          {!item.name && <NftCardBlank data={item} layout={layout} key={item.tokenId+tab+"smallBL"}/>}
        </div>
      }

      return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={item.tokenId+tab+"largeA"}>
        {!!item.name && <NftCard data={item} layout={layout} key={item.tokenId+tab+"largeB"}/>}
        {!item.name && <NftCardBlank data={item} layout={layout} key={item.tokenId+tab+"largeBl"}/>}
      </div>
    })
  }
  renderDummyNft = () =>{
    let{isGrid} = this.state;
    let layout={
      isAutoHeight:false,
      isSmallScale:true,
      isMobile: !!isGrid,
    }

    let _w = window.innerWidth;
    let count = _w< 576?4:_w< 768?8:_w< 992?12:_w< 1200?15:16;
    if(isGrid){
      count = _w< 576?8:_w< 768?12:_w< 992?15:_w< 1200?16:18;
    }
      return new Array(count).fill(undefined).map((item,i)=>{

        if(!!isGrid){
          return <div className="col-6 col-sm-4 col-lg-3 col-xl-2" key={"smallAD"+i}>
            <NftCardDummy layout={layout} key={"smallB"+i}/>
          </div>
        }

        return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={"largeAD"+i}>
          <NftCardDummy layout={layout} key={"largeB"+i}/>
        </div>
      })
  }



  onOption = (value)=>{
    let{isLoading,tab} = this.state
    if(isLoading) return Utils.setToastAlert("Previous request processing","info");
    if(tab === value) return console.log("same option");
    // console.log(value);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("nft", value)
    let params = urlParams.toString();
    if (window.history.pushState) {
      let url = `${window.location.pathname}?${params}`;
      window.history.pushState(null, null,url);
    }
    this.setState({tab:value},()=>{
      if(value === "TGsDs5v2KosDx2fqoQmSC5zVsjrQARMc4U"){
        this.getKNftHoldings()
      }
    })
  }


  handlePaginator = (value) =>{
    let{isLoading} = this.state;
    if(isLoading) return Utils.setToastAlert("Please wait while we finish previous request");
    // console.log(value);
    this.setState(value,this.fetchNfts)
    this.myRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  handleUpdateTokenList = (tokens) =>{
    this.setState({tokens},this.getNftHoldings)
  }

    render() {
      let{nfts,tokenIds,isLoading,isLoaded,tab,balances,tokens,address,start,limit,page} = this.state;
      let{user} = this.props.data;
      let isKnft = !tab || tab === "TGsDs5v2KosDx2fqoQmSC5zVsjrQARMc4U";

      let _token = tokens.find(i => i.nftAddress === tab);
      let count = _token && _token.balance?_token.balance:0;

        return (
          <div className="tab-pane active">
            <TRC721Model.AllTokens
              data={{tokens,balances,tab,view:"wallet"}}
              onOption={(e)=>this.onOption(e)}
              handleUpdateTokenList={this.handleUpdateTokenList}
              key="wallet"
              />
            {!isKnft && <Collection3p data={{tab,address,count,user}} key={tab+address+count}/>}

            {!!isKnft && <div className="row no-gutters" ref={this.myRef}>
              {!isLoading && !!nfts.length && this.renderNft()}
              {(!!isLoading || !isLoaded) && this.renderDummyNft()}
              {!isLoading && !!balances.length && !balances[0] && <h2 style={{color:"#888",margin:"15px 10px"}}>No NFT found</h2>}
              <div className="col-12">
                <Paginator  data={{count:tokenIds.length,start,limit,page,size:nfts.length}} handler={this.handlePaginator}/>
              </div>
            </div>}
          </div>
        )
    }
}




export default Collection
