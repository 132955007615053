import React, {Component,Fragment} from 'react';
import { Link } from 'react-router-dom'
import api from "../../api"
import {Utils,SearchNotFound} from "../../helper"
import AuthorCard,{AuthorCardDummy} from "../../components/authorCard/index.jsx"
import {Paginator,MenuSelect3} from "../../frag"





class Authors extends Component {

  constructor(props) {
      super(props)
      const urlParams = new URLSearchParams(window.location.search);
      let page = urlParams.get('page');
      page = Utils.floor(page)
      page = page && page > 0?page:1;
      let start = page > 4?page -3:1;

      let filterString = this.props.filterString ||"";

      this.state = {
        address:"",
        isLoading:false,
        authors:[],
        page,
        start,
        limit:20,
        count:0,
        category:"top",
        filterString,
      }
      this.myRef = React.createRef();
  }
  componentDidMount = () =>{
    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      // console.log(account);
      const {address} = account
      this.setState({address})
    })

    this.fetchAuthors()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let{page,filterString} = this.state;
      const urlParams = new URLSearchParams(window.location.search);
      let _page = urlParams.get('page');
      _page = Utils.floor(_page);
      if(_page !== page){
        page = _page && _page > 0?_page:1;
        let start = page > 4?page -3:1;
        this.setState({page,start},this.fetchAuthors)
      }else if (this.props.callback && nextProps.filterString !== filterString) {
        this.setState({category:"all",result:[],count:0,page:1,start:1,filterString:nextProps.filterString},this.fetchAuthors)
      }
     }

  updatePagingUrl = () =>{
    let{page} = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("page", page)
    let params = urlParams.toString();
    if (window.history.pushState) {
      let url = `${window.location.pathname}?${params}`;
      window.history.pushState(null, null,url);
    }

    if(this.props.callback){
      this.props.callback(this.state.filterString)
    }

  }
  onOption = (name,value)=>{
    let{isLoading} = this.state
    if(isLoading) return Utils.setToastAlert("Previous request processing","info");
    if(this.state[name] === value) return console.log("same option");
    this.setState({[name]:value,result:[],count:0},this.fetchAuthors)
  }
  fetchAuthors = async () =>{
    let{isLoading,page,limit,category,filterString} = this.state;
      try {
        if(isLoading) return Utils.setToastAlert("Previous request processing","info");
        this.setState({isLoading:true})
        let payload = !!filterString?{filterString}:{page,limit,category}
        let res = await api.getAuthors(payload);
        // console.log(res);
        let{result,count} = res.data;
        this.setState({authors:result,count,isLoading:false},this.updatePagingUrl)
      } catch (e) {
        // console.log(e);
        // console.log(e.response);
        let msg = e?.response?.data?.message??"Failed to fetch authors"
        Utils.setToastAlert(msg,"error");
        this.setState({isLoading:false})
      }
  }
  fetchFilterData = (event) =>{
    event.preventDefault()
    this.setState({category:"all",result:[],count:0,page:1,start:1},this.fetchAuthors)
  }

  renderAuthors = () =>{
    let{authors} = this.state;
    // authors = authors.concat(emptyPlace);
    return authors.map((item,i)=>{
      return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={item.address+1}>
        <AuthorCard data={item} key={item.address+2}/>
      </div>
    })
  }
  renderDummyAuthors = () =>{
    let count = window.innerWidth < 576?4:window.innerWidth < 992?8:window.innerWidth < 1200?12:16;
    return new Array(count).fill(undefined).map((item,i)=>{
      return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={i}>
        <AuthorCardDummy/>
      </div>
    })
  }

  handleInput = e =>{
    let val = e.target.value;
    if (!val || val.length <= 40) {
      this.setState({filterString:val})
    }
  }
  handlePaginator = (value) =>{
    this.setState(value,this.fetchAuthors)
    this.myRef.current.scrollIntoView({ behavior: 'smooth' })
  }

    render() {
      let{category,authors,isLoading,filterString,count,start,limit,page} = this.state;
      let options = {top:"Top Sellers",all:"All Users",verified:"Verified Users",mostFollowed:"Most followed",new:"New Users"};
        return (
        			<Fragment>

        				<div className="col-12" ref={this.myRef}>
        					<div className="ik__home__filter">
        						<form onSubmit={this.fetchFilterData.bind(this)} className="main__filter-search">
        							<input
                        type="text"
                        placeholder="Search name , username or address"
                        value = {filterString}
                        onChange={this.handleInput}
                        />
        							<button
                        onClick={this.fetchFilterData}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
                      </button>
        						</form>
                    <MenuSelect3 options={options} value={category} onOption={(e)=>this.onOption("category",e)}/>

        					</div>
        				</div>

                <div className="row no-gutters">
                  {!isLoading && !!authors.length && this.renderAuthors()}
                  {!!isLoading && this.renderDummyAuthors()}
                </div>

                {!isLoading && !authors.length && <div className="col-12"><SearchNotFound data={{msg:"Match Not Found",code:"Account"}}/></div>}

                <div className="col-12">
                  <Paginator  data={{count,start,limit,page,size:authors.length}} handler={this.handlePaginator}/>
                </div>
        			</Fragment>
        )
    }
}


class AuthorsPage extends Component {

  constructor(props) {
      super(props)
      this.state = {

      }
  }
  componentDidMount = () =>{
    document.title = "Authors || Kraftly";
  }
  componentWillUnmount(){
    document.title = "Kraftly";
  }


    render() {
        return (
          <main className="main">
        		<div className="container">
        			<div className="row no-gutters">
        				<div className="col-12">
        					<ul className="breadcrumb">
        						<li className="breadcrumb__item"><Link to="/home">Home</Link></li>
                    <li className="breadcrumb__item">Community</li>
        						<li className="breadcrumb__item breadcrumb__item--active">Authors</li>
        					</ul>
        				</div>

        				<div className="col-12">
        					<div className="section__title section__title__middle">
        						Users/Authors
        					</div>
        				</div>
                <Authors />

        			</div>
        		</div>
        	</main>
        )
    }
}
export default AuthorsPage

export{Authors }
