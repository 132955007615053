
import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {Utils} from "../helper"

import tronWeb from "../tronweb"
import api from "../api"
import * as Parts from "./parts"

import NftCard,{NftCardDummy} from "../components/nftCard/index.jsx"

import {CollectionBody} from "../components/profile-body";
import {Paginator,GridToggler2} from "../frag"



const StyledLink  = styled(Link).attrs({
    target : "_blank",
    rel : "noopener noreferrer"
})``;




class IkCollectionGallery extends Component{
  constructor(props) {
      super(props)
      // let limit = window.innerWidth < 768?20:30;
      const urlParams = new URLSearchParams(window.location.search);
      let page = urlParams.get('page');
      page = Utils.floor(page)
      page = page && page > 0?page:1;
      let start = page > 4?page -3:1;

      let collectionId = this.props.match.params.collectionId || 0;
      collectionId = Utils.ceil(collectionId,0);


      let filterString = urlParams.get('s');
      filterString = Utils.floor(filterString);
      filterString = !!filterString?filterString:"";

      this.state = {
        isoldFetching:false,

        isLoading:false,
        collectionId,

        count:0,
        page,
        start,
        limit:12,
        nfts:[],
        filterString,

        isGrid:localStorage.getItem('isGrid') === "true",

        collection : {},
        authorDoc : {},
        nftDoc : {},
        selectedTokenId:0,
        selected:"",

      }
      this.myRef = React.createRef();
  }
  componentDidMount (){
    // document.title = "My Mintable Collections";
    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);
      let {address} = account
      // address = "TJ9DXJnYzLWjodSuQaUN3JQmffR7HAgd18";

      if(address){
        this.setState({address})
      }else {
        this.setState({address:""})
      }
    })
    this.fetchCollectionDetails()
  }


  componentWillUnmount(){
    document.title = "Kraftly";
  }

  checkOldBlindCollection = async() =>{
    let{collection,collectionId,isoldFetching} = this.state;
    try {
      if(!!isoldFetching || !collection || !collection.author || collectionId >= 100) return "";
      this.setState({isoldFetching:true})

      let instance = await tronWeb.contract().at(Utils.BlindContract);
      let res = await instance.getCollection(collection.author).call();
      let [baseId,,mintedSize,,,] = res;
      // console.log(res);
      baseId = tronWeb.toDecimal(baseId._hex);
      if(!baseId) return console.log("Collection does not exists");;
      mintedSize = tronWeb.toDecimal(mintedSize);
      if(mintedSize > collection.mintedSize){
        collection.mintedSize = mintedSize;
        this.setState({collection})
        await api.updateOldBlindCol({collectionId:collection.collectionId});
        this.setState({isoldFetching:false})
      }
    } catch (e) {
        // console.log(e);
        // console.log(e.response);
        this.setState({isoldFetching:false})

    }
  }

  updatePagingUrl = () =>{
    let{page} = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("page", page)
    let params = urlParams.toString();
    if (window.history.pushState) {
      let url = `${window.location.pathname}?${params}`;
      window.history.pushState(null, null,url);
    }
  }


  fetchCollectionDetails = async() =>{
      try {
        let {collectionId,isLoading} = this.state;
        if(!collectionId) return console.log("Collection not exists");
        if(isLoading) return Utils.setToastAlert("Previous request processing","info");
        this.setState({collection:{},isLoading:true})

        let res = await api.getIkCollectionDetails({collectionId});
        // console.log(res.data);
        let{collection,authorDoc,nftDoc} = res?.data??{};
        collection = collection || {};
        authorDoc = authorDoc || {};
        nftDoc = nftDoc || {};

        this.setState({collection,authorDoc,nftDoc,isLoading:false},()=>{
          this.fetchNfts();
          this.checkOldBlindCollection();
        });
        document.title = `${collection.name || collectionId} | gallery`;

      } catch (e) {
        // console.log(e);
        this.setState({isLoading:false})
        let msg = e.response?.data?.message??"Unexpected error occured, please check your connection and refresh again";
        Utils.setToastAlert(msg,"error");

      }
    }

  fetchNfts = async()=>{
    let{collectionId,filterString,isLoading,page,limit} = this.state;
      try {
        if(isLoading) return Utils.setToastAlert("Previous request processing","info");
        this.setState({isLoading:true})
        let payload = {collectionId,filterString,page,limit}
        // console.log(limit);
        let res = await api.getIkGallery(payload);
        // console.log(res);
        let{result,count} = res.data;

        this.setState({nfts:result,count,isLoading:false},()=>{
          this.updatePagingUrl();
          this.fetchDataFromChain();
        })

      } catch (e) {
        console.log(e.response);
        let msg = e.response?.data?.message??"No NFT found";
        Utils.setToastAlert(msg,"error");
        // console.log(e.response);
        this.setState({isLoading:false})
      }
  }



  fetchFilterData = async(event) =>{
    event.preventDefault()
    let{nfts} = this.state;
      let{filterString,isLoading} = this.state;
      filterString = Utils.floor(filterString);
      if(!filterString && nfts.length !== 1) return Utils.setToastAlert("Search Token id only","info");
      if(isLoading) return Utils.setToastAlert("Previous request processing","info");

      if(!filterString){
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete("s")
        let params = urlParams.toString();
        if (window.history.pushState) {
          let url = `${window.location.pathname}?${params}`;
          window.history.pushState(null, null,url);
        }
      }
      this.setState({page:1,start:1},this.fetchNfts)
  }


  fetchDataFromChain = async() =>{
    try {

      let{collection,nfts} = this.state;
      let{collectionId,nftAddress}= collection;

      if(!nfts || !nfts.length) return "";
      let tokenIds = nfts.map(i => i.tokenId);

      if(collectionId >= 100){
        let instance = await tronWeb.contract().at(Utils.KnftCollection);
        let [minters,bidders,bids,bidTimes] = await instance.nftData(nftAddress,tokenIds).call();
        // console.log(data);
        nfts = nfts.map((item,i)=>{

          item.bidder = "";
          item.bid = 0;
          item.bidTime = 0;
          item.minter = "";

          let minter = tronWeb.address.fromHex(minters[i])
          let bid = tronWeb.toDecimal(bids[i])
          // console.log(minter);
          if(minter !== "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"){
            item.minter = minter;
          }else if (bid > 0) {
            item.bid = bid;
            item.bidder = tronWeb.address.fromHex(bidders[i]);
            item.bidTime = tronWeb.toDecimal(bidTimes[i]);
          }
          return item;
        })
        this.setState({nfts})

      }else {

        this.checkOldBlindCollection();

        // fetch data from old contract
        let instance = await tronWeb.contract().at(Utils.BlindContract);
        let minters = await instance.mintersOf(tokenIds).call();

        nfts = nfts.map((item,i) => {
          item.bidder = "";
          item.bid = 0;
          item.bidTime = 0;
          item.minter = "";

          let minter = tronWeb.address.fromHex(minters[i])
          if(minter !== "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"){
            item.minter = minter;
          }
          return item;
        });
        this.setState({nfts})
      }

    } catch (e) {
      console.log(e);
    }
  }

  handleRefresh = () =>{
    this.setState({selectedTokenId:0,selected:""},this.fetchDataFromChain)
  }


   renderNft = () =>{
     let{collection,nfts,authorDoc,isGrid,address} = this.state;
     let {collectionType,author} = collection;
     let isAuthor = !!address && address === author;

     let _nfts = JSON.parse(JSON.stringify(nfts));
     let layout = {
       isIkMint : true,
       isMobile: !!isGrid,
       isAutoHeight : false,
     }
     return _nfts.map((data,i)=>{
       let {minter,tokenId,bid,bidder,nftAddress} = data;
       let isBidder = !!address && address === bidder;

       data.user_name = authorDoc.name;
       data.verified = authorDoc.verified;
       data.blocked = authorDoc.blocked;
       data.owner = author;
       delete data.collectionId;

       return <div className={`col-${!!isGrid?6:12} col-sm-${!!isGrid?4:6} col-lg-${!!isGrid?4:3} col-xl-${!!isGrid?2:3}`} key={tokenId+nftAddress+"largeA"}>
         <NftCard data={data} layout={layout} key={tokenId+nftAddress+"largeB"}>


           {!!minter && <div className="card__info__price">
             <span>Minted By</span>
               <StyledLink className="nft__amount" to={`/author/${minter}/profile?tab=collection`}>
                 {Utils.shortAddress(minter,true)}
               </StyledLink>
           </div>}



           {!minter && <div className="card__btns">
             {collectionType !== 1 && !isAuthor && <button
               className="buy_btn"
               type="button"
               onClick={()=>this.setState({selected:"buy",selectedTokenId:tokenId})}
               ><span>Buy</span></button>}
             {collectionType !== 1 && !isAuthor && !isBidder && <button
               className="buy_btn"
               type="button"
               onClick={()=>this.setState({selected:"offer",selectedTokenId:tokenId})}
               ><span>Offer</span></button>}

             {collectionType !== 1 && !!isBidder && <button
               className="buy_btn"
               type="button"
               onClick={()=>this.setState({selected:"cancel",selectedTokenId:tokenId})}
               ><span>Cancel</span></button>}

             {collectionType !== 1 && !!isAuthor && !!bid && <button
               className="buy_btn"
               type="button"
               onClick={()=>this.setState({selected:"accept",selectedTokenId:tokenId})}
               ><span>Accept</span></button>}

             {collectionType === 1 && !isAuthor && <button
               className="buy_btn"
               type="button"
               onClick={()=>this.setState({selected:"blind",selectedTokenId:0})}
               ><span>Random Mint</span></button>}

           </div>}
         </NftCard>
       </div>
     })
   }

   renderDummyNft = () =>{
     let {isGrid} = this.state;
     let layout = {
       isIkMint : true,
       isMobile:!!isGrid,
     }
     let _w = window.innerWidth;
     let count = _w< 576?4:_w< 768?8:_w< 992?12:_w< 1200?15:16;
     if(isGrid){
       count = _w< 576?8:_w< 768?12:_w< 992?15:_w< 1200?16:18;
     }
       return new Array(count).fill(undefined).map((item,i)=>{
         return <div className="col-12 col-sm-4 col-md-4 col-xl-3" key={"largeAD"+i}>
           <NftCardDummy layout={layout} key={"largeB"+i}/>
         </div>
       })
   }


  handlePaginator = (value) =>{
    this.setState(value,this.fetchNfts)
    this.myRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  render(){
    let {collection,authorDoc,nftDoc,count,start,limit,page,nfts,isLoading,filterString,isGrid,selectedTokenId,selected} = this.state
    let {collectionType} = collection;
    let _selectedNft = nfts.find(i => i.tokenId === selectedTokenId);

    return(
      <CollectionBody
        data={{collection,authorDoc,nftDoc}}
        handler={this.fetchCollectionDetails}
        mintHandler={()=>this.setState({selected:"blind",selectedTokenId:0})}
        key={collection.collectionId || "collection"}>


        {selected === "buy" && <Parts.BuyNft
          data={{nft:_selectedNft,collection,nftDoc}}
          handleState={(e=>this.setState(e))}
          refresh={this.handleRefresh}
          />}

        {selected === "offer" && <Parts.OfferNft
          data={{nft:_selectedNft,collection,nftDoc}}
          handleState={(e=>this.setState(e))}
          refresh={this.handleRefresh}
          />}

        {selected === "cancel" && <Parts.WithdrawOfferNft
          data={{nft:_selectedNft,collection,nftDoc}}
          handleState={(e=>this.setState(e))}
          refresh={this.handleRefresh}
          />}

        {selected === "accept" && <Parts.AccceptOfferNft
          data={{nft:_selectedNft,collection,nftDoc}}
          handleState={(e=>this.setState(e))}
          refresh={this.handleRefresh}
          />}

        {selected === "blind" && collectionType ===1 && <Parts.BlindMintIk
          data={{collection}}
          handleState={(e=>this.setState(e))}
          refresh={this.handleRefresh}
          />}


        <div className="row " ref={this.myRef}>

          <div className="col-12">
            <div className="ik__home__filter">
              <form onSubmit={this.fetchFilterData} className="main__filter-search">
                <input
                  type="number"
                  placeholder="Search token Id..."
                  value = {filterString}
                  onChange={(e)=>this.setState({filterString:e.target.value})}
                  />
                <button
                  type="button"
                  onClick={this.fetchFilterData}
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
                </button>
              </form>
              <GridToggler2
                data={{isGrid}}
                handleGrid={(e)=>this.setState(e)}
                />
            </div>
          </div>

            <div className="col-12">
              <div className={`row no-gutters equal`}>
                {!isLoading && !!nfts.length && this.renderNft()}
                {!!isLoading  && this.renderDummyNft()}
                {!isLoading  && !nfts.length && <p className="ik__notfound__msg">No result found</p>}
              </div>
          </div>
          <div className="col-12">
            <Paginator  data={{count,start,limit,page,size:nfts.length}} handler={this.handlePaginator}/>
          </div>
        </div>

      </CollectionBody>
    )
  }
}



export default IkCollectionGallery;
