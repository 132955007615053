
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as helper from '../helper'
import * as pages from './'
import {Utils} from "../helper"


class Admin extends React.Component{
  constructor(props) {
      super(props)
      this.state = {
        address:"",
        isLogged:false,
      }
  }
  componentDidMount (){
    document.title = "Admin || Rock";
    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      const {address} = account
      if(address){
        this.setState({address,isLogged:false},this.checkAdmin)
      }else {
        this.setState({address:"",isLogged:false})
      }
    })
  }

  checkAdmin = async() =>{
    const {address} = this.state;
    try {
      let instance = await window.tronWeb.contract().at(Utils.NftContract);
      let isLogged = await instance.isMinter(address).call();
      // console.log(isLogged);
      this.setState({isLogged})
    } catch (e) {
      console.log(e);
    }
  }

  render(){
    let { isLogged,address} = this.state
    // isLogged = true;
    return (
      <div className="main">
      {!isLogged && !address && <h1>Please connect wallet</h1>}
      {!isLogged && !!address && <h1>Look like not an admin account</h1>}

        {isLogged && <Router>
            <Switch>
                  <Route path={["/admin","/admin/authors"]} exact component={pages.Authors} />
                  <Route path="/admin/nfts" exact component={pages.Explore} />
                  <Route path="/admin/revenue" exact component={pages.TronRevenue} />

                  <Route component={helper.PageNotFound} />

            </Switch>
        </Router>}
      </div>
    )
  }
}

export default Admin
