import React, {Component} from 'react'
// import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {Utils} from "../../helper"
import {DpViewer} from "../../helper/file-viewer.jsx"

import api from "../../api"
import {HomeBtnSelect} from "../../frag"

import {ReactComponent as Verified} from '../../images/verified_star.svg';
import {ReactComponent as Blocked} from '../../images/banned.svg';

import CarouselCollection from "../../components/carousel-collection/index.jsx";
import CarouselIkCollection from "../../components/carousel-ikcollection/index.jsx"
import CarouselTRC721 from "../../components/carousel-trc721/index.jsx"



class HomeCarouselCards extends Component{
  constructor(props) {
      super(props)
      this.state = {
        authors:[],
        type:"ikCol",
      }
  }

  onOption = (name,value)=>{
    let{isLoading} = this.state
    if(isLoading) return Utils.setToastAlert("Previous request processing","info");
    if(this.state[name] === value) return console.log("same option");
    this.setState({[name]:value})
  }

  render(){
    let {type} = this.state;
    let typeOptions = {
      users:"Active Users",
      ikCol:"Mintable Collections",
      trc721:"Custom Collections",
    };

    return(
      <section className="content__section">
        <div className="row no-gutters">
          <div className="col-12">
            <div className="section__title section__title__large">
              <HomeBtnSelect options={typeOptions} value={type} onOption={(e)=>this.onOption("type",e)} isLarge/>
            </div>
          </div>

          <div className="col-12">
            {type === "ikCol" && <CarouselIkCollection />}
            {type === "users" && <CarouselCollection />}
            {type === "trc721" && <CarouselTRC721 />}
          </div>
        </div>
      </section>

    )
  }
}

class TopAuthor extends Component{
  constructor(props) {
      super(props)
      this.state = {
        authors:[],
        duration:"all",
        type:"sellers",
        isLoading:false
      }
  }
  componentDidMount (){
    this.fetchAuthors()
  }
  fetchAuthors = async() =>{
    try {
      let{isLoading,duration,type} = this.state
      if(isLoading) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isLoading:true});
      // console.log(duration,type);
      let res = await api.getTopAuthors({duration,type})
      let authors = res.data.result;
      // console.log(authors);
      // let dum = Array(12).fill(authors[0])
      // authors = [...authors,...dum]
      this.setState({authors,isLoading:false})
    } catch (e) {
      // console.log(e.response);
      this.setState({isLoading:false})
    }
  }
  renderAuthors = () =>{
    let{authors} = this.state
    let usdtRate = Utils.viewUsdtRate();

    let emptyPlace =  !authors || authors.length <= 15 ?new Array(15 - authors.length).fill(undefined):[];
    // let emptyPlace =  !authors || !authors.length?new Array(15).fill(undefined):[];

    authors = authors.concat(emptyPlace);
    return authors.map((item,i)=>{
      if(!item){
        return <li key={i}>
          <span className="sellers-list__number" >{i+1}</span>
          <div className="sellers-list__author dummy_space">
            <img src="/asset/img/avatars/avatar5.jpg" alt=""/>
              <Link to={"#"}></Link>
            <span></span>
          </div>
        </li>
      }
      let {owner,amount,user_name,username,verified,blocked} = item;
      let ownerName = user_name?user_name:Utils.viewOwner(username || owner,32)

      return <li key={owner+''+i}>
        <span className="sellers-list__number" >{i+1}</span>
        <div className="sellers-list__author">
          <DpViewer address={owner} key={owner}/>
            <Link to={`/author/${owner}/profile`}>
              {ownerName}
              {!!verified && <Verified />}
              {!!blocked && <Blocked />}
            </Link>
          <span>{Utils.largePreety(amount/1e6)} TRX <p> ~${Utils.largePreety(amount*usdtRate/1e6,0)}</p></span>
        </div>
      </li>;
    })
  }
  renderDummyAuthors = () =>{

    return new Array(15).fill(undefined).map((item,i)=>{
        return <li key={i}>
          <span className="sellers-list__number" >{i+1}</span>
          <div className="sellers-list__author dummy_space">
            <img src="/asset/img/avatars/avatar5.jpg" alt=""/>
              <Link to="#"></Link>
            <span></span>
          </div>
        </li>
      })
  }
  onOption = (name,value)=>{
    let{isLoading} = this.state
    if(isLoading) return Utils.setToastAlert("Previous request processing","info");
    if(this.state[name] === value) return console.log("same option");
    this.setState({[name]:value},this.fetchAuthors)
  }
  render(){
    let{duration,type,isLoading} = this.state;
    let typeOptions = {sellers:"Sellers",buyers:"Buyers"};
    let durationsOptions = {"1d":"1 day","7d":"7 days","30d":"30 days","all":"_ days"};


    return(
      <section className="content__section">
        <div className="row no-gutters">
          <div className="col-12">
            <div className="section__title">
              Top
                <HomeBtnSelect options={typeOptions} value={type} onOption={(e)=>this.onOption("type",e)}/>
                in
                <HomeBtnSelect options={durationsOptions} value={duration} onOption={(e)=>this.onOption("duration",e)}/>
            </div>
          </div>

          <div className="col-12">
            <div className="sellers-list--outer">
              <ul className="sellers-list">
                {!isLoading && this.renderAuthors()}
                {!!isLoading && this.renderDummyAuthors()}
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }
}


export{
  HomeCarouselCards,
  TopAuthor,

}
