
import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {Utils} from "../helper"
import api from "../api"
import * as Parts from "./parts"

import {CollectionBody} from "../components/profile-body";
import {Paginator} from "../frag"

import NftCard,{NftCardDummy,NftCardBlank} from "../components/nftCard/index.jsx"
import {PreviewEachNft} from "./preview"


const StyledLink  = styled(Link).attrs({
    target : "_blank",
    rel : "noopener noreferrer"
})``;



class CollectionFill extends Component{
  constructor(props) {
      super(props)
      // let limit = window.innerWidth < 768?20:30;
      const urlParams = new URLSearchParams(window.location.search);
      let page = urlParams.get('page');
      page = Utils.floor(page)
      page = page && page > 0?page:1;
      let start = page > 4?page -3:1;

      let collectionId = this.props.match.params.collectionId || 0;
      collectionId = Utils.ceil(collectionId,0);

      this.state = {
        isLoading:false,
        collectionId,
        address:'',

        filled:0,
        count:0,
        page,
        start,
        limit:12,
        nfts:[],


        selected:"",
        json_data:[],
        multi_files:[],
        collection : {},
        authorDoc : {},
        nftDoc : {},

      }
      this.myRef = React.createRef();
  }
  componentDidMount (){
    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);
      let {address} = account
      // address = "TPVkcFYTEi9Dia45AveiTcaYoaU9ux7xC7";
      this.setState({nfts:[],json_data:[],multi_files:[],collection:{}})

      if(address){
        this.setState({address},this.fetchCollectionDetails)
      }else {
        this.setState({address:""})
      }
    })

  }
  componentWillUnmount(){
    document.title = "Kraftly";
  }


  updatePagingUrl = () =>{
    let{page} = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("page", page)
    let params = urlParams.toString();
    if (window.history.pushState) {
      let url = `${window.location.pathname}?${params}`;
      window.history.pushState(null, null,url);
    }
  }


  fetchCollectionDetails = async() =>{
    try {
      let {collectionId,isLoading,address} = this.state;
      if(!collectionId || !address) return console.log("Collection not exists");
      if(isLoading) return Utils.setToastAlert("Previous request processing","info");
      this.setState({collection:{},isLoading:true})
      // console.log("here");
      let res = await api.getIkCollectionDetails({mode:"author",address,collectionId});
      let{collection,authorDoc,nftDoc} = res.data;
      collection = collection || {};
      authorDoc = authorDoc || {};
      nftDoc = nftDoc || {};
      // console.log(res);
      this.setState({collection,authorDoc,nftDoc,isLoading:false},this.fetchNfts);
      document.title = collection.name || `${collectionId} | collection`;

    } catch (e) {
      // console.log(e.response);
      this.setState({isLoading:false})
      let msg = e.response?.data?.message??"Failed to fetch collection profile";
      Utils.setToastAlert(msg,"error");

    }
  }


  fetchNfts = async()=>{
    let{collectionId,isLoading,page,limit} = this.state;
      try {
        if(isLoading) return Utils.setToastAlert("Previous request processing","info");
        this.setState({isLoading:true})
        let payload = {collectionId,page,limit}
        // console.log(limit);
        let res = await api.getAuthorIkCollection(payload);
        let{result,count} = res.data;
        // console.log(result);
        // console.log(count);
        this.setState({nfts:result,filled:count,isLoading:false},()=>{
          this.updatePagingUrl();
          this.attachJsonInBlankNfts()
        })
      } catch (e) {
        // console.log(e);
        this.setState({isLoading:false})
        let msg = e.response?.data?.message??"No NFT found";
        Utils.setToastAlert(msg,"error");
        // console.log(e.response);
      }
  }


  renderNft = () =>{
    let{collection,nfts,isGrid,address,multi_files} = this.state;
    let {collectionType,author} = collection;
    let isAuthor = !!address && address === author;

    let layout = {
      isIkMint : true,
      isMobile: !!isGrid,
      isAutoHeight : false,
    }
    return nfts.map((data,i)=>{
      let {minter,tokenId,bid,bidder,isFilled,nftAddress} = data;
      let isBidder = !!address && address === bidder;
      nftAddress = !!nftAddress?nftAddress:collection.nftAddress?collection.nftAddress:"";

      let imgData = multi_files.find(j => j.name === data.file);
      if(imgData && !isFilled){
        data.imgData = imgData;
      }
      return <div className={`col-${!!isGrid?6:12} col-sm-${!!isGrid?4:6} col-lg-${!!isGrid?4:3} col-xl-${!!isGrid?2:3}`} key={tokenId+nftAddress+"largeA"}>
        {!isFilled && <NftCardBlank data={data} layout={layout} key={tokenId+nftAddress+"largeAB"}>
        {!!isAuthor && <button
            className="card__likes card__edit"
            onClick={()=>this.handleMarkOpen(tokenId)}
            >Upload</button>}
        </NftCardBlank>}

        {!!isFilled && <NftCard data={data} layout={layout} key={tokenId+nftAddress+"largeB"}>
          {!!minter && <div className="card__info__price">
            <span>Minted By</span>
              <StyledLink className="nft__amount" to={`/author/${minter}/profile?tab=collection`}>
                {Utils.shortAddress(minter,true)}
              </StyledLink>
          </div>}
          {!minter && <div className="card__btns">
            {collectionType !== 1 && !isAuthor && <button
              className="buy_btn"
              type="button"
              onClick={()=>this.setState({selected:"buy",selectedTokenId:tokenId})}
              ><span>Buy</span></button>}
            {collectionType !== 1 && !isAuthor && !isBidder && <button
              className="buy_btn"
              type="button"
              onClick={()=>this.setState({selected:"offer",selectedTokenId:tokenId})}
              ><span>Offer</span></button>}

            {collectionType !== 1 && !!isBidder && <button
              className="buy_btn"
              type="button"
              onClick={()=>this.setState({selected:"cancel",selectedTokenId:tokenId})}
              ><span>Cancel</span></button>}

            {collectionType !== 1 && !!isAuthor && !!bid && <button
              className="buy_btn"
              type="button"
              onClick={()=>this.setState({selected:"accept",selectedTokenId:tokenId})}
              ><span>Accept</span></button>}

            {collectionType === 1 && !isAuthor && <button
              className="buy_btn"
              type="button"
              onClick={()=>this.setState({selected:"blind",selectedTokenId:0})}
              ><span>Random Mint</span></button>}

          </div>}
        </NftCard>}
      </div>
    })
  }

  renderDummyNft = () =>{
    let {isGrid} = this.state;
    let layout = {
      isIkMint : true,
      isMobile:!!isGrid,
    }
    let _w = window.innerWidth;
    let count = _w< 576?4:_w< 768?8:_w< 992?12:_w< 1200?15:16;
    if(isGrid){
      count = _w< 576?8:_w< 768?12:_w< 992?15:_w< 1200?16:18;
    }
      return new Array(count).fill(undefined).map((item,i)=>{
        return <div className="col-12 col-sm-4 col-md-4 col-xl-3" key={"largeAD"+i}>
          <NftCardDummy layout={layout} key={"largeB"+i}/>
        </div>
      })
  }


  handleMarkOpen = (val) =>{
    val = val || 0;
    let{nfts,editPanelTokenId} = this.state;
    // console.log(val);
    if(val && isNaN(val)){

      let vacantNft = nfts.filter(i=> !i.isFilled);
      // console.log(vacantNft);
      if (!vacantNft || !vacantNft.length) {
        val = 0;
        this.fetchNfts()
     }else if(val === "prev"){
       let _index = vacantNft.filter(i => i.tokenId < editPanelTokenId);

         if (_index.length > 0) {
          val = _index[_index.length - 1].tokenId;
        }else {
          val = vacantNft[0].tokenId;
        }
      }else if(val === "next"){
        let _index = vacantNft.filter(i=> i.tokenId > editPanelTokenId);
        // console.log(_index);
        if(_index.length > 0){
          val = _index[0].tokenId;
        }else {
          val = vacantNft[0].tokenId;
        }
      }else if (val === "submit") {
        this.fetchNfts()
        // nfts.find(i => i.tokenId === editPanelTokenId).isFilled = true;
        vacantNft = nfts.filter(i=> !i.isFilled && i.tokenId !== editPanelTokenId);
        val = vacantNft.length?vacantNft[0].tokenId:0;
      }
    }
    this.setState({editPanelTokenId:val,nfts})
  }

  handlePaginator = (value) =>{
    this.setState(value,this.fetchNfts)
    this.myRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  jsonHandler = (val) =>{
    this.setState({json_data:val,selected:"manual"},this.attachJsonInBlankNfts)
  }


  attachJsonInBlankNfts = () =>{
    let{nfts,json_data,limit,page} = this.state;

    let _json_data = JSON.parse(JSON.stringify(json_data));
    let skip= (page -1) * limit;

    nfts = nfts.map((item,i)=>{
      let{tokenId,isFilled} = item;
      if(!tokenId || !!isFilled) return item;
      let _item = _json_data[skip+i];
      if(!_item) return {tokenId};
      _item.tokenId = tokenId;
      return _item;
    })
    this.setState({nfts})
  }

  render(){
    let {collection,authorDoc,nftDoc,address,collectionId,start,limit,page,nfts,isLoading,json_data,
      selected,editPanelTokenId,multi_files,filled} = this.state
// key={collection.author+collectionId}

    let _currentPreview = nfts.filter(i=>i.tokenId === editPanelTokenId)[0];
    _currentPreview = JSON.parse(JSON.stringify(_currentPreview || {}));
    _currentPreview.imgData = multi_files.find(j => j.name === _currentPreview.file);

    let isAuthor = !!address && collection.author === address;


    return(
      <CollectionBody
        data={{collection,authorDoc,nftDoc}}
        handler={this.fetchCollectionDetails}
        >

        <Parts.CollectionFillSelect
          data={{selected,collection}}
          handleState={(e=>this.setState(e))}
          />
        {selected === "json" && isAuthor &&
          <Parts.JsonMethod
            data={{address,collection,collectionId,json_data}}
            jsonHandler={(e=>this.jsonHandler(e))}
            handleState={(e=>this.setState(e))}
            />}

        {selected === "generate" && isAuthor &&
          <Parts.GenerateMethod
            data={{collection,collectionId,address}}
            handleState={(e=>this.setState(e))}
            jsonHandler={(e=>this.jsonHandler(e))}
            />}

        {selected === "multifile" && isAuthor &&
         <Parts.AttachMultiFile
          data={{nfts,collectionId,address,multi_files}}
          handleState={(e=>this.setState(e))}
          />}

        {selected === "upgrade" && isAuthor &&
          <Parts.UpgradeCollection
            data={{collection,nftDoc,collectionId,address}}
            handleState={(e=>this.setState(e))}
          />}
        {selected === "publish" && isAuthor &&
          <Parts.PublishCollection
            data={{collection,nftDoc,filled}}
            handleState={(e=>this.setState(e))}
            handleReload={this.fetchCollectionDetails}
          />}

        <div className="row" ref={this.myRef}>
            <div className="col-12">
              <div className="collection__create__title">
                <div className="collection__create__title__body">
                  <div className="collection__create__p">Upload Metadata</div>
                </div>
              </div>
            </div>


            <div className="col-12">
              <div className="row no-gutters equal">
                {!isLoading && !!nfts.length && isAuthor && this.renderNft()}
                {(!!isLoading || !nfts.length) && isAuthor && this.renderDummyNft()}

                {!isLoading  && !address && <p className="ik__notfound__msg">Please connect wallet</p>}
                {!isLoading  && !!address && !isAuthor && <p className="ik__notfound__msg">No result found</p>}

              </div>

              {!!editPanelTokenId && <PreviewEachNft
                handleMarkOpen={this.handleMarkOpen}
                data={_currentPreview}
                collection={collection}
                key={editPanelTokenId}
                />}
          </div>
          <div className="col-12">
            <Paginator  data={{count:collection.size,start,limit,page,size:nfts.length}} handler={this.handlePaginator}/>
          </div>
        </div>

      </CollectionBody>
    )
  }
}



export default CollectionFill;
