import React, {Component} from 'react';
// import styled from 'styled-components'
import { Link } from 'react-router-dom'
import $ from "jquery";

import api from "../../api"
import {Utils} from "../../helper"
import {NftViewer} from "../../helper/file-viewer.jsx"

class LikesCardDummy extends Component {

  render(){
    return <div className="col-6 col-sm-4 col-md-3 col-xl-2">
      <div className={`card nft__new_card card__margin nft__new_card__sm dummy_card`}>
        <div className="nft__new_card__body">

        <Link to="#" className="card__cover">&nbsp;</Link>
        <div className="card__meta card__meta__like_card">
          <div className="card__meta__left">
            <h3 className="card__title">&nbsp;</h3>
          </div>
          <div className="card__meta__right">
            <span>&nbsp;</span>
          </div>
        </div>
        </div>
      </div>
    </div>
  }
}
class LikesCard extends Component {
  constructor(props) {
      super(props)

      this.state = {
        isProcess:false,
        address:"",
        fontSize:'',
      }
  }
  async componentDidMount(){

  }

  handleLike = async() =>{
    let{isProcess} = this.state;
    let{tokenId,likes,nftAddress} = this.props.data
    try {
      let address = Utils.viewCreator();
      if(!address) return $(".header__action-btn--signin").click();
      if(isProcess) return Utils.setToastAlert("Previous request is in process");
      let payload = {tokenId,address,nftAddress}
      this.setState({isProcess:true})
      let liked = Utils.updateLike(nftAddress+tokenId)
      await api.likeNft(payload)
      this.props.data.likes = liked?likes+1:likes-1
      this.setState({isProcess:false})
    } catch (e) {
      // console.log(e);
      Utils.updateLike(nftAddress+tokenId)
      this.setState({isProcess:false})
    }
  }

  render(){
    let{fontSize} = this.state;
    let{tokenId,name,likes,nftAddress} = this.props.data;
    let _liked = Utils.viewCreator("liked").findIndex(i=> i === nftAddress+tokenId) !== -1;
    let _marketUrl = !nftAddress || nftAddress === Utils.NftContract?`/market/${tokenId}`:`/market3p/${nftAddress}/${tokenId}`;

    return <div className="col-6 col-sm-4 col-md-3 col-xl-2" key={tokenId+nftAddress+"A"}>
      <div className="card nft__new_card card__margin nft__new_card__sm">
        <div className="nft__new_card__body">
          <Link to={_marketUrl} className="card__cover card__cover__eq">
            <div className="card__cover__inner">
            <NftViewer data={this.props.data} key={tokenId+nftAddress+"B"}/>
              </div>
          </Link>

          <div className="card__meta card__meta__like_card">

            <div className="card__meta__left" ref={this.myRef}>
              <h3 className="card__title" style={{fontSize}} >
                <Link to={_marketUrl}>
                  {name}
                </Link>
              </h3>
            </div>
            <div className="card__meta__right">
              <button className={`card__likes ${_liked?"card__likes--active":""}`} type="button" onClick={this.handleLike} disabled={!name}>
                <span>{likes || 0}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  {!_liked && <path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z"/>}
                  {!!_liked && <path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/>}
                </svg>
              </button>
            </div>

          </div>

        </div>


      </div>
    </div>
  }
}



class Liked extends Component {

  constructor(props) {
      super(props)

      this.state = {
        isLoading:false,
        nfts:[],
        selectedIds:[],
        likes:[],
        page:1,
        start:1,
        limit:20,
        count:0,
      }
  }
  componentDidMount = () =>{
    let {user} = this.props.data
    let {liked} = user ||{}

    let {limit} = this.state
    if(liked && liked.length){
      let selectedIds = liked.slice(0, limit);
      this.setState({selectedIds,liked},this.fetchNfts)
    }
  }
  fetchNfts = async () =>{
    let{isLoading,selectedIds} = this.state;
      try {
        if(isLoading) return Utils.setToastAlert("Nfts Loading,please wait","info");
        this.setState({isLoading:true})
        let payload ={tokenIds:selectedIds,mode:"likes"}
        let res = await api.getBatchNft(payload);
        // console.log(res);
        let{result} = res.data;
        this.setState({nfts:result,isLoading:false})
      } catch (e) {
        // console.log(e);
        // console.log(e.response);
        this.setState({isLoading:false})

      }
  }
  renderNfts = () =>{
    let{nfts} = this.state;
    return nfts.map((item,i)=><LikesCard data={item} key={item.tokenId+"a"+i}/>)
  }
  renderDummyNfts = () =>{
    let count = window.innerWidth < 576?8:window.innerWidth < 768?9:window.innerWidth < 992?12:18;
    return new Array(count).fill(undefined).map((item,i)=><LikesCardDummy tokenId={item} key={i}/>)
  }
  renderPagings = () =>{
    let{likes,page,start,limit} = this.state;
    let totalPage = Math.ceil(likes.length /limit);
    totalPage = totalPage <= 4?totalPage:4;
    let pages = new Array(totalPage).fill(undefined).map((data,i)=>i+start);

    return pages.map((mark,i)=>{
      return <li className={page === mark?"active":""} key={i}><button onClick={()=>this.handlePaging(mark)}>{mark}</button></li>
    })
  }
  handlePaging = (val) =>{
    let{likes,limit,isLoading,page} = this.state
    if(isLoading) return Utils.setToastAlert("Please wait while we finish previous request");
    if(page === val)return console.log("on current page");

    let ini = (val * limit) - 1;
    let selectedIds = likes.slice(ini, ini + limit);
    this.setState({page:val,selectedIds},this.fetchNfts);
  }
  handlePagingArrow = (val) =>{
    let{likes,page,start,limit,isLoading} = this.state;
    if(isLoading) return Utils.setToastAlert("Please wait while we finish previous request");

    if(!likes || likes.length <= limit) return;
    let totalPage = Math.ceil(likes.length /limit);
    let _page = page;
      if(val === "prev"){
        if(page > 1){
          _page = page-1;
          if(totalPage > 4 && start > 1){
            start = start -1;
          }
        }
      }else {
        if(page < totalPage){
          _page = page+1;
          if(totalPage > 4 && start < _page - 3){
            start = start +1;
          }
        }
      }
      if(_page !== page){
        let ini = (_page * limit) - 1;
        let selectedIds = likes.slice(ini, ini + limit);
        this.setState({page:_page,start,selectedIds},this.fetchNfts);
      }


  }
    render() {
      let{nfts,likes,isLoading} = this.state;

        return (
          <main className="main">
        		<div className="container">
        			<div className="row no-gutters">
        				{!isLoading && !!nfts.length && this.renderNfts()}
                {!!isLoading && this.renderDummyNfts()}


                <div className="col-12">
                  <div className="paginator">
                    <span className="paginator__pages">{nfts.length || 0} from {likes.length || 0}</span>

                    <ul className="paginator__list">
                      <li>
                        <button  onClick={()=>this.handlePagingArrow("prev")}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"/></svg>
                        </button>
                      </li>
                      {this.renderPagings()}
                      <li>
                        <button  onClick={()=>this.handlePagingArrow("next")}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"/></svg>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
        			</div>
        		</div>
        	</main>
        )
    }
}
export default Liked
