import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {Utils} from "../helper"
// import api from "../api"

const CreateBtn = styled.button.attrs({
  className:"sign__btn ",
  type:"button"
})``





class CdnUploader extends Component{
  constructor(props) {
      super(props)
      this.state = {
        isProcess:false,
        loaded:0,
        total:0,
      }
    }
    handleDataUpload = async() =>{
      let{isProcess,imgData} = this.state;

      try {
        if(isProcess) return Utils.setToastAlert("File already uploading");

        this.setState({isProcess:true});
        Utils.setToastAlert(`Uploading ${imgData.name} of file size ${Utils.fileSize(imgData.size)}`)

        let payload = new FormData()
        payload.append('file', imgData)

        // await api.uploadFolder(payload,{
        //     onUploadProgress: progressEvent => {
        //       // console.log(progressEvent);
        //       let{loaded,total} = progressEvent
        //       this.setState({loaded,total})
        //     }
        // });
        Utils.setToastAlert("Uploaded success fully","success")
        this.setState({isProcess:false})

      } catch(e){
        // console.log(e);
        // console.log(e.response);
        this.setState({isProcess:false})
        Utils.setToastAlert("connection interrupted","error")
        // this.props.back()
      }
    }
    handleFile = event =>{
      let file = event.target.files[0];
      // console.log(file);
      if(!file){
        return Utils.setToastAlert("File not selected");
      }

      this.setState({imgData:file});
    }
    renderProgressBar = () =>{
      let{loaded,total,imgData} = this.state;
      total = total || imgData.size;
      let precentage = Utils.ceil(loaded*100/total,0);
      precentage = precentage || 5;
      return <div className="preview__item">
        <span>Uploading file </span>
        <div className="progress__bar_upload">
          <p style={{width:precentage+"%"}}></p>
          <div className="show__upload_size">{Utils.fileSize(loaded)}/{Utils.fileSize(total)}</div>
        </div>
      </div>
    }
    render(){
      let {isProcess,imgData} = this.state;
      let file_name = imgData?imgData.name:"Select file or folder";


    return(
      <main className="main">
    		<div className="container">
    			<div className="row row--grid">
    				<div className="col-12">
    					<ul className="breadcrumb">
                <li className="breadcrumb__item"><Link to="/home">Home</Link></li>
    						<li className="breadcrumb__item breadcrumb__item--active">cdn uploader</li>
    					</ul>
    				</div>

    				<div className="col-12">
              <div className="row justify-content-center">


              <div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6">
                <div className="col-12">
                  <h4 className="sign__title">Upload file</h4>
                </div>
                <div className="col-12">
                  <div className="sign__file">
                    <label id="file1" htmlFor="sign__file-upload" >{file_name}</label>
                    <input
                      data-name="#file1"
                      id="sign__file-upload"
                      name="file"
                      className="sign__file-upload"
                      type="file"
                      onChange={this.handleFile}
                      readOnly={isProcess}
                      />
                  </div>
                </div>
                {!!isProcess && <div className="col-12">
                  {this.renderProgressBar()}
                </div>}
                <div className="col-12 ">
                  <CreateBtn
                    onClick={this.handleDataUpload}
                    disabled={true}
                    >
                    upload
                    {isProcess && <div className="ball__pulse">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>}
                  </CreateBtn>
                </div>
            </div>
            </div>
    				</div>
    			</div>
    		</div>
    	</main>
    )
  }
}

export default CdnUploader;
