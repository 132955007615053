import React, { Component } from 'react'
//import ReactTable from 'react-table'
import $ from 'jquery';
// import styled from 'styled-components'
// import { Link } from 'react-router-dom'
import '../scss/select.scss'


class MenuSelect extends Component{

  renderOptions = () =>{
    let {options,value} = this.props
    return Object.entries(options).map(([k,v],i) => {
      return <span onClick={()=>this.props.onOption(k)} key={k}>
              {v}
              {value === k && <svg  fill="none" height="24" stroke="#6164ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><polyline points="20 6 9 17 4 12"/></svg>}
            </span>
   })
  }
  render(){
    let {options,value} = this.props
    return(
       <div className="custom__select">
        <h2>{options[value]} <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M81.8457,25.3876a6.0239,6.0239,0,0,0-8.45.7676L48,56.6257l-25.396-30.47a5.999,5.999,0,1,0-9.2114,7.6879L43.3943,69.8452a5.9969,5.9969,0,0,0,9.2114,0L82.6074,33.8431A6.0076,6.0076,0,0,0,81.8457,25.3876Z"/></svg></h2>
        <div className="custom__options">
           {this.renderOptions()}
        </div>
      </div>
    )
  }
}

export default MenuSelect;



class MenuSelect2 extends Component{
  constructor(props) {
      super(props)
      this.state = {
        canShow:this.props?.isOpen??false,
        thisName:Date.now()+"-select-explore"+Object.keys(this.props.options)[0]
      }
  }
  componentDidMount(){
    // document.addEventListener('mouseup', this.fireEvents,{passive:true});
  }
  componentWillUnmount(){
    // document.removeEventListener('mouseup', this.fireEvents,{passive:true});
  }
  fireEvents = (e) =>{
    let{thisName} = this.state;
    if (window.innerWidth > 576 && $(e.target).closest("."+thisName).length === 0){
      this.setState({canShow:false})
      }
  }
  renderOptions = () =>{
    let {options,value} = this.props
    return Object.entries(options).map(([k,v],i) => {
      return <span onClick={()=>this.props.onOption(k)} key={k}>
              {v}
              <p className={`${value === k?"active":""}`}><i></i></p>
            </span>
   })
  }
  closeOption = () =>{
    if(window.innerWidth < 576) return '';
    this.setState({canShow:false})
  }
  render(){
    let {canShow,thisName} = this.state;
    let {options,value} = this.props;
    let isSmall = window.innerWidth < 576;
    // canShow = canShow || isSmall;

    return(
       <div className={`custom__select ${thisName}`}>
        <h2 onClick={()=>this.setState({canShow:!canShow})}><span>{!!isSmall?Object.values(options)[0]:options[value]}</span> <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M81.8457,25.3876a6.0239,6.0239,0,0,0-8.45.7676L48,56.6257l-25.396-30.47a5.999,5.999,0,1,0-9.2114,7.6879L43.3943,69.8452a5.9969,5.9969,0,0,0,9.2114,0L82.6074,33.8431A6.0076,6.0076,0,0,0,81.8457,25.3876Z"/></svg></h2>
        {!!canShow && <div className="custom__options" onClick={this.closeOption}>
           {this.renderOptions()}
        </div>}
      </div>
    )
  }
}
class MenuSelect3 extends Component{
  constructor(props) {
      super(props)
      this.state = {
        canShow:false,
        thisName:Date.now()+"-select-explore"+Object.keys(this.props.options)[0]
      }
  }
  componentDidMount(){
    document.addEventListener('mouseup', this.fireEvents,{passive:true});
  }
  async componentWillUnmount(){
    document.removeEventListener('mouseup', this.fireEvents,{passive:true});
  }
  fireEvents = (e) =>{
    let{thisName} = this.state;
    if (window.innerWidth > 576 && $(e.target).closest("."+thisName).length === 0){
      this.setState({canShow:false})
      }
  }
  handleOptions = (value) =>{
    this.props.onOption(value);
    this.setState({canShow:false})

  }
  renderOptions = () =>{
    let {options,value} = this.props
    return Object.entries(options).map(([k,v],i) => {
      return <span onClick={()=>this.handleOptions(k)} key={k}>
              {v}
              {value === k && <svg fill="none" height="24" stroke="#6164ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><polyline points="20 6 9 17 4 12"/></svg>}
            </span>
   })
  }
  closeOption = () =>{
    if(window.innerWidth < 576) return '';
    this.setState({canShow:false})
  }
  render(){
    let {canShow,thisName} = this.state;
    let {options,value} = this.props;
    // canShow = canShow || isSmall;

    return(
       <div className={`custom__select select-for-explore ${thisName}`}>
        <h2 onClick={()=>this.setState({canShow:!canShow})}>{options[value]} <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M81.8457,25.3876a6.0239,6.0239,0,0,0-8.45.7676L48,56.6257l-25.396-30.47a5.999,5.999,0,1,0-9.2114,7.6879L43.3943,69.8452a5.9969,5.9969,0,0,0,9.2114,0L82.6074,33.8431A6.0076,6.0076,0,0,0,81.8457,25.3876Z"/></svg></h2>
        {!!canShow && <div className="custom__options" onClick={this.closeOption}>
           {this.renderOptions()}
        </div>}
      </div>
    )
  }
}


class MenuSelect4 extends Component{
  constructor(props) {
      super(props)
      this.state = {
        canShow:false,
        thisName:Date.now()+"-select-explore"+Object.keys(this.props.options)[0]
      }
  }
  componentDidMount(){
    document.addEventListener('mouseup', this.fireEvents,{passive:true});
  }
  async componentWillUnmount(){
    document.removeEventListener('mouseup', this.fireEvents,{passive:true});
  }
  fireEvents = (e) =>{
    // let{thisName} = this.state;
    // this.setState({canShow:false})

  }
  handleOptions = (value) =>{
    this.props.onOption(value);
    this.setState({canShow:false})
  }
  renderOptions = () =>{
    let {options,value} = this.props
    return Object.entries(options).map(([k,v],i) => {
      return <span onClick={()=>this.handleOptions(k)} key={k}>
              {v}
              {value === k && <svg fill="none" height="24" stroke="#6164ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><polyline points="20 6 9 17 4 12"/></svg>}
            </span>
   })
  }
  closeOption = () =>{
    if(window.innerWidth < 576) return '';
    this.setState({canShow:false})
  }
  render(){
    let {canShow,thisName} = this.state;
    let {options,value} = this.props;
    // canShow = canShow || isSmall;

    return(
       <div className={`custom__select select-for-explore large-select ${thisName}`}>
        <h2 onClick={()=>this.setState({canShow:!canShow})}>{options[value]} <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M81.8457,25.3876a6.0239,6.0239,0,0,0-8.45.7676L48,56.6257l-25.396-30.47a5.999,5.999,0,1,0-9.2114,7.6879L43.3943,69.8452a5.9969,5.9969,0,0,0,9.2114,0L82.6074,33.8431A6.0076,6.0076,0,0,0,81.8457,25.3876Z"/></svg></h2>
        {!!canShow && <div className="custom__options" onClick={this.closeOption}>
           {this.renderOptions()}
        </div>}
      </div>
    )
  }
}


class ToggleMenu extends Component{

  renderOptions = () =>{
    let {options,value} = this.props
    return Object.entries(options).map(([k,v],i) => {
      return <div className={`search__toggler__btn ${value === k?"active":""}`}
              onClick={()=>this.props.onOption(k)}
              key={k}>{v}</div>
   })
  }
  render(){
    return(
       <div className="search__toggler">
         <div className="search__toggler__body">
           {this.renderOptions()}
         </div>
      </div>
    )
  }
}


class HomeBtnSelect extends Component{
  constructor(props) {
      super(props)
      this.state = {
        canShow:false,
      }
  }

  handleOptions = (value) =>{
    this.props.onOption(value);
    this.setState({canShow:false})
  }

  renderOptions = () =>{
    let {options,value} = this.props
    return Object.entries(options).map(([k,v],i) => {
      return <span onClick={()=>this.handleOptions(k)} key={k}>
              {v}
              {value === k && <svg  fill="none" height="24" stroke="#6164ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><polyline points="20 6 9 17 4 12"/></svg>}
            </span>
   })
  }
  render(){
    let{canShow} = this.state;
    let {options,value, isLarge} = this.props
    return(
       <div className={`custom__select home__btn__select ${isLarge?"home__select__large":""}`}>
        <div className="home__select__title" onClick={()=>this.setState({canShow:!canShow})}>
          <div className="home__select__title__body">
            {options[value]} <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M81.8457,25.3876a6.0239,6.0239,0,0,0-8.45.7676L48,56.6257l-25.396-30.47a5.999,5.999,0,1,0-9.2114,7.6879L43.3943,69.8452a5.9969,5.9969,0,0,0,9.2114,0L82.6074,33.8431A6.0076,6.0076,0,0,0,81.8457,25.3876Z"/></svg>
          </div>
        </div>
        {!!canShow && <div className="custom__options">
           {this.renderOptions()}
        </div>}
      </div>
    )
  }
}

export {MenuSelect2,MenuSelect3,MenuSelect4,HomeBtnSelect,ToggleMenu}
