import React, { Component ,Fragment} from 'react'
import axios from 'axios'
import Utils from "./utils"
// import styled from 'styled-components'
// import {Link} from 'react-router-dom'
import Banned from '../images/ban-vertical.png';

import VideoPlayer from './video-player';

const api = axios.create({
    timeout: 4 * 60 * 1000,
    withCredentials: true,
    credentials: 'include',
    responseType: 'blob'
})



const SmLoader = () =>{
  return (
  <div className="model__loader">
    <svg  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" xmlSpace="preserve">
    <circle fill="none" stroke="#fff" strokeWidth="6"  strokeMiterlimit="15" strokeDasharray="14.2472,14.2472" cx="50" cy="50" r="47" >
      <animateTransform
         attributeName="transform"
         attributeType="XML"
         type="rotate"
         dur="5s"
         from="0 50 50"
         to="360 50 50"
         repeatCount="indefinite" />
  </circle>
  <circle fill="none" stroke="#fff" strokeWidth="1"  strokeMiterlimit="10" strokeDasharray="10,10" cx="50" cy="50" r="39">
      <animateTransform
         attributeName="transform"
         attributeType="XML"
         type="rotate"
         dur="5s"
         from="0 50 50"
         to="-360 50 50"
         repeatCount="indefinite" />
  </circle>
  <g fill="#fff">
  <rect x="30" y="35" width="5" height="30">
    <animateTransform
       attributeName="transform"
       dur="1s"
       type="translate"
       values="0 5 ; 0 -5; 0 5"
       repeatCount="indefinite"
       begin="0.1"/>
  </rect>
  <rect x="40" y="35" width="5" height="30" >
    <animateTransform
       attributeName="transform"
       dur="1s"
       type="translate"
       values="0 5 ; 0 -5; 0 5"
       repeatCount="indefinite"
       begin="0.2"/>
  </rect>
  <rect x="50" y="35" width="5" height="30" >
    <animateTransform
       attributeName="transform"
       dur="1s"
       type="translate"
       values="0 5 ; 0 -5; 0 5"
       repeatCount="indefinite"
       begin="0.3"/>
  </rect>
  <rect x="60" y="35" width="5" height="30" >
    <animateTransform
       attributeName="transform"
       dur="1s"
       type="translate"
       values="0 5 ; 0 -5; 0 5"
       repeatCount="indefinite"
       begin="0.4"/>
  </rect>
  <rect x="70" y="35" width="5" height="30" >
    <animateTransform
       attributeName="transform"
       dur="1s"
       type="translate"
       values="0 5 ; 0 -5; 0 5"
       repeatCount="indefinite"
       begin="0.5"/>
  </rect>
  </g>
</svg>
</div>

  )
}


class BannerViewer extends Component{

  constructor(props) {
    super(props)
    this.state = {
      isProcess:false,
      data:null,
    }
  }

  componentDidMount (){
    this.mounted = true;
    this.fetchFile()
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  setState(state,callback){
    if (this.mounted){
      super.setState(state,callback);
    }
  }

 fetchFile = async() =>{
   try {
     let {address,files} = this.props.data;
     files = files || {};

     if(!address && !files.banner) return "";
     let url = Utils.getBanner(address,files.banner)
     this.setState({isProcess:true})
     let _fileData = await api.get(url);


     let _url = URL.createObjectURL(_fileData.data)
     this.setState({isProcess:false,data:_url})

   } catch (e) {
     this.setState({isProcess:false})
   }
 }

 redirectHandler = (e) =>{
   // e.preventDefault();
   let {address} = this.props.data;
   if(!this.props.data.hasOwnProperty('imgData')){
     window.location.href = `/author/${address}`;
   }
 }

  render(){
    let{data,isProcess} = this.state;
    data = !!data && !isProcess?data:Utils.getBanner();
    let {cName,imgData} = this.props.data;
    let imgSource = imgData?URL.createObjectURL(imgData):data;

    return(
      <div
        className={cName || ""}
        style={{"backgroundImage": `url(${imgSource})`}}
        onClick={this.redirectHandler}
        >
        {this.props.children}
      </div>
    )
  }
}


class DpViewer extends Component{

  constructor(props) {
    super(props)
    this.state = {
      isProcess:false,
      data:null,
    }
  }

  componentDidMount (){
    this.mounted = true;
    this.fetchFile()
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  setState(state,callback){
    if (this.mounted){
      super.setState(state,callback);
    }
  }

 fetchFile = async() =>{
   try {
     let address = this.props.address;
     let files = this.props?.data?.files??{};

     if(!address && !files.dp) return "";

     let url = Utils.getDp(address,files.dp);
     // console.log(url);
     this.setState({isProcess:true})

     let _fileData = await api.get(url);
     // console.log(_fileData);
     let _url = URL.createObjectURL(_fileData.data);
     this.setState({isProcess:false,data:_url})

   } catch (e) {
     // console.log(e.response);
     this.setState({isProcess:false})
   }
 }

  render(){
    let{data,isProcess} = this.state;
    let type = this.props?.data?.type??"";

    data = !!data && !isProcess?data:type === "trc721"?"/asset/img/avatars/kty-sm-grey.png":Utils.getDp()
    return(
      <img src={data} alt=""/>
    )
  }
}





class NftViewer extends Component{

  constructor(props) {
    super(props)
    this.state = {
      isProcess:true,
      data:null,
      fileType:"jpg"
    }
  }

  componentDidMount (){
    this.mounted = true;
    let{url,isBanned,blocked,cid,baseurl,formate,imgData} = this.props.data;
    let fileType = url?url.split(".")[1].toLowerCase():formate?formate:"jpg";

    if(!!imgData){
      this.setState({data:URL.createObjectURL(imgData),fileType,isProcess:false})
    }else if(isBanned || blocked){
      this.setState({data:Banned,isProcess:false})
    }else if (!url) {
      if(cid){
        this.setState({data:`${Utils.getCidBaseUrl(baseurl)}${cid}`,fileType,isProcess:false})
      }
    }else if (url.split("/").length === 2) {
      this.setState({data: "https://cdn.kraftly.io/images/"+url,fileType,isProcess:false})
    }else {
      this.setState({fileType},this.fetchFile)
    }
 }

 componentWillUnmount() {
   this.mounted = false;
 }

 setState(state,callback){
   if (this.mounted){
     super.setState(state,callback);
   }
 }

 fetchFile = async() =>{
   try {
     let{url} = this.props.data;

     url = "https://server.kraftly.io/images/"+url;
     // url = "https://192.168.124.87:5000/images/"+url;

     let _fileData = await api.get(url);
     let _url = URL.createObjectURL(_fileData.data)
     this.setState({isProcess:false,data:_url})

   } catch (e) {
     this.setState({isProcess:false})
   }
 }



  renderFile = () =>{
    let{data,fileType,isProcess} = this.state;
    let{collectionId} = this.props.data;
    let style = collectionId === "TMCBfg4XDFTLCZapnwAVHypW2sm9hB3okZ"?{"imageRendering": "pixelated"}:{}

    if(isProcess){
      return <SmLoader />
    }else if(!data){
     return <img src={Utils.getImage()} alt=""/>
   }else if(fileType === "jpg" || fileType === "gif" || fileType === "png"){
     return <img src={data} style={style} alt=""/>
   }else if (fileType === "mp3") {
     return <audio src={data} controls loop>
       browser not supported
     </audio>
   }else if (fileType === "mp4") {
     return <VideoPlayer src={data} />
   }
 }

  render(){
    return(
      <Fragment>
        {this.renderFile()}
      </Fragment>
    )
  }
}




export{
  BannerViewer,
  DpViewer,
  NftViewer,
  SmLoader,
};
