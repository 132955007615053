
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as helper from '../helper'
import * as pages from './'


function Clouds() {

    return (
        <Router>
            <Switch>
                  <Route path="/cypto-clouds/mint" exact component={pages.MintCloud} />
                  <Route path={["/cypto-clouds","/cypto-clouds/gallery"]} exact component={pages.CloudGallery} />
                  <Route component={helper.PageNotFound} />

            </Switch>
        </Router>
    )
}

export default Clouds
