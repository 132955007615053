import React, {Component} from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
// import $ from "jquery";

import api from "../../api"
import {Utils} from "../../helper"

import '../../scss/rewards.scss'

const StyledLink  = styled.a.attrs({
    target : "_blank",
    rel : "noopener noreferrer"
})``;
class Rewards extends Component {

  constructor(props) {
      super(props)

      this.state = {
        isLoading:false,
        address:this.props.match.params.address ||"",
        rewards:[],
      }
  }
  componentDidMount = () =>{
    this.fetchHistory()
  }
  fetchHistory = async () =>{
    let{isLoading,address} = this.state;
      try {
        if(isLoading) return Utils.setToastAlert("History Loading,please wait","info");
        this.setState({isLoading:true})
        let payload ={address}
        let res = await api.getRewards(payload);
        console.log(res);
        let{result} = res.data;
        this.setState({rewards:result,isLoading:false})
      } catch (e) {
        console.log(e.response);
        this.setState({isLoading:false})

      }
  }
  renderReward = () =>{
    let {isLoading,rewards} = this.state;
    if(!rewards.length) return <div className="reward-table-row">
      <p>{!!isLoading?"History Loading":"No History Available"}</p>
    </div>;
    return rewards.map((item,i)=>{
      let{name,amount,txid,createdAt} = item;
      let time = createdAt?new Date(createdAt).toLocaleString() :'';

      return <div className="reward-table-row">
        <div className="reward-table-cell">
          {name}
        </div>
        <div className="reward-table-cell">
          {Utils.ceil(amount/1e6,3)}
        </div>
        <div className="reward-table-cell">
          {time}
        </div>
        <div className="reward-table-cell">
          <StyledLink href={`https://tronscan.org/#/transaction/${txid}`}>{Utils.shortHash(txid)}</StyledLink>
        </div>
      </div>
    })

  }

    render() {
      let {address} = this.state;
        return (
          <main className="main">
        		<div className="container">
            <div className="col-12">
              <ul className="breadcrumb">
                <li className="breadcrumb__item"><Link to="/home">Home</Link></li>
                <li className="breadcrumb__item"><Link to={`/author/${address}/profile?tab=created`} >Profile</Link></li>
                <li className="breadcrumb__item breadcrumb__item--active">Rewards</li>
              </ul>
            </div>

              <div className="row">

                <div className="col-12">
                  <div className="reward-panel">
                    <h1>Reward History</h1>

                    <div className="reward-table">
                      <div className="reward-table-head">
                        <div className="reward-table-cell">
                          Type
                        </div>
                        <div className="reward-table-cell">
                          Amount (KTY)
                        </div>
                        <div className="reward-table-cell">
                          Time
                        </div>
                        <div className="reward-table-cell">
                          Hash
                        </div>
                      </div>
                      <div className="reward-table-body">
                        {this.renderReward()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        		</div>
        	</main>
        )
    }
}
export default Rewards
