import React, { Component } from 'react'
import api from "../../api"
import {Utils} from "../../helper"
import NftCard,{NftCardDummy} from "../../components/nftCard/index.jsx";
import {AuthorCardDummy} from "../../components/authorCard/index.jsx";
import {ToggleMenu} from "../../frag"
import {Explore} from '../explore'
import{Authors} from "../authors"
import {IkCollections} from '../../mintable-collection/home.jsx'
import {TRC721Contracts} from '../../mintable-collection/custom-contract/all-contract'

class SearchPanel extends Component {
  constructor(props) {
      super(props)
      let query = this.props.match.params.query;
      const urlParams = new URLSearchParams(window.location.search);
      let type = urlParams.get('type');
      // type = type && (type === "nfts" || type === "authors")?type:"nfts";

      let page = urlParams.get('page');
      page = Utils.floor(page)
      page = page && page > 0?page:1;
      let start = page > 4?page -3:1;

      this.state = {
        filterString:query,
        type,
        isLoading:false,
        result:[],
        page,
        start,
        limit:12,
        count:0,
      }
  }
  componentDidMount = () =>{
    // this.fetchQueryDetails();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let query = nextProps.match.params.query;
    let{page} = this.state;
      const urlParams = new URLSearchParams(window.location.search);
      let _page = urlParams.get('page');
      _page = Utils.floor(_page);
      if(_page !== page){
        page = _page && _page > 0?_page:1;
        let start = page > 4?page -3:1;
        this.setState({page,start},this.fetchQueryDetails)
      }else if (query) {
        this.handleInput(query);
      }
     }

  updatePagingUrl = () =>{
    let{page,type,filterString} = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("page", page)
    urlParams.set('type',type);

    let params = urlParams.toString();
    if (window.history.pushState) {
      let url = `/search/${filterString}?${params}`;
      window.history.pushState(null, null,url);
    }
  }
 fetchQueryDetails = async() =>{
     let{isLoading,filterString,type} = this.state;

     if(isLoading) return Utils.setToastAlert("Previous request processing","info");
     if(!filterString) return Utils.setToastAlert("Please provide search string","info");
     if(type === "nfts"){
       this.setState({isLoading:true,result:[],count:0,page:1,start:1},this.fetchNfts)
     }

     const urlParams = new URLSearchParams(window.location.search);
     urlParams.set('type',type);
     let params = urlParams.toString();
     if (window.history.pushState) {
       let url = `/search/${filterString}?${params}`;
       window.history.pushState(null, null,url);
     }

  }
 fetchNfts = async()=>{
   try {
     let{filterString,page,limit} = this.state;
     let payload ={mode:"explore",page,limit,filterString}
     let res = await api.getMarket(payload);
     // console.log(res);
     let{result,count} = res.data;
     this.setState({result,count,isLoading:false},this.updatePagingUrl)
   } catch (e) {
     // console.log(e);
     // console.log(e.response);
     this.setState({isLoading:false})
     let msg = e?.response?.data?.message??"Failed to fetch NFTs";
     Utils.setToastAlert(msg,"error");
   }
 }
 handleSelect = (value) =>{
   let{type} = this.state;
   if(!value || type === value)return console.log("same option");
     this.setState({type:value,page:1,result:[],count:0},this.fetchQueryDetails)
 }

 handleInput = (value) =>{
   let{filterString} = this.state;
   this.setState({filterString:value})
   let query = this.props.match.params.query;

   if (value && value !== filterString && window.history.pushState) {
     let url = window.location.href.replace(query,value);
     window.history.pushState(null, null,url);
   }
 }

 renderDummy = () =>{
   let{type} = this.state;
   let count = window.innerWidth < 576?1:window.innerWidth < 992?4:window.innerWidth < 1200 ? 6:8;
     return new Array(count).fill(undefined).map((item,i)=>{
       return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={i}>
         {type === "nfts" && <NftCardDummy/>}
         {type === "authors" && <AuthorCardDummy/>}
       </div>
     })
 }
 renderNft = () =>{
   let{result} = this.state;
   return result.map((data,i)=>{
     return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={data.tokenId+"1"+i}>
       {<NftCard data={data} key={data.tokenId+"2"+i}/>}
     </div>
   })
 }

 handlePaginator = (value) =>{
   this.setState(value,this.fetchQueryDetails)
 }

    render() {
      let{filterString,type} = this.state;
      let options = {nfts:"NFTs",authors:"Users",collections:"Collections",trc721:"TRC721"};

        return (
          <main className="main">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <ToggleMenu options={options} value={type} onOption={(e)=>this.handleSelect(e)}/>
                </div>
              </div>

              {type === "nfts" && <div className="row no-gutters">
                <Explore
                  filterString={filterString}
                  callback={this.handleInput}
                  />
              </div>}
              {type === "authors" && <div className="row no-gutters">
                <Authors
                  filterString={filterString}
                  callback={this.handleInput}
                  />
              </div>}
              {type === "collections" && <div className="row no-gutters">
                <IkCollections
                  filterString={filterString}
                  callback={this.handleInput}
                  />
              </div>}
              {type === "trc721" && <div className="row no-gutters">
                <TRC721Contracts
                  filterString={filterString}
                  callback={this.handleInput}
                  />
              </div>}

            </div>
          </main>
        )
    }
}








export default SearchPanel
