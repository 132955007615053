
import React, { Component } from 'react'
import {Utils} from "../../helper"
import tronWeb from "../../tronweb"
import api from "../../api"

import NftCard,{NftCardDummy} from "../../components/nftCard/index.jsx"
import {TRC721Body} from "../../components/profile-body";
import {Paginator,GridToggler2} from "../../frag"





class TRC721Gallery extends Component{
  constructor(props) {
      super(props)
      // let limit = window.innerWidth < 768?20:30;
      const urlParams = new URLSearchParams(window.location.search);
      let page = urlParams.get('page');
      page = Utils.floor(page)
      page = page && page > 0?page:1;
      let start = page > 4?page -3:1;

      let nftAddress = this.props.match.params.address||"";
      nftAddress = tronWeb.isAddress(nftAddress)?nftAddress:"";

      let filterString = urlParams.get('s');
      filterString = Utils.floor(filterString);
      filterString = !!filterString?filterString:"";

      this.state = {
        isoldFetching:false,

        isProcess:false,
        nftAddress,

        count:0,
        page,
        start,
        limit:24,
        nfts:[],
        filterString,

        isGrid:localStorage.getItem('isGrid') === "true",

        trc721Token : {},
        authorDoc:{},

      }
      this.myRef = React.createRef();
  }
  componentDidMount (){
    // document.title = "My Mintable Collections";
    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);
      let {address} = account
      // address = "TJ9DXJnYzLWjodSuQaUN3JQmffR7HAgd18";

      if(address){
        this.setState({address})
      }else {
        this.setState({address:""})
      }
    })
    this.fetchCollectionDetails()
  }


  componentWillUnmount(){
    document.title = "Kraftly";
  }


  updatePagingUrl = () =>{
    let{page} = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("page", page)
    let params = urlParams.toString();
    if (window.history.pushState) {
      let url = `${window.location.pathname}?${params}`;
      window.history.pushState(null, null,url);
    }
  }


  fetchCollectionDetails = async() =>{
      try {
        let {nftAddress,isProcess} = this.state;
        if(!nftAddress) return console.log("Token not exists");
        if(isProcess) return Utils.setToastAlert("Previous request processing","info");
        this.setState({trc721Token:{},isProcess:true})

        let res = await api.getNftContract({nftAddress});
        // console.log(res);
        let{result,authorDoc} = res.data;
        result = result || {};
        authorDoc = authorDoc || {};
        // console.log(res);
        this.setState({trc721Token:result,authorDoc,isProcess:false},()=>{
          this.fetchNfts();
        });
        document.title = `${result.name || 'Token'} | Gallery`;

      } catch (e) {
        // console.log(e.response);
        this.setState({isProcess:false})
        let msg = e.response?.data?.message??"Failed to fetch TRC721 contract";
        Utils.setToastAlert(msg,"error");

      }
    }


  fetchNfts = async()=>{
      try {
        let{nftAddress,filterString,isProcess,page,limit} = this.state;
        if(!nftAddress) return console.log("Token not exists");

        if(isProcess) return Utils.setToastAlert("Previous request processing","info");
        this.setState({isProcess:true})
        let payload = {nftAddress,filterString,page,limit}
        // console.log(limit);
        let res = await api.getUserCreatedNft(payload);
        // console.log(res);
        let{result} = res.data;

        this.setState({nfts:result,isProcess:false},()=>{
          this.updatePagingUrl();
        })

      } catch (e) {
        // console.log(e.response);
        let msg = e.response?.data?.message??"No NFT found";
        Utils.setToastAlert(msg,"error");
        // console.log(e.response);
        this.setState({isProcess:false})
      }
  }



  fetchFilterData = async(event) =>{
    event.preventDefault()
    let{nfts} = this.state;
      let{filterString,isProcess} = this.state;
      if(!filterString && nfts.length !== 1) return Utils.setToastAlert("Search Token id only","info");
      if(isProcess) return Utils.setToastAlert("Previous request processing","info");

      if(!filterString){
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete("s")
        let params = urlParams.toString();
        if (window.history.pushState) {
          let url = `${window.location.pathname}?${params}`;
          window.history.pushState(null, null,url);
        }
      }
      this.setState({page:1,start:1},this.fetchNfts)
  }

  renderNft = () =>{
    let{nfts,nftAddress,isGrid} = this.state;
    let layout={
      isMobile: !!isGrid,
    }

    return nfts.map((data,i)=>{

      if(!!isGrid){
        return <div className="col-6 col-sm-4 col-lg-3 col-xl-2" key={data.tokenId+nftAddress+"largeA"}>
          <NftCard data={data} layout={layout} key={data.tokenId+nftAddress+"largeB"}/>
        </div>
      }

      return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={data.tokenId+nftAddress+"smallA"}>
        <NftCard data={data} layout={layout} key={data.tokenId+nftAddress+"smallB"}/>
      </div>
    })
  }
  renderDummyNft = () =>{
    let{isGrid} = this.state;
    let layout={
      isMobile: !!isGrid,
    }
    let _w = window.innerWidth;
    let count = _w< 576?4:_w< 768?8:_w< 992?12:_w< 1200?15:16;
    if(isGrid){
      count = _w< 576?8:_w< 768?12:_w< 992?15:_w< 1200?16:18;
    }
      return new Array(count).fill(undefined).map((item,i)=>{

        if(!!isGrid){
          return <div className="col-6 col-sm-4 col-lg-3 col-xl-2" key={"smallAD"+i}>
            <NftCardDummy layout={layout} key={"smallB"+i}/>
          </div>
        }

        return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={"largeAD"+i}>
          <NftCardDummy layout={layout} key={"largeB"+i}/>
        </div>
      })
  }



  handlePaginator = (value) =>{
    this.setState(value,this.fetchNfts)
    this.myRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  render(){
    let {trc721Token,authorDoc,nftAddress,start,limit,page,nfts,isProcess,filterString,isGrid} = this.state
    // console.log(trc721Token);


    return(
      <TRC721Body
        data={{token:trc721Token,authorDoc,nftAddress}}
        handler={this.fetchCollectionDetails}
        key={nftAddress}>


        <div className="row no-gutters" ref={this.myRef}>

          <div className="col-12">
            <div className="ik__home__filter">
              <form onSubmit={this.fetchFilterData} className="main__filter-search">
                <input
                  type="number"
                  placeholder="Search NFT by token Id"
                  value = {filterString}
                  onChange={(e)=>this.setState({filterString:e.target.value})}
                  />
                <button
                  type="button"
                  onClick={this.fetchFilterData}
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
                </button>
              </form>
              <GridToggler2
                data={{isGrid}}
                handleGrid={(e)=>this.setState(e)}
                />
            </div>
          </div>

            <div className="col-12">
              <div className="row no-gutters">
                {!isProcess && !!nfts.length && this.renderNft()}
                {!!isProcess  && this.renderDummyNft()}
                {!isProcess  && !nfts.length && <p className="ik__notfound__msg">No result found</p>}
              </div>
          </div>
          <div className="col-12">
            <Paginator  data={{count:trc721Token.nftCount,start,limit,page,size:nfts.length}} handler={this.handlePaginator}/>
          </div>
        </div>

      </TRC721Body>
    )
  }
}



export default TRC721Gallery;
