import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {Utils} from "../../helper"
import {NftViewer} from "../../helper/file-viewer.jsx"

import Data from "../../pages/market3p/data.js"

import $ from "jquery";
import api from "../../api"
import styled from 'styled-components'
import "../../scss/nft-card.scss"
import "./style.scss"


import {ReactComponent as Verified} from '../../images/verified_star.svg';
import {ReactComponent as Blocked} from '../../images/banned.svg';

import PaintBrush from '../../images/brush.png';

const StyledLink  = styled(Link).attrs({
    target : "_blank",
    rel : "noopener noreferrer"
})``;

class NftCard extends Component{
  constructor(props) {
      super(props)

      this.state = {
        timer:'',
        isProcess:false,
        fontSize:"1em",
      }
      this.myRef = React.createRef();
  }
  componentDidMount(){
    let{time,tokenId} = this.props.data;
    this.setupFontWidth();

    if(time > 0){
      let timer = setInterval(function() {
                  $(`.card__clock${tokenId}`).text(Utils.getTimeOffset(time));
                  if (time * 1000 < Date.now()) {
                     $('.card__time').fadeOut('slow');
                     clearInterval(timer);
                  }
               }, 1000);
       this.setState({timer})


    }
  }
  componentWillUnmount(){
    if(this.state.timer){
      clearInterval(this.state.timer);
    }
  }


  setupFontWidth = () =>{
    let {isMobile} = this?.props?.layout??{};
    let{tokenId,name} = this?.props?.data??{};
    name = name || tokenId;
    if(!name) return this.setState({fontSize:"1em"});

    let _width = this.myRef.current.offsetWidth;
    // Adjust name font
    let namContext = document.createElement('canvas').getContext('2d');
    namContext.font = "600 16px Inter";
    let fontSize = Math.floor(_width*16/namContext.measureText(name).width);
    let fontLimit = !isMobile?16:14;
    let fontLower = !isMobile?12:11;

    fontSize = fontSize > fontLimit?fontLimit:fontSize;
    fontSize = fontSize > fontLower?fontSize:fontLower;
    this.setState({fontSize})
  }
  handleLike = async() =>{
    let{isProcess} = this.state;
    let{tokenId,likes,nftAddress} = this.props.data;
    try {
      let address = Utils.viewCreator();
      if(!address) return $(".header__action-btn--signin").click();
      if(isProcess) return Utils.setToastAlert("Another request is in process");
      let payload = {tokenId,address}
      this.setState({isProcess:true})
      let liked = Utils.updateLike(nftAddress+tokenId)
      await api.likeNft(payload)
      this.props.data.likes = liked?likes+1:likes-1
      this.setState({isProcess:false})
    } catch (e) {
      // console.log(e);
      Utils.updateLike(nftAddress+tokenId)
      this.setState({isProcess:false})
    }
  }

  render(){
    let{fontSize} = this.state;
    let{is3rdParty} = this?.props?.data??{};
    let {isMobile,isAutoHeight,isSmallScale,isSquare,isSlider,isIkMint} = this?.props?.layout??{};
    isMobile = !!isMobile?" nft__new_card__sm":"";
    isAutoHeight = !!isAutoHeight?" card__free__height":"";
    isSmallScale = !!isSmallScale?" card__small__scale":"";
    isSquare = !!isSquare?" card__cover__eq":"";
    isSlider = !!isSlider?" nft__new_card__slider":"";



    let{owner,bid,price,time,tokenId,name,likes,username,user_name,verified,blocked,eventName,rank,collectionId,nftAddress,currencyId} = this.props.data;
    let _liked = Utils.viewCreator("liked").findIndex(i=> i === nftAddress+tokenId) !== -1;
    price = price || 0;
    bid = bid || 0;
    time = time || 0;
    let {img,symbol} = Utils.getTokenInfo(currencyId);

    let priceTag = eventName === "SoldAuction"?"Last sold for":
    eventName === "NFTminted"?"Minted":
    eventName === "CancelAuction"?"Last Bid":
    eventName === "OfferRemoved"?"Last Offer":
    eventName === "NewOffer"?"Current Offer":
    collectionId > 100 && !eventName?"Not yet minted":
    !eventName?"Not for sale":
    bid?"Current bid":
    !bid  && time?"Minimum Bid":
    price && !time?"Fixed price":
    "Offerable Auction";


    let rate = eventName === "SoldAuction" || eventName === "NFTminted"?bid:
    eventName === "NewOffer"?bid/1.05:
    !eventName?0:
    bid?bid/1.025:price;

    let viewPrice = !!rate?rate:0;
    rate = !!rate?Utils.largePreety(rate/1e6):!eventName?0:"> 0"
    let usdtRate = Utils.viewUsdtRate();

    let _url = !!collectionId && !eventName?`/mintable/${collectionId}/gallery`:`/author/${owner}/profile?tab=collection`;
    let _marketUrl = !nftAddress || nftAddress === Utils.NftContract?`/market/${tokenId}`:`/market3p/${nftAddress}/${tokenId}`;

    let ownerName = user_name?Utils.shortName(user_name,18):Utils.viewOwner(username || owner,20);
    return(
        <div
          className={`nft__new_card card__margin${isMobile}${isSmallScale}${isAutoHeight}${isSlider}`}>
          <div className="nft__new_card__body">

          <StyledLink to={_marketUrl} className={`card__cover${isSquare}`}>
            <div className="card__cover__inner">
            <NftViewer data={this.props.data} key={tokenId}/>
              {!!time && <span className="card__timer">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.46777,8.39453l-.00225.00183-.00214.00208ZM18.42188,8.208a1.237,1.237,0,0,0-.23-.17481.99959.99959,0,0,0-1.39941.41114,5.78155,5.78155,0,0,1-1.398,1.77734,8.6636,8.6636,0,0,0,.1333-1.50977,8.71407,8.71407,0,0,0-4.40039-7.582,1.00009,1.00009,0,0,0-1.49121.80567A7.017,7.017,0,0,1,7.165,6.87793l-.23047.1875a8.51269,8.51269,0,0,0-1.9873,1.8623A8.98348,8.98348,0,0,0,8.60254,22.83594.99942.99942,0,0,0,9.98,21.91016a1.04987,1.04987,0,0,0-.0498-.3125,6.977,6.977,0,0,1-.18995-2.58106,9.004,9.004,0,0,0,4.3125,4.0166.997.997,0,0,0,.71534.03809A8.99474,8.99474,0,0,0,18.42188,8.208ZM14.51709,21.03906a6.964,6.964,0,0,1-3.57666-4.40234,8.90781,8.90781,0,0,1-.17969-.96387,1.00025,1.00025,0,0,0-.79931-.84473A.982.982,0,0,0,9.77,14.80957a.99955.99955,0,0,0-.8667.501,8.9586,8.9586,0,0,0-1.20557,4.71777,6.98547,6.98547,0,0,1-1.17529-9.86816,6.55463,6.55463,0,0,1,1.562-1.458.74507.74507,0,0,0,.07422-.05469s.29669-.24548.30683-.2511a8.96766,8.96766,0,0,0,2.89874-4.63269,6.73625,6.73625,0,0,1,1.38623,8.08789,1.00024,1.00024,0,0,0,1.18359,1.418,7.85568,7.85568,0,0,0,3.86231-2.6875,7.00072,7.00072,0,0,1-3.2793,10.457Z"/></svg>
                <span className={"card__clock"+tokenId}></span>
              </span>}
              </div>
          </StyledLink>
          {!!rank && <p className="rank__tag__card">Rank: {rank}</p>}


          <div className="card__meta">

            <div className="card__meta__left card__meta__left__fill" ref={this.myRef}>
              <div className="card__author card__author_show">
                  <StyledLink to={_url}>{ownerName}</StyledLink>
                  {!!verified && <Verified />}
                  {!!blocked && <Blocked />}
              </div>

              <h3 className="card__title" style={{fontSize}} >
                <StyledLink to={_marketUrl}>
                  {name || tokenId}
                </StyledLink>
              </h3>
            </div>


          </div>

          <div className="card__info">

            {!isIkMint && <div className="card__info__price">
              <span>{priceTag}</span>
               <div className="nft__amount">
                <div className="tooltip tooltip__right">
                  <span className="tooltip__text">{symbol}</span>
                </div>
                {!!rate && rate}
                {!!rate && <img src={img} alt={symbol} />}
                {!!viewPrice && !!usdtRate && `~$${Utils.largePreety(viewPrice*usdtRate/1e6,1)}`}
              </div>
            </div>}
            {!!isIkMint && this.props.children}


            {!is3rdParty && <button className={`card__likes ${_liked?"card__likes--active":""}`} type="button" onClick={this.handleLike} disabled={!name}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                {!_liked && <path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z"/>}
                {!!_liked && <path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/>}
              </svg>
              <span>{likes || 0}</span>
            </button>}
          </div>
          </div>
        </div>
    )
  }
}


class NftCardBlank extends Component{


  renderFile = () =>{
    let{imgData} = this?.props?.data??{};


    let fileType = imgData?imgData.name.split(".")[1].toLowerCase():"";
    let url = !!imgData?URL.createObjectURL(imgData):"";
    if(!url){
      return <img src={PaintBrush} style={{"padding":"5px"}} alt=""/>
    }else if(fileType === "jpg" || fileType === "gif" || fileType === "png"){
      return <img src={url} alt=""/>
    }else if (fileType === "mp3") {
      return <audio src={url} controls loop muted>
        browser not supported
      </audio>
    }else if (fileType === "mp4") {
      return <video src={url} controls autoPlay loop muted>
        browser not supported
      </video>
    }
  }

  render(){
    let {isMobile,isIkMint} = this?.props?.layout??{};
    isMobile = !!isMobile?" nft__new_card__sm":"";

    let {tokenId,user_name,username,owner,verified,blocked,collectionId,name,rank} = this?.props?.data??{};
    collectionId = collectionId || Utils.NftContract;
    let ownerName = user_name?Utils.shortName(user_name,18):Utils.viewOwner(username || owner,20)
    return(
        <div className={`nft__new_card card__margin ${isMobile} card__small__scale card__free__height}`}>
          <div className="nft__new_card__body">
          <Link to={!isIkMint?`/create?tokenId=${tokenId}&t=${collectionId}`:'#'} className="card__cover">
            <div className="card__cover__inner">{this.renderFile()}</div>
          </Link>
          {!!rank && <p className="rank__tag__card">Rank: {rank}</p>}


          <div className="card__meta">
            <div className="card__meta__left card__meta__left__fill" ref={this.myRef}>
              {!isIkMint && <div className="card__author card__author_show">
                  <StyledLink to="#" onClick={(e)=>e.preventdefault()}>
                    {ownerName}
                    {!!verified && <Verified />}
                    {!!blocked && <Blocked />}
                  </StyledLink>
              </div>}

              <h3 className="card__title">
                <StyledLink to="#" onClick={(e)=>e.preventdefault()}>
                  {!name?"Blank NFT":name}
                </StyledLink>
              </h3>
            </div>
          </div>

          <div className="card__info">

            <div className="card__info__price">
              <span>NFT ID</span>
              <div className="nft__amount">{tokenId}</div>
            </div>
            {!isIkMint && <Link to={`/create?tokenId=${tokenId}&t=${collectionId}`} className="card__likes card__edit">
              Upload
            </Link>}
            {!!isIkMint && this.props.children}
          </div>
          </div>
        </div>
    )
  }
}



class NftCardDummy extends Component{


  render(){
    let {isMobile,isSlider,isIkMint} = this?.props?.layout??{};
    isMobile = !!isMobile?" nft__new_card__sm":"";
    isSlider = !!isSlider?" nft__new_card__slider":"";

    let tokenId = this.props.tokenId;
    let url = tokenId?`/market/${tokenId}`:"#";
    // let style = tokenId?{"background":"none"}:{};
    return(
        <div className={`nft__new_card card__margin ${isMobile} dummy_card ${isSlider}`}>
          <div className="nft__new_card__body">

          <Link to={url} className="card__cover">&nbsp;</Link>
          <div className="card__meta">
            <div className="card__meta__left">
              {!isIkMint && <div className="card__author card__author_show">&nbsp;</div>}
              <h3 className="card__title">&nbsp;</h3>
            </div>
          </div>

          <div className="card__info">
            <div className="card__info__price">
              <span>&nbsp;</span>
              <div className="nft__amount">&nbsp;</div>
            </div>

            <button className="card__likes">
              <span>&nbsp;</span>
            </button>
          </div>
          </div>
        </div>
    )
  }
}



class NftNeoCard extends Component{
  constructor(props) {
      super(props)

      this.state = {
        timer:'',
        isProcess:false,
      }
  }
  async componentDidMount(){

  }

  handleLike = async() =>{
    let{isProcess} = this.state;
    let{tokenId,likes,nftAddress} = this.props.data
    try {
      let address = Utils.viewCreator();
      if(!address) return $(".header__action-btn--signin").click();
      if(isProcess) return Utils.setToastAlert("Another request is in process");
      let payload = {tokenId,address,nftAddress}
      this.setState({isProcess:true})
      let liked = Utils.updateLike(nftAddress+tokenId)
      await api.likeNft(payload)
      this.props.data.likes = liked?likes+1:likes-1
      this.setState({isProcess:false})
    } catch (e) {
      console.log(e);
      Utils.updateLike(nftAddress+tokenId)
      this.setState({isProcess:false})
    }
  }

  render(){
    let {isMobile,isSmallScale} = this?.props?.layout??{};
    isMobile = !!isMobile?" nft__new_card__sm":"";
    isSmallScale = !!isSmallScale?" card__small__scale":"";

    let{owner,minter,tokenId,name,likes,username,user_name,verified,blocked,rank,nftAddress,price} = this.props.data;
    // console.log(this.props.data);
    let ownerName = user_name?Utils.shortName(user_name,18):Utils.viewOwner(username || owner,20)

    let _liked = Utils.viewCreator("liked").findIndex(i=> i === nftAddress+tokenId) !== -1;
    let {img,symbol} = Utils.getTokenInfo();

    return(
        <div className={`nft__new_card card__margin ${isMobile} ${isSmallScale}`}>
          <div className="nft__new_card__body">

          <StyledLink to={`/market/${tokenId}`} className="card__cover">
            <NftViewer data={this.props.data} key={tokenId}/>
          </StyledLink>
          {!!rank && <p className="rank__tag__card">Rank: {rank}</p>}


          <div className="card__meta">

            <div className="card__meta__left">
              <div className="card__author">
                  <StyledLink to={`/author/${owner}/profile?tab=collection`}>{ownerName}</StyledLink>
                    {!!verified && <Verified />}
                    {!!blocked && <Blocked />}
              </div>

              <h3 className="card__title">
                <StyledLink to={`/market/${tokenId}`}>
                  {name || tokenId}
                </StyledLink>
              </h3>
            </div>
            <div className="card__meta__right">
              <span>Price</span>
              <div className="nft__amount">
                <div className="tooltip">
                  <span className="tooltip__text">{symbol}</span>
                </div>
                <img src={img} alt={symbol} />
                {Utils.largePreety(price/1e6)}
              </div>
            </div>

          </div>


          <div className="card__info">
            <div className="minter__info">
              <span>Minter</span>
              {!minter &&<span>Not minted</span>}
              {!!minter && <StyledLink to={`/author/${minter}/profile?tab=collection`}>
                {Utils.shortAddress(minter,true)}
              </StyledLink>}
            </div>

            <button className={`card__likes ${_liked?"card__likes--active":""}`} type="button" onClick={this.handleLike} disabled={!name}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                {!_liked && <path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z"/>}
                {!!_liked && <path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/>}
              </svg>
              <span>{likes || 0}</span>
            </button>
          </div>
        </div>
        </div>
    )
  }
}


class NftNeoCardDummy extends Component{
  render(){
    let isMobile = !!this.props.isMobile;

    let {tokenId,nftAddress} = this.props?.data??{};
    let url = tokenId?`/market3p/${nftAddress}/${tokenId}`:"#";
    // let style = tokenId?{"background":"none"}:{};
    return(
        <div className={`nft__new_card card__margin ${!!isMobile?" nft__new_card__sm":""} dummy_card`}>
          <div className="nft__new_card__body">

          <Link to={url} className="card__cover">&nbsp;</Link>
            <div className="card__meta">
              <div className="card__meta__left">
                <div className="card__author">&nbsp;</div>

                <h3 className="card__title">&nbsp;</h3>
              </div>
              <div className="card__meta__right">
                <span>&nbsp;</span>
                <div className="nft__amount">&nbsp;</div>
              </div>
            </div>
            <div className="card__info">
              <div className="minter__info">
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </div>

              <button className="card__likes" type="button">
                <span>&nbsp;</span>
              </button>
            </div>
          </div>
        </div>
    )
  }
}

class Nft3pCard extends Component{
  constructor(props) {
      super(props)

      this.state = {
        timer:'',
        isProcess:false,
      }
  }
  componentDidMount(){
    let{time,tokenId} = this.props.data;
    // time = Math.floor(Date.now()/1000)+86500;

    if(time > 0){
      let timer = setInterval(function() {
                  $(`.card__clock${tokenId}`).text(Utils.getTimeOffset(time));
                  if (time * 1000 < Date.now()) {
                     $('.card__time').fadeOut('slow');
                     clearInterval(timer);
                  }
               }, 1000);
       this.setState({timer})
    }
  }
  componentWillUnmount(){
    if(this.state.timer){
      clearInterval(this.state.timer);
    }
  }

  render(){
    let {isMobile,isAutoHeight,isSmallScale,isSquare} = this?.props?.layout??{};

    let _isMobile = !!isMobile?" nft__new_card__sm":"";
    isAutoHeight = !!isAutoHeight?" card__free__height":"";
    isSmallScale = !!isSmallScale?" card__small__scale":"";
    isSquare = !!isSquare?" card__cover__eq":"";



    let _nameSize = !!isMobile && window.innerWidth <= 576?24:!!isMobile?18:window.innerWidth <= 576?27:window.innerWidth <= 768?16:window.innerWidth <= 992?22:window.innerWidth <= 1200?22:window.innerWidth <= 1400?16:22;
    let _cNameSize = !!isMobile && window.innerWidth <= 576?26:!!isMobile?26:window.innerWidth <= 576?30:window.innerWidth <= 768?22:window.innerWidth <= 992?26:window.innerWidth > 1400?22:18;

    let{name,owner,nftAddress,bid,price,time,tokenId,username,user_name,verified,blocked,currencyId,eventName} = this.props.data;
    _cNameSize = !verified?_cNameSize:_cNameSize-3;
    // console.log(this.props.data);
    let {img,symbol} = Utils.getTokenInfo(currencyId);

    let _fSize = 1;
    if(name && name.length >= _nameSize){
      _fSize = _nameSize*100/name.length;
      _fSize = Math.floor(_fSize*0.9)/100;
      _fSize = _fSize > 0.6?_fSize:0.6;
    }
    _fSize += "em";

    price = price || 0;
    bid = bid && bid > 0? bid / 1.025:0;
    time = time || 0;

    let priceTag = eventName === "SoldAuction"?"Last sold for":
    eventName === "CancelAuction"?"Last Bid":
    eventName === "OfferRemoved"?"Last Offer":
    eventName === "NewOffer"?"Current Offer":
    bid?"Current bid":
    !bid  && time?"Minimum Bid":
    price && !time?"Fixed price":
    "";

    let rate = bid >= price?bid:price;
    rate = Utils.largePreety(rate/1e6,!isMobile?2:1);

    let ownerName = !!user_name?Utils.viewOwner(user_name,_cNameSize):
    !!username?Utils.ellipsisName(username,_cNameSize):
    owner && owner !== Utils.Zero_address?Utils.viewOwner(owner,_cNameSize):
    Data.getBaseName(nftAddress);

    owner = owner && owner !== Utils.Zero_address?owner:nftAddress;
    // time = true;
    return(
        <div className={`nft__new_card card__margin ${_isMobile} ${isSmallScale} ${isAutoHeight}`}>
          <div className="nft__new_card__body">

          <Link to={`/market3p/${nftAddress}/${tokenId}`} className={`card__cover ${isSquare}`}>
            <NftViewer data={this.props.data} key={tokenId}/>
              {!!time && <span className="card__timer">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.46777,8.39453l-.00225.00183-.00214.00208ZM18.42188,8.208a1.237,1.237,0,0,0-.23-.17481.99959.99959,0,0,0-1.39941.41114,5.78155,5.78155,0,0,1-1.398,1.77734,8.6636,8.6636,0,0,0,.1333-1.50977,8.71407,8.71407,0,0,0-4.40039-7.582,1.00009,1.00009,0,0,0-1.49121.80567A7.017,7.017,0,0,1,7.165,6.87793l-.23047.1875a8.51269,8.51269,0,0,0-1.9873,1.8623A8.98348,8.98348,0,0,0,8.60254,22.83594.99942.99942,0,0,0,9.98,21.91016a1.04987,1.04987,0,0,0-.0498-.3125,6.977,6.977,0,0,1-.18995-2.58106,9.004,9.004,0,0,0,4.3125,4.0166.997.997,0,0,0,.71534.03809A8.99474,8.99474,0,0,0,18.42188,8.208ZM14.51709,21.03906a6.964,6.964,0,0,1-3.57666-4.40234,8.90781,8.90781,0,0,1-.17969-.96387,1.00025,1.00025,0,0,0-.79931-.84473A.982.982,0,0,0,9.77,14.80957a.99955.99955,0,0,0-.8667.501,8.9586,8.9586,0,0,0-1.20557,4.71777,6.98547,6.98547,0,0,1-1.17529-9.86816,6.55463,6.55463,0,0,1,1.562-1.458.74507.74507,0,0,0,.07422-.05469s.29669-.24548.30683-.2511a8.96766,8.96766,0,0,0,2.89874-4.63269,6.73625,6.73625,0,0,1,1.38623,8.08789,1.00024,1.00024,0,0,0,1.18359,1.418,7.85568,7.85568,0,0,0,3.86231-2.6875,7.00072,7.00072,0,0,1-3.2793,10.457Z"/></svg>
                <span className={"card__clock"+tokenId}></span>
              </span>}
          </Link>

          <div className="card__meta">

            <div className="card__meta__left">
              <div className={`card__author ${!priceTag && !!isMobile?"card__author_show":""}`}>
                  <StyledLink to={`/author/${owner}/profile?tab=collection`}>
                    {ownerName}
                    {!!verified && <Verified />}
                    {!!blocked && <Blocked />}
                  </StyledLink>
              </div>

              <h3 className="card__title">
                <StyledLink to={`/market3p/${nftAddress}/${tokenId}`} style={{"fontSize":_fSize}}>
                  {name}
                </StyledLink>
              </h3>

            </div>
            {!!priceTag && <div className="card__meta__right">
              <span>{priceTag}</span>
              <div className="nft__amount">
                <div className="tooltip">
                  <span className="tooltip__text">{symbol}</span>
                </div>
                <img src={img} alt={symbol} />
                {rate}
              </div>
            </div>}

          </div>
          </div>
        </div>
    )
  }
}

class Nft3pCardDummy extends Component{
  render(){
    let isMobile = !!this.props.isMobile;

    let {tokenId,nftAddress} = this.props?.data??{};
    let url = tokenId?`/market3p/${nftAddress}/${tokenId}`:"#";
    // let style = tokenId?{"background":"none"}:{};
    return(
        <div className={`nft__new_card card__margin ${!!isMobile?" nft__new_card__sm":""} dummy_card`}>
          <div className="nft__new_card__body">

          <Link to={url} className="card__cover">&nbsp;</Link>
            <div className="card__meta">

              <div className="card__meta__left">
                <div className="card__author">&nbsp;</div>

                <h3 className="card__title">&nbsp;</h3>
              </div>
              <div className="card__meta__right">
                <span>&nbsp;</span>
                <div className="nft__amount">&nbsp;</div>
              </div>

            </div>
          </div>
        </div>
    )
  }
}

export default NftCard


export {
  NftCardDummy,NftCardBlank,
  NftNeoCard,NftNeoCardDummy,
  Nft3pCard,Nft3pCardDummy,
}
