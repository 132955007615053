import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {Utils} from "../../helper"
import BuyBurn from "./burn-data.js"


const StyledLink  = styled.a.attrs({
    target : "_blank",
    rel : "noopener noreferrer"
})``;


class KTYBurnPool extends Component{
  constructor(props){
    super(props)
    this.state ={
      type:"buys",
    }
  }
  componentDidMount (){
    document.title = "KTY Burn Pool";

  }

  componentWillUnmount(){
    document.title = "Kraftly";
  }

  renderList = () =>{
    let { type } = this.state;

    return BuyBurn[type].map((item,i)=>{
      let{hash,kty,trx} = item;

      return <div className="buy__burn__row" key={hash+type+i}>
          <StyledLink href={Utils.tronscanHash(hash)}>{Utils.shortHash(hash)}</StyledLink>
          <p>{Utils.preety.ceil(kty,2)}</p>
          {type === "buys" && <p>{Utils.preety.ceil(trx,2)}</p>}
      </div>
    })

  }


  render(){
    let { type } = this.state;
    let totalKtyBought = BuyBurn.buys.reduce((a,b) => a + b.kty ,0 );

    let totalKty = BuyBurn[type].reduce((a,b) => a + b.kty ,0 );
    let totalTrx = BuyBurn[type].reduce((a,b) => a + b.trx ,0 )

    return(
      <main className="main">
    		<div className="container">

          <div className="row ">

    				<div className="col-12">
    					<ul className="breadcrumb">
    						<li className="breadcrumb__item"><Link to="/home">Home</Link></li>
                <li className="breadcrumb__item">Community</li>
                <li className="breadcrumb__item"><Link to="/kty-info">KTY</Link></li>
    						<li className="breadcrumb__item breadcrumb__item--active">Burn</li>
    					</ul>
    				</div>

            <div className="col-12 col-lg-8 offset-lg-2">
              <div className="buy__burn__title">
                <h1>Kraftly (KTY)</h1>
                <h3>Buy Back & Burn</h3>
              </div>
            </div>


            <div className="col-12 col-lg-8 offset-lg-2">
              <div className="buy__burn__toggle">
                  <button
                    className={type === "buys"?"active":""}
                    onClick={()=>this.setState({type:"buys"})}
                    >
                    Buy Order
                  </button>
                  <button
                    className={type === "burns"?"active":""}
                    onClick={()=>this.setState({type:"burns"})}
                    >
                    Burn History
                  </button>
              </div>
              <div className={`buy__burn__table ${type}`}>
                <div className="buy__burn__row buy__burn__row__head">
                    <p>Txn Id</p>
                    <p>{type === "buys"?"KTY Bought":"KTY Burned"}</p>
                    {type === "buys" && <p>TRX Used</p>}
                </div>

                {type === "burns" && <div className="buy__burn__row">
                    <p>Expected Next Burn</p>
                    <p>{Utils.preety.ceil(totalKtyBought - totalKty,2)}</p>
                </div>}

              {this.renderList()}

              <div className="buy__burn__row buy__burn__row__sum">
                  <p>Total Sum</p>
                  <p>{Utils.preety.ceil(totalKty,2)}</p>
                  {type === "buys" && <p>{Utils.preety.ceil(totalTrx,2)}</p>}
              </div>
              </div>
          </div>

          </div>
    		</div>
    	</main>
    )
  }
}

export default KTYBurnPool;
