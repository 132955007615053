const data = {
  "burns":[
    {
      "hash": "2238fd33dabcf77e64acd9099f662e73070ec77364255f663166bd12eea90a0c",
      "kty": 121865.25
    },
    {
      "hash": "84697abd2c3e60fec7f563eef12cedc9cfd1473ec444c00c6400eaad6b19f05e",
      "kty": 23659.71
    },
    {
      "hash": "09b47343d41f464b846c34c65caae96fbe9db2d95707a20ffe077fbdd2cae52a",
      "kty": 25496.612445
    },
    {
      "hash": "c5c5439ad272490a45cb298d17a72f5ed37e12b26cd3789adcfb512332c124bb",
      "kty": 154743.043076
    },
    {
      "hash": "62654bf5586e3a35e6d8809882a9053bc4dba193518938305cc0d7ea16975be6",
      "kty": 21541.09
    },
    {
      "hash": "3dd78c878e9f663db99d468fce14c1828b629c5cb040a68aceadec23f7283aee",
      "kty": 34819.293688
    },
    {
      "hash": "1a5960b3c429cf91e0999da0656ea3dd6c168e25e7f80300762075bd003e7276",
      "kty": 43440
    },
    {
      "hash": "9f99d98f57902802086a2c8f13b99bdf7aaa7795f9bc273018ae335d099b306c",
      "kty": 70202.350327
    },
    {
      "hash": "0585d70486f6ae7375e8d73f1cf0ed5a516d907dbf10dc67801716a169d23326",
      "kty": 18096.538773
    },
    {
      "hash": "84539a02e09f04847514378fa312c3f06aafa32cc7749ffb9c17a1b4902524b4",
      "kty": 62811.114547
    },
    {
      "hash": "e7dcd7cfe1f02a9d670fdcef3338a809d61691241a0f5e91b6b6f5d46b067935",
      "kty": 140067,
    },
    {
      "hash": "1fbe04805a17af76ed5e6e9388d921fa04842f46fbd586bc77c0ec4cc39f43cf",
      "kty": 55345
    },
  ],

  "buys":[
    {
        // last burn order
        "hash": "3d88a39d1117833705d3cc9795ea1b4304dc407574f52f87e79e7fedfac16c3e",
        "trx": 15000,
        "kty": 91368.188278
    },
    {
        "hash": "6ce1be3a3d4f49af2cbf2d312fd7a143c60d3ee03ec60f2000f5176712cc5ac6",
        "trx": 4000,
        "kty": 30497.070411
    },
    {
        "hash": "570be44e1138eacf9f86bfd47eda4767ed9b798fe5a32c8b2e8365b99f760973",
        "trx": 2940,
        "kty": 23659.710814
    },
    {
        "hash": "ccd701a236aa2259a0c7edb388446659d8b8d9ee01fd96a4e74c6fdc621ca2aa",
        "trx": 3500,
        "kty": 25496.612445
    },
    {
        "hash": "cd8984e91d83f1155885cf2c86ca096e4a3e1022a5d1177ac8ce4c44e4ebcb82",
        "trx": 5000,
        "kty": 28582.59675
    },
    {
        "hash": "852e9517de0ca49a8ceaea76e020d4c387a5ba685c3b478902e7262c187e18be",
        "trx": 5000,
        "kty": 31906.938563
    },
    {
        "hash": "63eb770221fcc22b9619b3f84a526b1f157a58d734cf771e0e88c12a86c55c29",
        "trx": 1000,
        "kty": 6949.546344
    },
    {
        "hash": "b258aba96b6e8927660b774a14af8a3d810efc59bd5e7de091acc4a6c59d8d50",
        "trx": 5000,
        "kty": 35249.790271
    },
    {
        "hash": "4607098e5c4bc57ad4d3016920be4f5518ec4f0bbf134b3ac32b9d81522d1fa7",
        "trx": 5000,
        "kty": 39650.188801
    },
    {
        "hash": "f7fbfeb6dda9888bf094a765954857d5f24c30f337349f3b8aa0f31acc8275be",
        "trx": 1250,
        "kty": 6785.245003
    },
    {
        "hash": "76b1ebbcc4132b38b236ce6a8d8e30c260b6d2e1cd86f792bfb72909d86a816a",
        "trx": 1000,
        "kty": 5584.324272
    },
    {
        "hash": "c298fc04c6fcd5ef6ff3e296c68e8cbb1352c52d3912fe5cb90db5b094156728",
        "trx": 1238.25,
        "kty": 6201.173652
    },
    {
        "hash": "5efacb8ca01d174fbe01daaec438614726b8451194c5ca379905c5d1c992dc05",
        "trx": 1440,
        "kty": 7456.82842
    },
    {
        "hash": "eae3eeff257dd08bed8f52afa59b12201cf99e81a46c0b19a57562bef510c6ff",
        "trx": 1500,
        "kty": 7879
    },
    {
        "hash": "c97d6f6a14724cf7c635567d1fbb3a032e9f014e475d6809b48283589083955d",
        "trx": 95.19,
        "kty": 500
    },
    {
        "hash": "2111083c3141d2970882e03fc1e4e81f38ea0cec8d991818525b56376de42259",
        "trx": 2462.62,
        "kty": 13341.404752
    },
    {
        "hash": "a784c39038f10d190a29fbb65f239cd56e44f1b556942c4088c1af24e9459dd9",
        "trx": 2274.57,
        "kty": 11947.484237
    },
    {
        "hash": "5d9e8f9749b63f08837d496c9d82bf7899b071a68fdd698e34092f17169a85da",
        "trx": 1775,
        "kty": 9030.404699
    },
    {
        "hash": "c4583463c4ec4a503300a41f848c2db9cf955597f3936576605db544be33e5c6",
        "trx": 7490.952,
        "kty": 26700
    },
    {
        "hash": "f61024df9b2382b60cc8d26dacf41e91aad692824ad999e1160b0f2237c75d7e",
        "trx": 2604.99,
        "kty": 9285
    },
    {
        "hash": "cc0fa56453097004b4d0ffde1ee09b7740c73eb24b9f5d1ddc8a34f79a4503b1",
        "trx": 10.87,
        "kty": 35
    },
    {
        "hash": "0647fb25b77dbc73665e1c37efcd5f7c9952c0661246e13f5e451f3499e019ef",
        "trx": 2230.452,
        "kty": 7410.377085
    },
    {
        "hash": "1b52330cd5baa846236a9399a22d0ef81a36fd276a0bc895f6ecace886cfcd90",
        "trx": 4087.41753,
        "kty": 14311.686026
    },
    {
        "hash": "c23e9bd2e950b1614a649ef927a48a923a7178d5e1cd332383a91f2015f535cc",
        "trx": 5748.47,
        "kty": 20489.268995
    },
    {
        "hash": "dc45fcad296a8a238ec09e07dba6c6bd65ef0b8eb93e0dae84ac04741e609b96",
        "trx": 9577.116,
        "kty": 35400
    },
    {
        "hash": "9fc5652ba5ba1dc2f7893140dd8fc76d2fbca1c7b08cefdcfa82698a438d4f84",
        "trx": 5825,
        "kty": 18096.538773
    },

    {
        "hash": "9459ca15fce45ae2a5ad9117425367ed7a7db221575af60eaa606b2b8fc50dca",
        "trx": 25603.5,
        "kty": 62811.114547
    },

    {
        "hash": "6299e1e9f020ceb16d6c1aa662ca4e6c9d88a7e9448b0627e83ba965b5abb62e",
        "trx": 5000,
        "kty": 12860.832317
    },
    {
        "hash": "880e6a45c1b84e1f51540bc0cf945ec5a26d76d2809808e6a49333ecbc901b19",
        "trx": 5000,
        "kty": 13805.355968
    },
    {
        "hash": "9204225dbe56f0b7c0bbaf0ab009f2de58886ac339df038299ba83d2d2485e83",
        "trx": 5000,
        "kty": 12177.605189
    },
    {
        "hash": "6960ee6468c059a8b1e277fbd499b267ab7f7f6f6975d33a143579f5e0e8a2eb",
        "trx": 2000,
        "kty": 4118.315883
    },
    {
        "hash": "1204e0de2492bd6b00871ee993ff71ed4d5760f2ca5f32cf3eb0efad295ed5ce",
        "trx": 5000,
        "kty": 10602.153739
    },
    {
        "hash": "3d0c4c1e94e5d1e7002a46e4550b43546538296f0c440fd39a897e18f5bb7476",
        "trx": 5000,
        "kty": 11173.712291
    },
    {
        "hash": "15c81c60ba533273ce0bf6de3ae0be13694a8ca1028cfa501c159ccd26e6a0d2",
        "trx": 3000,
        "kty": 5582.717124
    },
    {
        "hash": "51ccee537742735243c83be1d888d0a7c12094077cbf40d6eb93fcd3dba88ddc",
        "trx": 5000,
        "kty": 9403.277022
    },
    {
        "hash": "666f88f6c6a6918a85cd93717bc2da5dfa0aeb127e120f6fb2ea8f4328f94595",
        "trx": 5000,
        "kty": 8964.685785
    },
    {
        "hash": "ef44aa9d5b36c41fcc151e88f4ebfb9a37233e8855f6fa68deb1b70e48b270b4",
        "trx": 5000,
        "kty": 9294.726152
    },
    {
        "hash": "a3cc6d0e18839cfcfe07e92a6b4bb3603c0b3aaa4942611e6f2d9ea4380d592e",
        "trx": 3000,
        "kty": 5182.770437
    },
    {
        "hash": "9961eebae7a233e4aed7548ab71adac36cc4ced5f9cc7210415e7b956d21a6b0",
        "trx": 5000,
        "kty": 9018.109574
    },
    {
        "hash": "2a31619991287810c3a7610dc6788267feff14ad8ea076b47eb63574081c4497",
        "trx": 5000,
        "kty": 8199.832368
    },
    {
        "hash": "5970a4525b3459ae92c3beb12c43042558cdca140e61af083cd6d51d0728fdf6",
        "trx": 5000,
        "kty": 8842.721463
    },
    {
        "hash": "67018a23312812e6aaaced4e985b1dc717283f47083daa82cc131edd8e7cd4b1",
        "trx": 2000,
        "kty": 3342.517821
    },
    {
        "hash": "e1f235d799b184e3a3d3db88ea5ca56c242916bf003e50d059fe030befbc2bcf",
        "trx": 2000,
        "kty": 3414.891999
    },
    {
        "hash": "ba765175a50a23ec0a373fd0a871fa90b8abb9ad89582914139b79bd6b5bf8eb",
        "trx": 500,
        "kty": 820.228427
    },
    {
        "hash": "9c27d63c061a2fd517f24ad159d55efb8683803109644b5358cd633534d2d99e",
        "trx": 2000,
        "kty": 3272.221087
    },
    {
        "hash": "9a3a6e627cc9d447c1951cbcdc904865efb0b8f800a3cf34a13c34face5770ff",
        "trx": 5000,
        "kty": 7823.258965
    },
    {
        "hash": "efe4948632c040785fdacba92791fd9ea5515aeebaa7069fe24d870617f0f83c",
        "trx": 1000,
        "kty": 2063.612654
    },
    {
        "hash": "ebea4c2a8a638e4b33f22c045ba20f78ee9c0c631ed10c7bc6a8431f1d17b422",
        "trx": 3000,
        "kty": 6801.440495
    },
    {
        "hash": "74882060519e320fa92874d4534292cd51ea324834c8c81303ab8f50f8329048",
        "trx": 1000,
        "kty": 2227.630331
    },
    {
        "hash": "e6fad636013428e0fbec1779ccdfa6d5804a2febd9f2df6a3981251a04b23a4e",
        "trx": 2000,
        "kty": 4534.645762
    },
    {
        "hash": "b08b91aa7cfb30768adc9821b64a93dc484096272fea4e1b2ffec9fbcd4c5848",
        "trx": 1600,
        "kty": 3296.591151
    },
    {
        "hash": "4216c383fefa6cb4a739712f8264531b303d602ea6e51dae520b45efc04d44fd",
        "trx": 2000,
        "kty": 3402.579843
    },
    {
        "hash": "abc81d35e19eda110d7d13d597c95fa166a1d424c03c7ad38ebac855dc6274a3",
        "trx": 5000,
        "kty": 9235.311855
    },
    {
        "hash": "94d472b3b448afcd6c4d745991c11d06c4efb2fbcb50744f943809d2651f4be5",
        "trx": 10000,
        "kty": 14596.60815
    },
    {
        "hash": "c7bf96960ba78525c033e93d58bf21ab928ecb3539428088d2f5ab715ff1fc65",
        "trx": 1300,
        "kty": 1368.748027
    }
]

}
export default data
