
import React,{useEffect} from 'react'
import { BrowserRouter as Router, Route, Switch,useLocation} from 'react-router-dom'

import * as helper from '../helper'
import * as pages from './'

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

const App = (props) => {
    return (
        <Router >
          <ScrollToTop />
            <Switch>
                  <Route path="/3d/knft/:tokenId" exact component={pages.KnftCert} />
                  <Route path="/3d/marbel/:tokenId" exact component={pages.Planet} />
                  <Route path="/3d/planet" exact component={pages.Galaxy} />
                  <Route path="/3d/meta" exact component={pages.Metaverse} />
                  <Route component={helper.PageNotFound} />

            </Switch>
        </Router>
    )
}

export default App
