let allMeta = [
    {
        "count": 257,
        "trait": [
            {
                "key": "sun edition",
                "items": 5
            },
            {
                "key": "justin sun",
                "items": 1
            },
            {
                "key": "candace",
                "items": 12
            },
            {
                "key": "3 eyes",
                "items": 20
            },
            {
                "key": "gol d roger",
                "items": 3
            },
            {
                "key": "big mom",
                "items": 5
            },
            {
                "key": "gas mask",
                "items": 3
            },
            {
                "key": "mask",
                "items": 94
            },
            {
                "key": "pipe",
                "items": 31
            },
            {
                "key": "ashley",
                "items": 2
            },
            {
                "key": "bite lips",
                "items": 4
            },
            {
                "key": "gun",
                "items": 1
            },
            {
                "key": "face cut",
                "items": 3
            },
            {
                "key": "strapes",
                "items": 5
            },
            {
                "key": "dope",
                "items": 15
            },
            {
                "key": "rainbow",
                "items": 6
            },
            {
                "key": "gold chain",
                "items": 8
            },
            {
                "key": "demon",
                "items": 9
            },
            {
                "key": "cigar",
                "items": 33
            },
            {
                "key": "bittorrent",
                "items": 3
            },
            {
                "key": "btt",
                "items": 3
            },
            {
                "key": "silver chain",
                "items": 3
            },
            {
                "key": "pepe",
                "items": 2
            },
            {
                "key": "hanky",
                "items": 4
            },
            {
                "key": "ghost",
                "items": 6
            },
            {
                "key": "cigarette",
                "items": 7
            },
            {
                "key": "amber",
                "items": 3
            },
            {
                "key": "ape nft",
                "items": 1
            },
            {
                "key": "ape",
                "items": 1
            },
            {
                "key": "tron",
                "items": 2
            },
            {
                "key": "trx",
                "items": 2
            },
            {
                "key": "double",
                "items": 1
            }
        ]
    },
    {
        "count": 465,
        "gender": [
            {
                "key": "female",
                "items": 72
            },
            {
                "key": "male",
                "items": 393
            }
        ]
    },
    {
        "count": 492,
        "color": [
            {
                "key": "white",
                "items": 96
            },
            {
                "key": "pink",
                "items": 39
            },
            {
                "key": "black",
                "items": 47
            },
            {
                "key": "red",
                "items": 86
            },
            {
                "key": "brown",
                "items": 37
            },
            {
                "key": "blue",
                "items": 121
            },
            {
                "key": "purple",
                "items": 71
            },
            {
                "key": "orange",
                "items": 46
            },
            {
                "key": "green",
                "items": 124
            },
            {
                "key": "yellow",
                "items": 86
            },
            {
                "key": "milky",
                "items": 10
            },
            {
                "key": "mix",
                "items": 24
            },
            {
                "key": "golden",
                "items": 6
            },
            {
                "key": "rainbow",
                "items": 15
            },
            {
                "key": "grey",
                "items": 1
            },
            {
                "key": "ice",
                "items": 1
            }
        ]
    },
    {
        "count": 249,
        "shades": [
            {
                "key": "pink",
                "items": 17
            },
            {
                "key": "purple",
                "items": 10
            },
            {
                "key": "black",
                "items": 142
            },
            {
                "key": "star",
                "items": 25
            },
            {
                "key": "heart",
                "items": 16
            },
            {
                "key": "white",
                "items": 10
            },
            {
                "key": "yellow",
                "items": 1
            },
            {
                "key": "green",
                "items": 3
            },
            {
                "key": "rainbow",
                "items": 2
            },
            {
                "key": "snorkeling",
                "items": 8
            },
            {
                "key": "grey",
                "items": 13
            },
            {
                "key": "blue",
                "items": 2
            }
        ]
    },
    {
        "count": 344,
        "hat": [
            {
                "key": "red",
                "items": 17
            },
            {
                "key": "horn",
                "items": 25
            },
            {
                "key": "pirate",
                "items": 17
            },
            {
                "key": "tribal",
                "items": 16
            },
            {
                "key": "nurse",
                "items": 21
            },
            {
                "key": "santa",
                "items": 31
            },
            {
                "key": "student",
                "items": 6
            },
            {
                "key": "thief",
                "items": 1
            },
            {
                "key": "black",
                "items": 43
            },
            {
                "key": "magic",
                "items": 36
            },
            {
                "key": "king",
                "items": 9
            },
            {
                "key": "joker",
                "items": 30
            },
            {
                "key": "police",
                "items": 14
            },
            {
                "key": "brown",
                "items": 25
            },
            {
                "key": "thug",
                "items": 10
            },
            {
                "key": "pink",
                "items": 5
            },
            {
                "key": "green",
                "items": 13
            },
            {
                "key": "chef",
                "items": 8
            },
            {
                "key": "rainbow",
                "items": 1
            },
            {
                "key": "pepe",
                "items": 2
            },
            {
                "key": "blue",
                "items": 6
            },
            {
                "key": "yellow",
                "items": 1
            },
            {
                "key": "fly",
                "items": 3
            },
            {
                "key": "construction",
                "items": 4
            },
            {
                "key": "hair",
                "items": 1
            },
            {
                "key": "bandana",
                "items": 1
            },
            {
                "key": "purple",
                "items": 2
            }
        ]
    },
    {
        "count": 165,
        "profession": [
            {
                "key": "devil",
                "items": 25
            },
            {
                "key": "pirate",
                "items": 17
            },
            {
                "key": "nurse",
                "items": 20
            },
            {
                "key": "santa",
                "items": 29
            },
            {
                "key": "student",
                "items": 6
            },
            {
                "key": "thief",
                "items": 1
            },
            {
                "key": "king",
                "items": 9
            },
            {
                "key": "police",
                "items": 14
            },
            {
                "key": "scuba diver",
                "items": 2
            },
            {
                "key": "thug",
                "items": 12
            },
            {
                "key": "chef",
                "items": 8
            },
            {
                "key": "racer",
                "items": 2
            },
            {
                "key": "snorkeling",
                "items": 8
            },
            {
                "key": "astronaut",
                "items": 4
            },
            {
                "key": "army",
                "items": 4
            },
            {
                "key": "construction",
                "items": 4
            }
        ]
    },
    {
        "count": 66,
        "moustache": [
            {
                "key": "true",
                "items": 66
            }
        ]
    },
    {
        "count": 32,
        "beard": [
            {
                "key": "true",
                "items": 32
            }
        ]
    },
    {
        "count": 94,
        "pattern": [
            {
                "key": "true",
                "items": 94
            }
        ]
    }
]


export default allMeta
