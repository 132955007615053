import React, { Component,Fragment } from 'react'
import styled from 'styled-components'
import api from "../../api"
import {Utils} from "../../helper"
import tronWeb from "../../tronweb"

import {UserBody} from "../../components/profile-body";
import Collection from "../../components/collection/index.jsx";
import Created from "../../components/created/index.jsx";
import Activity from "../../components/activity/index.jsx";
import OnSale from "../../components/onSale/index.jsx";
import Following from "./following"
import Liked from "./liked"

import {MenuSelect3} from "../../frag"


const NavBtn = styled.button.attrs(props=>({
  className: props.active?"active":""
}))``


class AuthorProfile extends Component {
  constructor(props) {
      super(props)
      let tabs = ["onsale","collection","likes","following","created","activity"]
      const urlParams = new URLSearchParams(window.location.search);
      let tab = urlParams.get('tab')
      tab = tabs.includes(tab)?tab:"created";


      let address = this.props.match.params.address||"";
      address = tronWeb.isAddress(address)?address:"";
      this.state = {
        tab,
        address,
        myAddress:"",
        user:{},
        isUser:false,
      }
  }
  componentDidMount (){
    document.title = "Profile || Kraftly";
    if (this.state.address) {
      this.fetchUserDetails()
    }

    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);
      // console.log(account);
      const {address} = account
      this.setState({myAddress:address})
    })

  }

  componentWillUnmount(){
    document.title = "Kraftly";
  }
  componentDidUpdate(prevProps) {
    let address = this.props.match.params.address||"";
    address = tronWeb.isAddress(address)?address:"";
   if(this.state.address !== address){
     this.setState({address,user:{},isUser:false},()=>{
       if(!!address){
         this.fetchUserDetails()
       }
     })
   }
   const urlParams = new URLSearchParams(window.location.search);
   let tab = urlParams.get('tab')
   let tabs = ["onsale","collection","likes","following","created","activity"]

   if(tab && tab !== this.state.tab && tabs.includes(tab)){
     this.setState({tab})
   }
 }

  fetchUserDetails = async() =>{
    let {address} = this.state
    try {
      let res = await api.getUser({address})
      let user = res.data.result;
      user = user || {};
      this.setState({user,isUser:true})
    } catch (e) {
      this.setState({isUser:true})
      // console.log(e.response);
    }
  }

  handleNavBtn = (tab) =>{
    if (window.history.pushState) {
      window.history.pushState(null, null,`${window.location.pathname}?tab=${tab}`);
    }
    this.setState({tab})
  }

    render() {
      let {tab,address,user,isUser,myAddress} = this.state;
      let options = {onsale:"On Sale",collection:"Collection",created:"Created",likes:"Likes",following:"Following",activity:"Activity"};
      let isSmall = window.innerWidth < 768;
        return (
          <UserBody
            handler = {this.fetchUserDetails}
            data={{user,isUser,address}}
            key={user.address || "user"}
            >
            <div className="profile">
              {!!isSmall && <MenuSelect3 options={options} value={tab} onOption={(e)=>this.handleNavBtn(e)}/>}



  						<ul className="nav nav-tabs profile__tabs" id="profile__tabs" role="tablist">
                {!isSmall && <Fragment>
                <li>
  								<NavBtn
                    active={tab === "onsale"}
                    onClick={()=>this.handleNavBtn("onsale")}>
                    On Sale
                  </NavBtn>
  							</li>

  							<li>
  								<NavBtn
                    active={tab === "collection"}
                    onClick={()=>this.handleNavBtn("collection")}>
                    Collection
                  </NavBtn>
  							</li>
                <li>
  								<NavBtn
                    active={tab === "created"}
                    onClick={()=>this.handleNavBtn("created")}>
                    Created
                  </NavBtn>
  							</li>
                <li>
  								<NavBtn
                    active={tab === "likes"}
                    onClick={()=>this.handleNavBtn("likes")}>
                    Likes
                  </NavBtn>
  							</li>
                <li>
  								<NavBtn
                    active={tab === "following"}
                    onClick={()=>this.handleNavBtn("following")}>
                    Following
                  </NavBtn>
  							</li>
                <li>
  								<NavBtn
                    active={tab === "activity"}
                    onClick={()=>this.handleNavBtn("activity")}>
                    Activity
                  </NavBtn>
  							</li>
                </Fragment>}
  						</ul>
  					</div>
            <div className="tab-content">
              {tab === "onsale" && isUser && <OnSale data={{user,address}}/>}
              {tab === "collection" && isUser && <Collection data={{user,address}}/>}
              {tab === "likes" && isUser && <Liked data={{user,myAddress}}/>}
              {tab === "following" && isUser && <Following data={{user,myAddress}}/>}
              {tab === "created" && isUser && <Created data={{address}}/>}
              {tab === "activity" && isUser && <Activity data={{address}}/>}
            </div>



          </UserBody>
        )
    }
}

export default AuthorProfile
